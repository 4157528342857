import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip, TooltipProps } from '@mui/material'
import buildLogger from '../../util/logger'

const logger = buildLogger('InfoTooltip')
export interface InfoTooltipProps {
  readonly placement?: TooltipProps['placement']
  readonly description: string
}

export const InfoTooltip: React.FC<React.PropsWithChildren<InfoTooltipProps>> = ({ description, placement }) => {
  return (
    <Tooltip title={description} placement={placement} sx={{ color: (theme) => theme.palette.action.active }}>
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  )
}
