import { countries } from 'countries-list'
import { AccountAddressFragment, AccountContactFragment, AccountDetailFragment } from '../generated/graphql'

type CountryAbbr = keyof typeof countries | ''
type CountryOption = {
  name: string
  abbr: CountryAbbr
}

const noneOptions: CountryOption = {
  name: '(None)',
  abbr: '',
}
export const allCountriesOptions: readonly CountryOption[] = Object.entries(countries)
  .map(([abbr, country]) => ({ name: country.name, abbr: abbr as CountryOption['abbr'] }))
  .sort((a, b) => a.name.localeCompare(b.name))

export const allCountryOptionsWithEmpty = [noneOptions].concat(allCountriesOptions)

export const getCountryDisplayName = (abbr: string): string => {
  if (abbr in countries) {
    return countries[abbr as keyof typeof countries].name
  } else return noneOptions.name
}

export const DEFAULT_COUNTRY_ABBR: CountryAbbr = 'US'

export function isAccountAddressPresent(address?: AccountAddressFragment | null): boolean {
  return !!(
    address &&
    (address?.city ||
      address?.country ||
      address?.state ||
      address?.streetAddressLine1 ||
      address?.streetAddressLine2 ||
      address?.zipcode)
  )
}

export function isAccountAddressComplete(address?: AccountAddressFragment | null): boolean {
  return !!(
    address &&
    address?.city &&
    address?.country &&
    address?.state &&
    address?.streetAddressLine1 &&
    address?.zipcode
  )
}

export function mapUpsertContactVariableUsingAccountAddress(
  contactAddress: AccountContactFragment,
  accountDetail: AccountDetailFragment
) {
  const useAvalara = false
  return {
    skipValidation: !useAvalara,
    strict: useAvalara,
    accountId: accountDetail.id,
    externalId: contactAddress.externalId,
    phoneNumber: contactAddress.phoneNumber ?? '',
    title: contactAddress.title ?? '',
    email: contactAddress.email,
    firstName: contactAddress.firstName,
    lastName: contactAddress.lastName ?? '',
    id: contactAddress.id,
    address: accountDetail.address,
  }
}
