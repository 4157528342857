import React, { ForwardedRef, useCallback, useMemo } from 'react'
import { Checkbox, CheckboxProps, FormControlLabel, Tooltip } from '@mui/material'
import { Draft } from 'immer'
import { useAtom } from 'jotai'
import { selectAtom, useUpdateAtom } from 'jotai/utils'
import buildLogger from '../../util/logger'
import { JotaiForm } from '../state/useJotaiForm'

const logger = buildLogger('JotaiMuiCheckbox')

export type JotaiMuiCheckboxProps<T> = {
  atomSelector: (form: T) => boolean | undefined | null
  atomUpdater: (value: boolean, draft: Draft<T>) => void
  checkboxProps?: CheckboxProps
  disabledExplanation?: string
  fieldLabel?: string
  form: JotaiForm<T>
  hidden?: boolean
}

function JotaiMuiCheckbox<T>(
  { atomSelector, atomUpdater, checkboxProps, disabledExplanation, fieldLabel, form, hidden }: JotaiMuiCheckboxProps<T>,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element {
  const { atom } = form
  const setAtom = useUpdateAtom(atom)
  const selectorAtom = useMemo(() => selectAtom(atom, atomSelector), [atom, atomSelector])
  const [atomValue] = useAtom(selectorAtom)

  const checkbox = (
    <div>
      <Checkbox
        inputRef={ref}
        color="primary"
        onChange={useCallback(
          (e) => {
            const value = e.target.checked
            setAtom((draft) => {
              atomUpdater(value, draft)
            })
          },
          [atomUpdater, setAtom]
        )}
        disabled={!!disabledExplanation}
        aria-disabled={!!disabledExplanation}
        checked={atomValue || false}
        aria-checked={atomValue || false}
        name={checkboxProps?.name ? checkboxProps.name : fieldLabel?.toLowerCase()}
        role="checkbox"
        {...checkboxProps}
      />
    </div>
  )

  if (!fieldLabel) {
    return <Tooltip title={disabledExplanation || ''}>{checkbox}</Tooltip>
  }

  return (
    <Tooltip title={disabledExplanation || ''}>
      <FormControlLabel
        sx={
          hidden
            ? { display: 'none' }
            : {
                height: '54px',
              }
        }
        disabled={!!disabledExplanation}
        control={checkbox}
        label={fieldLabel}
      />
    </Tooltip>
  )
}

export default React.forwardRef(JotaiMuiCheckbox)
