import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

export interface ConfirmDialogProps {
  readonly title?: string
  readonly message: string
  readonly onCancel?: () => void
  readonly onConfirm?: () => void
  readonly color?: ButtonProps['color']
  readonly confirmText?: string
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const { message, onConfirm, onCancel } = props

  return (
    <Dialog
      data-testid={ConfirmDialog.name}
      open={true}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title ?? 'Are you sure?'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <Button color={props.color ?? 'primary'} onClick={onConfirm} variant="contained">
          {props.confirmText ?? 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
