import * as React from 'react'
import { useContext } from 'react'
import { Grid, GridProps } from '@mui/material'
import { GridSize } from '@mui/material/Grid/Grid'
import { BillyResponsiveGridContainerContext } from './billyResponsiveGridContainer'

type BillyResponsiveGridItemProps = {
  autoSize?: boolean
  children?: React.ReactNode
  gridProps?: GridProps
  lg?: GridSize
  md?: GridSize
  sm?: GridSize
  xl?: GridSize
  xs?: GridSize
}

const BillyResponsiveGridItem = ({
  autoSize,
  children,
  gridProps,
  sm = 6,
  md = sm,
  lg = md,
  xl = lg,
  xs = 12,
}: BillyResponsiveGridItemProps): JSX.Element => {
  const { isSm, isMd, isLg, isXl } = useContext(BillyResponsiveGridContainerContext)

  if (autoSize) {
    md = 4
    lg = 3
    xl = 2
  }

  let size = xs
  if (isSm) {
    size = sm
  } else if (isMd) {
    size = md
  } else if (isLg) {
    size = lg
  } else if (isXl) {
    size = xl
  }
  return (
    <Grid item xs={size} {...gridProps}>
      {children}
    </Grid>
  )
}

export default BillyResponsiveGridItem
