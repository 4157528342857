import React from 'react'
import { formatQuantity } from '../../../util/priceTier'

const QuantityCell = ({ value }: { value: string }): JSX.Element => {
  if (value === null || value === undefined) {
    return <div />
  }
  return <div>{formatQuantity(value)}</div>
}

export default React.memo(QuantityCell)
