import React from 'react'
import Error from 'next/error'
import { Role } from '../../generated/graphql'
import { useUserTenantSession } from '../UserTenantSessionProvider/UserTenantSessionContext'
import { isRoleAllowed } from '../../util/roleUtils'

function ProtectedRoute(children: React.ReactNode, allowedRoles: Array<Role>): JSX.Element {
  const userTenantSession = useUserTenantSession()
  const userRole = userTenantSession.currentUser.role

  if (!isRoleAllowed(userRole, allowedRoles)) {
    return <Error statusCode={401} title={'Unauthorized access'} />
  }

  return <>{children}</>
}

export default ProtectedRoute
