import { AccountAddress } from '../../../generated/graphql'
import { getCountryDisplayName, isAccountAddressPresent } from '../../../util/address'
import { notEmpty } from '../../../util/array'

export default function AddressCell({ address }: { address?: AccountAddress | null }): JSX.Element {
  // TODO: we should use a library and internationalize address rendering.
  const showAddress = isAccountAddressPresent(address)
  return showAddress ? (
    <>
      {!!address?.streetAddressLine1 && (
        <>
          {address?.streetAddressLine1}
          <br />
        </>
      )}
      {!!address?.streetAddressLine2 && (
        <>
          {address?.streetAddressLine2}
          <br />
        </>
      )}
      {[address?.city, address?.state, address?.zipcode].filter(notEmpty).join(', ')}
      <br />

      {!!address?.country && <>{getCountryDisplayName(address?.country || '')}</>}
    </>
  ) : (
    <>None</>
  )
}
