import React, { useCallback, useContext, useMemo } from 'react'
import { CardContent, CardHeader, Grid, MenuItem } from '@mui/material'
import BillyCard from '../../components/card/billyCard'
import JotaiMuiSelect from '../../components/input/JotaiMuiSelect'
import { JotaiFormContext } from '../../components/state/jotaiFormProvider'
import { JotaiForm } from '../../components/state/useJotaiForm'
import { useJotaiUrqlQuery } from '../../components/state/useJotaiUrqlQuery'
import {
  DocumentMasterTemplateStatus,
  DocumentTemplateType,
  GetDocumentMasterTemplatesDocument,
  GetDocumentMasterTemplatesQuery,
  GetDocumentMasterTemplatesQueryVariables,
} from '../../generated/graphql'
import { AmendSubscriptionPageState } from './AmendmentOrderPage/AmendmentOrderPage'
import { NewOrderFormData } from './EditOrderPage/NewOrderPage'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'

function DocumentMasterTemplateCard({
  templateType = DocumentTemplateType.Order,
}: {
  templateType?: DocumentTemplateType
}): JSX.Element {
  const jotaiForm: JotaiForm<NewOrderFormData | AmendSubscriptionPageState> =
    useContext<JotaiForm<NewOrderFormData | AmendSubscriptionPageState>>(JotaiFormContext)

  const documentMasterTemplates = jotaiForm.useSelect(useCallback((form) => form.documentMasterTemplates, []))

  useJotaiUrqlQuery<
    NewOrderFormData | AmendSubscriptionPageState,
    GetDocumentMasterTemplatesQuery,
    GetDocumentMasterTemplatesQueryVariables
  >(
    useMemo(
      () => ({
        document: GetDocumentMasterTemplatesDocument,
        jotaiForm,
        onData: (data, draft) => {
          const masterTemplates = data.documentMasterTemplates?.filter(
            (mt) => mt.status === DocumentMasterTemplateStatus.Active && mt.type === templateType
          )
          draft.documentMasterTemplates = deepMutable(masterTemplates)
        },
      }),
      [jotaiForm]
    )
  )

  if (!documentMasterTemplates.length) {
    return <></>
  }

  return (
    <BillyCard>
      <CardHeader title="Document Template" />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <JotaiMuiSelect
              atomSelector={(form) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                form.orderDetail?.documentMasterTemplate?.id || form.orderDetail?.documentMasterTemplateId
              }
              atomUpdater={(value, draft) => {
                if (templateType === DocumentTemplateType.CancelAndRestructure) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  draft.orderDetail.documentMasterTemplateId = documentMasterTemplates.filter(
                    (mt) => mt.id == value
                  )[0].id
                } else {
                  draft.orderDetail.documentMasterTemplate = documentMasterTemplates.filter((mt) => mt.id == value)[0]
                }
              }}
              errorPath="orderDetail.documentMasterTemplateId"
              form={jotaiForm}
              textFieldProps={{
                label: 'Document Template',
              }}
            >
              {[...documentMasterTemplates]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((documentMasterTemplate) => (
                  <MenuItem value={documentMasterTemplate.id || ''} key={documentMasterTemplate.id || ''}>
                    {documentMasterTemplate.name}
                    {documentMasterTemplate.isDefault && '(Default)'}
                  </MenuItem>
                ))}
            </JotaiMuiSelect>
          </Grid>
        </Grid>
      </CardContent>
    </BillyCard>
  )
}

export default DocumentMasterTemplateCard
