import React from 'react'
import { Tooltip, Typography, TypographyProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export interface TooltipTextProps extends TypographyProps {
  text: string
  maxLength: number
  component?: React.ElementType
}

const useStyle = makeStyles()((theme, _params, _classes) => ({
  truncate: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

const TooltipText: React.FC<React.PropsWithChildren<TooltipTextProps>> = ({
  text,
  children,
  maxLength,
  className,
  ...props
}) => {
  const { classes, cx } = useStyle()
  return (
    <Tooltip title={text.length < maxLength ? '' : text}>
      <Typography className={cx(classes.truncate, className)} {...props}>
        {children ?? text}
      </Typography>
    </Tooltip>
  )
}

export default TooltipText
