export function KuiKatGif({ hasInput }: { hasInput?: boolean }) {
  return (
    <figure
      style={{
        width: '38px',
        height: '38px',
        overflow: 'hidden',
        margin: 0,
      }}
    >
      <img
        src="/kuikat.gif"
        alt="kuikat running..."
        style={{
          width: '300%',
          margin: '-60% -50% -60% -100%',
          transform: `scaleX(${hasInput ? '-1' : '1'})`,
          transition: 'transform 0.2s',
        }}
      />
    </figure>
  )
}
