import JotaiMuiField from '@/components/input/JotaiMuiField'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { Draft } from 'immer'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { OrderItemRowProps, useOrderChargeRowStyles } from '../orderChargeRow'

export function LineItemGroupCell({ lineItemIndex }: OrderItemRowProps) {
  const { classes } = useOrderChargeRowStyles()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  return (
    <JotaiMuiField
      atomSelector={(form: NewOrderFormData) => form.orderDetail.lineItems[lineItemIndex]?.itemGroupId}
      atomUpdater={(value, draft: Draft<NewOrderFormData>) => {
        const lineItem = draft.orderDetail.lineItems[lineItemIndex]

        if (lineItem) {
          lineItem.itemGroupId = value
        }
      }}
      errorPath={`orderDetail.lineItems[${lineItemIndex}].itemGroupId`}
      form={jotaiForm}
      textFieldProps={{
        className: classes.fieldShrink,
        size: 'small',
      }}
    />
  )
}
