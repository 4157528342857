import { Box } from '@mui/system'
import { ChargeRowEditIconButton } from '../orderChargeRow'
import { memo } from 'react'

type QuantityViewCellProps = {
  showPencil: boolean
  showResetQuantity: boolean
  resetToQuantity: number | undefined
  displayQuantity: string
  disabledExplanation?: string
  onClickPencil?: () => void
}
function QuantityViewCellWithoutMemo({
  showPencil,
  showResetQuantity,
  resetToQuantity,
  displayQuantity,
  disabledExplanation,
  onClickPencil,
}: QuantityViewCellProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {showResetQuantity && (
        <Box
          sx={{
            color: 'error.main',
            textDecoration: 'line-through',
            mr: '8px',
          }}
        >
          ({resetToQuantity})
        </Box>
      )}
      <div>{displayQuantity}</div>

      {showPencil && (
        <ChargeRowEditIconButton
          tooltipTitle={disabledExplanation}
          label="Edit Quantity"
          onClick={onClickPencil}
          disabled={!!disabledExplanation}
        />
      )}
    </Box>
  )
}

export const QuantityViewCell = memo(QuantityViewCellWithoutMemo)
