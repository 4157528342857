import { makeStyles } from 'tss-react/mui'

export const useAmendmentOrderPageStyles = makeStyles()((theme) => ({
  heading: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: 500,
  },
  dataHeading: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: '#2D2D2D',
  },
  fieldGridItem: {
    display: 'flex',
  },
  rowLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  positiveValue: {
    color: '#4CAF50',
  },
  negativeValue: {
    color: '#D93651',
  },
}))
