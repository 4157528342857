import buildLogger from '@/util/logger'
import { Grid, InputBase } from '@mui/material'
import { Stack } from '@mui/system'
import { useCallback, useEffect, useState } from 'react'
import { QuickCastOption } from './QuickCastBarManager'
import { QuickCastSearchInput } from './QuickCastSearchInput'
const logger = buildLogger('QuickCastOptions')

export type QuickCastUserInput = Record<string, string>

export function QuickCastOptionsSearcher({
  inputValue,
  options,
  setInputValue,
  setSelectedIndex,
  selectedIndex,
  gqlCallParams,
  onConfirm,
  children,
}: {
  inputValue: string
  options: QuickCastOption[]
  setInputValue: (value: string) => void
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  onConfirm?: (userInput: QuickCastUserInput) => void
  onChange?: (option: QuickCastOption) => void
  gqlCallParams?: string[]
  children?: JSX.Element
}) {
  const showVariableChips = !!gqlCallParams?.length

  const [currentUserInput, setCurrentUserInput] = useState<QuickCastUserInput>({})

  const optionsParityStr = JSON.stringify(options.map((option) => option.id))
  useEffect(() => {
    setSelectedIndex(0)
  }, [optionsParityStr, setSelectedIndex])

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onConfirm?.(currentUserInput)
        e.stopPropagation()
      }
      if (e.key === 'ArrowDown') {
        e.stopPropagation()
        setSelectedIndex(options.length - 1 > selectedIndex ? selectedIndex + 1 : selectedIndex)
      }
      if (e.key === 'ArrowUp') {
        e.stopPropagation()
        setSelectedIndex(selectedIndex - 1 >= 0 ? selectedIndex - 1 : selectedIndex)
      }
    },
    [selectedIndex, options, currentUserInput, onConfirm, setSelectedIndex]
  )
  return (
    <Stack>
      <QuickCastSearchInput
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
        }}
        onKeyDown={onInputKeyDown}
      >
        {showVariableChips ? (
          <Grid
            container
            item
            xs={8}
            spacing={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              overflowX: 'hidden',
              gap: '0.5rem',
            }}
            wrap="nowrap"
            style={{ width: '100%' }}
          >
            {gqlCallParams?.map((param) => {
              return (
                <InputBase
                  key={param}
                  sx={{
                    pt: 1.5,
                    verticalAlign: 'baseline',
                    display: 'flex',
                    maxWidth: '80px',
                    fontSize: '0.875rem',
                    lineHeight: '1.5rem',
                  }}
                  size="small"
                  placeholder={param}
                  inputProps={{
                    tabIndex: 1,
                  }}
                  onChange={(e) => {
                    setCurrentUserInput((prev) => ({ ...prev, [param]: e.target.value }))
                  }}
                  onKeyDown={onInputKeyDown}
                />
              )
            })}
          </Grid>
        ) : (
          <></>
        )}
      </QuickCastSearchInput>
      {children}
    </Stack>
  )
}
