export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined && !!value
}

export function onlyUnique<TValue>(value: TValue, index: number, self: TValue[]) {
  return self.indexOf(value) === index
}

export function isSubsetArray(array: Array<unknown | undefined>, target: Array<unknown | undefined>) {
  return target.every((v) => array.includes(v))
}

export function indexDGPRows<T>(data: T[]): (T & { id: string })[] {
  // general function that takes any data and adds an id to it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data?.map((row, index) => ({ ...row, id: (row as any)?.id ?? index.toString() }))
}
