import BillyDataGridPro from '@/components/table/billyDataGridPro'
import StatusChip from '@/components/table/cells/statusChip'
import TableEmptyContent from '@/components/table/tableEmptyContent'
import { ImmutableElectronicSignatureAuditLogFragment } from '@/generated/graphql'
import { notEmpty } from '@/util/array'
import { UnixtimeSeconds } from '@/util/datetime/luxon/dateUtil'
import buildLogger from '@/util/logger'
import { GridColDef } from '@mui/x-data-grid-pro'
const logger = buildLogger('EsignEmailAuditTrailTable')

export type EsignEmailAuditTrailTableProps = {
  electronicSignatureAuditLogs?: ImmutableElectronicSignatureAuditLogFragment[]
}

export function EsignEmailAuditTrailTable({
  electronicSignatureAuditLogs,
}: EsignEmailAuditTrailTableProps): JSX.Element {
  const rows = electronicSignatureAuditLogs?.filter(notEmpty) ?? []

  const auditTrailColumns: GridColDef[] = [
    {
      headerName: 'Initiated By',
      field: 'initiatedBy',
      align: 'left',
      flex: 6,
    },
    {
      headerName: 'Status',
      field: 'status',
      align: 'left',
      renderCell: (params) => <StatusChip value={params.value} />,
      flex: 3,
    },
    {
      headerName: 'Created On',
      field: 'createdOn',
      align: 'left',
      valueFormatter: (params) => UnixtimeSeconds.toDateTimeStr(params.value),
      flex: 3,
    },
  ]
  return (
    <BillyDataGridPro
      variant="stacked"
      columns={auditTrailColumns}
      rows={rows}
      slots={{
        noRowsOverlay: TableEmptyContent,
        noResultsOverlay: TableEmptyContent,
      }}
      slotProps={{
        noRowsOverlay: { message: 'No audit trail logs.' },
        noResultsOverlay: { message: 'No audit trail logs.' },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        },
      }}
    />
  )
}
