import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { useTenantTimeZone } from '../../../components/UserTenantSessionProvider/useTenantTimeZone'
import JotaiMuiDateField from '../../../components/input/JotaiMuiDateField'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { OrderType } from '../../../generated/graphql'
import { unixTimeSecondsToLuxonWithTz } from '../../../util/datetime/luxon/dateUtil'

export function OrderAmendOrCancelDate() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const tenantTZ = useTenantTimeZone()
  const { minDate, maxDate, label } = jotaiForm.useSelect(
    useCallback(
      (form) => ({
        minDate: unixTimeSecondsToLuxonWithTz(form.orderDetail.currentSubscription?.startDate, tenantTZ)?.toISO(),
        maxDate: unixTimeSecondsToLuxonWithTz(form.orderDetail.currentSubscription?.endDate, tenantTZ)?.toISO(),
        label:
          form.orderDetail.orderType === OrderType.Amendment
            ? 'Amendment Date'
            : form.orderDetail.orderType === OrderType.Cancel
            ? 'Cancellation Date'
            : undefined,
      }),
      [tenantTZ]
    )
  )
  const isLoading = jotaiForm.useSelect(
    useCallback((form) => {
      return !!form.urqlIsFetching?.DryRunCancellation || !!form.urqlIsFetching?.DryRunAmendment
    }, [])
  )

  return label ? (
    <Grid item xs={4}>
      <JotaiMuiDateField
        atomSelector={(form) => form.orderDetail.startDate}
        atomUpdater={(value, draft) => {
          if (value && draft.orderDetail.startDate !== value) {
            draft.orderDetail.startDate = value
          }
        }}
        errorPath="orderDetail.startDate"
        form={jotaiForm}
        datePickerProps={{
          label,
          minDate,
          maxDate,
        }}
        disabled={isLoading}
      />
    </Grid>
  ) : (
    <></>
  )
}
