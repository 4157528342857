import ActionButton from '@/components/button/actionButton'
import { Alert, CardHeader, FormControlLabel, Grid, Switch } from '@mui/material'
import BillyCard from '../../../../components/card/billyCard'
import LineItemsEditTable, { LineItemsTableOrderType } from '../../LineItemsEditTable/LineItemsEditTable'
import { useOrderDiscountDialog } from '../../OrderItemsDiscount/OrderDiscountDialog'
import { NewOrderFormData } from '../NewOrderPage'
import { useState } from 'react'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { Feature } from '@/generated/graphql'
import { WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { useYupError } from '@/util/jotai'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'

export function OrderEditItemsCard({
  orderType = 'NEW',
  parentLocator,
}: { orderType?: LineItemsTableOrderType } & WithCustomizationLocator) {
  const onToggleOrderDiscountDialog = useOrderDiscountDialog<NewOrderFormData>({
    orderType,
  })
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()

  const [hidePlan, setHidePlan] = useState(false)
  const error = useYupError(jotaiForm, 'orderDetail.lineItems')
  return (
    <BillyCard aria-label="Order Edit Items Card">
      <CardHeader
        title="Order Items"
        action={
          <>
            <WithDynamicFeatureFlag feature={Feature.UiLineItemsEditTable} status="enabled">
              <FormControlLabel
                control={<Switch />}
                label={hidePlan ? 'Show Plan Info' : 'Hide Plan Info'}
                value={hidePlan}
                onChange={() => setHidePlan(!hidePlan)}
                name="hidePlan"
              />
            </WithDynamicFeatureFlag>
            {orderType === 'NEW' && (
              <ActionButton
                key={'Apply Target Price'}
                buttonData={{
                  label: 'Apply Target Price',
                  buttonProps: {
                    onClick: onToggleOrderDiscountDialog,
                    variant: 'text',
                  },
                }}
              />
            )}
          </>
        }
      />

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          {!!error && <Alert severity="error">{error}</Alert>}
          <LineItemsEditTable orderType={orderType} hidePlan={hidePlan} parentLocator={parentLocator} />
        </Grid>
      </Grid>
    </BillyCard>
  )
}
