import BillyGridCell from '@/components/grid/billyGridCell'
import BillyLink from '@/components/link/billyLink'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { billableCurrencyFormat } from '@/util/currencyUtil'
import { Grid, Typography, useTheme } from '@mui/material'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'

function CancellationOrderPageDrawer({
  orderDetail: { subscriptionId, currency, totalListAmount, totalAmount, orderMetrics },
}: {
  orderDetail: Pick<
    CommonOrderFormPageState['orderDetail'],
    'subscriptionId' | 'currency' | 'totalListAmount' | 'totalAmount' | 'orderMetrics'
  >
}): JSX.Element {
  const theme = useTheme()
  const shouldRecalculateTotals = useShouldRecalculateTotals()

  const renewalOrderPageDrawerData = [
    {
      label: 'Subscription being Cancelled',
      description: <BillyLink nextProps={{ href: `/subscriptions/${subscriptionId}` }}>{subscriptionId}</BillyLink>,
    },
    {
      label: 'Total List Amount',
      description: (
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightMedium}
          style={{
            color: totalListAmount ? (totalListAmount > 0 ? theme.palette.success.main : theme.palette.error.main) : '',
          }}
        >
          {billableCurrencyFormat({
            currency,
            value: totalListAmount ?? 0,
          })}
        </Typography>
      ),
    },
    {
      label: 'Total Order Amount',
      description: shouldRecalculateTotals ? (
        <LineItemCellLoadingIndicator />
      ) : (
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightMedium}
          style={{
            color: totalAmount ? (totalAmount > 0 ? theme.palette.success.main : theme.palette.error.main) : '',
          }}
        >
          {billableCurrencyFormat({
            currency,
            value: totalAmount ?? 0,
          })}
        </Typography>
      ),
    },
    {
      label: 'Delta ARR',
      description: (
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightMedium}
          style={{
            color:
              orderMetrics && orderMetrics.deltaArr !== 0
                ? orderMetrics.deltaArr > 0
                  ? theme.palette.success.main
                  : theme.palette.error.main
                : '',
          }}
        >
          {orderMetrics && orderMetrics.deltaArr > 0 ? '+' : ''}
          {billableCurrencyFormat({ currency, value: orderMetrics?.deltaArr ?? 0 })}
        </Typography>
      ),
      hide: !orderMetrics?.deltaArr,
    },
  ]

  const getCellOrder = (length: number, index: number, alwaysLastRow?: boolean): number => {
    const getLastRowOrder = (length: number): number => {
      return length - (length % 4) + 2
    }
    return alwaysLastRow ? getLastRowOrder(length) : index + 1
  }

  return (
    <>
      <Typography variant="overline" fontWeight={theme.typography.fontWeightRegular}>
        CANCEL
      </Typography>
      <Typography variant="h6" fontWeight={theme.typography.fontWeightMedium}>
        Order Summary
      </Typography>
      <Grid container rowGap={2} pt={2}>
        {renewalOrderPageDrawerData.map((cell, index) => (
          <BillyGridCell
            gridRow={1}
            {...cell}
            key={index}
            order={getCellOrder(renewalOrderPageDrawerData.length, index)}
          />
        ))}
      </Grid>
    </>
  )
}

export default CancellationOrderPageDrawer
