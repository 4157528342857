import { useOrderQueueBatchCustomFieldEditsDialog } from '@/components/CustomFields/EditCustomFieldsDialog'
import { useNewOrderLineItemDialog } from '@/pageComponents/orders/lineItemDialog'
import { useOrderLineItemRateCardAttributeSelectDialog } from '@/pageComponents/rateCard/RateCardAttributeSelectDialog'
import buildLogger from '@/util/logger'
import { createContext, useCallback, useContext, useMemo, useRef } from 'react'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { useLineItemDiscountDialog } from '../LineItemDiscount/LineItemDiscountDialog'
import { useLineItemPriceOverrideDialog } from '../LineItemPriceOverride/LineItemPriceOverrideDialog'
import { LineItemsTableOrderType } from './LineItemsEditTable'
import { useLineItemArrDialog } from '@/pageComponents/orders/LineItemArr/LineItemArrDialog'
const logger = buildLogger('LinItemEditContextProvider')

function useLineItemEditActionsBuilder(orderType: LineItemsTableOrderType) {
  const [toggleLineItemDiscountDialog, setDialogLineItemDiscountIndex] = useLineItemDiscountDialog({
    orderType,
  })

  const onClickDiscount = useCallback(
    (index: number) => {
      setDialogLineItemDiscountIndex(index)
      toggleLineItemDiscountDialog()
    },
    [setDialogLineItemDiscountIndex, toggleLineItemDiscountDialog]
  )

  const [toggleLineItemPriceOverrideDialog, setDialogLineItemPriceOverrideIndex] = useLineItemPriceOverrideDialog({
    orderType,
  })

  const onClickPriceOverride = useCallback(
    (index: number) => {
      setDialogLineItemPriceOverrideIndex(index)
      toggleLineItemPriceOverrideDialog()
    },
    [setDialogLineItemPriceOverrideIndex, toggleLineItemPriceOverrideDialog]
  )

  const [toggleLineItemArrOverrideDialog, setDialogLineItemArrOverrideIndex] = useLineItemArrDialog()
  const onClickArrOverride = useCallback(
    (index: number) => {
      setDialogLineItemArrOverrideIndex(index)
      toggleLineItemArrOverrideDialog()
    },
    [setDialogLineItemArrOverrideIndex, toggleLineItemArrOverrideDialog]
  )

  const [toggleOrderQueueBatchCustomFieldEditsDialog] = useOrderQueueBatchCustomFieldEditsDialog({})

  const onClickCustomField = useCallback(
    (index: number) => {
      toggleOrderQueueBatchCustomFieldEditsDialog(index)
    },
    [toggleOrderQueueBatchCustomFieldEditsDialog]
  )

  const toggleLineItemDialog = useNewOrderLineItemDialog<NewOrderFormData>({ orderType })

  const onEditRateAttribute = useOrderLineItemRateCardAttributeSelectDialog()

  const value = useMemo(
    () => ({
      orderType,
      onClickDiscount,
      onClickPriceOverride,
      onClickCustomField,
      onClickChargeName: toggleLineItemDialog,
      onEditRateAttribute,
      onClickArrOverride,
    }),
    [
      onClickDiscount,
      onClickPriceOverride,
      onClickCustomField,
      toggleLineItemDialog,
      onEditRateAttribute,
      onClickArrOverride,
      orderType,
    ]
  )
  const contextRef = useRef(value)
  return contextRef
}

export type OrderItemRowActionHandlers = {
  onClickDiscount?: (orderLineIndex: number) => void
  onClickPriceOverride?: (orderLineIndex: number) => void
  onClickCustomField?: (orderLineIndex: number) => void
  onClickChargeName?: (orderLineIndex: number) => void
  onEditRateAttribute?: (orderLineIndex: number) => void
  onClickArrOverride?: (orderLineIndex: number) => void
}

const LineItemEditContext = createContext<OrderItemRowActionHandlers>({})

export const LineItemEditContextProvider = ({
  children,
  orderType,
  tableContext = {},
}: {
  children: React.ReactNode
  orderType: LineItemsTableOrderType
  tableContext: LineItemTableContextProps
}) => {
  const contextRef = useLineItemEditActionsBuilder(orderType)

  return (
    <LineItemTableContext.Provider value={tableContext}>
      <LineItemEditContext.Provider value={contextRef.current}>{children}</LineItemEditContext.Provider>
    </LineItemTableContext.Provider>
  )
}

export const useLineItemEditActions = () => useContext(LineItemEditContext)

export type LineItemTableContextProps = {
  currency?: string
}
const LineItemTableContext = createContext<LineItemTableContextProps>({})

export const useLineItemTableContext = () => useContext(LineItemTableContext)
