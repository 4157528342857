import { useCallback } from 'react'
import { toUpperSnakeCase } from '../state/useDynamicFeatureFlag'
import { useBillyRouter } from './useBillyRouter'

export type WithQueryParam = {
  queryParamName: string
}

export function getQueryKey(idField: string, key: string) {
  return `${idField}_${key}`
}

export function useTableSearchQueryParam(tableId: string): [string, (newValue: string) => void] {
  const searchQueryParam = getQueryKey(tableId, 'search')
  const pageQueryParam = getQueryKey(tableId, 'page')

  const router = useBillyRouter()
  const searchValue = (router.query[searchQueryParam] as string) ?? ''

  const setQuery = useCallback(
    (newTabValue: string) => {
      const query = {
        ...router.query,
      }
      delete query[pageQueryParam]
      if (newTabValue) {
        query[searchQueryParam] = newTabValue
      } else {
        delete query[searchQueryParam]
      }
      void router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { scroll: false, shallow: true }
      )
    },
    [router, searchQueryParam, pageQueryParam]
  )

  return [searchValue, setQuery]
}

export function useTablePageQueryParam(tableId: string): [string, (newValue: string) => void] {
  return useQueryParam(getQueryKey(tableId, 'page'))
}

export function useQueryParam(queryParamName: string): [string, (newValue: string) => void] {
  const router = useBillyRouter()
  const queryValue = (router.query[queryParamName] as string) ?? ''

  const setQuery = useCallback(
    (newTabValue: string) => {
      const query = {
        ...router.query,
      }
      if (newTabValue) {
        query[queryParamName] = newTabValue
      } else {
        delete query[queryParamName]
      }
      void router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { scroll: false, shallow: true }
      )
    },
    [router, queryParamName]
  )

  return [queryValue, setQuery]
}

export function formatQueryName(id: string) {
  return toUpperSnakeCase(id).toLowerCase()
}
