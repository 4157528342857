import { CustomFieldDefinitionFragment, CustomFieldType } from '../../generated/graphql'
import { DeepMutable } from '../SchemaForm/DeepMutable'
import { ISchema } from '../SchemaForm/SchemaFormEngine'
import { EditCustomFieldsInstanceType } from './EditCustomFieldsDialog'

const getCustomFieldType = (fieldType: CustomFieldType) => {
  if (fieldType === CustomFieldType.Picklist) return 'select'
  if (fieldType === CustomFieldType.MultiselectPicklist) return 'select-multi'
  return 'string'
}

const formatCustomFieldOptions = (options: readonly (string | null)[]) => {
  return options.map((item) => ({
    id: item as string,
    label: item as string,
  }))
}

export type CustomFieldBuilderDefinition = Pick<
  CustomFieldDefinitionFragment,
  'fieldName' | 'fieldType' | 'options' | 'fieldLabel'
>

export function createCustomFieldsSchema({
  customFieldDefinitions,
}: {
  customFieldDefinitions: ReadonlyArray<CustomFieldBuilderDefinition>
}) {
  const schema: DeepMutable<ISchema<EditCustomFieldsInstanceType>> = { fields: {} }

  customFieldDefinitions.forEach((customFieldDefinition) => {
    schema.fields[customFieldDefinition.fieldName] = {
      type: getCustomFieldType(customFieldDefinition.fieldType),
      label: customFieldDefinition.fieldLabel || customFieldDefinition.fieldName,
      xs: 6,
      md: 6,
      items: formatCustomFieldOptions(customFieldDefinition?.options || []),
    }
  })
  return schema
}
