import {
  FC,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { v4 as uuidV4 } from 'uuid'
import buildLogger from '../../util/logger'
import { useBillyRouter } from '../route/useBillyRouter'
import { SnackbarTypes } from '../snackBar/billySnackBar'
import { IActiveSnackbar } from './IActiveSnackbar'
import SnackbarAlertContainer from './SnackbarAlertContainer'

const logger = buildLogger('SnackbarHandler')

type snackbarOptions = {
  /**
   * used for async operations that needs to be persisted across route changes
   */
  persisting?: boolean
}

export type SnackbarHandler = Readonly<{
  inProgressAlert: (message: string, option?: snackbarOptions) => string
  successAlert: (message: string, option?: snackbarOptions) => string
  pushAlert: (message: string, type: SnackbarTypes, option?: snackbarOptions) => string
  dismissAlert: (key: string) => void
  dismissAllAlert: () => void
}>

interface SnackbarHandlerDelegateProps {
  readonly onSnackbarHandler: (snackbarHandler: SnackbarHandler) => void
}
export type SnackPack = readonly IActiveSnackbar[]
const SnackbarHandlerDelegate = memo(function SnackbarHandlerDelegate(props: SnackbarHandlerDelegateProps) {
  const { onSnackbarHandler } = props
  const router = useBillyRouter()
  const [snackPack, setSnackPack] = useState<SnackPack>([])
  const [persistingKeys, setPersistingKeys] = useState<readonly string[]>([])

  const handleOptions = (key: string, options: snackbarOptions = {}) => {
    const { persisting } = options
    if (persisting) {
      setPersistingKeys((keys) => [...keys, key])
    }
  }

  const replaceAlert = useCallback(
    (type: SnackbarTypes) => (message: string, option?: snackbarOptions) => {
      const key = uuidV4()
      handleOptions(key, option)
      setSnackPack([{ type, message, key }])
      return key
    },
    [setSnackPack]
  )

  const pushAlert = useCallback(
    (type: SnackbarTypes) => (message: string, option?: snackbarOptions) => {
      const key = uuidV4()
      handleOptions(key, option)
      setSnackPack((packs) => [...packs, { type, message, key }])
      return key
    },
    [setSnackPack]
  )

  const dismissAlert = useCallback(
    (key: string) => {
      setSnackPack((packs) => packs.filter((pack) => pack.key !== key))
    },
    [setSnackPack]
  )

  const dismissAllAlert = useCallback(() => {
    setSnackPack([])
  }, [setSnackPack])

  const dismissAllNonPersistingAlert = useCallback(() => {
    setSnackPack((packs) => packs.filter((pack) => persistingKeys.includes(pack.key)))
  }, [setSnackPack, persistingKeys])

  useEffect(() => {
    if (router?.route) {
      dismissAllNonPersistingAlert()
    }
  }, [router?.route, dismissAllNonPersistingAlert])

  useEffect(() => {
    onSnackbarHandler({
      inProgressAlert: replaceAlert('in-progress'),
      successAlert: replaceAlert('success'),
      pushAlert: (message: string, type: SnackbarTypes) => pushAlert(type)(message),
      dismissAlert,
      dismissAllAlert,
    })
  })

  return <SnackbarAlertContainer snackPack={snackPack} onClose={dismissAlert} />
})

const DEFAULT_FALLBACK: SnackbarHandler = {
  inProgressAlert: (message) => {
    console.info({ type: 'in-progress', message })
    return 'default progress'
  },
  successAlert: (message) => {
    console.info({ type: 'success', message })
    return 'default success'
  },
  pushAlert: (message, type) => {
    console.error({ type, message })
    return 'default push'
  },
  dismissAlert: (key) => console.info({ message: 'dismiss alert', key }),
  dismissAllAlert: () => console.info({ message: 'dismiss all alerts' }),
}

const SnackbarHandlerContext = createContext<SnackbarHandler>(DEFAULT_FALLBACK)

interface SnackbarHandlerProps {
  readonly children: ReactNode | FC<React.PropsWithChildren<unknown>> | JSX.Element
}

/**
 * Hook to allow any component in the app to create snackbars.
 */
export function useSnackbarHandler() {
  return useContext(SnackbarHandlerContext)
}

/**
 * Root SnackbarHandler for the app.  Allows any component to just
 * useSnackbarHandler on any promise and use snackbar alerts.
 *
 * It's designed to not trigger any app re-renders by only updating state in an
 * inner delegate, not the whole tree.
 */
export default memo(function SnackbarHandler(props: SnackbarHandlerProps) {
  const delegateHandlerRef = useRef<SnackbarHandler>(DEFAULT_FALLBACK)

  const snackbarHandler: SnackbarHandler = useMemo(() => {
    return {
      inProgressAlert: (message, option) => delegateHandlerRef.current.inProgressAlert(message, option),
      successAlert: (message, option) => delegateHandlerRef.current.successAlert(message, option),
      pushAlert: (message, type, option) => delegateHandlerRef.current.pushAlert(message, type, option),
      dismissAlert: (key) => delegateHandlerRef.current.dismissAlert(key),
      dismissAllAlert: () => delegateHandlerRef.current.dismissAllAlert(),
    }
  }, [])

  return (
    <SnackbarHandlerContext.Provider value={snackbarHandler}>
      <>
        <SnackbarHandlerDelegate
          onSnackbarHandler={(snackbarHandler) => (delegateHandlerRef.current = snackbarHandler)}
        />
        {props.children}
      </>
    </SnackbarHandlerContext.Provider>
  )
})
