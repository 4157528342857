import React from 'react'
import { Button, Tooltip } from '@mui/material'
import Link from 'next/link'

export type NewItemProps = {
  text: string
  action?: () => void
  disabledExplanation?: string | false
  href?: string
  hide?: boolean
}
const TableNewItemAction: React.FC<React.PropsWithChildren<NewItemProps>> = (newItem) => {
  if (newItem.hide) {
    return <></>
  }

  return (
    <Tooltip title={newItem.disabledExplanation}>
      <div>
        {newItem.href ? (
          <Link href={newItem.href} passHref>
            <Button
              variant="outlined"
              role="link"
              onClick={newItem.action}
              disabled={!!newItem.disabledExplanation}
              color="primary"
            >
              {newItem.text}
            </Button>
          </Link>
        ) : (
          <Button variant="outlined" onClick={newItem.action} disabled={!!newItem.disabledExplanation} color="primary">
            {newItem.text}
          </Button>
        )}
      </div>
    </Tooltip>
  )
}
export default TableNewItemAction
