import React from 'react'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../../util/logger'
import clsx from 'clsx'
const logger = buildLogger('TermsContentView')

export type TermsContentViewProps = {
  dangerouslySetInnerHTML: string
}

export function TermsContentView({ dangerouslySetInnerHTML }: TermsContentViewProps): JSX.Element {
  const useStyles = makeStyles()((theme, _params, _classes) => ({
    content: {
      borderSize: 1,
      borderColor: theme.palette.text.disabled,
      flexGrow: 1,
      borderStyle: 'none',
      '& > *:first-child': {
        marginTop: 0,
      },
      ...{
        '& td, & th': {
          border: '1px solid',
          padding: '8px',
        },
      },
    },
  }))

  const { classes } = useStyles()
  return <div dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }} className={clsx(classes.content)} />
}
