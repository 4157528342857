import { Button, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLoginFormStyles } from '../../pageComponents/login/loginForm'
import { currentDateTimeInTenantTz } from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import BillyCard from '../card/billyCard'
import CenteredLayout from '../layout/centeredLayout'
import { useRouter } from 'next/router'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
const logger = buildLogger('BillyErrorPage')
export type BillyErrorPageProps = {
  statusCode?: number
}

export const Billy404Page = ({ notFoundMessage }: { notFoundMessage?: string }) => {
  const { classes } = useLoginFormStyles({ skipTransition: false })
  return (
    <>
      <title>Page Not Found</title>
      <CenteredLayout currentYear={currentDateTimeInTenantTz().year.toString()}>
        <div>
          <BillyCard className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Page Not Found</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {notFoundMessage ||
                      'We are sorry, the page you requested cannot be found. The URL may be misspelled or the page you are seeking may no longer be available.'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" variant="contained" size="large" fullWidth href="/">
                    Home
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </BillyCard>
        </div>
      </CenteredLayout>
    </>
  )
}

export const Billy500Page: React.FC<React.PropsWithChildren<Record<string, never>>> = () => {
  const { classes } = useLoginFormStyles({ skipTransition: false })
  return (
    <>
      <title>Internal Server Error</title>
      <CenteredLayout currentYear={currentDateTimeInTenantTz().year.toString()}>
        <div>
          <BillyCard className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Internal Server Error</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Our team should have been notified to correct the problem. If this problem persists, please contact
                    Subskribe.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" variant="contained" size="large" fullWidth href="/">
                    Home
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </BillyCard>
        </div>
      </CenteredLayout>
    </>
  )
}

export const BillyErrorPage: React.FC<React.PropsWithChildren<Record<string, never>>> = () => {
  const { classes } = useLoginFormStyles({ skipTransition: false })
  return (
    <>
      <title>Unknown Error</title>
      <CenteredLayout currentYear={currentDateTimeInTenantTz().year.toString()}>
        <div>
          <BillyCard className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Unknown Error</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>The page you requested had an error.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button color="primary" variant="contained" size="large" fullWidth href="/">
                    Home
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </BillyCard>
        </div>
      </CenteredLayout>
    </>
  )
}

export const RedirectToHomePage = () => {
  const router = useRouter()
  const errorHandler = useErrorHandler()

  useEffect(() => {
    router.push('/').catch(errorHandler)
  }, [router, errorHandler])

  return <></>
}
