import ActionButton from '@/components/button/actionButton'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback, useState } from 'react'

type ApprovalFlowAdminBypassDialogProps = {
  onSubmit?: (decision: boolean) => Promise<void> | void
}

type DialogProps = Omit<WithModalParams, 'onSubmit'> & ApprovalFlowAdminBypassDialogProps

const formID = 'ApprovalFlowAdminBypassDialog'
function ApprovalFlowAdminBypassDialog({ open, onClose, onSubmit }: DialogProps): JSX.Element {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  function handleSubmit(decision: boolean) {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.(decision)
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Submit for Approval</DialogTitle>
      <DialogContent dividers>
        <Typography>Do you want to bypass all approvals?</Typography>
      </DialogContent>
      <DialogActions>
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: {
              variant: 'outlined',
              style: {
                margin: 0,
              },
            },
          }}
        />
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key="No"
          buttonData={{
            label: 'No',
            loading: isSubmitting,
            buttonProps: {
              variant: 'outlined',
            },
            onClick: () => handleSubmit(false),
          }}
        />

        <ActionButton
          key={'Yes'}
          buttonData={{
            label: 'Yes',
            loading: isSubmitting,
            onClick: () => handleSubmit(true),
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useApprovalFlowAdminBypassDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ApprovalFlowAdminBypassDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
