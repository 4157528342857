import { useCallback, useMemo } from 'react'
import { selectAtom, useAtomValue } from 'jotai/utils'
import buildLogger from '../../util/logger'
import JotaiReadValue from './jotaiReadValue'
import { JotaiForm } from './useJotaiForm'

const logger = buildLogger('JotaiReadArray')

type JotaiReadArrayProps<T, V> = {
  atomSelector: (form: T) => V[] | null | undefined
  form: JotaiForm<T>
  render: (value: V, index: number, arrayLength: number) => JSX.Element | undefined | null
  renderEmpty?: () => JSX.Element
}

export default function JotaiReadArray<T, V>({
  atomSelector,
  form,
  render,
  renderEmpty,
}: JotaiReadArrayProps<T, V>): JSX.Element {
  const atomSelectorLength = useMemo(
    () => selectAtom(form.atom, (form) => atomSelector(form)?.length),
    [atomSelector, form.atom]
  )

  const arrayLength = useAtomValue(atomSelectorLength)
  const elementSelector = useCallback((i: number) => (form: T) => atomSelector(form)?.[i], [atomSelector])

  return useMemo(
    () => (
      <>
        {arrayLength && arrayLength > 0 ? (
          [...Array(arrayLength)].map((_, i) => (
            <JotaiReadValue
              key={i}
              atomSelector={elementSelector(i)}
              form={form}
              render={(value) => render(value as V, i, arrayLength)}
            />
          ))
        ) : (
          <></>
        )}
        {arrayLength === 0 && renderEmpty && renderEmpty()}
        {arrayLength === undefined && <div>Loading...</div>}
      </>
    ),
    [arrayLength, elementSelector, form, render, renderEmpty]
  )
}
