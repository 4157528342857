import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature, useGetSettingQuery } from '@/generated/graphql'

export const useIsUpdateOrderStartDateEnabled = () => {
  const isUpdateOrderStartDateEnabled = useDynamicFeatureFlag(Feature.UpdateOrderStartDate)
  const [settingResponse] = useGetSettingQuery()

  // TODO: Remove the feature flag check but keep the tenant setting check when the feature is ready
  return isUpdateOrderStartDateEnabled && settingResponse.data?.setting?.isUpdateOrderStartDateEnabled
}
