import { useRouter } from 'next/router'

const noAuthPaths = [
  '/login',
  '/legal',
  '/approval-flow-status',
  '/payments',
  '/automatic-payment',
  '/invoice-payment',
  '/doc-commenting',
  '/doc-view',
  '/sales-room',
]
export function routeRequiresAuthentication(path: string) {
  return noAuthPaths.every((noAuthPath) => !path.startsWith(noAuthPath))
}

interface AppAuthRouterProps {
  readonly authenticated: JSX.Element
  readonly unauthenticated: JSX.Element
}

export default function AppAuthRouter(props: AppAuthRouterProps) {
  const router = useRouter()

  if (routeRequiresAuthentication(router.pathname)) {
    return props.authenticated
  }

  return props.unauthenticated
}
