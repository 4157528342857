import { makeStyles } from 'tss-react/mui'

export const useOrderPageFormStyles = makeStyles()((theme, _params, _classes) => ({
  formRow: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  formLabel: {
    color: theme.customPalette.textGray,
    fontSize: 12,
    fontWeight: 600,
  },
  cardSplitter: {
    marginTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  fieldGridItem: {
    display: 'flex',
  },
  smallNumberField: {
    width: 180,
  },
  rampDatesCard: {
    backgroundColor: theme.customPalette.backgroundColor2,
    maxWidth: 350,
    padding: theme.spacing(2),
  },
  rampIntervalText: {
    color: theme.customPalette.textGray,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '19px',
    marginRight: theme.spacing(1.25),
  },
  rampDateText: {
    color: theme.customPalette.textDark,
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 600,
  },
  ramp: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
    padding: 8,
    borderBottom: `1px solid ${theme.customPalette.borderColor}`,
    '&:first-of-type': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
      border: 'none',
      marginBottom: 0,
    },
  },
  rowLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  linkText: {
    fontWeight: 550,
    fontSize: '0.875rem',
  },
}))
