import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import { Grid, Popover, PopoverProps, Theme, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'

export const useMouseOverPopoverProps = () => {
  const [open, setOpenedPopover] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined)

  const popoverAnchor = useRef(null)
  const onOpen = () => {
    const shouldQueueOpen = !open && !timeoutId
    if (open) {
      setOpenedPopover(true)
    }
    if (shouldQueueOpen) {
      const id = setTimeout(() => {
        setOpenedPopover(true)
      }, 200)
      setTimeoutId(id)
    }
  }

  const onClose = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(undefined)
    }
    setOpenedPopover(false)
  }

  const popoverPaperSx = {
    maxWidth: 480,
    maxHeight: 240,
    minWidth: 160,
    pointerEvents: 'auto',
    padding: (theme: Theme) => theme.spacing(2),
  }

  const popoverProps: PopoverProps = {
    open,
    anchorEl: popoverAnchor.current,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    disableRestoreFocus: true,
    slotProps: {
      root: {
        sx: {
          pointerEvents: 'none',
        },
      },
      paper: {
        onMouseEnter: onOpen,
        onMouseLeave: onClose,
        sx: popoverPaperSx,
      },
    },
  }

  return {
    open,
    onOpen,
    onClose,
    popoverAnchor,
    anchorProps: {
      ref: popoverAnchor,
      onMouseEnter: onOpen,
      onMouseLeave: onClose,
    },
    popoverProps,
    popoverPaperSx,
  }
}

export function WithMouseOverPopoverCard({ cells, children }: { cells: GridCellProps[]; children: React.ReactNode }) {
  const { popoverProps, anchorProps } = useMouseOverPopoverProps()

  return (
    <>
      <Typography {...anchorProps}>{children}</Typography>
      <Popover {...popoverProps}>
        <Grid container spacing={1}>
          {cells.map((cell, i) => (
            <BillyGridCell key={i} {...cell} />
          ))}
        </Grid>
      </Popover>
    </>
  )
}
