import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { WithUrql } from '../../../components/state/useJotaiUrqlQuery'
import useJotaiForm, { JotaiForm } from '../../../components/state/useJotaiForm'
import { LineItemsTableOrderType } from '../LineItemsEditTable/LineItemsEditTable'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { OrderDiscountDialogContent } from './OrderDiscountDialogContent'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'

export type OrderDiscountDialogState = Pick<NewOrderFormData, 'orderDetail'> & WithUrql
export type OrderDiscountDialogProps<F> = {
  jotaiForm: JotaiForm<F>
  orderType: LineItemsTableOrderType
}

type DialogProps<F> = WithModalParams & OrderDiscountDialogProps<F>
const formID = 'OrderDiscountDialog'
const OrderDiscountDialog = <F extends OrderDiscountDialogState>({
  open,
  onClose,
  onSubmit,
  jotaiForm,
  orderType,
}: DialogProps<F>) => {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  function handleSubmit() {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.()
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Apply Target Price to Order</DialogTitle>
      <DialogContent dividers>
        <OrderDiscountDialogContent jotaiForm={jotaiForm} orderType={orderType} />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Apply',
            color: 'primary',
            loading: isSubmitting,
            onClick: handleSubmit,
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useOrderDiscountDialog<F extends OrderDiscountDialogState>({
  orderType,
}: {
  orderType: LineItemsTableOrderType
}) {
  const [, , toggleModal] = useModalsContext()
  const orderJotaiForm = useJotaiFormContext<F>()

  const orderDetail = orderJotaiForm.useSelect(useCallback((form) => form.orderDetail, []))

  const priceOverrideDraftForm = useJotaiForm<OrderDiscountDialogState>(
    useMemo(
      () => ({
        defaultValue: {
          orderDetail,
        },
      }),
      [orderDetail]
    )
  )

  const { queueDryRun } = useDryRunActions()

  const onSavePriceOverride = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: priceOverrideDraftForm.atom,
        onSubmit: (value: OrderDiscountDialogState) => {
          orderJotaiForm.set((form) => {
            form.orderDetail.lineItems = value.orderDetail.lineItems
          })
          queueDryRun()
        },
      }),
      [priceOverrideDraftForm.atom, orderJotaiForm, queueDryRun]
    )
  )
  useModal<DialogProps<OrderDiscountDialogState>>({
    component: OrderDiscountDialog,
    schema: {
      key: formID,
      modalProps: {
        jotaiForm: priceOverrideDraftForm,
        orderType,
        onSubmit: onSavePriceOverride,
      },
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
