import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import buildLogger from '../../util/logger'
import { BillyErrorPage } from '../error/BillyErrorPage'

const logger = buildLogger('BillyErrorBoundary')

interface IProps {
  readonly children: JSX.Element
}

export default function BillyErrorBoundary(props: IProps) {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        logger.error({ error })
        return <BillyErrorPage />
      }}
    >
      {props.children}
    </ErrorBoundary>
  )
}
