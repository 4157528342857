import * as React from 'react'
import { useState } from 'react'
import { Grid, GridProps } from '@mui/material'
import useResizeObserver from 'use-resize-observer'
import buildLogger from '../../util/logger'

const logger = buildLogger('BillyResponsiveGridContainer')

export const BillyResponsiveGridContainerContext = React.createContext({
  isXs: false,
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false,
})

type BillyResponsiveGridContainerProps = {
  autoWidthByXs?: number
  children?: React.ReactNode
  gridProps?: GridProps
  lgWidth?: number
  maxCols?: number
  mdWidth?: number
  smWidth?: number
  xlWidth?: number
}

const BillyResponsiveGridContainer = ({
  autoWidthByXs,
  children,
  gridProps,
  maxCols = 12,
  smWidth = 400,
  mdWidth = smWidth,
  lgWidth = mdWidth,
  xlWidth = lgWidth,
}: BillyResponsiveGridContainerProps): JSX.Element => {
  const [width, setWidth] = useState(1)
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width }) => {
      window.requestAnimationFrame(() => {
        if (width) {
          setWidth(width)
        }
      })
    },
  })

  if (autoWidthByXs) {
    if (maxCols >= 2) {
      smWidth = autoWidthByXs * 2
    } else {
      smWidth = 9999
    }
    if (maxCols >= 3) {
      mdWidth = autoWidthByXs * 3
    } else {
      mdWidth = 9999
    }
    if (maxCols >= 4) {
      lgWidth = autoWidthByXs * 4
    } else {
      lgWidth = 9999
    }
    if (maxCols >= 6) {
      xlWidth = autoWidthByXs * 6
    } else {
      xlWidth = 9999
    }
  }

  const isXs = width < smWidth
  const isSm = !isXs && width < mdWidth
  const isMd = !isXs && !isSm && width < lgWidth
  const isLg = !isXs && !isSm && !isMd && width < xlWidth
  const isXl = !isXs && !isSm && !isMd && !isLg && width >= xlWidth

  return React.useMemo(
    () => (
      <BillyResponsiveGridContainerContext.Provider value={{ isXs, isSm, isMd, isLg, isXl }}>
        <Grid ref={ref} container {...gridProps}>
          {children}
        </Grid>
      </BillyResponsiveGridContainerContext.Provider>
    ),
    [children, gridProps, isLg, isMd, isSm, isXl, isXs, ref]
  )
}

export default BillyResponsiveGridContainer
