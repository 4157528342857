import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useCopy } from '../button/CopyButton'
import ActionButton from '../button/actionButton'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  codeblock: {
    background: theme.customPalette.codeblockBackgroundColor,
    borderRadius: '3px',
    wordWrap: 'break-word',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5, 1),
    fontFamily: 'Courier, monospace',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
}))

type ClipboardDialogProps = {
  title: string
  open: boolean
  dialogText: string
  clipboardText: string
  handleClose: () => any
}

function ClipboardDialog({ title, open, dialogText, clipboardText, handleClose }: ClipboardDialogProps): JSX.Element {
  const { classes } = useStyles()

  const [showAlert, setShowAlert] = useState(false)
  const [clipboardSuccess, setClipboardSuccess] = useState(false)

  const [copyText] = useCopy({
    onSuccess: () => setClipboardSuccess(true),
  })

  const copyContentToClipboard = () => {
    copyText(clipboardText)
    setShowAlert(true)
  }

  return (
    <>
      <Dialog open={open} maxWidth={'sm'} scroll={'paper'} disableEscapeKeyDown={true}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          {showAlert && clipboardSuccess && <Alert severity="success">Copied to the clipboard!</Alert>}
          {showAlert && !clipboardSuccess && <Alert severity="warning">Please try again.</Alert>}
          {dialogText}
          <Typography variant="body2" className={classes.codeblock}>
            {clipboardText}
          </Typography>
          <ActionButton
            buttonData={{
              label: 'Copy to clipboard',
              onClick: copyContentToClipboard,
              buttonProps: { variant: 'outlined', sx: { marginLeft: '0 !important' } },
            }}
          />
        </DialogContent>
        <DialogActions>
          <ActionButton
            buttonData={{
              label: 'Close',
              onClick: () => {
                handleClose()
                setShowAlert(false)
                setClipboardSuccess(false)
              },
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ClipboardDialog
