import Big from 'big.js'
import { isArray } from 'lodash'
import {
  ActionType,
  AmendmentSubscriptionFragment,
  Charge,
  ChargeModel,
  ChargeType,
  GetOrderDetailQuery,
  LineItemFragment,
  SubscriptionFragment,
} from '../generated/graphql'
import { chargeHasNoQuantity } from '../pageComponents/orders/LineItemsEditTable/orderChargeRow'
import buildLogger from './logger'
import { mapPriceTier, mapTierToQuantity } from './priceTier'

const logger = buildLogger('chargeUtil')

const tieredChargeModels = [ChargeModel.Volume, ChargeModel.Tiered, ChargeModel.Block]

export function getLineItemCondition(lineItem: GetOrderDetailQuery['orderDetail']['lineItems'][0]) {
  const quantity = lineItem?.quantity
  const unitPriceAmount = lineItem?.charge.amount
  const chargeModel = lineItem?.charge.chargeModel
  const chargeType = lineItem?.charge.type

  const chargeTypeNeedsQuantity = [ChargeType.PercentageOf, ChargeType.Prepaid, ChargeType.Recurring]

  const chargeTypeWithoutQuantity = [ChargeType.Usage, ChargeType.OneTime]

  const allowDiscount =
    chargeTypeWithoutQuantity.includes(chargeType) || (chargeTypeNeedsQuantity.includes(chargeType) && !!quantity)

  const allowGoalSeeking =
    (chargeType === ChargeType.Usage && chargeModel === ChargeModel.PerUnit && !!unitPriceAmount) ||
    [ChargeModel.FlatFee, ChargeModel.RateCardLookup, ChargeModel.PerUnit].includes(chargeModel) ||
    chargeType === ChargeType.OneTime ||
    (chargeTypeNeedsQuantity.includes(chargeType) && !!quantity)

  return {
    allowDiscount,
    allowGoalSeeking,
  }
}

export function lookupChargeModel(chargeModel?: ChargeModel): string {
  switch (chargeModel) {
    case ChargeModel.Block:
      return 'Block'
    case ChargeModel.FlatFee:
      return 'Flat Fee'
    case ChargeModel.PerUnit:
      return 'Per Unit'
    case ChargeModel.Tiered:
      return 'Tiered'
    case ChargeModel.Volume:
      return 'Volume Discount'
    case ChargeModel.RateCardLookup:
      return 'Rate Card'
  }
  return ''
}

export function isQuantifiedByTier(chargeModel: ChargeModel) {
  return chargeModel === ChargeModel.Block
}

export function getDefaultQuantity(charge: Charge): number | undefined {
  if (chargeHasNoQuantity(charge)) {
    return undefined
  }
  if (isQuantifiedByTier(charge.chargeModel) && charge.priceTiers?.length) {
    return Big(mapPriceTier(charge.priceTiers).map(mapTierToQuantity)[0]).toNumber()
  }
  return charge.defaultQuantity ?? charge.minQuantity ?? 0
}

export function isTieredPricing(chargeModel: ChargeModel): boolean {
  return tieredChargeModels.includes(chargeModel)
}

export function isRecurringCharge(chargeType: ChargeType): boolean {
  return chargeType === ChargeType.Recurring || chargeType === ChargeType.Usage
}

export function isCustomCharge(chargeType: ChargeType, chargeModel: ChargeModel): boolean {
  return (
    (chargeType === ChargeType.OneTime || chargeType === ChargeType.Recurring) &&
    (chargeModel === ChargeModel.FlatFee || chargeModel === ChargeModel.PerUnit)
  )
}

export function isListPriceEditable(chargeType: ChargeType, chargeModel: ChargeModel): boolean {
  if (chargeType === ChargeType.Usage && chargeModel !== ChargeModel.RateCardLookup) {
    return true
  }
  return [ChargeType.OneTime, ChargeType.Recurring, ChargeType.Prepaid].includes(chargeType)
}

export function supportsQuantityConstraints(chargeType: ChargeType, chargeModel: ChargeModel): boolean {
  return (
    chargeType !== ChargeType.Usage &&
    (chargeType !== ChargeType.PercentageOf || chargeModel === ChargeModel.PerUnit) &&
    (chargeModel === ChargeModel.PerUnit || isTieredPricing(chargeModel) || chargeModel === ChargeModel.RateCardLookup)
  )
}

export function supportAmountConstraints(chargeType: ChargeType): boolean {
  return chargeType === ChargeType.PercentageOf
}

export const CHARGE_TYPES_ALLOW_ALIAS = [ChargeType.OneTime, ChargeType.Usage]

export type WithSubscriptions = {
  charges: SubscriptionFragment['charges']
  lineItems?: undefined
  subscriptionId?: string
  currentSubscription?: undefined
}

export type WithOrderLineItems = {
  lineItems: LineItemFragment[]
  charges?: undefined
  subscriptionId?: undefined
  currentSubscription?: AmendmentSubscriptionFragment | null
}

export function instanceOfWithSubscriptions(object): object is WithSubscriptions {
  return isArray(object.charges)
}

export function instanceOfWithOrderLineItems(object): object is WithOrderLineItems {
  return isArray(object.lineItems)
}

export type WithViewPageOptions = WithSubscriptions | WithOrderLineItems

export function reconcileLineItemAction({
  lineItem,
  matchingCharge,
}: {
  lineItem: LineItemFragment
  matchingCharge:
    | {
        readonly quantity: number
      }
    | undefined
    | null
}) {
  if (!matchingCharge) {
    return lineItem.action
  }
  const shouldSetActionToNone =
    lineItem.action === ActionType.Update &&
    lineItem.charge.type === ChargeType.Recurring &&
    matchingCharge?.quantity === lineItem.quantity
  return shouldSetActionToNone ? ActionType.None : lineItem.action
}
