import { useCallback } from 'react'
import JotaiUrqlErrors from '@/components/error/jotaiUrqlErrors'
import { Grid, InputAdornment } from '@mui/material'
import { Box } from '@mui/system'
import JotaiMuiNumberField from '@/components/input/JotaiMuiNumberField'
import { billableCurrencyFormat, currencySymbol } from '@/util/currencyUtil'
import { LineItemArrDialogProps, LineItemArrDialogState } from '@/pageComponents/orders/LineItemArr/LineItemArrDialog'
import { ChargeType } from '@/generated/graphql'
import { MAX_PRICE_INPUT_VALUE } from '@/util/validationSchemas'

export const LineItemArrDialogContent = <F extends LineItemArrDialogState>({
  jotaiForm,
  lineItemIndex,
}: LineItemArrDialogProps<F>): JSX.Element => {
  const arrWithoutOverride = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form?.orderDetail?.lineItems?.[lineItemIndex]
        return lineItem?.metrics?.arrWithoutOverride
      },
      [lineItemIndex]
    )
  )

  const charge = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.lineItems?.[lineItemIndex]?.charge, [lineItemIndex])
  )
  const currency = jotaiForm.useSelect(useCallback((form) => form.orderDetail.currency ?? 'USD', []))

  const disabledExplanation = !charge?.shouldTrackArr
    ? 'Line item does not track ARR'
    : charge?.type == ChargeType.Usage
    ? 'Usage line items cannot override ARR'
    : ''

  return (
    <>
      <JotaiUrqlErrors jotaiForm={jotaiForm} />
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginBottom: '1rem' }} role="gridcell">
          <Grid item display="flex">
            <Box
              sx={{
                flexFlow: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <JotaiMuiNumberField
                atomSelector={(form) => {
                  const arrOverride = form.orderDetail.lineItems?.[lineItemIndex]?.arrOverride
                  return arrOverride != null
                    ? arrOverride
                    : form.orderDetail.lineItems[lineItemIndex]?.metrics?.arr ?? 0.0
                }}
                atomUpdater={(value, draft) => {
                  const lineItem = draft.orderDetail.lineItems[lineItemIndex]
                  if (value != null && +value < MAX_PRICE_INPUT_VALUE) {
                    lineItem.arrOverride = +value
                  }
                }}
                errorPath={`orderDetail.lineItems[${lineItemIndex}].arrOverride`}
                form={jotaiForm}
                maxDecimalPlaces={2}
                textFieldProps={{
                  'aria-label': 'Line Item ARR',
                  placeholder: `${arrWithoutOverride?.toString() || '0'}`,
                  size: 'small',
                  label: 'Line Item ARR Value',
                  disabled: !!disabledExplanation,
                  'aria-disabled': !!disabledExplanation,
                  inputProps: {
                    startAdornment: (
                      <InputAdornment position="start">{currencySymbol(currency ?? 'USD')}</InputAdornment>
                    ),
                    sx: { height: '2.5rem', textAlign: 'right' },
                  },
                  helperText: `Subskribe computed ARR is ${billableCurrencyFormat({
                    value: arrWithoutOverride,
                    currency: currency ?? 'USD',
                  })}`,
                }}
                disabledExplanation={disabledExplanation}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
