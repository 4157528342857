import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '../../EditOrderPage/CommonOrderFormPageState'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { useLineItemEditActions } from '../LineItemEditContextProvider'
import { OrderItemRowProps } from '../orderChargeRow'
import { DiscountEditCell, DiscountEditCellProps } from './DiscountEditCell'

export function LineItemEditDiscountCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  orderType,
  currency,
}: OrderItemRowProps) {
  const shouldRecalculateTotals = useShouldRecalculateTotals()

  const jotaiForm = useJotaiFormContext<F>()
  const { onClickDiscount } = useLineItemEditActions()
  const discountProps: Omit<DiscountEditCellProps, 'onClickDiscount' | 'lineItemIndex'> = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          clickable: reduced.isDiscountEnabled,
          discount: reduced.discount,
          unitDiscount: reduced.unitDiscount,
          discountPercentage: reduced.discountPercentage,
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )

  return shouldRecalculateTotals ? (
    <LineItemCellLoadingIndicator />
  ) : (
    <DiscountEditCell
      {...{
        ...discountProps,
        onClickDiscount,
        lineItemIndex,
      }}
    />
  )
}
