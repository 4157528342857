import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModalsContext, useModal } from '../../../components/state/context/modalsContext'

type ShipToAddressInvalidProps = {
  accountId: string
}

type DialogProps = WithModalParams & ShipToAddressInvalidProps
const formID = 'ShipToAddressInvalid'
export function ShipToAddressInvalid({ accountId, open, onClose }: DialogProps): JSX.Element {
  return (
    <>
      <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
        <DialogTitle>Ship to Address Invalid</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Ship to address cannot be validated. Please visit the account section to update the address and submit for
            approval again.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ActionButton
            key={'Go to Account'}
            buttonData={{
              label: 'Go to Account',
              buttonProps: {
                href: `/accounts/${accountId}`,
              },
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export function useShipToAddressInvalid(modalProps: ShipToAddressInvalidProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ShipToAddressInvalid,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
