import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { EditTimelineDialogSchema, EditTimelineDialogProps } from './EditTimelineDialog'

export function useIsTimelineDirty({
  form,
  defaults,
}: {
  form: UseFormReturn<z.infer<typeof EditTimelineDialogSchema>>
  defaults: EditTimelineDialogProps['defaults']
}) {
  const values = form.watch()
  return (['startDateType', 'termType', 'startDate', 'endDate', 'duration'] as const).some(
    (key) => values[key] !== defaults[key]
  )
}
