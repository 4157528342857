import React from 'react'
import { Alert } from '@mui/material'
import { CombinedError } from '@urql/core'
import scrollIntoView from 'scroll-into-view-if-needed'
import { extrapolateCombinedErrorMessages } from '../../util/gqlErrorHandling'
import buildLogger from '../../util/logger'

const logger = buildLogger('GqlErrorDisplay')

type Props = {
  error?: CombinedError
}

function GqlErrorDisplay({ error }: Props): JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null)

  const { messages, type } = extrapolateCombinedErrorMessages(error)

  React.useEffect(() => {
    if (error && (error.graphQLErrors?.length > 0 || error.networkError) && ref.current) {
      scrollIntoView(ref.current, {
        scrollMode: 'if-needed',
      })
    }
  }, [error])

  return (
    <>
      <div ref={ref} />
      {messages.map((message, index) => (
        <Alert key={index} severity={type} sx={{ mb: 2 }}>
          {message}
        </Alert>
      ))}
    </>
  )
}

export default GqlErrorDisplay
