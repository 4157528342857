import React, { ReactNode, useCallback, useMemo } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import List from '@mui/material/List'
import { Draft } from 'immer'
import ActionButton from '../button/actionButton'
import JotaiMuiAutocomplete, { JotaiMuiAutocompleteProps } from '../input/JotaiMuiAutocomplete'
import JotaiReadArray from '../state/jotaiReadArray'
import JotaiReadValue from '../state/jotaiReadValue'
import useJotaiForm from '../state/useJotaiForm'

export type BillyAddRemoveListProps<T, A_V, L_V> = {
  addLabel?: string
  emptyLabel?: string
  jotaiMuiAutocompleteProps: Omit<JotaiMuiAutocompleteProps<T, A_V>, 'atomValueSelector'>
  listDeleteUpdater: (value: L_V, draft: Draft<T>) => void
  listItemKey: (value: L_V) => string
  listSelector: (form: T) => L_V[]
  renderListItem: (value: L_V) => ReactNode
}

type BillyAddRemoveListForm = {
  showAutocomplete: boolean
}

export default function BillyAddRemoveList<T, A_V, L_V>({
  addLabel,
  emptyLabel,
  jotaiMuiAutocompleteProps,
  listDeleteUpdater,
  listItemKey,
  listSelector,
  renderListItem,
}: BillyAddRemoveListProps<T, A_V, L_V>): JSX.Element {
  const jotaiForm = jotaiMuiAutocompleteProps.form
  const innerJotaiForm = useJotaiForm<BillyAddRemoveListForm>(
    useMemo(() => ({ defaultValue: { showAutocomplete: false } }), [])
  )

  const handleAddButtonClick = useCallback(
    () =>
      innerJotaiForm.set((draft) => {
        draft.showAutocomplete = true
      }),
    [innerJotaiForm]
  )
  const handleAutoCompleteBlur = useCallback(
    () =>
      innerJotaiForm.set((draft) => {
        draft.showAutocomplete = false
      }),
    [innerJotaiForm]
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <List style={{ paddingTop: 0 }}>
          <JotaiReadArray
            atomSelector={listSelector}
            form={jotaiForm}
            render={useCallback(
              (value: L_V) => (
                <ListItem key={listItemKey(value)} style={{ paddingLeft: 0, paddingTop: 0 }}>
                  {renderListItem(value)}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => jotaiForm.set((draft) => listDeleteUpdater(value, draft))}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ),
              [jotaiForm, listDeleteUpdater, listItemKey, renderListItem]
            )}
            renderEmpty={useCallback(
              () => (
                <ListItem style={{ paddingLeft: 0, paddingTop: 0 }}>
                  <ListItemText primary={emptyLabel || 'Empty'} />
                </ListItem>
              ),
              [emptyLabel]
            )}
          />
        </List>
      </Grid>
      <Grid item xs={12}>
        <JotaiReadValue
          atomSelector={useCallback((form: BillyAddRemoveListForm) => form.showAutocomplete, [])}
          form={innerJotaiForm}
          render={useCallback(
            (showAutocomplete: boolean) => (
              <>
                {!showAutocomplete && (
                  <ActionButton
                    buttonData={{
                      label: addLabel || 'Add',
                      onClick: handleAddButtonClick,
                      color: 'primary',
                      buttonProps: { variant: 'outlined', style: { marginLeft: 0 } },
                    }}
                  />
                )}
                {showAutocomplete && (
                  <JotaiMuiAutocomplete
                    {...jotaiMuiAutocompleteProps}
                    atomValueSelector={() => null}
                    autocompleteProps={{
                      ...jotaiMuiAutocompleteProps.autocompleteProps,
                      blurOnSelect: true,
                      onBlur: handleAutoCompleteBlur,
                      open: true,
                    }}
                    textFieldProps={{ ...jotaiMuiAutocompleteProps.textFieldProps, autoFocus: true }}
                  />
                )}
              </>
            ),
            [addLabel, handleAddButtonClick, handleAutoCompleteBlur, jotaiMuiAutocompleteProps]
          )}
        />
      </Grid>
    </Grid>
  )
}
