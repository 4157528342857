import React from 'react'
import { getTimeZone } from '../../util/datetime/luxon/dateUtil'
import DateTime from '../DateTime/DateTime'
import { TDateTime } from '../../util/datetime/datetimeTypes'

interface IProps {
  readonly datetime: TDateTime
}

export default function DateRangeEnd(props: IProps) {
  return <DateTime datetime={props.datetime} noTime={true} timezone={getTimeZone()} endExclusive={true} />
}
