/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { CellProps } from 'react-table'
import { billableCurrencyFormat, unitPriceFormatter } from '../../../util/currencyUtil'
import { makeStyles } from 'tss-react/mui'
import { DefaultCell } from '@/components/table/cells/DefaultCell'

const useStyles = makeStyles()(() => ({
  noWrapCell: {
    whiteSpace: 'nowrap',
  },
}))

export function dynamicCurrencyCell(
  currencyField: string
): (props: React.PropsWithChildren<CellProps<any>>) => JSX.Element {
  return function DynamicCurrencyCell({ row, value }) {
    return <CurrencyCell currency={row.values[currencyField]} value={value} />
  }
}

export function staticCurrencyCell(currency?: string): (props: CellProps<any>) => JSX.Element {
  return function StaticCurrencyCell({ value }: CellProps<any>): JSX.Element {
    return <CurrencyCell currency={currency || 'USD'} value={value} />
  }
}

export function unitPriceCell(currency?: string): (props: CellProps<any>) => JSX.Element {
  return function StaticCurrencyCell({ value }: CellProps<any>): JSX.Element {
    return <UnitPriceCell currency={currency || 'USD'} value={value} />
  }
}

export const currencyCellBuilder = ({ currencyField }: { currencyField?: string | null }) => {
  const CurrencyCelLWithCurrencyFieldInRow = ({ value, row }: { value?: number | null; row: any }) => {
    const currency = currencyField ? row?.[currencyField] : undefined
    return currency ? <CurrencyCell currency={currency} value={value} /> : <DefaultCell value={value} />
  }
  return CurrencyCelLWithCurrencyFieldInRow
}

const CurrencyCell = ({ currency, value }: { currency?: string | null; value?: number | null }): JSX.Element => {
  const { classes } = useStyles()
  if (value === null || value === undefined) {
    return <div />
  }
  return <div className={classes.noWrapCell}>{billableCurrencyFormat({ currency, value })}</div>
}

const UnitPriceCell = ({ currency, value }: { currency?: string | null; value?: number | null }): JSX.Element => {
  if (value === null || value === undefined) {
    return <div />
  }
  return <div>{unitPriceFormatter({ currency, value })}</div>
}

export default React.memo(CurrencyCell)
