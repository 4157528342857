import { QuickCastUserInput } from '@/components/QuickCastBar/QuickCastOptionsSearcher'
import buildLogger from '@/util/logger'
import { useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { useSnackbarHandler } from '../SnackbarHandler/SnackbarHandler'
import { useMutation } from '../data/billyUrql'
import { QuickCastOption, defaultOptions, filterOptions, getRequirementVariables } from './QuickCastBarManager'
const logger = buildLogger('useQuickCastBarManager')

export type WithQuickAccessPortalProps = {
  goToOptions: () => void
  onDismiss: () => void
  onESC: () => void
  onEvoke: () => void
}

type QuickCastMode = 'home' | 'options' | 'portal'
export function useQuickCastBarManager() {
  const [mode, setMode] = useState<QuickCastMode>('options')
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)

  const isInputEmpty = inputValue === ''

  const goToOptions = useCallback(() => {
    setMode('options')
  }, [setMode])

  const onEvoke = useCallback((keyboardEvent?: KeyboardEvent) => {
    setOpen((prev) => !prev)
    keyboardEvent?.preventDefault()
  }, [])

  const onDismiss = useCallback((): void => {
    setOpen(false)
    goToOptions()
    setInputValue('')
  }, [goToOptions])

  const onESC = useCallback(() => {
    if (open) {
      if (mode === 'options') {
        if (isInputEmpty) {
          setOpen(false)
        } else {
          setInputValue('')
        }
      }

      if (mode === 'portal') {
        setInputValue('')
        goToOptions()
      }
    }
  }, [open, mode, goToOptions, isInputEmpty])

  useHotkeys('meta+k', onEvoke)
  useHotkeys('esc', onESC)

  const options = filterOptions(defaultOptions, {
    inputValue,
    getOptionLabel: (option) => option.label,
  })

  const targetOption = options[selectedIndex] ?? undefined
  const targetDocument = targetOption?.slot?.gqlDocument

  const [, mutator] = useMutation(targetDocument ?? '')
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()

  const doCommandAsync = useCallback(
    async (option: QuickCastOption, userInput: QuickCastUserInput) => {
      const progressKey = snackbarHandler.inProgressAlert(`Executing "${option.label}"...`, {
        persisting: true,
      })
      try {
        await mutator?.(
          option.slotMapper?.gqlDocument?.({
            params: userInput,
          })
        )
        snackbarHandler.dismissAlert(progressKey)
        snackbarHandler.successAlert(`Executed "${option.label}" successfully!`)
        setSelectedIndex(0)
      } catch (err) {
        snackbarHandler.dismissAlert(progressKey)
        throw err
      }
    },
    [mutator, snackbarHandler]
  )

  const handleOnQueryResultConfirm = useCallback(
    (option: QuickCastOption, userInput: QuickCastUserInput) => {
      if (option) {
        if (option.type === 'command') {
          setOpen(false)
          doCommandAsync(option, userInput).catch(errorHandler)
        }
        if (option.type && ['search'].includes(option.type)) {
          setMode('portal')
        }
      }
    },
    [errorHandler, doCommandAsync, setMode, setOpen]
  )

  return {
    quickCastOptionsSearcherProps: {
      inputValue,
      options,
      setInputValue,
      setSelectedIndex,
      selectedIndex,
      gqlCallParams: getRequirementVariables(targetOption?.id),
      onConfirm: (userInput: QuickCastUserInput) => handleOnQueryResultConfirm(targetOption, userInput),
    },
    dialogProps: {
      open,
      onClose: onESC,
      disableEscapeKeyDown: mode !== 'home' && mode !== 'options',
      ...(mode === 'options' ? {} : targetOption?.slotMapper?.['dialogProps']),
    },
    searchResultListProps: {
      options,
      setSelectedIndex,
      selectedIndex,
      onDoubleClick: () => {
        if (targetOption?.type === 'search') {
          setMode('portal')
        }
      },
    },
    targetOption,
    mode,
    portalProps: {
      goToOptions,
      onDismiss,
      onESC,
      onEvoke,
    },
  }
}
