import { datadogLogs } from '@datadog/browser-logs'
import { isString } from 'lodash'
import pino from 'pino'
import 'pino-pretty'
import env from './env'

const withDDLogs =
  (pinoLogger: pino.Logger, pinoLevel: 'error' | 'warn' | 'info' | 'debug' | 'fatal' | 'trace', pinoName: string) =>
  (logObj, ...args) => {
    const logMessage = `PinoLogger-${pinoName}: ${
      isString(logObj) ? logObj : logObj.message ?? logObj.msg ?? 'Logged Object'
    }`
    switch (pinoLevel) {
      case 'error':
      case 'fatal':
        datadogLogs.logger.error(logMessage, {
          pinoLevel,
          pinoName,
          logObj,
          args,
        })
        break
      case 'warn':
        datadogLogs.logger.warn(logMessage, { pinoLogger, pinoLevel, logObj, args })
        break
      case 'info':
      case 'trace':
        datadogLogs.logger.info(logMessage, { pinoLogger, pinoLevel, logObj, args })
        break
      case 'debug':
        datadogLogs.logger.debug(logMessage, { pinoLogger, pinoLevel, logObj, args })
        break
    }
    pinoLogger?.[pinoLevel](logObj)
  }
const buildLogger = (name: string): pino.Logger => {
  let logLevel = env.NEXT_PUBLIC_LOG_LEVEL || 'error'
  if (process.browser) {
    const storedLogLevel = window.localStorage.getItem('logLevel')
    if (storedLogLevel) {
      logLevel = storedLogLevel
    }
  }

  const pinoLogger = pino({
    name,
    level: logLevel,
    prettyPrint: {
      colorize: env.NEXT_PUBLIC_LOG_PRETTY === 'true',
      translateTime: 'yyyy-mm-dd HH:MM:ss',
      messageFormat: '{name}: {msg}',
      ignore: 'pid,hostname,name',
    },
    formatters: {
      level(level) {
        return { level }
      },
    },
  })

  return {
    ...pinoLogger,
    error: withDDLogs(pinoLogger, 'error', name),
    warn: withDDLogs(pinoLogger, 'warn', name),
    info: withDDLogs(pinoLogger, 'info', name),
    debug: withDDLogs(pinoLogger, 'debug', name),
    fatal: withDDLogs(pinoLogger, 'fatal', name),
  } as pino.Logger
}

export default buildLogger
