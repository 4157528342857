import { OrderApprovalSegmentSelect } from '../../../OrderApprovalSegmentSelect'
import { OrderOwnerEsSelect } from '../../../OrderOwnerEsSelect'
import { FieldProps } from './BillyOrderDetailsCard'
import { DetailsGridLayout } from './DetailsGridLayout'
import { ExpiresOnDateField } from './ExpiresOnDateField'
import { OrderNameField } from './OrderNameField'

const orderGeneralDetailsRow: FieldProps[] = [
  {
    Field: () => <OrderNameField parentLocator="order::new::" />,
  },
  {
    Field: OrderOwnerEsSelect,
  },
  {
    Field: OrderApprovalSegmentSelect,
  },
  {
    Field: ExpiresOnDateField,
  },
]

export const OrderDetailsSection = () => {
  return <DetailsGridLayout fields={orderGeneralDetailsRow} />
}
