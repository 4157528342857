import { Feature } from '../../generated/graphql'
import useDynamicFeatureFlag from '../state/useDynamicFeatureFlag'

/**
 * - enabled indicates the logic will run when the feature flag is enabled.
 * - disabled indicates the logic will run when the feature flag is disabled.
 */
export type DynamicFeatureFlagStatus = 'enabled' | 'disabled'

export interface WithDynamicFeatureFlagType {
  readonly feature: Feature
  readonly status: DynamicFeatureFlagStatus
}

type WithDynamicFeatureFlagProps = WithDynamicFeatureFlagType & {
  readonly children: JSX.Element
}

/**
 * This component should be used to wrap feature toggles instead of using a hook.
 *
 * We're going to eventually replace this with an update implementation of
 * useDynamicFeatureFlag that supports errors and loading status and this way
 * new code can be written using this feature toggle.
 *
 */
export default function WithDynamicFeatureFlag(props: WithDynamicFeatureFlagProps) {
  const featureFlagStatusEnabled = useDynamicFeatureFlag(props.feature)

  const featureFlagStatus = featureFlagStatusEnabled ? 'enabled' : 'disabled'

  if (featureFlagStatus === props.status) {
    return props.children
  }

  return null
}
