import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'
import { getEnv } from '@/util/isEnv'
import { GridApiPro } from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import { useCallback } from 'react'

export const useGridApiRefWithPersistState = ({
  apiRef,
  isEnabled,
  tableId,
}: {
  apiRef: React.MutableRefObject<GridApiPro>
  isEnabled?: boolean
  tableId?: string
}) => {
  const envInfo = getEnv()
  const isSavingTableStateEnabled = useDynamicFeatureFlag(Feature.UiSaveDgpTableState) && isEnabled

  const parseSnapshot = useCallback(() => {
    if (isSavingTableStateEnabled) {
      const savedTableStateString = window.localStorage.getItem('data-grid-pro-state')
      const savedTableStateEnv = window.localStorage.getItem('data-grid-pro-state-env')
      const savedTableState: GridInitialStatePro =
        savedTableStateString && savedTableStateEnv === envInfo && tableId
          ? JSON.parse(savedTableStateString)
          : undefined
      return savedTableState
    }
  }, [envInfo, tableId, isSavingTableStateEnabled])

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && isEnabled && window && tableId && isSavingTableStateEnabled) {
      const currentState = apiRef.current.exportState()
      const storedState = parseSnapshot()
      window.localStorage.setItem(
        'data-grid-pro-state',
        JSON.stringify({
          ...storedState,
          [tableId]: currentState,
        })
      )
      window.localStorage.setItem('data-grid-pro-state-env', envInfo || '')
    }
  }, [apiRef, envInfo, isEnabled, parseSnapshot, tableId, isSavingTableStateEnabled])

  const loadSnapshot = useCallback(() => {
    if (apiRef?.current?.restoreState && isEnabled && window && tableId && isSavingTableStateEnabled) {
      const savedTableState = parseSnapshot()?.[tableId]
      if (savedTableState) {
        apiRef.current.restoreState(savedTableState)
      }
    }
  }, [apiRef, parseSnapshot, isEnabled, tableId, isSavingTableStateEnabled])

  return { apiRef, saveSnapshot, loadSnapshot }
}
