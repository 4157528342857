import { useCallback, useEffect } from 'react'
import { JotaiForm } from '../../components/state/useJotaiForm'
import { WithUrql, useJotaiUrqlQuery } from '../../components/state/useJotaiUrqlQuery'
import { GetCurrentUserDocument, GetCurrentUserQuery, GetCurrentUserQueryVariables } from '../../generated/graphql'
import buildLogger from '../../util/logger'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'
import { CancelAndRestructureFormData } from './CancelAndRestructureOrderForm'

const logger = buildLogger('useJotaiDefaultNewOrderOwner')

export function useUpdateDefaultOrderOwner<
  F extends (
    | Pick<CommonOrderFormPageState, 'orderDetail' | 'currentUser' | 'approvalSegments'>
    | CancelAndRestructureFormData
  ) &
    WithUrql
>({ jotaiForm, isNew, pause }: { jotaiForm: JotaiForm<F>; isNew: boolean; pause?: boolean }) {
  useJotaiUrqlQuery<F, GetCurrentUserQuery, GetCurrentUserQueryVariables>({
    document: GetCurrentUserDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.currentUser = data.currentUser), []),
    queryOpts: { requestPolicy: 'cache-first' },
  })

  const shouldUpdateOwner = jotaiForm.useSelect(
    useCallback((form) => !pause && !!form.currentUser && isNew, [isNew, pause])
  )

  const updateOwner = useCallback(
    () =>
      jotaiForm.set((form) => {
        form.orderDetail.owner = form.currentUser
        form.approvalSegments = []
        form.orderDetail.approvalSegment = undefined
      }),
    [jotaiForm]
  )

  useEffect(() => {
    if (shouldUpdateOwner) updateOwner()
  }, [shouldUpdateOwner, updateOwner])
}
