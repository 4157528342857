import React, { useMemo } from 'react'
import BaseTable, { BillyColumn } from '../../components/table/baseTable'
import buildLogger from '../../util/logger'

const logger = buildLogger('PredefinedDiscountsTable')

type PredefinedDiscountsTableProps = {
  currency?: string
  predefinedDiscounts?: Array<any>
}

function PredefinedDiscountsTable({ predefinedDiscounts, currency }: PredefinedDiscountsTableProps): JSX.Element {
  const predefinedDiscountColumns: ReadonlyArray<BillyColumn> = useMemo(
    () => [
      {
        Header: 'Discount Name',
        accessor: 'name',
      },
      {
        Header: 'Percentage',
        accessor: 'percent',
      },
      {
        Header: 'Order Discount',
        accessor: 'amount',
        currencyType: currency,
        dataType: 'currency',
      },
    ],
    [currency]
  )

  return (
    <>
      <BaseTable
        columns={predefinedDiscountColumns}
        data={predefinedDiscounts}
        onRowClick={(id) => {
          logger.info({ msg: 'clicked', id })
        }}
        rowId={'id'}
        hideFooter={true}
      />
    </>
  )
}

export default PredefinedDiscountsTable
