import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Autorenew } from '@mui/icons-material'
import { CardHeader, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useCallback } from 'react'
import JotaiReadValue from '../../../../../components/state/jotaiReadValue'
import { OrderType } from '../../../../../generated/graphql'
import SubscriptionLink from '../../../../../pages/subscriptions/subscriptionLink'

export function OrderDetailsHeader() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const { orderId, isRenewal } = jotaiForm.useSelect(
    useCallback((form) => {
      return {
        orderId: form.orderDetail.id,
        isRenewal: form.orderDetail.orderType === OrderType.Renewal,
      }
    }, [])
  )
  return isRenewal ? (
    <JotaiReadValue
      atomSelector={(form: CommonOrderFormPageState) => ({
        accountName: form.orderDetail?.account?.name,
        subscriptionId: form.orderDetail?.renewalForSubscription?.id,
      })}
      form={jotaiForm}
      render={({ accountName, subscriptionId }) => (
        <CardHeader
          avatar={<Autorenew color="success" />}
          title={accountName}
          subheader={
            <Box sx={{ display: 'flex', flexFlow: 'row' }}>
              <Typography variant="body2">{`Renewal order for`}&nbsp;</Typography>
              {!!subscriptionId && <SubscriptionLink subscriptionId={subscriptionId} />}
            </Box>
          }
        />
      )}
    />
  ) : (
    <CardHeader title="Order Details" subheader={orderId} />
  )
}
