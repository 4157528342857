import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { Feature, OrderStatus } from '@/generated/graphql'
import { useApprovalFlowAdminBypassDialog } from '@/pageComponents/orders/ViewOrderPage/ApprovalFlowAdminBypassDialog'
import buildLogger from '@/util/logger'
import { APPROVAL_WORKFLOW_ADMIN_BYPASS_ROLE } from '@/util/roleUtils'
import { useCallback } from 'react'
const logger = buildLogger('useSubmitForApproval')

export type OrderStatusChangeFn = (
  status: OrderStatus,
  statusUpdatedOn?: number,
  adminApprovalFlowBypass?: boolean
) => void

type SubmitForApprovalOptions = {
  autoApprove?: boolean
}
export function useSubmitForApproval(statusChangeFn: OrderStatusChangeFn, option: SubmitForApprovalOptions = {}) {
  const userTenantSession = useUserTenantSession()
  const role = userTenantSession.currentUser.role
  const isBypassEnabled = useDynamicFeatureFlag(Feature.ApprovalFlowAdminBypass)
  const onToggleAdminApprovalFlowBypass = useApprovalFlowAdminBypassDialog({
    onSubmit: (decision) => {
      logger.info({
        message: 'ApprovalFlowAdminBypassDialog onSubmit',
        decision,
      })
      statusChangeFn(OrderStatus.Submitted, undefined, decision)
    },
  })

  const onSubmitForApproval = useCallback(() => {
    if (isBypassEnabled && !option.autoApprove && APPROVAL_WORKFLOW_ADMIN_BYPASS_ROLE.includes(role)) {
      logger.info({
        message: 'onSubmitForApproval routing for admin bypass',
      })
      onToggleAdminApprovalFlowBypass()
    } else {
      logger.info({
        message: 'onSubmitForApproval changing order status to submitted',
      })
      statusChangeFn(OrderStatus.Submitted)
    }
  }, [statusChangeFn, onToggleAdminApprovalFlowBypass, role, isBypassEnabled, option])
  return onSubmitForApproval
}
