import { useMemo } from 'react'
import { TDateTime, TimeZoneStr } from '../../util/datetime/datetimeTypes'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  formatTDateTimeWithTimezone,
  TDateTimeToJSDate,
} from '../../util/datetime/luxon/dateUtil'

interface IProps {
  readonly datetime: TDateTime
  readonly timezone: TimeZoneStr
  readonly endExclusive?: boolean
  readonly noTime?: boolean
}

export default function DateTime(props: IProps) {
  const date = useMemo(() => {
    const d = TDateTimeToJSDate(props.datetime)

    if (props.endExclusive) {
      return new Date(d.getTime() - 1)
    } else {
      return d
    }
  }, [props.datetime, props.endExclusive])

  const dateFmt = formatTDateTimeWithTimezone(date, props.timezone, DEFAULT_DATE_FORMAT)
  const timeFmt = formatTDateTimeWithTimezone(date, props.timezone, DEFAULT_TIME_FORMAT)

  return <>{props.noTime ? dateFmt : timeFmt}</>
}
