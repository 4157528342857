import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import { useGenerateDocumentLinkMutation } from '../../generated/graphql'
import ClipboardDialog from '../../components/dialog/clipboardDialog'
import { useErrorHandler } from '../../components/ErrorHandler/ErrorHandler'

function DocumentLink({ orderId }: { orderId: string }): JSX.Element {
  const errorHandler = useErrorHandler()
  const [documentLink, setDocumentLink] = useState('')
  const [documentLinkDialog, setDocumentLinkDialog] = useState(false)
  const [, generateDocumentLink] = useGenerateDocumentLinkMutation()

  const onGenerateDocumentLink = useCallback((): void => {
    if (!orderId) return
    generateDocumentLink({ orderId: orderId })
      .then((response) => {
        if (response.data) {
          setDocumentLink(response.data?.generateDocumentLink)
          setDocumentLinkDialog(true)
        }
      })
      .catch(errorHandler)
  }, [generateDocumentLink, orderId, errorHandler])

  return (
    <>
      <Button
        onClick={() => onGenerateDocumentLink()}
        color="primary"
        sx={{
          padding: '0',
          verticalAlign: 'baseline',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          minWidth: '0',
        }}
      >
        Get Link
      </Button>
      <ClipboardDialog
        open={documentLinkDialog}
        title="Share Document"
        dialogText={`Below order form link can be shared with others for read only access.`}
        clipboardText={documentLink}
        handleClose={() => {
          setDocumentLinkDialog(false)
        }}
      />
    </>
  )
}

export default DocumentLink
