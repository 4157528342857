import { DiscountJson, PredefinedDiscountDetail, PredefinedDiscountLineItemDetail } from '../generated/graphql'

export const predefinedDiscountToLineItemDiscount = (
  discountDetail: PredefinedDiscountDetail | DiscountJson
): PredefinedDiscountLineItemDetail => ({
  amount: 0,
  description: discountDetail.description,
  id: discountDetail.id || '',
  name: discountDetail.name,
  percent: discountDetail.percent,
  type: discountDetail.type,
})
