import { EmailContacts } from '@/generated/graphql'
import { notEmpty } from '@/util/array'

export type EmailContactsCellProps = {
  value: EmailContacts
}

export function EmailContactsCell({ value }: EmailContactsCellProps): JSX.Element {
  if (!value) {
    return <></>
  }

  const toContacts = value.toContacts.filter(notEmpty).map((contact) => `To: ${contact.email}`)
  const ccContacts = value.ccContacts.filter(notEmpty).map((contact) => `Cc: ${contact.email}`)
  const bccContacts = value.bccContacts.filter(notEmpty).map((contact) => `Bcc: ${contact.email}`)
  const allContacts = [...toContacts, ...ccContacts, ...bccContacts]
  return (
    <div>
      {allContacts.map((contact) => (
        <>
          {contact}
          {<br />}
        </>
      ))}
    </div>
  )
}
