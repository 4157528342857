import TooltipText from '@/components/typography/tooltipText'
import buildLogger from '@/util/logger'
import { Chip, Grid, Tooltip } from '@mui/material'
const logger = buildLogger('EmailContactDisplay')

type EmailContact = {
  name: string
  email: string
}

export type EmailContactCellProps = {
  contact: EmailContact | undefined | null
  variant?: 'text' | 'outlined'
}

export function EmailContactCell({ contact, variant = 'text' }: EmailContactCellProps): JSX.Element {
  if (!contact) {
    return <></>
  }

  const displayText = `${contact.name.trim()} <${contact.email}>`
  return (
    <>
      {variant === 'text' && <TooltipText variant="body2" text={displayText} maxLength={24} />}
      {variant === 'outlined' && (
        <Tooltip title={displayText}>
          <Chip variant="outlined" label={displayText} />
        </Tooltip>
      )}
    </>
  )
}

export type EmailContactsCellProps = {
  contacts: EmailContact[]
}

export function EmailContactsCell({ contacts }: EmailContactsCellProps): JSX.Element {
  return (
    <Grid container gap={1}>
      {contacts.map((contact, index) => (
        <EmailContactCell key={index} contact={contact} variant="outlined" />
      ))}
    </Grid>
  )
}
