import React, { ForwardedRef } from 'react'
import buildLogger from '../../util/logger'
import JotaiMuiField, { BillyMuiField, BillyMuiFieldProps, JotaiMuiFieldProps } from './JotaiMuiField'
import { Checkbox, MenuItem } from '@mui/material'
import { isArray } from 'lodash'

const logger = buildLogger('JotaiMuiSelect')

function JotaiMuiSelect<T>(props: JotaiMuiFieldProps<T>, ref: ForwardedRef<HTMLInputElement>): JSX.Element {
  return (
    <JotaiMuiField ref={ref} {...props} textFieldProps={{ ...props.textFieldProps, select: true }}>
      {props.children ?? []}
    </JotaiMuiField>
  )
}

export default React.forwardRef(JotaiMuiSelect)

export type OptionalItemsType = { items?: Array<{ id: string; label: string | JSX.Element }> }
export function BillyMuiSelect(props: BillyMuiFieldProps & OptionalItemsType) {
  const isMulti = props.textFieldProps?.SelectProps?.multiple ?? false

  const defaultChildren = props.items
    ? props.items.map((current) =>
        isMulti && isArray(props.textFieldProps?.value) ? (
          <MenuItem key={current.id} value={current.id}>
            <Checkbox checked={props.textFieldProps?.value.includes(current.id)} />
            {current.label}
          </MenuItem>
        ) : (
          <MenuItem key={current.id} value={current.id}>
            {current.label}
          </MenuItem>
        )
      )
    : null

  return (
    <BillyMuiField {...props} textFieldProps={{ ...props.textFieldProps, select: true }}>
      {props.textFieldProps?.children ?? defaultChildren}
    </BillyMuiField>
  )
}
