import {
  BillyMuiAutocompleteProps,
  BillyMuiAutocomplete,
  DataTypeWithIdAndLabel,
} from '@/components/input/JotaiMuiAutocomplete'
import { Checkbox, Chip, Grid, MenuItem, createFilterOptions } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import { z } from 'zod'
import { LayoutProps } from '../BillyTsForm'
import buildLogger from '@/util/logger'

const logger = buildLogger('AutocompleteSelect')

export const AutocompleteSchema = createUniqueFieldSchema(
  z.object({
    id: z.string(),
    label: z.string(),
  }),
  'autocomplete-select'
)

export const OptionalAutocompleteSchema = createUniqueFieldSchema(
  z
    .object({
      id: z.string(),
      label: z.string(),
    })
    .optional(),
  'optional-autocomplete-select'
)

export const OptionalAutocompleteMultiSchema = createUniqueFieldSchema(
  z
    .array(
      z.object({
        id: z.string(),
        label: z.string(),
      })
    )
    .optional(),
  'optional-autocomplete-multi'
)

type AutocompleteSelectProps = LayoutProps &
  Partial<BillyMuiAutocompleteProps<DataTypeWithIdAndLabel, false, true>> & {
    items: DataTypeWithIdAndLabel[]
  }

export function AutocompleteSelect({
  layout,
  hidden,
  items: schemaItems,
  autocompleteProps,
  textFieldProps,
}: AutocompleteSelectProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<DataTypeWithIdAndLabel>()

  const items = schemaItems ?? []

  const { label, placeholder } = useDescription()
  return hidden ? (
    <></>
  ) : (
    <Grid container item xs {...layout}>
      <BillyMuiAutocomplete<DataTypeWithIdAndLabel, false, true>
        autocompleteProps={{
          ...autocompleteProps,
          options: items,
          value: value ?? {
            id: '',
            label: '',
          },
          onChange: (_event, value) => {
            if (value) {
              onChange(value)
            }
          },
          filterOptions: (options, params) => {
            const filter = createFilterOptions<DataTypeWithIdAndLabel>()
            const filtered = filter(options, params)
            return filtered
          },
        }}
        error={error?.errorMessage || ''}
        textFieldProps={{
          label,
          disabled: isLoading || isSubmitting,
          placeholder: placeholder,
          ...textFieldProps,
        }}
      />
    </Grid>
  )
}

type AutocompleteMultiSelectProps = LayoutProps &
  Partial<BillyMuiAutocompleteProps<DataTypeWithIdAndLabel, true, true>> & {
    items: DataTypeWithIdAndLabel[]
  }

export function AutocompleteMultiSelect({
  layout,
  items: schemaItems,
  autocompleteProps,
  textFieldProps,
}: AutocompleteMultiSelectProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<Array<DataTypeWithIdAndLabel>>()

  const items = schemaItems ?? []

  const { label, placeholder } = useDescription()
  return (
    <Grid container item xs {...layout}>
      <BillyMuiAutocomplete<DataTypeWithIdAndLabel, true, true>
        autocompleteProps={{
          ...autocompleteProps,
          disableCloseOnSelect: true,
          options: items,
          value: value ?? [],
          multiple: true,
          renderOption(props, option, state) {
            return (
              <MenuItem {...props}>
                <Checkbox checked={state.selected} />
                {option.label}
              </MenuItem>
            )
          },
          renderTags: (values, getTagProps) =>
            values.map((option, index: number) => (
              <Chip variant="outlined" label={option.label} {...getTagProps({ index })} key={option.id} />
            )),
          onChange: (_event, value) => {
            if (value) {
              onChange(value)
            }
          },
          filterOptions: (options, params) => {
            const filter = createFilterOptions<DataTypeWithIdAndLabel>()
            const filtered = filter(options, params)
            return filtered
          },
        }}
        error={error?.errorMessage || ''}
        textFieldProps={{
          label,
          disabled: isLoading || isSubmitting,
          placeholder: placeholder,
          ...textFieldProps,
        }}
      />
    </Grid>
  )
}
