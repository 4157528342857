import { useConfiguredCustomColumns } from '@/pageComponents/orders/LineItemsEditTable/useLineItemCustomFieldColumns'
import { CustomFieldViewCell } from '@/pageComponents/orders/LineItemsViewTable/Cells/CustomFieldViewCell'

export function LineItemViewChargeCustomFieldCells(props: Parameters<typeof useConfiguredCustomColumns>[0]) {
  const { definitions } = useConfiguredCustomColumns(props)
  return (
    <>
      {definitions?.map((customField) => (
        <CustomFieldViewCell customField={customField} key={customField.id} />
      ))}
    </>
  )
}
