import { Autocomplete, Chip, Grid, TextField } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import z from 'zod'
import { LayoutProps } from '../BillyTsForm'
import { makeStyles } from 'tss-react/mui'
import { useState } from 'react'

export const AutocompleteWithCreateSchema = createUniqueFieldSchema(
  z.array(z.string()).nonempty({ message: 'Please enter at least one value' }),
  'autocomplete-create'
)

export type AutocompleteWithCreateFieldProps = LayoutProps & { initialOptions?: Array<string> }

const useStyles = makeStyles()(() => ({
  textField: {
    width: '100%',
  },
}))

export function AutocompleteWithCreateField({ initialOptions = [], layout }: AutocompleteWithCreateFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<Array<string>>()

  // Array to hold the list of options
  const [options, setOptions] = useState(initialOptions)

  // Function to add a new option to the array
  const handleCreateOption = (newValue: string) => {
    if (newValue !== '' && !options.includes(newValue)) {
      setOptions([...options, newValue])
    }
  }

  const { label, placeholder } = useDescription()
  const { classes } = useStyles()

  return (
    <Grid container item xs {...layout}>
      <Autocomplete
        freeSolo
        className={classes.textField}
        options={[...initialOptions, ...(value ? value : [])]}
        multiple
        onChange={(_event, values) => {
          onChange(values)
        }}
        value={value}
        disabled={isLoading || isSubmitting}
        onInputChange={(_event, newValue) => handleCreateOption(newValue)}
        renderInput={(params) => (
          <TextField
            error={!!error}
            helperText={error?.errorMessage}
            {...params}
            label={label}
            placeholder={placeholder}
          />
        )}
        renderTags={(values: string[], getTagProps) =>
          values.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
          ))
        }
      />
    </Grid>
  )
}
