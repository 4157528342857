import MenuIcon from '@mui/icons-material/Menu'
import { Box, Grid, Hidden, Typography, useTheme } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { Theme } from '@mui/material/styles'
import Image from 'next/image'
import Sticky from 'react-stickynode'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../util/logger'
import { useUserTenantSession } from '../UserTenantSessionProvider/UserTenantSessionContext'
import BillyAvatar from '../avatar/billyAvatar'
import ActionMenu from '../menu/actionMenu'
import { TabBar, TabBarProps, isTabEmpty } from '../tab/TabBar'
import { BreadcrumbDataList } from './breadcrumbNav'
import navCommon from './navCommon'
import SecondaryBar from './secondaryBar'
import { useLogout } from './useLogout'
import { useTenantTimeZone } from '../UserTenantSessionProvider/useTenantTimeZone'
import { Role } from '../../generated/graphql'
import { isCurrentEnvironment } from '../../util/isEnv'
import { memo } from 'react'
import { EntitySelectionDropdown } from '../Entity/EntitySelectionPage'

const logger = buildLogger('TopBar')

const useStyles = makeStyles()((theme: Theme) => ({
  logo: {
    height: 28,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  tenantName: {
    lineHeight: 1,
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    height: navCommon.topBarHeight(theme),
    '& span': {
      fontSize: 12,
      fontWeight: 550,
    },
    '& strong': {
      fontSize: 12,
      fontWeight: 600,
    },
  },
  arrow: {
    position: 'relative',
    top: -8,
  },
}))

type Props = TabBarProps & {
  breadcrumbs: BreadcrumbDataList
  handleDrawerToggle: () => void
  secondaryBar?: JSX.Element | undefined
}

function TopBarWithoutMemo({ breadcrumbs, handleDrawerToggle, secondaryBar, onTabChange, tabs }: Props): JSX.Element {
  const { classes } = useStyles()
  const commonStyles = navCommon.styles().classes
  const theme = useTheme()
  const { currentUser, currentTenant } = useUserTenantSession()
  const [handleLogout] = useLogout()
  const tenantTZ = useTenantTimeZone()

  return (
    <Box
      className={commonStyles.drawerOffset}
      sx={{ zIndex: theme.zIndex.appBar, position: 'absolute', flex: '1', display: 'flex', flexFlow: 'column nowrap' }}
    >
      <Sticky>
        {() => (
          <>
            <AppBar
              position="absolute"
              sx={{
                zIndex: theme.zIndex.appBar,
                boxShadow: 'none',
              }}
              role="navigation"
              aria-label="app-bar"
            >
              <Toolbar className={commonStyles.appBar}>
                <Hidden only={navCommon.drawerShownBreakpoints}>
                  <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: '1' }}>
                  <Image src="/Subskribe_Logo_Horizontal_Color.svg" width={130} height={24} alt="Subskribe" />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EntitySelectionDropdown />
                    <Box aria-label="user-menu" role="menu">
                      <ActionMenu menuItems={[{ onClick: handleLogout, children: 'Logout' }]} variant="app-bar">
                        <BillyAvatar name={currentUser.email || ''} />
                        <Grid container direction="column" className={classes.userDetails}>
                          <Typography component="span" variant="body2" align="left">
                            {currentUser.email}
                          </Typography>
                          <Typography component="strong" variant="body2" align="left">
                            {currentTenant.name}
                            &nbsp;
                            {`(${tenantTZ})`}
                          </Typography>
                          {!isCurrentEnvironment('local') && currentUser.role === Role.BillyAdmin && (
                            <Typography bgcolor="red">Caution: Billy Admin</Typography>
                          )}
                          {!isCurrentEnvironment('local') && currentUser.role === Role.BillyEngineer && (
                            <Typography bgcolor="yellow">Caution: Billy Engineer</Typography>
                          )}
                        </Grid>
                        <Box className={classes.arrow}>
                          <Image src="/icons/arrow.svg" width={20} height={10} />
                        </Box>
                      </ActionMenu>
                    </Box>
                  </Box>
                </Box>
              </Toolbar>

              <Box
                component={'nav'}
                sx={(theme: Theme) => ({
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  borderColor: theme.customPalette.borderColor,
                  bgcolor: theme.palette.background.paper,
                  zIndex: theme.zIndex.appBar,
                })}
              >
                <Box>
                  {(!!secondaryBar || !!breadcrumbs.length) && (
                    <SecondaryBar breadcrumbs={breadcrumbs} handleDrawerToggle={handleDrawerToggle}>
                      {secondaryBar}
                    </SecondaryBar>
                  )}
                  {isTabEmpty(tabs) && <TabBar onTabChange={onTabChange} tabs={tabs} />}
                </Box>
              </Box>
            </AppBar>
          </>
        )}
      </Sticky>
    </Box>
  )
}

export default memo(TopBarWithoutMemo) as typeof TopBarWithoutMemo
