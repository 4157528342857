import { ModalProps } from '@mui/material'
import React, { PropsWithChildren, memo, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import buildLogger from '../../../util/logger'
import { useBillyRouter } from '../../route/useBillyRouter'
import JotaiFormProvider from '../jotaiFormProvider'
import { JotaiForm } from '../useJotaiForm'

const logger = buildLogger('modalsContext')

// used by new modal components as a part of props

export type WithBaseModalParams = Readonly<{
  open?: boolean
  onModalClose?: ModalProps['onClose']
  onClose?: () => void
}>

export type WithModalParams = WithBaseModalParams &
  Readonly<{
    // any is needed for types to customize onSubmit function shape
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit?: (...args: any[]) => any | Promise<any>
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDelete?: (...args: any[]) => any | Promise<any>
  }>

type ModalComponentOptions = Readonly<{
  disableBackdropClick?: boolean
}>

type ModalSchema<T, F> = Readonly<{
  key: string
  modalProps?: T
  jotaiForm?: JotaiForm<F>
  options?: ModalComponentOptions
}>
type ModalsType<T extends WithModalParams = WithModalParams, F = any> = Readonly<
  Record<string, ModalComponentProps<T, F> & { open: boolean }>
>

type ModalContext = Readonly<
  [
    modals: ModalsType,
    setModals: React.Dispatch<React.SetStateAction<ModalsType>>,
    toggleModal: (key?: string, setTo?: boolean) => void
  ]
>
const DEFAULT_MODALS_CONTEXT: ModalContext = [
  {},
  () => {
    console.log('No modal context for setModals()')
    return
  },
  () => {
    console.log('No modal context for toggleModal()')
    return
  },
]

const ModalsContext = React.createContext<ModalContext>(DEFAULT_MODALS_CONTEXT)

export const useModalsContext = () => {
  return React.useContext(ModalsContext)
}

type ModalComponentProps<T, F> = Readonly<{
  component: React.FC<T>
  schema: ModalSchema<T, F>
  open?: boolean
}>

type ModalComponentHolderProps<T, F> = Readonly<{
  component: React.FC<T>
  schema: ModalSchema<T, F>
  open?: boolean
  toggleModal: (key?: string, setTo?: boolean) => void
}>

// eslint: this is legacy code for use with jotai
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalComponentHolder = <T extends WithModalParams = WithModalParams, F = any>(
  props: ModalComponentHolderProps<T, F> & { open?: boolean }
) => {
  const { schema, open, toggleModal } = props
  const ModalContent = props.component

  const { modalProps, jotaiForm, key } = schema
  const handleClose = useCallback(() => {
    modalProps?.onClose?.()
    toggleModal(key, false)
  }, [modalProps, toggleModal, key])
  const handleModalClose: ModalProps['onClose'] = useCallback(
    (event, reason) => {
      {
        if (reason === 'escapeKeyDown') handleClose?.()
        if (!schema.options?.disableBackdropClick && reason === 'backdropClick') handleClose?.()
      }
    },
    [handleClose, schema.options?.disableBackdropClick]
  )
  const contentProps = {
    ...modalProps,
    onClose: handleClose,
    onModalClose: handleModalClose,
    open,
  } as PropsWithChildren<T>
  const content = <ModalContent {...contentProps} />

  return jotaiForm ? <JotaiFormProvider form={jotaiForm}>{content}</JotaiFormProvider> : content
}

/**
  - maintain all open status for existing modals
  - (only 1 modal is open at a time due to toggle logic)
  - new ones are default to be false
 */
export const useModal = <T extends WithModalParams = WithModalParams, F = any>({
  component,
  schema,
}: ModalComponentProps<T, F>): [ModalSchema<T, F>['key']] => {
  const [, setModals] = useModalsContext()

  const { key } = schema

  useEffect(() => {
    setModals((prev) => {
      const newModals = {
        ...prev,
        [key]: {
          component,
          schema,
          open: prev?.[key]?.open ?? false,
        },
      } as ModalsType
      return newModals
    })
  }, [key, setModals, component, schema])

  return [key]
}

const Modals: React.FC<{ delegator: (args: ModalContext) => void; isQueryControlled?: boolean }> = memo(
  function ModalsWithoutMemo({ delegator, isQueryControlled }) {
    const [modals, setModals] = useState<ModalContext['0']>({})
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const router = useBillyRouter()
    const openModalKey = (router.query.modal ?? '') as string

    const toggleRouterQueryModal = useCallback(
      (newTargetKey?: string, setTo?: boolean) => {
        const isTargetKeyOpen = !!openModalKey && !!newTargetKey && openModalKey === newTargetKey
        const targetKeyValue = setTo ?? !isTargetKeyOpen ? newTargetKey : undefined
        const otherKeyValue = targetKeyValue || openModalKey
        const isTarget = isTargetKeyOpen || !openModalKey

        const newModalKey = isTarget ? targetKeyValue : otherKeyValue
        router.push({ pathname: router.pathname, query: { ...router.query, modal: newModalKey } }, undefined, {
          shallow: true,
        })
      },
      [router, openModalKey]
    )

    const toggleInMemoryModal = useCallback(
      (key?: string, setTo?: boolean) => {
        setModals((prev) => {
          const targetKeyValue = setTo ?? !(Object.entries(prev).find((entry) => entry[0] === key)?.[1]?.open ?? false)
          return Object.entries(prev).reduce((ret, curr) => {
            const [currKey, value] = curr
            const otherKeyValue = targetKeyValue ? false : value.open
            const isTarget = currKey === key
            return {
              ...ret,
              [currKey]: {
                ...value,
                open: isTarget ? targetKeyValue : otherKeyValue,
              },
            }
          }, {})
        })
      },
      [setModals]
    )

    const clearAllInMemoryModals = useCallback(() => {
      setModals((prev) => {
        return Object.entries(prev).reduce((ret, curr) => {
          const [currKey, value] = curr
          return {
            ...ret,
            [currKey]: {
              ...value,
              open: false,
            },
          }
        }, {})
      })
      forceUpdate()
    }, [setModals, forceUpdate])
    const toggleModal = isQueryControlled ? toggleRouterQueryModal : toggleInMemoryModal

    const modalsLoaded = openModalKey in modals
    useEffect(() => {
      if (openModalKey) {
        toggleInMemoryModal(openModalKey, !!openModalKey)
      } else {
        clearAllInMemoryModals()
      }
    }, [openModalKey, toggleInMemoryModal, modalsLoaded, clearAllInMemoryModals])

    useEffect(() => {
      delegator([modals, setModals, toggleModal])
    }, [delegator, modals, setModals, toggleModal])

    return (
      <>
        {Object.values(modals).map((context, i) => (
          <ModalComponentHolder key={`modals-${i}`} {...context} toggleModal={toggleModal} />
        ))}
      </>
    )
  }
)
interface ModalsProviderProp {
  readonly isQueryControlled?: boolean
  readonly children: React.ReactNode
}

export const ModalsProvider = memo(function ModalsProviderNoMemo({ isQueryControlled, children }: ModalsProviderProp) {
  const [isReady, setIsReady] = useState<boolean>(false)
  const contextRef = useRef<ModalContext | undefined>(undefined)

  const handleContext = useCallback((newContext: ModalContext) => {
    if (!contextRef.current) {
      setIsReady(!!newContext)
    }
    contextRef.current = newContext
  }, [])

  return (
    <>
      <Modals delegator={handleContext} isQueryControlled={isQueryControlled} />

      {!!isReady && contextRef.current && (
        <ModalsContext.Provider value={contextRef.current}>{children}</ModalsContext.Provider>
      )}
    </>
  )
})
