import { Link, LinkProps } from '@mui/material'
import { LinkProps as NextProps } from 'next/dist/client/link'
import NextLink from 'next/link'
import React, { CSSProperties } from 'react'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  link: {
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
    color: theme.palette.primary.main,
  },
  withHover: {
    '&:hover, &:focus': {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
      outline: 'none',
      '&::after': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
  },
  withUnderline: {
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      minWidth: '100%',
      height: 1,
      backgroundColor: theme.palette.primary.light,
      opacity: 0,
      transition: 'opacity 300ms, transform 300ms',
      transform: 'translate3d(-100%, 0, 0)',
    },
  },
}))

export type BillyLinkProps = Readonly<{
  children?: React.ReactNode
  linkProps?: LinkProps
  nextProps?: NextProps
  noHover?: boolean
  noUnderline?: boolean
  target?: string
  style?: CSSProperties
}>

function BillyLink({
  children,
  linkProps,
  nextProps,
  noHover,
  noUnderline,
  target,
  style,
}: BillyLinkProps): JSX.Element {
  const { classes, cx } = useStyles()

  const link = (
    <Link
      role={nextProps ? 'link' : 'button'}
      target={target}
      style={style}
      {...linkProps}
      className={cx(classes.link, noUnderline || classes.withUnderline, noHover || classes.withHover)}
    >
      {children || ''}
    </Link>
  )

  if (nextProps) {
    return (
      <NextLink {...nextProps} passHref={true}>
        {link}
      </NextLink>
    )
  } else {
    return link
  }
}

export default BillyLink
