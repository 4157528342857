import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material'
import { useModal, useModalsContext } from '../state/context/modalsContext'
import { useCallback } from 'react'
import { WithModalParams } from '../state/context/modalsContext'
import { CustomFieldEntryFragment, CustomFieldParentType, useGetCustomFieldsQuery } from '../../generated/graphql'
import { toTitleCase } from '../../util/string'
import BillyGridCell from '../grid/billyGridCell'

type CustomFieldDialogProps = WithModalParams & {
  parentObjectType: CustomFieldParentType
  parentObjectId: string
}

type CustomFieldDialogPropsWithSchema = CustomFieldDialogProps & {
  customFields: readonly CustomFieldEntryFragment[]
}

function ViewCustomFieldsDialog({ open, onClose, parentObjectType, customFields }: CustomFieldDialogPropsWithSchema) {
  const billyGridCells = customFields.map((field) => ({
    label: field.label || field.name,
    description: field.value || field.selections?.join(', ') || '-',
  }))

  return (
    <Dialog open={!!open} onClose={onClose} fullWidth>
      <DialogTitle>Custom Fields for {toTitleCase(parentObjectType)}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          {billyGridCells.map((cell, index) => (
            <BillyGridCell {...cell} key={index} xs={5} labelProps={{ sx: { overflowWrap: 'anywhere !important' } }} />
          ))}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function ViewCustomFieldsDialogWrapper(modalProps: CustomFieldDialogProps) {
  const [customFieldsResponse] = useGetCustomFieldsQuery({
    variables: { parentObjectType: modalProps.parentObjectType, parentObjectId: modalProps.parentObjectId },
  })
  if (!customFieldsResponse.fetching && !customFieldsResponse.stale) {
    return <ViewCustomFieldsDialog {...modalProps} customFields={customFieldsResponse.data?.customFields ?? []} />
  } else return null
}

function ViewCustomFieldsDialogContainer({ ...modalProps }: CustomFieldDialogProps) {
  if (modalProps.open) return <ViewCustomFieldsDialogWrapper {...modalProps} key={modalProps.parentObjectId} />

  return null
}

export function useViewCustomFieldsDialog(modalProps: CustomFieldDialogProps) {
  const [, , toggleModal] = useModalsContext()
  const key = `viewCustomFieldsDialog-${modalProps.parentObjectType}-${modalProps.parentObjectId}`
  useModal({
    component: ViewCustomFieldsDialogContainer,
    schema: {
      key,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(key)
  }, [toggleModal, key])
}
