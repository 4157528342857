/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrderDetailFragment, ApprovalFlowInstanceStatus } from '@/generated/graphql'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { Config, ImmutableTree, Utils as QbUtils } from '@react-awesome-query-builder/mui'
import isEqual from 'fast-deep-equal/es6/react'
import { cloneDeep } from 'lodash'
const logger = buildLogger('approvalWorkflowUtil')

export const cleanJsonLogic = (
  // generic until
  jsonObjOriginal: Record<string, any>
  // generic until
): Record<string, any> => {
  const jsonObj = cloneDeep(jsonObjOriginal)
  if (jsonObj !== null && typeof jsonObj == 'object') {
    Object.entries(jsonObj).forEach(([key, value]) => {
      if (value && typeof value == 'object') {
        if (key === 'or' && Array.isArray(value) && value.length < 2) {
          delete jsonObj[key]
          jsonObj.and = cleanJsonLogic(value)
        } else if (Array.isArray(value)) {
          jsonObj[key] = value.map((valueItem) => cleanJsonLogic(valueItem))
        } else {
          jsonObj[key] = cleanJsonLogic(value)
        }
      } else if (key === 'var') {
        const newVarValue = value ? value : ''
        //handle field name remapping
        const processedNewVarValue = newVarValue.replace(/\./g, ';')
        jsonObj[key] = processedNewVarValue
      }
    })
  }
  return jsonObj
}

// generic until
export const readJsonLogic = (jsonObjOriginal: Record<string, any>): Record<string, any> => {
  const jsonObj = cloneDeep(jsonObjOriginal)
  if (jsonObj !== null && typeof jsonObj == 'object') {
    Object.entries(jsonObj).forEach(([key, value]) => {
      if (value && typeof value == 'object') {
        if (Array.isArray(value)) {
          jsonObj[key] = value.map((valueItem) => readJsonLogic(valueItem))
        } else {
          jsonObj[key] = readJsonLogic(value)
        }
      } else if (key === 'var') {
        //handle field name remapping
        const newVarValue = value.replace(';', '.')
        jsonObj[key] = newVarValue
      }
    })
  }
  return jsonObj
}

export function isTreeEmpty(tree: ImmutableTree): boolean {
  return Object.keys(QbUtils.getTree(tree).children1 ?? {}).length === 0
}

export function encodeQbTree(immutableTree: ImmutableTree, config: Config): string {
  const originalLogic = QbUtils.jsonLogicFormat(immutableTree, config).logic ?? {}
  const jsonLogic = cleanJsonLogic(originalLogic)
  logger.trace({ msg: 'encoding json logic', originalLogic, jsonLogic })
  return isEqual(jsonLogic, {}) ? '' : Buffer.from(JSON.stringify(jsonLogic) ?? '').toString('base64')
}

export function getOrderApprovalFlowError(orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]) {
  switch (orderApprovalFlow?.approvalStatus) {
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerIsMissingToFindApprovalSegment:
      return 'Order owner is missing to find approval segment so this order cannot be routed for approval.'
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerNotPartOfAnyApprovalSegment:
      return 'Order owner is not part of any approval segment so this order cannot be routed for approval. Please contact your organization’s Subskribe administrator for assistance.'
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerNotPartOfApprovalSegmentSelectedOnOrder:
      return 'Order owner is not part of approval segment selected on order so this order cannot be routed for approval.'
    case ApprovalFlowInstanceStatus.ErrorOrderOwnerPartOfMultipleApprovalSegments:
      return 'Order owner is part of multiple approval segments so this order cannot be routed for approval.'
  }
}

export function getOrderApprovalFlowsError(flows: OrderDetailFragment['approvalFlows']) {
  return flows
    .filter(notEmpty)
    .map((flow) => getOrderApprovalFlowError(flow))
    .filter(notEmpty)
}

export function isOrderDataAutoApprove(orderData?: Pick<OrderDetailFragment, 'approvalFlows'>) {
  return orderData?.approvalFlows?.[0]?.approvalFlowInstances.length === 0
}
