import { IconButton, InputBase, InputBaseProps, Paper } from '@mui/material'
import { KuiKatGif } from './KuiKatGif'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export function QuickCastSearchInput({
  value,
  onChange,
  inputProps,
  onKeyDown,
  children,
  onGoBack,
}: Pick<InputBaseProps, 'inputProps' | 'onChange' | 'onKeyDown' | 'value'> & {
  children?: JSX.Element
  onGoBack?: () => void
}) {
  const showBackButton = !!onGoBack
  return (
    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', flex: '1 1 auto' }}>
      {!!showBackButton && (
        <IconButton aria-label="go-back" size="large" onClick={onGoBack} tabIndex={0}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <InputBase
        value={value}
        sx={{ ml: 1, minWidth: '80px', flex: 1 }}
        // for power user
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={onChange}
        inputProps={{
          tabIndex: 1,
          'aria-label': 'Quick Cast Bar Input',
          role: 'textbox',
          placeholder: `Search for commands. Try with "add" or "list"...`,
          ...inputProps,
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          onKeyDown?.(event)
          if (event.key === 'Escape') {
            onGoBack?.()
          }
        }}
      />
      {children}
      <KuiKatGif hasInput={!!value} />
    </Paper>
  )
}
