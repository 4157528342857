import { useEffect, useRef } from 'react'
import { CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { Draft } from 'immer'
import { makeStyles } from 'tss-react/mui'
import { useYupError } from '../../util/jotai'
import buildLogger from '../../util/logger'
import JotaiReadArray from '../state/jotaiReadArray'
import { JotaiForm } from '../state/useJotaiForm'
import JotaiMuiCheckbox from './JotaiMuiCheckbox'

const logger = buildLogger('JotaiMuiMultiCheckbox')

export type CheckboxData = {
  label: JSX.Element
  value: boolean
  disabledExplanation?: string
}

export type JotaiMuiMultiCheckboxProps<T> = {
  atomSelector: (formData: T) => Array<CheckboxData>
  atomUpdater: (index: number, value: boolean, draft: Draft<T>) => void
  checkboxProps?: CheckboxProps
  errorPath: string
  form: JotaiForm<T>
  renderEmpty?: () => JSX.Element
}

const useStyles = makeStyles()((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
}))

function JotaiMuiMultiCheckbox<T>({
  atomSelector,
  atomUpdater,
  checkboxProps,
  errorPath,
  form,
  renderEmpty,
}: JotaiMuiMultiCheckboxProps<T>): JSX.Element {
  const { classes } = useStyles()

  const errorMessage = useYupError(form, errorPath)
  const ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (errorMessage) {
      ref.current?.focus()
    }
  }, [errorMessage])

  return (
    <FormControl required error={!!errorMessage} component="fieldset" className={classes.formControl}>
      <FormHelperText ref={ref} tabIndex={0}>
        {errorMessage}
      </FormHelperText>
      <JotaiReadArray
        atomSelector={atomSelector}
        form={form}
        render={({ label, disabledExplanation }, index) => (
          <FormControlLabel
            control={
              <JotaiMuiCheckbox
                atomSelector={(form: T) => atomSelector(form)[index].value}
                atomUpdater={(value, draft) => atomUpdater(index, value, draft)}
                form={form}
                checkboxProps={checkboxProps}
                disabledExplanation={disabledExplanation}
              />
            }
            key={index}
            label={label}
          />
        )}
        renderEmpty={renderEmpty}
      />
    </FormControl>
  )
}

export default JotaiMuiMultiCheckbox
