import { FC, ReactNode, createContext, memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { extrapolateCombinedErrorMessages, isTypeOfCombinedError } from '../../util/gqlErrorHandling'
import { GraphQLErrors } from '../data/GraphQLErrors'
import { BillyErrorSnackbarDisplay } from '../error/BillyErrorSnackbarDisplay'
import { useRouter } from 'next/router'
import { datadogLogs } from '@datadog/browser-logs'
import { isApiError } from '../data/billyRestClient'

/**
 * The main error handler type to allow user to use a string or an Error
 */
export type TError = Pick<Error, 'message'>

function isError(err: unknown): err is TError {
  if (typeof err !== 'object') {
    return false
  }

  return 'message' in (err as object)
}

export type ErrorHandler = (error: unknown) => void

type ErrorDisplay = ReturnType<typeof extrapolateCombinedErrorMessages>
interface IErrorHandlerDelegateProps {
  readonly onErrorHandler: (errorHandler: ErrorHandler) => void
}

const ErrorHandlerDelegate = memo(function ErrorHandlerDelegate(props: IErrorHandlerDelegateProps) {
  const { onErrorHandler } = props
  const [errorDisplay, setErrorDisplay] = useState<ErrorDisplay | undefined>(undefined)

  const router = useRouter()

  useEffect(() => {
    // if the route changes remove the last error.
    setErrorDisplay(undefined)
  }, [router?.asPath])

  useEffect(() => {
    onErrorHandler((error: unknown) => {
      if (isError(error)) {
        if (!GraphQLErrors.isIgnorable(error)) {
          if (isTypeOfCombinedError(error)) {
            setErrorDisplay(extrapolateCombinedErrorMessages(error))
          } else {
            setErrorDisplay({ messages: [error.message], type: 'error' })
            datadogLogs.logger.error(error.message, { error })
          }
        } else if (isApiError(error) && error.response?.data) {
          setErrorDisplay({ messages: [error.response?.data.message], type: 'error' })
        }
      } else {
        // fallback if we handle an error without an error property which is possible
        // in javascript.
        setErrorDisplay({ messages: ['An error has occurred.'], type: 'error' })
      }
    })
  }, [onErrorHandler])

  return <>{errorDisplay && <BillyErrorSnackbarDisplay messages={errorDisplay.messages} type={errorDisplay.type} />}</>
})

const DEFAULT_FALLBACK_LOGGER = (err: unknown) => {
  console.error(err)
}

const ErrorHandlerContext = createContext<ErrorHandler>(DEFAULT_FALLBACK_LOGGER)

interface ErrorHandlerProps {
  readonly children: ReactNode | FC<React.PropsWithChildren<unknown>> | JSX.Element
}

/**
 * Hook to allow any component in the app to report an error.
 */
export function useErrorHandler() {
  return useContext(ErrorHandlerContext)
}

export function isHandleableError(error): error is TError {
  if (error && error instanceof Object && error.message && typeof error.message === 'string') {
    return true
  } else return false
}

/**
 * Root ErrorHandler for the app.  Allows any component to just
 * useErrorHandler on any promise and use snackbar alerts.
 *
 * It's designed to not trigger any app re-renders by only updating state in an
 * inner delegate, not the whole tree.
 */
export const ErrorHandlerProvider = memo(function ErrorHandlerProvider(props: ErrorHandlerProps) {
  const delegateErrorHandlerRef = useRef<ErrorHandler | undefined>(undefined)

  const errorHandler = useCallback((err: unknown) => {
    delegateErrorHandlerRef.current?.(err)
  }, [])

  return (
    <ErrorHandlerContext.Provider value={errorHandler}>
      <>
        <ErrorHandlerDelegate onErrorHandler={(errorHandler) => (delegateErrorHandlerRef.current = errorHandler)} />
        {props.children}
      </>
    </ErrorHandlerContext.Provider>
  )
})
