import ActionButton from '@/components/button/actionButton'
import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import BillyLink from '@/components/link/billyLink'
import { JotaiForm } from '@/components/state/useJotaiForm'
import TableEmptyContent from '@/components/table/tableEmptyContent'
import {
  CustomFieldEntryFragment,
  CustomFieldParentType,
  CustomFieldType,
  useGetCustomFieldsQuery,
} from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import { CardContent, CardHeader, Divider, Grid, Popover, Skeleton, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useMouseOverPopoverProps } from '../../../components/MouseOverPopoverCard/MouseOverPopoverCard'

export function formatCustomField(customField: CustomFieldEntryFragment) {
  if (customField.type === CustomFieldType.String) {
    return customField.value
  }
  if (customField.type === CustomFieldType.MultiselectPicklist || customField.type === CustomFieldType.Picklist) {
    return customField?.selections?.filter(notEmpty).join(', ')
  }
}

function customFieldToGridCell(customField: CustomFieldEntryFragment): GridCellProps {
  return {
    label: customField?.name ?? '',
    description: formatCustomField(customField) || 'None',
    xs: 6,
  }
}

function EmptyCustomFieldView() {
  return <TableEmptyContent message="No Custom Fields Details." />
}

function useQueryLineItemsCustomFieldCells({ open, lineItemId }: { open: boolean; lineItemId: string }) {
  const [customFieldsResponse] = useGetCustomFieldsQuery({
    variables: {
      parentObjectId: lineItemId ?? '',
      parentObjectType: CustomFieldParentType.OrderItem,
    },
    pause: !open,
  })

  const cells: GridCellProps[] = customFieldsResponse.data?.customFields?.map(customFieldToGridCell) ?? []

  return { cells, fetching: customFieldsResponse.fetching }
}

export function OrderViewLineItemPopoverLink({
  lineItemId,
  onClickLink,
  children,
}: {
  lineItemId: string
  onClickLink?: () => void
  children: React.ReactNode
}) {
  const { anchorProps, popoverProps } = useMouseOverPopoverProps()
  const { cells, fetching } = useQueryLineItemsCustomFieldCells({ open: popoverProps.open, lineItemId })

  return (
    <div>
      <BillyLink
        linkProps={{
          onClick: onClickLink,
        }}
      >
        <Typography {...anchorProps}>{children}</Typography>
      </BillyLink>
      <Popover {...popoverProps}>
        {fetching ? (
          <Skeleton />
        ) : cells.length ? (
          <Grid container rowGap={2} columnSpacing={1}>
            {cells.map((cell, i) => (
              <BillyGridCell key={i} {...cell} />
            ))}
          </Grid>
        ) : (
          <EmptyCustomFieldView />
        )}
      </Popover>
    </div>
  )
}

export function OrderEditLineItemPopoverLink({
  lineItemIndex,
  onClickLink,
  jotaiForm,
  children,
  onEdit,
}: {
  jotaiForm: JotaiForm<NewOrderFormData>
  lineItemIndex: number
  onClickLink?: () => void
  children: React.ReactNode
  onEdit?: () => void
}) {
  const { anchorProps, popoverProps, popoverPaperSx } = useMouseOverPopoverProps()
  const cells = jotaiForm.useSelect(
    useCallback(
      (form) => {
        return (
          form.orderDetail.lineItems?.[lineItemIndex]?.customFields?.filter(notEmpty).map(customFieldToGridCell) ?? []
        )
      },
      [lineItemIndex]
    )
  )

  return (
    <div>
      <BillyLink
        linkProps={{
          onClick: onClickLink,
        }}
      >
        <Typography {...anchorProps}>{children}</Typography>
      </BillyLink>
      <Popover
        {...popoverProps}
        slotProps={{
          ...popoverProps.slotProps,
          paper: {
            ...popoverProps.slotProps?.paper,
            sx: {
              ...popoverPaperSx,
              padding: 0,
            },
          },
        }}
      >
        <CardHeader
          title={'Custom Fields'}
          action={
            <ActionButton
              buttonData={{
                label: 'Edit',
                onClick: onEdit,
                buttonProps: { variant: 'text', size: 'small' },
              }}
            />
          }
        />

        <Divider />
        {cells.length ? (
          <CardContent>
            <Grid container rowGap={2} columnSpacing={1}>
              {cells.map((cell, i) => (
                <BillyGridCell key={i} {...cell} />
              ))}
            </Grid>
          </CardContent>
        ) : (
          <EmptyCustomFieldView />
        )}
      </Popover>
    </div>
  )
}
