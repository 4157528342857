import React from 'react'
import BillyLink from '../../components/link/billyLink'

const SubscriptionLink: React.FC<React.PropsWithChildren<{ subscriptionId: string }>> = ({ subscriptionId }) => {
  return (
    <BillyLink
      nextProps={{
        href: `/subscriptions/${subscriptionId}`,
      }}
      linkProps={{
        variant: 'body2',
      }}
    >
      {subscriptionId}
    </BillyLink>
  )
}

export default SubscriptionLink
