import React, { useState } from 'react'
import BillyLink from '../link/billyLink'
import { CircularProgress, Typography } from '@mui/material'
import {
  OrderDetailFragment,
  useCreateSalesRoomLinkMutation,
  useGetElectronicSignatureProviderQuery,
  useGetElectronicSignatureQuery,
  useGetSalesRoomLinkQuery,
} from '@/generated/graphql'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import ClipboardDialog from '../dialog/clipboardDialog'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { useSaveEsignatureDetailsDialog } from './SaveEsignatureDetailsDialog'

interface GenerateSalesRoomLinkProps {
  orderId: string
  orderData: OrderDetailFragment
}

type DialogProps = WithModalParams & {
  salesRoomId?: string | null
}

function CreateSalesRoomDialog({ open, onClose, salesRoomId }: DialogProps) {
  return (
    <ClipboardDialog
      open={!!open}
      title="Share Sales Room"
      dialogText={`Below order sales room link can be shared with others.`}
      clipboardText={`${window.location.protocol}//${window.location.host}/sales-room/${salesRoomId}`}
      handleClose={() => {
        onClose?.()
      }}
    />
  )
}

const salesRoomDialogKey = 'create-sales-room-dialog'

export function useCreateSalesRoomDialog(
  modalProps: DialogProps = {}
): [() => void, (salesRoomId: string | undefined) => void] {
  const [, , toggleModal] = useModalsContext()
  const [salesRoomId, setSalesRoomId] = useState<string | undefined>()
  useModal<DialogProps>({
    component: CreateSalesRoomDialog,
    schema: {
      key: salesRoomDialogKey,
      modalProps: {
        ...modalProps,
        salesRoomId,
      },
    },
  })
  function toggleSalesRoomDialog() {
    toggleModal(salesRoomDialogKey)
  }

  return [toggleSalesRoomDialog, setSalesRoomId]
}

export function GenerateSalesRoomLink({ orderId, orderData }: GenerateSalesRoomLinkProps) {
  const errorHandler = useErrorHandler()
  const [, createSalesRoomLink] = useCreateSalesRoomLinkMutation()
  const [salesRoomLinkResponse, refreshSalesRoomLinkQuery] = useGetSalesRoomLinkQuery({ variables: { orderId } })
  const [toggleCreateSalesRoomDialog, setSalesRoomId] = useCreateSalesRoomDialog()
  const [eSignatureResponse] = useGetElectronicSignatureQuery({ variables: { orderId }, handleError: false })
  const [eSignatureProviderResponse] = useGetElectronicSignatureProviderQuery({ handleError: false })

  const eSignatureSupported =
    !!eSignatureProviderResponse.data?.electronicSignatureProvider &&
    !eSignatureResponse.data?.electronicSignature.status

  async function generateSalesRoomLinkAndOpenDialog() {
    if (salesRoomLinkResponse.data?.salesRoomLink?.linkId) {
      setSalesRoomId(salesRoomLinkResponse.data.salesRoomLink.linkId)
      toggleCreateSalesRoomDialog()
    } else {
      const response = await createSalesRoomLink({ orderId })
      setSalesRoomId(response.data?.createSalesRoomLink?.linkId)
      refreshSalesRoomLinkQuery()
      toggleCreateSalesRoomDialog()
    }
  }

  const toggleSaveEsignatureDetailsDialog = useSaveEsignatureDetailsDialog({
    eSignData: eSignatureResponse.data?.electronicSignature,
    orderData,
    orderId,
    onSuccessCallback: () => {
      generateSalesRoomLinkAndOpenDialog().catch(errorHandler)
    },
  })

  function onClickGenerateSalesRoomLink() {
    if (eSignatureSupported) {
      toggleSaveEsignatureDetailsDialog()
    } else {
      generateSalesRoomLinkAndOpenDialog().catch(errorHandler)
    }
  }
  return (
    <>
      {salesRoomLinkResponse.fetching ? (
        <>
          <CircularProgress size={14} />
        </>
      ) : (
        <BillyLink
          linkProps={{
            role: 'button',
            onClick: onClickGenerateSalesRoomLink,
          }}
        >
          <Typography style={{ fontSize: '14px' }}>Sales Room Link</Typography>
        </BillyLink>
      )}
    </>
  )
}
