import { Maybe } from '../generated/graphql'
import buildLogger from './logger'

const logger = buildLogger('paymentTermUtil')

export const getPaymentTermDisplayText = (value?: Maybe<string>): string => {
  const isPaymentTerm = value?.startsWith('NET')
  if (!isPaymentTerm) {
    logger.info(`Invalid payment term: ${value}`)
    return ''
  }
  if (!value) {
    return ''
  }
  const termLength = value.substring(3).trim()
  return 'Net ' + termLength
}

export const getPaymentTermInputValue = (value?: string): string => {
  const parsed = value?.split('NET')?.[1]?.trim()
  const parsedInt = parseInt(parsed || '', 10)
  if (!parsed || isNaN(parsedInt) || parsedInt.toString() !== parsed) {
    return ''
  } else {
    return parsed
  }
}
