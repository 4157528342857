import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import ActionButton, { ButtonData } from '../button/actionButton'

type ActionDialogProps = {
  open: boolean
  title: string
  content: string
  actionButtonData: ButtonData
  cancelButtonData: ButtonData
}

function ActionDialog({ open, title, content, actionButtonData, cancelButtonData }: ActionDialogProps): JSX.Element {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'sm'}
      scroll={'paper'}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton buttonData={cancelButtonData} />
        <ActionButton buttonData={actionButtonData} />
      </DialogActions>
    </Dialog>
  )
}

export default ActionDialog
