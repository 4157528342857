import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useState } from 'react'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import { UpsertCrmContactResponseFragment } from '../../../generated/graphql'
import { ImportContactSummaryTable } from './ImportContactSummaryTable'

type ImportCrmContactSummaryDialogProps = {
  upsertResponse: readonly UpsertCrmContactResponseFragment[]
}

type DialogProps = WithModalParams & ImportCrmContactSummaryDialogProps

const formID = 'ImportCrmContactSummaryDialog'

export function ImportCrmContactSummaryDialog({ open, onClose, upsertResponse }: DialogProps): JSX.Element {
  return (
    <>
      <Dialog open={!!open} onClose={onClose} maxWidth={'md'} scroll={'paper'} fullWidth>
        <DialogTitle>Summary of Imported Contacts</DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <ImportContactSummaryTable upsertResponse={upsertResponse} />
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1 }} />
          <ActionButton
            key={'Close'}
            buttonData={{
              label: 'Close',
              onClick: onClose,
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export function useImportCrmContactSummaryDialog() {
  const [, , toggleModal] = useModalsContext()
  const [upsertResponse, setUpsertResponse] = useState<readonly UpsertCrmContactResponseFragment[]>([])

  useModal<DialogProps>({
    component: ImportCrmContactSummaryDialog,
    schema: {
      key: formID,
      modalProps: {
        upsertResponse,
      },
    },
  })

  const toggleCrmContactSummaryDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  const ret: [typeof toggleCrmContactSummaryDialog, typeof setUpsertResponse] = [
    toggleCrmContactSummaryDialog,
    setUpsertResponse,
  ]
  return ret
}
