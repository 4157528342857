import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useCallback, useMemo } from 'react'
import * as yup from 'yup'
import ActionButton from '../../../components/button/actionButton'
import JotaiMuiField from '../../../components/input/JotaiMuiField'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import JotaiFormProvider from '../../../components/state/jotaiFormProvider'
import JotaiReadValue from '../../../components/state/jotaiReadValue'
import useJotaiForm, { JotaiForm, WithYup } from '../../../components/state/useJotaiForm'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { CommonOrderFormPageState } from '../EditOrderPage/CommonOrderFormPageState'
import ReactTinyMCEEditor, { withTableStyleTemplate } from '@/components/input/ReactTinyMCEEditor'

export interface CustomContentDialogState extends WithYup {
  title: string
  content: string
}

export const localFormSchema = yup
  .object({
    title: yup.string().trim().max(100, 'Maximum 100 characters allowed').required(),
    content: yup.string().trim().max(65535, 'Maximum 65535 characters allowed').required(),
  })
  .defined()

type DocumentCustomContentDialogProps<F> = {
  orderPageForm: JotaiForm<F>
  onSubmit?: (form: CustomContentDialogState) => void
  onDelete?: () => void
}

export type DocumentCustomContentDialogPropsStates = Pick<CommonOrderFormPageState, 'orderDetail'>

type DialogProps<F> = WithModalParams & DocumentCustomContentDialogProps<F>
const formID = 'DocumentCustomContentDialog'

const DocumentCustomContentDialog = <F extends DocumentCustomContentDialogPropsStates>({
  orderPageForm,
  open,
  onClose,
  onDelete,
  onSubmit,
  onModalClose,
}: DialogProps<F>): JSX.Element => {
  const documentCustomContent = orderPageForm.useSelect(
    useCallback((form) => form.orderDetail.documentCustomContent, [])
  )
  const disableDelete = orderPageForm.useSelect(
    useCallback((form) => !form.orderDetail.documentCustomContent?.content, [])
  )

  const localForm = useJotaiForm<CustomContentDialogState>(
    useMemo(() => {
      return {
        defaultValue: {
          title: documentCustomContent?.title || '',
          content: documentCustomContent?.content || '',
        },
      }
    }, [documentCustomContent])
  )

  const onEditorChanged = (content: string): void => {
    localForm.set((draft) => {
      if (content.trim().length === 0) {
        draft.content = ''
      } else {
        draft.content = withTableStyleTemplate(content)
      }
    })
  }

  const handleSubmit = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: localForm.atom,
        onSubmit: (form: CustomContentDialogState) => {
          onSubmit?.(form)
          onClose?.()
        },
        schema: localFormSchema,
      }),
      [localForm.atom, onClose, onSubmit]
    )
  )

  const yupErrors = localForm.useSelect(useCallback((form) => form.yupErrors, []))

  return (
    <JotaiFormProvider form={localForm}>
      <Dialog open={!!open} onClose={onModalClose} maxWidth={'md'} scroll={'paper'} disableEnforceFocus={true}>
        <DialogTitle>Custom Terms</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {(!!yupErrors?.content || !!yupErrors?.title) && (
              <Grid item xs={12}>
                <Alert severity="warning">All fields are required</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <JotaiMuiField
                atomSelector={(form: CustomContentDialogState) => form.title}
                atomUpdater={(value, draft) => {
                  draft.title = value
                }}
                form={localForm}
                textFieldProps={{ label: 'Document Section Title' }}
              />
            </Grid>
            <Grid item xs={12}>
              <JotaiReadValue
                atomSelector={(form: CustomContentDialogState) => form.content}
                form={localForm}
                render={(content) => (
                  <>
                    <ReactTinyMCEEditor style={{ height: 350 }} value={content} onChange={onEditorChanged} />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ActionButton
            key={'Delete'}
            buttonData={{
              label: 'Delete',
              disabledExplanation: disableDelete ? 'No custom term to delete' : undefined,
              onClick: () => {
                onDelete?.()
                onClose?.()
              },
              color: 'danger',
              buttonProps: { variant: 'outlined', style: { marginLeft: 0 } },
            }}
          />
          <div style={{ flexGrow: 1 }} />
          <ActionButton
            key={'Cancel'}
            buttonData={{
              label: 'Cancel',
              onClick: onClose,
              color: 'inherit',
              buttonProps: { variant: 'outlined' },
            }}
          />
          <ActionButton
            key={'Save'}
            buttonData={{
              label: 'Save',
              onClick: handleSubmit,
            }}
          />
        </DialogActions>
      </Dialog>
    </JotaiFormProvider>
  )
}

export const useDocumentCustomContentDialog = <F extends DocumentCustomContentDialogPropsStates>(
  modalProps: DialogProps<F>
) => {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps<F>>({
    component: DocumentCustomContentDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
