import React, { ReactNode } from 'react'
import {
  CheckCircleOutlineOutlined,
  HighlightOffOutlined,
  InfoOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, AlertProps, AlertTitle, CircularProgress, IconButton, LinearProgress, Typography } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'

type ColorTypes = 'root' | 'success' | 'info' | 'warning' | 'error'

export interface BillyAlertProps {
  alertType: ColorTypes

  title?: string
  message?: string

  showIcon?: boolean
  showProgress?: boolean

  handleClose?: () => void

  alertProps?: AlertProps
  children?: ReactNode
}

const useStyles = makeStyles()((theme, _params, _classes) => ({
  root: {
    alignItems: 'center',
    borderRadius: 6,
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    color: theme.customPalette.textDark,
    backgroundColor: theme.customPalette.hoverColor1,
    border: `1px solid transparent`,
    '& .MuiAlert-icon .MuiSvgIcon-root': {
      color: '#4F97CF',
    },
    '& .MuiAlert-message, .MuiAlert-icon, .MuiAlert-action': {
      padding: 0,
    },
    '& .MuiAlert-message': {
      flexGrow: 1,
    },
    '& .MuiAlert-action': {
      width: 30,
      height: 24,
      '& .MuiIconButton-root': {
        padding: theme.spacing(1),
        '& .MuiSvgIcon-root': {
          width: 14,
          height: 14,
        },
      },
    },
  },
  success: {
    // backgroundColor: theme.palette.success.light,
    backgroundColor: '#E7F5EE',
    // border: `1px solid ${theme.palette.success.dark}`,
    border: `1px solid transparent`,
    '& .MuiAlert-icon .MuiSvgIcon-root': {
      // color: theme.palette.success.dark,
      color: '#3AAE76',
    },
  },
  info: {
    // backgroundColor: theme.palette.info.light,
    backgroundColor: '#E5F2F8',
    // border: `1px solid ${theme.palette.info.dark}`,
    border: `1px solid transparent`,
    '& .MuiAlert-icon .MuiSvgIcon-root': {
      // color: theme.palette.success.dark,
      color: '#4F97CF',
    },
  },
  warning: {
    // backgroundColor: theme.palette.warning.light,
    backgroundColor: '#FFFBE6',
    // border: `1px solid ${theme.palette.warning.dark}`,
    border: `1px solid #FFE58F`,
    '& .MuiAlert-icon .MuiSvgIcon-root': {
      // color: theme.palette.success.dark,
      color: '#FAAD14',
    },
  },
  error: {
    // backgroundColor: theme.palette.error.light,
    backgroundColor: '#FFEBEE',
    // border: `1px solid ${theme.palette.error.dark}`,
    border: `1px solid #EF9A9A`,
    '& .MuiAlert-icon .MuiSvgIcon-root': {
      // color: theme.palette.success.dark,
      color: '#F44336',
    },
  },
}))

const iconOutput = (type: string): JSX.Element => {
  switch (type) {
    case 'success':
      return <CheckCircleOutlineOutlined />
    case 'error':
      return <HighlightOffOutlined />
    case 'warning':
      return <WarningAmberOutlined />
    case 'info':
      return <InfoOutlined />
    default:
      return <CircularProgress style={{ width: 24, height: 24 }} />
  }
}

const BillyAlert: React.FC<React.PropsWithChildren<BillyAlertProps>> = ({
  alertType,

  title,
  message,

  showIcon,
  showProgress,

  handleClose,

  alertProps,

  children,
}): JSX.Element => {
  const { classes } = useStyles()

  return (
    <Alert
      className={clsx(classes.root, classes[alertType] || classes.info)}
      icon={(showIcon && iconOutput(alertType)) ?? false}
      action={
        handleClose && (
          <IconButton aria-label="close" size="large" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )
      }
      {...alertProps}
    >
      {title && (
        <AlertTitle>
          <>
            {title} {children}
          </>
        </AlertTitle>
      )}
      {showProgress && <LinearProgress variant="determinate" value={35} />}
      {message && (
        <Typography variant="body2" component="span">
          <>
            {message} {children}
          </>
        </Typography>
      )}
    </Alert>
  )
}

export default BillyAlert
