import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import { GetCrmContactsForAccountQuery } from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { ImportContactTable } from './ImportContactTable'

const logger = buildLogger('ImportCrmContactDialog')

export type ImportCrmContactDialogProps = {
  crmContacts: GetCrmContactsForAccountQuery['crmContactsForAccount']
  onSubmit?: (crmIds: readonly string[]) => Promise<void>
}

type DialogProps = Omit<WithModalParams, 'onSubmit'> & ImportCrmContactDialogProps
const formID = 'ImportCrmContactDialog'

export function ImportCrmContactDialog({ crmContacts, open, onClose, onSubmit }: DialogProps): JSX.Element {
  const [selectedContacts, setSelectedContacts] = useState<readonly GridRowId[]>([])
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    async function doAsync() {
      setIsSubmitting(true)
      const crmIds = selectedContacts.map((contact) => contact.toString())
      await onSubmit?.(crmIds)
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  const handleSelect = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      setSelectedContacts(rowSelectionModel)
    },
    [setSelectedContacts]
  )

  const disableImportExplanation = selectedContacts?.length ? '' : 'No contacts to import'

  useEffect(() => {
    if (open) {
      setSelectedContacts([])
    }
  }, [open])

  return (
    <>
      <Dialog open={!!open} maxWidth={'md'} scroll={'paper'} fullWidth>
        <DialogTitle>Add or Replace Selected Contacts</DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <form onSubmit={handleSubmit} id={formID}>
            <ImportContactTable contacts={crmContacts ?? []} onSelect={handleSelect} />
          </form>
        </DialogContent>
        <DialogActions>
          <div style={{ flexGrow: 1 }} />
          <ActionButton
            key={'Cancel'}
            buttonData={{
              label: 'Cancel',
              onClick: onClose,
              color: 'inherit',
              buttonProps: { variant: 'outlined' },
            }}
          />
          <ActionButton
            key={'Import Contacts'}
            buttonData={{
              label: 'Import Contacts',
              disabledExplanation: disableImportExplanation,
              loading: isSubmitting,
              buttonProps: {
                type: 'submit',
                form: formID,
              },
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export function useImportCrmContactDialog(modalProps: ImportCrmContactDialogProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ImportCrmContactDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
