import { useMemo } from 'react'
import { selectAtom, useAtomValue } from 'jotai/utils'
import buildLogger from '../../util/logger'
import { JotaiForm } from './useJotaiForm'

const logger = buildLogger('JotaiReadValue')

type JotaiReadValueProps<T, V> = {
  atomSelector: (form: T) => V
  form: JotaiForm<T>
  render: (value: V) => JSX.Element | undefined | null | false
}

export default function JotaiReadValue<T, V>({ atomSelector, form, render }: JotaiReadValueProps<T, V>): JSX.Element {
  const { atom } = form
  const selectorAtom = useMemo(() => selectAtom(atom, atomSelector), [atom, atomSelector])
  const atomValue = useAtomValue(selectorAtom)
  return useMemo(() => render(atomValue) || <></>, [atomValue, render])
}
