import { Button, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import BillyCard from '../card/billyCard'
import CenteredLayout from '../layout/centeredLayout'
import { EntityFragment } from '@/generated/graphql'
import Cookies from 'js-cookie'
import ActionMenu, { CustomMenuItemProps } from '../menu/actionMenu'
import { Box } from '@mui/system'
import Image from 'next/image'
import { ALL_ENTITIES } from '@/util/entity'
import { useEntitySession } from './UseEntitySession'

export function EntitySelectionPage({
  availableEntities,
  allowAllEntities = false,
}: {
  availableEntities: ReadonlyArray<EntityFragment> | undefined
  allowAllEntities?: boolean
}) {
  const showAllEntities = allowAllEntities && availableEntities && availableEntities?.length > 1
  const authorizedEntities = availableEntities as EntityFragment[]
  return (
    <CenteredLayout>
      <div>
        <BillyCard>
          <CardHeader title="Please select an entity to continue:" />
          <CardContent>
            <Grid container spacing={2}>
              {authorizedEntities
                ?.sort((a, b) => {
                  if (!a.displayId || !b.displayId) {
                    return -1
                  }
                  return a.displayId.localeCompare(b.displayId)
                })
                .map((entity) => (
                  <Grid item xs={12} key={entity.id}>
                    <Button
                      onClick={() => {
                        Cookies.set('entity_id', entity.id || '')
                        window.location.reload()
                      }}
                    >
                      {entity?.displayId} - {entity?.name}
                    </Button>
                  </Grid>
                ))}
              {showAllEntities && (
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      Cookies.set('entity_id', ALL_ENTITIES)
                      window.location.reload()
                    }}
                  >
                    All entities
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </BillyCard>
      </div>
    </CenteredLayout>
  )
}

export function EntitySelectionDropdown() {
  const entityContext = useEntitySession()

  let entities: CustomMenuItemProps[] = []
  let currentEntity: EntityFragment | undefined
  if (entityContext.hasFullSelection) {
    currentEntity = { name: 'All Entities', id: ALL_ENTITIES, displayId: 'All Entities' }
  } else {
    currentEntity = entityContext.selectedEntities[0]
  }

  if (entityContext.availableEntities.length > 1) {
    const authorizedEntities = entityContext.availableEntities as EntityFragment[]
    entities = authorizedEntities
      ?.sort((a, b) => {
        if (!a.displayId || !b.displayId) {
          return -1
        }
        return a.displayId.localeCompare(b.displayId)
      })
      .map((entity) => {
        return {
          onClick: () => {
            Cookies.set('entity_id', entity?.id || '')
            window.location.reload()
          },
          children: entity.displayId + ' - ' + entity?.name,
        }
      })
    entities.unshift({
      onClick: () => {
        Cookies.set('entity_id', ALL_ENTITIES)
        window.location.reload()
      },
      children: 'All Entities',
    })
  }

  return (
    <>
      {currentEntity && entities.length > 1 && (
        <Box aria-label="entity-menu" role="menu">
          <ActionMenu
            menuItems={entities}
            variant="app-bar"
            buttonProps={{ color: 'info' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', height: '64px' }}>
              <Typography component="span" variant="body2" align="left">
                {currentEntity.displayId}
              </Typography>
              <Box>
                <Image src="/icons/arrow.svg" width={15} height={7} />
              </Box>
            </Box>
          </ActionMenu>
        </Box>
      )}
    </>
  )
}
