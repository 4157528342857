import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Collapse, Grid, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import TooltipText from '../../components/typography/tooltipText'
import { PriceTier } from '../../generated/graphql'
import { unitPriceFormatter } from '../../util/currencyUtil'
import { formatPriceTier, mapPriceTier } from '../../util/priceTier'

export const PRICING_TIER_LIMIT = 50

export const usePriceTierStyles = makeStyles()((theme, _params, _classes) => ({
  tierPriceRange: {
    color: theme.customPalette.textGray,
    fontWeight: theme.typography.fontWeightRegular,
  },

  tierPriceValue: {
    textAlign: 'right',
    color: theme.customPalette.textGray,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

export const PriceTiersTable: React.FC<
  React.PropsWithChildren<{
    priceTiers: PriceTier[]
    currency?: string
    minQuantity?: number | null
    maxQuantity?: number | null
  }>
> = ({ priceTiers, currency, minQuantity, maxQuantity }) => {
  const { classes } = usePriceTierStyles()

  const mappedPriceTier = mapPriceTier(priceTiers, minQuantity, maxQuantity)
  const visiblePriceTier = mappedPriceTier.slice(0, PRICING_TIER_LIMIT)
  const shouldPromptShowMore = mappedPriceTier.length > PRICING_TIER_LIMIT

  return (
    <Grid container justifyContent={'flex-start'}>
      <Grid item container xs={6}>
        <Box display={'block'} maxWidth={'100%'} width={'fit-content'}>
          {visiblePriceTier.map((tier, i) => {
            return (
              <Grid item key={`price-range-${i}`} xs={12}>
                <TooltipText maxLength={12} className={classes.tierPriceRange} text={formatPriceTier(tier)} />
              </Grid>
            )
          })}
        </Box>
      </Grid>
      <Grid item container xs={6}>
        <Box display={'block'} maxWidth={'100%'} width={'fit-content'}>
          {visiblePriceTier.map((tier, i) => {
            const amount = unitPriceFormatter({ value: tier.amount, currency })
            return (
              <Grid item key={`price-value-${i}`} xs={12}>
                <TooltipText maxLength={12} className={classes.tierPriceValue} text={amount} />
              </Grid>
            )
          })}
        </Box>
      </Grid>
      {shouldPromptShowMore && <ShowMoreButton />}
    </Grid>
  )
}

export function ShowMoreButton() {
  const [showing, setShowing] = React.useState(false)
  return (
    <>
      <Grid item container xs={12}>
        <Button
          variant="text"
          size="small"
          sx={{
            padding: 0,
          }}
          onClick={() => {
            setShowing((prev) => !prev)
          }}
          endIcon={showing ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {showing ? 'Show Less' : 'Show More'}
        </Button>
      </Grid>
      <Grid item container xs={12}>
        <Collapse in={showing}>
          <Grid item container xs={12}>
            <Typography variant="caption">Please contact support for more pricing tiers information.</Typography>
          </Grid>
        </Collapse>
      </Grid>
    </>
  )
}
