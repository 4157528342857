import { createContext, useContext } from 'react'
import { GetUserTenantSessionQuery } from '../../generated/graphql'

export const UserTenantSessionContext = createContext<GetUserTenantSessionQuery | undefined>(undefined)

export function useUserTenantSession() {
  const result = useContext(UserTenantSessionContext)

  if (!result) {
    throw new Error('No UserTenantSessionContext defined.  Must be setup in the root context providers.')
  }

  return result
}

export function useUserTenantSessionWithoutThrowing() {
  const result = useContext(UserTenantSessionContext)
  return result
}
