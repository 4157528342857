import { CustomFieldBuilderDefinition } from '@/components/CustomFields/createCustomFieldsSchema'
import JotaiMuiField from '@/components/input/JotaiMuiField'
import JotaiMuiSelect from '@/components/input/JotaiMuiSelect'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CustomFieldEntryFragment, CustomFieldType } from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { MenuItem } from '@mui/material'
import { isArray } from 'lodash'
const logger = buildLogger('CustomColumnHeader')

export type CustomColumnEditFieldProps<T> = {
  definition: CustomFieldBuilderDefinition
  atomSelector: (form: T) => CustomFieldEntryFragment | undefined | null
  lineItemIndex: number
}

/**
 * TODO: once line items table is refactored out of jotaiForm, reimplement this component
 */
export function CustomColumnEditField<T extends Pick<NewOrderFormData, 'orderDetail'> = NewOrderFormData>({
  definition,
  atomSelector = () => undefined,
  lineItemIndex,
}: CustomColumnEditFieldProps<T>) {
  const form = useJotaiFormContext<T>()
  return (
    <>
      {definition.fieldType === CustomFieldType.MultiselectPicklist && (
        <JotaiMuiSelect
          atomSelector={(form) => {
            const selections = atomSelector(form)?.selections ?? []
            // selection must be an array if SelectProps.multiple is true
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return selections as any
          }}
          atomUpdater={(newSelections: string | string[], draft) => {
            if (isArray(newSelections)) {
              const customField = draft.orderDetail.lineItems[lineItemIndex].customFields?.find(
                (field) => field?.name === definition.fieldName
              )
              if (customField) {
                customField.selections = (newSelections as string[]) ?? []
              }
            }
          }}
          textFieldProps={{
            size: 'small',
            SelectProps: {
              onClick: (e) => {
                e.stopPropagation()
              },
              multiple: true,
            },
          }}
          //TODO: validation for custom fields
          form={form}
        >
          {definition.options?.filter(notEmpty)?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </JotaiMuiSelect>
      )}
      {definition.fieldType === CustomFieldType.Picklist && (
        <JotaiMuiSelect
          atomSelector={(form) => {
            return atomSelector(form)?.selections?.[0]
          }}
          atomUpdater={(value, draft) => {
            const customField = draft.orderDetail.lineItems[lineItemIndex].customFields?.find(
              (field) => field?.name === definition.fieldName
            )
            if (customField) {
              customField.selections = [value]
            }
          }}
          textFieldProps={{
            size: 'small',
          }}
          form={form}
        >
          {definition.options?.filter(notEmpty)?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </JotaiMuiSelect>
      )}
      {definition.fieldType === CustomFieldType.String && (
        <JotaiMuiField
          atomSelector={(form) => {
            return atomSelector(form)?.value
          }}
          atomUpdater={(value, draft) => {
            const customField = draft.orderDetail.lineItems[lineItemIndex].customFields?.find(
              (field) => field?.name === definition.fieldName
            )
            if (customField) {
              customField.value = value
            }
          }}
          textFieldProps={{
            size: 'small',
          }}
          form={form}
        />
      )}
    </>
  )
}
