import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Box, Link, Typography, TypographyProps } from '@mui/material'

const ClampedTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
`

export function ReadMore({
  children,
  className,
  lineClampCount,
  ...typographyProps
}: TypographyProps & { lineClampCount?: number }) {
  const [readMore, setReadMore] = useState(false)
  const clampedRef = useRef<HTMLParagraphElement | null>(null)
  const [isTextClamped, setTextClamped] = useState(false)
  useEffect(() => {
    setTextClamped(clampedRef.current ? clampedRef.current?.scrollHeight > clampedRef.current?.clientHeight : false)
  }, [clampedRef.current?.scrollHeight, clampedRef.current?.clientHeight])
  function toggleReadMore() {
    setReadMore(!readMore)
  }

  return (
    <Box>
      <ClampedTypography
        ref={clampedRef}
        style={{
          WebkitLineClamp: readMore ? undefined : lineClampCount || 3,
        }}
        {...typographyProps}
        className={className}
      >
        {children}
      </ClampedTypography>
      {(isTextClamped || readMore) && (
        <Link fontSize={typographyProps.fontSize} variant="button" onClick={toggleReadMore}>
          Read {readMore ? 'less' : 'more'}
        </Link>
      )}
    </Box>
  )
}
