import { dedupExchange, errorExchange, fetchExchange } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import { Cache, cacheExchange } from '@urql/exchange-graphcache'
import { NextPage } from 'next'
import { withUrqlClient } from 'next-urql'
import { ClientOptions } from 'urql'
import { isDevEnv } from '../../util/isEnv'
import buildLogger from '../../util/logger'
import { GraphQLErrors } from './GraphQLErrors'
import Cookies from 'js-cookie'
import { setWasAtUrl } from '../../util/cookieUtil'
import { handleEntityNotFoundStatusCode } from '../Entity/entityNotFoundStatusCodeHandler'

const logger = buildLogger('withBillyGql')

const introspectedSchema = {
  __schema: {
    queryType: { name: 'Query' },
    mutationType: { name: 'Mutation' },
    subscriptionType: { name: 'BillySubscription' },
  },
}

function resetAll(cache: Cache, queryMethod: string, gqlType: string): void {
  // TODO: this is a workaround hack due lack of a method to invalidate a list of cached objects
  // eslint-disable-next-line
  // @ts-expect-error
  const cacheLines = cache.data.links.base.get('Query')[queryMethod]
  cacheLines.forEach((key: string) => {
    const id = key.split(':')[1]
    cache.invalidate({ __typename: gqlType, id })
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withBillyGql<P, IP = P>(component: NextPage<P, IP>): NextPage<P, IP> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return withUrqlClient(
    function clientConfig(ssrExchange, ctx) {
      const fetchOptions = (): RequestInit => {
        const fetchOptions = {
          headers: {
            cookie: ctx && ctx.req && ctx.req.headers.cookie,
          } as HeadersInit,
        }
        if (typeof window == 'object') {
          const apiKey = window.localStorage.getItem('api_key') || Cookies.get('api_key')
          if (apiKey) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const headers: any = fetchOptions.headers
            headers['x-api-key'] = apiKey
          }
          const googleIdToken = window.localStorage.getItem('google_id_token')
          if (googleIdToken) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const headers: any = fetchOptions.headers
            headers['x-internal-id-token'] = googleIdToken
          }
        }
        return fetchOptions
      }
      const config: ClientOptions = {
        exchanges: [
          dedupExchange,
          cacheExchange({
            keys: {
              AccountAddress: () => null,
              AccountMetrics: () => null,
              AmendmentOrder: () => null,
              ApprovalFlowInstanceDetail: () => null,
              ApprovalFlowInstanceStateDetail: () => null,
              ApprovalFlowInstanceDataDetail: () => null,
              ApprovalRuleConditions: () => null,
              ApprovalStateAction: () => null,
              AvalaraIntegration: () => null,
              CatalogRelationship: () => null,
              CustomFieldWithParentReference: () => null,
              LedgerAccountMapping: () => null,
              CustomFieldEntry: () => null,
              BillyChartDataset: () => null,
              BillyChartDataValue: () => null,
              Currency: (currency: any) => currency.currencyCode,
              Discount: () => null,
              DiscountDetailJson: () => null,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              DocumentTemplate: (template: any) => template.id + template.version,
              DocuSignIntegrationResponse: (data: any) => data.integrationId,
              Invoice: (data: any) => data.invoiceNumber,
              InvoiceItem: () => null,
              OrderDetail: (order: any) => order.id,
              OrderLineItemDetail: () => null,
              PaymentStripeConnectIntegration: (integration) => integration.connectAccountId as string,
              PredefinedDiscountLineItemDetail: (discount: any) => discount.id + discount.amount,
              PredefinedReports: () => null,
              PredefinedReportDefinition: () => null,
              PredefinedReportParameter: () => null,
              PredefinedReportDefinitionChart: () => null,
              Recurrence: () => null,
              SalesforceIntegrationResponse: () => null,
              Setting: () => null,
              SubscriptionCharge: () => null,
              TimeSeriesAmount: () => null,
              TenantDetails: () => null,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              User: (user: any) => user.id,
              UserAuthInfo: () => null,
              FeatureSetting: () => null,
              priceTier: () => null,
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              tenantSignatory: ({ user }: any) => user?.id,
              orderPageConfiguration: () => null,
            },
            schema: introspectedSchema,
            updates: {
              Mutation: {
                updateOrderStatus: (_result, args, cache) =>
                  cache.invalidate({ __typename: 'OrderDetail', id: args.orderId as string }),
                updateAccount: (_result, args, cache) => {
                  const accountDetailArgs = args as any
                  cache.invalidate({ __typename: 'AccountDetail', id: accountDetailArgs.account.id })
                },
                upsertAccountContact: (_result, args, cache) => {
                  const contactArgs = args as any
                  cache.invalidate({ __typename: 'AccountDetail', id: contactArgs.accountId })
                },
                deleteAccountContact: (result, _args, cache) => {
                  const deleteAccountContactResult = result.deleteAccountContact as any
                  cache.invalidate({ __typename: 'AccountDetail', id: deleteAccountContactResult.accountId })
                },
                deleteUnitOfMeasure: (result, _args, cache) => {
                  const deletedObj = result.deleteUnitOfMeasure as any
                  cache.invalidate({ __typename: 'UnitOfMeasure', id: deletedObj.id })
                },
                upsertUnitOfMeasure: (_result, _args, cache) => {
                  resetAll(cache, 'unitsOfMeasure', 'UnitOfMeasure')
                },
                deleteTaxRate: (result, _args, cache) => {
                  const deletedObj = result.deleteTaxRate as any
                  cache.invalidate({ __typename: 'TaxRate', id: deletedObj.id })
                },
                upsertTaxRate: (_result, _args, cache) => {
                  resetAll(cache, 'taxRates', 'TaxRate')
                },
                deleteDocumentTemplate: (result, _args, cache) => {
                  const deletedObj = result.deleteDocumentTemplate as any
                  cache.invalidate({ __typename: 'DocumentTemplate', id: deletedObj.id })
                },
                upsertDocumentTemplate: (result, _args, cache) => {
                  const template = result.upsertDocumentTemplate as any
                  cache.invalidate({ __typename: 'DocumentTemplate', id: template.id })
                },
                applyPayment: (_result, args, cache) => {
                  const applyPaymentArgs = args as any
                  cache.invalidate({
                    __typename: 'Invoice',
                    invoiceNumber: applyPaymentArgs.ApplyPaymentRequest.invoiceNumber,
                  })
                },
                deleteDocuSignIntegration: (result, _args, cache) => {
                  const integrationData = result.deleteDocuSignIntegration as any
                  cache.invalidate({
                    __typename: 'DocuSignIntegrationResponse',
                    integrationId: integrationData.integrationId,
                  })
                },
                voidEsignatureDocumentV2: (result, _args, cache) => {
                  const voidedDocumentId = result.voidEsignatureDocumentV2 as string
                  cache.invalidate({
                    __typename: 'ElectronicSignature',
                    id: voidedDocumentId,
                  })
                },
              },
            },
          }),
          ssrExchange,
          errorExchange({
            onError: (error) => {
              if (!process.browser) {
                return
              }
              if (GraphQLErrors.requestFailedForLegalReasons(error)) {
                const origin = window.location.origin
                const legalUrl = new URL(`${origin}/legal`)
                logger.debug({ msg: 'Redirecting to terms and conditions page', legalUrl: legalUrl.toString() })
                window.location.replace(legalUrl.toString())
              } else if (GraphQLErrors.isAuthenticationRequired(error)) {
                if (!window.location.pathname.startsWith('/login')) {
                  const origin = window.location.origin
                  const loginUrl = new URL(`${origin}/login?logout=true`)
                  logger.debug({ msg: 'Redirecting to login page', loginUrl: loginUrl.toString() })
                  setWasAtUrl()
                  window.location.replace(loginUrl.toString())
                }
              } else if (GraphQLErrors.isEntitySelectionRequired(error)) {
                handleEntityNotFoundStatusCode()
              }
            },
          }),
          fetchExchange,
        ],
        fetchOptions,
        requestPolicy: 'cache-and-network',
        suspense: false,
        url: `/api/backend/graphql`,
        maskTypename: true,
      }

      if (isDevEnv()) {
        config.exchanges?.splice(0, 0, devtoolsExchange)
      }

      return config
    },
    { ssr: false }
  )(component)
}

// Temporary fix until we remove all instances of withBillyGql from all page components
export default function Identity<P, IP = P>(Component: NextPage<P, IP>, _forceLoggedIn?: boolean): NextPage<P, IP> {
  return Component
}
