import JotaiMuiNumberField from '@/components/input/JotaiMuiNumberField'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/CancelAndRestructureOrderPage'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import {
  useDryRunActions,
  useShouldRecalculateTotals,
} from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { OrderItemRowProps, useOrderChargeRowStyles } from '@/pageComponents/orders/LineItemsEditTable/orderChargeRow'
import { currencySymbol } from '@/util/currencyUtil'
import { InputAdornment } from '@mui/material'

export function JotaiLineItemCustomListPriceEditCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  currency,
}: OrderItemRowProps) {
  const { classes } = useOrderChargeRowStyles()
  const jotaiForm = useJotaiFormContext<F>()
  const { clearDryRun, queueDryRun } = useDryRunActions()
  const shouldRecalculateTotals = useShouldRecalculateTotals()

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <JotaiMuiNumberField
        atomSelector={(form) => form.orderDetail.lineItems[lineItemIndex]?.listUnitPrice}
        atomUpdater={(value, draft) => {
          const lineItem = draft.orderDetail.lineItems[lineItemIndex]
          if (lineItem) {
            lineItem.listUnitPrice = value
            lineItem.arrOverride = undefined
          }
        }}
        errorPath={`orderDetail.lineItems[${lineItemIndex}].listUnitPrice`}
        form={jotaiForm}
        textFieldProps={{
          className: classes.fieldShrink,
          label: '',
          size: 'small',
          disabled: shouldRecalculateTotals,
          sx: {
            maxWidth: 8 * 6,
            float: 'right',
            textAlign: 'right',
          },
          inputProps: {
            onFocus: clearDryRun,
            onBlur: queueDryRun,
            startAdornment: <InputAdornment position="start">{currencySymbol(currency || 'USD')}</InputAdornment>,
            style: { textAlign: 'right' },
          },
        }}
      />
    </div>
  )
}
