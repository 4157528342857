import { PlanSearchTableView } from '@/components/QuickCastBar/PlanSearchTable'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Dialog, DialogProps, FilterOptionsState, SvgIconTypeMap, createFilterOptions } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { DocumentNode } from 'graphql'
import {
  Feature,
  UpsertAccountContactDocument,
  UpsertAccountContactMutationVariables,
  UpsertAccountDocument,
  UpsertAccountMutationVariables,
} from '../../generated/graphql'
import buildLogger from '../../util/logger'
import { EsSearchOption } from '../input/JotaiMuiEsAutocomplete'
import { QuickCastOptionsSearcher } from './QuickCastOptionsSearcher'
import { SearchResultList } from '@/components/QuickCastBar/SearchResultList'
import { useQuickCastBarManager } from './useQuickCastBarManager'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'

export const logger = buildLogger('QuickCastBar')

type QuickCastOptionSlots = {
  gqlDocument?: DocumentNode
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  RenderPortal?: (...args: any[]) => JSX.Element
  params?: string[]
}

type QuickCastOptionSlotMappers = Partial<
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<keyof QuickCastOptionSlots, (ownerState: Partial<Record<keyof QuickCastOptionSlots, any>>) => any>
> & {
  dialogProps?: Partial<DialogProps>
}

export type QuickCastOption = EsSearchOption & {
  inputValue?: string
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
    muiName: string
  }
  domain?: string
  type?: 'command' | 'search'
  gqlDocument?: DocumentNode
  commandId?: string
  slot?: QuickCastOptionSlots
  slotMapper?: QuickCastOptionSlotMappers
}
export type QuickCastBarProps = {
  onChange?: (option: QuickCastOption) => void
}

type QuickCastParam<T extends string[]> = {
  [key in T[number]]: string
}

export const defaultOptions: QuickCastOption[] = [
  {
    id: 'list product',
    Icon: FormatListBulletedIcon,
    label: 'List Product',
    domain: 'Product',
    type: 'search',
    slot: {
      RenderPortal: PlanSearchTableView,
    },
    slotMapper: {
      dialogProps: {
        fullWidth: true,
        maxWidth: 'lg',
      },
    },
  },
  {
    id: 'add account',
    Icon: AddCircleOutlineIcon,
    label: 'Add Account',
    domain: 'Account',
    type: 'command',
    slot: {
      params: ['accountName'],
      gqlDocument: UpsertAccountDocument,
    },
    slotMapper: {
      gqlDocument: ({ params }: { params?: QuickCastParam<['accountName']> }): UpsertAccountMutationVariables => {
        return {
          account: {
            name: params?.accountName ?? '',
          },
        }
      },
    },
  },
  {
    id: 'add contact',
    Icon: AddCircleOutlineIcon,
    label: 'Add Contact',
    domain: 'Account',
    type: 'command',
    slot: {
      gqlDocument: UpsertAccountContactDocument,
      params: ['accountId', 'firstName', 'lastName', 'email'],
    },
    slotMapper: {
      gqlDocument: ({
        params,
      }: {
        params?: QuickCastParam<['accountId', 'firstName', 'lastName', 'email']>
      }): UpsertAccountContactMutationVariables => {
        return {
          email: params?.email ?? '',
          firstName: params?.firstName ?? '',
          lastName: params?.lastName ?? '',
          accountId: params?.accountId ?? '',
          skipValidation: true,
          strict: false,
        }
      },
    },
  },
]

export function getRequirementVariables(id: string) {
  return defaultOptions.find((option) => option.id === id)?.slot?.params ?? []
}

export const filterOptions = (options: QuickCastOption[], state: FilterOptionsState<QuickCastOption>) => {
  const filter = createFilterOptions<QuickCastOption>()
  const filtered = filter(options, state)
  const { inputValue } = state
  return inputValue ? [...filtered] : []
}

export function QuickCastBarManager({ onChange }: QuickCastBarProps): JSX.Element {
  const { quickCastOptionsSearcherProps, dialogProps, searchResultListProps, mode, targetOption, portalProps } =
    useQuickCastBarManager()

  return (
    <WithDynamicFeatureFlag status="enabled" feature={Feature.QuickAccess}>
      <Dialog maxWidth={'sm'} fullWidth scroll={'paper'} {...dialogProps}>
        {mode === 'options' && (
          <QuickCastOptionsSearcher {...quickCastOptionsSearcherProps} onChange={onChange}>
            <SearchResultList {...searchResultListProps} />
          </QuickCastOptionsSearcher>
        )}
        {mode === 'portal' && targetOption?.slot?.RenderPortal && (
          <targetOption.slot.RenderPortal {...targetOption.slotMapper?.['RenderPortal']?.({})} {...portalProps} />
        )}
      </Dialog>
    </WithDynamicFeatureFlag>
  )
}

export function getCommandParamWithId(options: QuickCastOption[], targetOption: QuickCastOption) {
  return options.find((option) => option.type === 'command' && option.id === targetOption.commandId)
}
