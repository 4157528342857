import ActionButton from '@/components/button/actionButton'
import BillyCard from '@/components/card/billyCard'
import { useOrderLevelCustomFieldEditDialog } from '@/components/CustomFields/useOrderLevelCustomFieldEditDialog'
import { formatCustomFieldEntry } from '@/components/CustomFields/utils'
import BillyGridCell from '@/components/grid/billyGridCell'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import TableEmptyContent from '@/components/table/tableEmptyContent'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { CardContent, CardHeader, Grid } from '@mui/material'
import React, { useCallback } from 'react'
const logger = buildLogger('EditOrderCustomFieldsCard')

export type EditOrderCustomFieldsCardProps = {
  children?: React.ReactNode
  disableHiddenCardOnEmptyCustomFields?: boolean
}

export function EditOrderCustomFieldsCard({
  children,
  disableHiddenCardOnEmptyCustomFields,
}: EditOrderCustomFieldsCardProps): JSX.Element {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const { customFields } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      const { customFields } = form.orderDetail
      return {
        customFields: customFields?.filter(notEmpty) ?? [],
      }
    }, [])
  )

  const { queueDryRun } = useDryRunActions()
  const [onModifyOrderLevelCustomFields] = useOrderLevelCustomFieldEditDialog({
    onSubmit: queueDryRun,
  })

  const hasCustomFields = !!customFields.length
  const shouldHide = disableHiddenCardOnEmptyCustomFields ? false : !hasCustomFields

  return !shouldHide ? (
    <BillyCard>
      <CardHeader
        title="Custom Fields"
        action={
          <ActionButton
            mode="card-action"
            buttonData={{
              label: 'Modify',
              onClick: onModifyOrderLevelCustomFields,
              buttonProps: {
                'aria-label': 'Modify Order Level Custom Fields',
              },
              disabledExplanation: !hasCustomFields ? 'No custom fields to modify' : '',
            }}
          />
        }
      />
      <CardContent>
        {children}
        {customFields.length ? (
          <Grid container spacing={2}>
            {customFields.map((customField) => {
              const formatted = formatCustomFieldEntry(customField)
              return (
                <BillyGridCell
                  key={customField.id}
                  xs={12}
                  md={3}
                  {...formatted}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                />
              )
            })}
          </Grid>
        ) : (
          <TableEmptyContent message="No custom fields" />
        )}
      </CardContent>
    </BillyCard>
  ) : (
    <></>
  )
}
