import { useCallback, useMemo } from 'react'
import { WritableAtom, atom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import buildLogger from '../../util/logger'
import JotaiReadAtom from '../state/jotaiReadAtom'
import JotaiReadValue from '../state/jotaiReadValue'
import { JotaiForm } from '../state/useJotaiForm'
import BillySnackBar from './billySnackBar'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'

const logger = buildLogger('UseJotaiBillySnackBar')

export type UseJotaiBillySnackBarProps<T> = {
  isWorkingAtomSelector?: (form: T) => boolean | undefined
  jotaiForm: JotaiForm<T>
  onSuccessClose?: () => void
  showSuccessAtom?: WritableAtom<boolean, boolean>
  successMessage?: string
  workingMessage?: string
}

export type UseJotaiBillySnackBarResult = {
  jsxElement: JSX.Element
  showSuccess: () => void
}

function useJotaiBillySnackBar<T>({
  isWorkingAtomSelector,
  jotaiForm,
  onSuccessClose,
  successMessage,
  workingMessage,
  ...props
}: UseJotaiBillySnackBarProps<T>): UseJotaiBillySnackBarResult {
  let showSuccessAtom = useMemo(() => atom(false) as WritableAtom<boolean, boolean>, [])
  if (props.showSuccessAtom) {
    showSuccessAtom = props.showSuccessAtom
  }

  const setShowSuccess = useAtomCallback<void, boolean>(
    useCallback(
      (get, set, showSuccess) => {
        set(showSuccessAtom, showSuccess)
      },
      [showSuccessAtom]
    )
  )

  const errorHandler = useErrorHandler()
  return useMemo(() => {
    const atomSelector = isWorkingAtomSelector ?? (() => false)

    return {
      jsxElement: (
        <>
          <JotaiReadValue
            atomSelector={atomSelector}
            form={jotaiForm}
            render={(isWorking: boolean | undefined) => {
              return (
                <BillySnackBar isOpen={isWorking || false} type="in-progress" message={workingMessage || 'Saving'} />
              )
            }}
          />
          <JotaiReadAtom
            atom={showSuccessAtom}
            render={(showSuccess) => (
              <BillySnackBar
                handleClose={() => {
                  setShowSuccess(false).catch(errorHandler)
                  onSuccessClose && onSuccessClose()
                }}
                isOpen={showSuccess}
                message={successMessage || 'Saved Successfully'}
                type="success"
              />
            )}
          />
        </>
      ),
      showSuccess: () => {
        setShowSuccess(true).catch(errorHandler)
      },
    }
  }, [
    isWorkingAtomSelector,
    jotaiForm,
    onSuccessClose,
    setShowSuccess,
    showSuccessAtom,
    successMessage,
    workingMessage,
    errorHandler,
  ])
}

export default useJotaiBillySnackBar
