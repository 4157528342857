import { OrderCustomerDetailsSection } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/DetailsGridLayout'
import { OrderDetailsSection } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/OrderDetailsSection'
import { CardContent } from '@mui/material'
import { memo } from 'react'
import BillyCard from '../../../../../components/card/billyCard'
import { EditOrderSubscriptionDetailsForm } from './EditOrderSubscriptionDetailForm'
import { OrderDetailsHeader } from './OrderDetailsHeader'
import { WithCustomizationLocator } from '@/components/state/context/customizationContext'
export type FieldProps = {
  Field: React.FunctionComponent
  hidden?: boolean
}
export function BillyOrderDetailsCardWithoutMemo({
  parentLocator,
  currencySelectDisableExplanation,
}: { currencySelectDisableExplanation?: string } & WithCustomizationLocator): JSX.Element {
  return (
    <BillyCard aria-label="order-edit-details-card">
      <OrderDetailsHeader />
      <CardContent>
        <OrderDetailsSection />
        <OrderCustomerDetailsSection />
        <EditOrderSubscriptionDetailsForm
          parentLocator={parentLocator}
          currencySelectDisableExplanation={currencySelectDisableExplanation}
        />
      </CardContent>
    </BillyCard>
  )
}
export const BillyOrderDetailsCard = memo(BillyOrderDetailsCardWithoutMemo)
