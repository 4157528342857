// eslint-disable-next-line @typescript-eslint/ban-types
type DeepImmutablePrimitive = undefined | null | boolean | string | number | Function

/**
 * Converts a type to becoming immutable where all properties are readonly
 * and all objects, arrays and maps as well.
 *
 * Note that there is an Immutable in immer, but there's no inverse of this in a
 * DeepMutable which we also need for compatibility with mutable types.
 */
export type DeepImmutable<T> = T extends DeepImmutablePrimitive
  ? T
  : T extends Array<infer U>
  ? DeepImmutableArray<U>
  : T extends Map<infer K, infer V>
  ? DeepImmutableMap<K, V>
  : T extends Set<infer M>
  ? DeepImmutableSet<M>
  : DeepImmutableObject<T>

export type DeepImmutableArray<T> = ReadonlyArray<DeepImmutable<T>>
export type DeepImmutableMap<K, V> = ReadonlyMap<DeepImmutable<K>, DeepImmutable<V>>
export type DeepImmutableSet<T> = ReadonlySet<DeepImmutable<T>>
export type DeepImmutableObject<T> = { readonly [K in keyof T]: DeepImmutable<T[K]> }

/**
 * Wrapper function that takes a parameterized type to allow us to cast
 * from T to DeepImmutable<T> without accidentally casting to an incompatible
 * type since 'as' is a cast and can be dangerous.
 */
export function deepImmutable<T>(instance: T): DeepImmutable<T> {
  return instance as DeepImmutable<T>
}
