import { useCallback } from 'react'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { OperationContext } from '@urql/core/dist/types/types'
import { DocumentNode } from 'graphql'
import { OperationDefinitionNode } from 'graphql/language/ast'
import { Draft } from 'immer'
import { useClient } from 'urql'
import { getDocumentName } from '../../util/gqlUtil'
import { JotaiForm } from './useJotaiForm'
import { WithUrql } from './useJotaiUrqlQuery'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'

export type UseJotaiUrqlMutationProps<T extends WithUrql, Q, V> = {
  document: TypedDocumentNode<Q, V>
  jotaiForm: JotaiForm<T>
  refreshDocuments?: DocumentNode[]
}

export function useJotaiUrqlMutation<T extends WithUrql, Q, V extends Record<string, unknown>>({
  document,
  jotaiForm,
  refreshDocuments,
}: UseJotaiUrqlMutationProps<T, Q, V>): (args: {
  onData?: (data: Q, draft: Draft<T>) => void
  mutationOpts?: Partial<OperationContext>
  variables: V
}) => void {
  const urql = useClient()

  const name = getDocumentName(document)

  const errorHandler = useErrorHandler()

  return useCallback(
    ({
      onData,
      mutationOpts,
      variables,
    }: {
      onData?: (data: Q, draft: Draft<T>) => void
      mutationOpts?: Partial<OperationContext>
      variables: V
    }) => {
      urql
        .mutation(document, variables, mutationOpts)
        .toPromise()
        .then((response) => {
          jotaiForm.reset((form) => {
            if (form.urqlIsFetching?.[name]) {
              form.urqlIsFetching[name] = false
            }
          })
          if (response.error && response.error.message) {
            jotaiForm.reset((form) => {
              if (!form.urqlErrors) {
                form.urqlErrors = {}
              }
              form.urqlErrors[name] = response.error as any
            })
            return
          }
          if (response.data && onData) {
            const data = response.data
            jotaiForm.reset((draft) => {
              if (!draft.urqlSuccesses) {
                draft.urqlSuccesses = {}
              }
              draft.urqlSuccesses[name] = true
              if (refreshDocuments) {
                refreshDocuments.forEach((refreshDoc) => {
                  const opNode = refreshDoc.definitions.find(
                    (definition) => definition.kind === 'OperationDefinition'
                  ) as OperationDefinitionNode | undefined
                  const name = opNode?.name?.value || ''
                  if (!draft.urqlRefreshCounter) {
                    draft.urqlRefreshCounter = {}
                  }
                  if (!draft.urqlRefreshCounter[name]) {
                    draft.urqlRefreshCounter[name] = 1
                  } else {
                    draft.urqlRefreshCounter[name] += 1
                  }
                })
              }
              onData(data, draft)
            })
          }
        })
        .catch(errorHandler)
      jotaiForm.reset((form) => {
        if (!form.urqlIsFetching) {
          form.urqlIsFetching = {}
        }
        form.urqlIsFetching[name] = true
      })
    },
    [document, jotaiForm, name, urql, refreshDocuments, errorHandler]
  )
}
