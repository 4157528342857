import ActionButton from '@/components/button/actionButton'
import JotaiUrqlErrors from '@/components/error/jotaiUrqlErrors'
import JotaiMuiField from '@/components/input/JotaiMuiField'
import JotaiMuiRadioGroup from '@/components/input/JotaiMuiRadioGroup'
import { JotaiUrqlBillySnackBar } from '@/components/snackBar/useJotaiUrqlBillySnackBar'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import JotaiReadValue from '@/components/state/jotaiReadValue'
import useJotaiOnSubmit from '@/components/state/useJotaiOnSubmit'
import { useJotaiUrqlMutation } from '@/components/state/useJotaiUrqlMutation'
import {
  ApprovalFlowInstanceStatus,
  GetOrderDetailDocument,
  InputApprovalFlowInstanceStateUpdates,
  UpdateOrderApprovalStateDocument,
} from '@/generated/graphql'
import { OrderApprovalFlowsViewForm } from '@/pageComponents/orders/OrderApprovalFlowView/OrderApprovalFlowsView'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material'
import { useCallback, useMemo } from 'react'

export function OrderApprovalDialog(): JSX.Element {
  const jotaiForm = useJotaiFormContext<OrderApprovalFlowsViewForm>()
  const isOpen = jotaiForm.useSelect(useCallback((form) => !!form.approvalDialogData, []))
  const updateState = useJotaiUrqlMutation({
    document: UpdateOrderApprovalStateDocument,
    jotaiForm,
    refreshDocuments: [GetOrderDetailDocument],
  })
  const isApprovalStateUpdating = jotaiForm.useSelect(
    useCallback((form) => form.urqlIsFetching?.UpdateOrderApprovalState, [])
  )

  const onSubmit = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: jotaiForm.atom,
        onSubmit: (form: OrderApprovalFlowsViewForm) => {
          const data = form.approvalDialogData
          const status =
            form.approvalDialogRadio == 'Approve'
              ? ApprovalFlowInstanceStatus.Approved
              : ApprovalFlowInstanceStatus.Rejected
          const note = form.approvalDialogNote
          if (data) {
            const orderApprovalStateUpdates: InputApprovalFlowInstanceStateUpdates = {
              groupId: data.orderApprovalFlow.id,
              stateUpdates: [
                {
                  approvalFlowId: data.approvalFlowInstance.approvalFlowId,
                  stateId: data.approvalState.id,
                  status,
                  note,
                },
              ],
            }
            updateState({
              onData: (data, draft) => {
                draft.approvalDialogData = undefined
                draft.approvalDialogNote = ''
              },
              variables: { orderApprovalStateUpdates },
            })
          }
        },
      }),
      // prevent infinite re-render
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  )

  const confirmOrRejectButton = useMemo(() => {
    return (
      <JotaiReadValue
        atomSelector={(form) => form.approvalDialogRadio}
        form={jotaiForm}
        render={(approvalDialogRadio) =>
          approvalDialogRadio === 'Approve' ? (
            <ActionButton
              buttonData={{
                label: 'Confirm',
                onClick: onSubmit,
                disabledExplanation: isApprovalStateUpdating
                  ? 'Please wait while the request is in progress'
                  : undefined,
              }}
            />
          ) : (
            <ActionButton
              buttonData={{
                color: 'error',
                label: 'Revert to Draft',
                onClick: onSubmit,
                disabledExplanation: isApprovalStateUpdating
                  ? 'Please wait while the request is in progress'
                  : undefined,
              }}
            />
          )
        }
      />
    )
  }, [onSubmit, jotaiForm, isApprovalStateUpdating])

  const subtitle = useMemo(
    () => (
      <JotaiReadValue
        atomSelector={(form: OrderApprovalFlowsViewForm) => form.approvalDialogData?.approvalFlowInstance.data.name}
        form={jotaiForm}
        render={(name) => <Typography fontWeight={600}>{name}</Typography>}
      />
    ),
    [jotaiForm]
  )

  return (
    <Dialog open={isOpen} maxWidth={'sm'} scroll={'paper'}>
      <DialogTitle>Review Approval</DialogTitle>
      <DialogContent dividers={true} sx={{ minWidth: 400 }}>
        <JotaiUrqlErrors jotaiForm={jotaiForm} />
        <JotaiUrqlBillySnackBar
          jotaiForm={jotaiForm}
          document={UpdateOrderApprovalStateDocument}
          workingMessage="Saving"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {subtitle}
          </Grid>
          <Grid item xs={12} mb={2}>
            <JotaiMuiRadioGroup
              atomSelector={(form: OrderApprovalFlowsViewForm) => form.approvalDialogRadio}
              form={jotaiForm}
              atomUpdater={(value, draft) =>
                (draft.approvalDialogRadio = value as OrderApprovalFlowsViewForm['approvalDialogRadio'])
              }
            >
              <Grid container direction="row" spacing={0}>
                <Grid item xs={12}>
                  <FormControlLabel value="Approve" control={<Radio color="primary" />} label="Approve" />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel value="Reject" control={<Radio color="primary" />} label="Reject" />
                </Grid>
              </Grid>
            </JotaiMuiRadioGroup>
          </Grid>
          <Grid item xs={12}>
            <JotaiMuiField
              atomSelector={(form: OrderApprovalFlowsViewForm) => form.approvalDialogNote}
              form={jotaiForm}
              atomUpdater={(value, draft) =>
                (draft.approvalDialogNote = value as OrderApprovalFlowsViewForm['approvalDialogRadio'])
              }
              textFieldProps={{
                label: 'Comment',
                multiline: true,
                minRows: 2,
                placeholder: 'Optional',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: () =>
              jotaiForm.set((form) => {
                form.approvalDialogData = undefined
                form.urqlErrors = undefined
              }),
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        {confirmOrRejectButton}
      </DialogActions>
    </Dialog>
  )
}
