import JotaiMuiSelect from '@/components/input/JotaiMuiSelect'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { ChargeFragment, LineItemFragment } from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import { findPriceTier, formatPriceTier, mapPriceTier, mapTierToQuantity } from '@/util/priceTier'
import { MenuItem, TextFieldProps } from '@mui/material'
import Big from 'big.js'
import { memo } from 'react'

type JotaiBlockQuantitySelectCellProps = {
  lineItemIndex: number
  jotaiForm: JotaiForm<NewOrderFormData>
  lineItem: Pick<LineItemFragment, 'charge'>
  charge: Pick<ChargeFragment, 'priceTiers' | 'minQuantity' | 'maxQuantity'>
  textFieldProps?: TextFieldProps
}

function JotaiBlockQuantitySelectCellWithoutMemo({
  lineItemIndex,
  jotaiForm,
  lineItem,
  charge,
  textFieldProps,
  ...props
}: JotaiBlockQuantitySelectCellProps) {
  return (
    <JotaiMuiSelect
      atomSelector={(form) => {
        const lineItem = form.orderDetail.lineItems[lineItemIndex]
        return mapTierToQuantity(
          findPriceTier(
            mapPriceTier(
              lineItem?.charge?.priceTiers?.filter(notEmpty) ?? [],
              lineItem?.charge?.minQuantity,
              lineItem?.charge?.maxQuantity
            ),
            lineItem?.quantity ?? 0
          )
        )
      }}
      atomUpdater={(value, draft) => {
        const lineItem = draft.orderDetail.lineItems[lineItemIndex]
        if (lineItem) {
          lineItem.quantity = Big(value).toNumber()
          lineItem.arrOverride = undefined
        }
      }}
      errorPath={`orderDetail.lineItems[${lineItemIndex}].quantity`}
      textFieldProps={{
        size: 'small',
        ...textFieldProps,
      }}
      form={jotaiForm}
      {...props}
    >
      {mapPriceTier(lineItem?.charge?.priceTiers?.filter(notEmpty) ?? [], charge.minQuantity, charge.maxQuantity).map(
        (tier) => {
          const content = formatPriceTier(tier)
          const quantity = mapTierToQuantity(tier)
          return (
            <MenuItem key={content} value={quantity}>
              {content}
            </MenuItem>
          )
        }
      )}
    </JotaiMuiSelect>
  )
}

export const JotaiBlockQuantitySelectCell = memo(JotaiBlockQuantitySelectCellWithoutMemo)
