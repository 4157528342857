import { GraphQLError } from 'graphql'
import { useCallback } from 'react'
import { useSnackbarHandler } from '../../components/SnackbarHandler/SnackbarHandler'
import {
  AccountAddressFragment,
  UpsertAccountContactMutationVariables,
  useUpsertAccountContactMutation,
} from '../../generated/graphql'
import { isTypeOfCombinedError } from '../../util/gqlErrorHandling'
import { useErrorHandler } from '../../components/ErrorHandler/ErrorHandler'

type ValidationError = {
  readonly suggestedAddress: AccountAddressFragment
}

/**
 * Delegate that allow us to easily test account contact upsert and to test errors.
 */
export async function handleUpsertAccountContact(
  variables: UpsertAccountContactMutationVariables,
  upsertAccountContact: ReturnType<typeof useUpsertAccountContactMutation>[1]
): Promise<
  [ValidationError | undefined, Awaited<ReturnType<ReturnType<typeof useUpsertAccountContactMutation>[1]>> | undefined]
> {
  try {
    const response = await upsertAccountContact(variables)
    return [undefined, response]
  } catch (err) {
    if (isTypeOfCombinedError(err)) {
      const graphQLErrors = err.graphQLErrors
      const errorLineWithAddressError = graphQLErrors.find((errItem: GraphQLError) => {
        return errItem.extensions && errItem.extensions?.errorId && errItem.extensions?.errorId === 'AddressValidation'
      })

      if (errorLineWithAddressError && errorLineWithAddressError.extensions?.suggestedAddress) {
        return [
          {
            suggestedAddress: errorLineWithAddressError.extensions.suggestedAddress,
          },
          undefined,
        ]
      }
    }

    throw err
  }
}

type UseHandleUpsertAccountContactOpts = Readonly<{
  onAddressValidationError?: (suggestedAddress: AccountAddressFragment) => void
}>

export const useHandleUpsertAccountContact = (opts: UseHandleUpsertAccountContactOpts) => {
  const { onAddressValidationError } = opts
  const snackbarHandler = useSnackbarHandler()
  const errorHandler = useErrorHandler()

  const [, upsertAccountContact] = useUpsertAccountContactMutation()

  return useCallback(
    async (variables?: UpsertAccountContactMutationVariables) => {
      if (!variables) return false

      const key = snackbarHandler.inProgressAlert('Saving contact...')
      try {
        const [validationError, response] = await handleUpsertAccountContact(variables, upsertAccountContact)

        if (validationError) {
          onAddressValidationError?.(validationError.suggestedAddress)
        } else {
          snackbarHandler.successAlert('Contact saved successfully')
        }
        return response
      } catch (err) {
        errorHandler(err)
        return false
      } finally {
        snackbarHandler.dismissAlert(key)
      }
    },
    [snackbarHandler, upsertAccountContact, onAddressValidationError, errorHandler]
  )
}
