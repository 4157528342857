import React from 'react'
import StarIcon from '@mui/icons-material/Star'
import { Chip, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

type PrimaryOrderChipProps = {
  value?: boolean | null
}

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: theme.customPalette.textGray,
  },
  chip: {
    borderRadius: '2px',
    border: `1px solid`,
    background: theme.palette.background.default,
    borderColor: theme.customPalette.disabledBorder,
    color: theme.palette.text.primary,
  },
}))

function PrimaryOrderChip({ value }: PrimaryOrderChipProps): JSX.Element {
  const { classes } = useStyles()

  return value ? (
    <Tooltip title={'Primary CRM Opportunity'}>
      <Chip icon={<StarIcon className={classes.icon}></StarIcon>} className={classes.chip} label={'Primary'} />
    </Tooltip>
  ) : (
    <></>
  )
}

export default PrimaryOrderChip
