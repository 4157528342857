import React from 'react'
import { Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type Props = {
  children?: React.ReactNode
}

const useStyles = makeStyles()((theme, _params, _classes) => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

export default function InnerPageContainer({ children }: Props): JSX.Element {
  const { classes } = useStyles()
  return (
    <Container className={classes.container} maxWidth={false}>
      <>{children}</>
    </Container>
  )
}
