import { Grid, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { AccountContactFragment } from '../../../generated/graphql'
import TooltipText from '../../typography/tooltipText'
import AddressCell from './addressCell'

const useStyle = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
}))
export interface ContactCellProps extends React.HTMLAttributes<HTMLDivElement> {
  contact: AccountContactFragment
}

export function MissingContactCell(): JSX.Element {
  return (
    <Grid container wrap="nowrap" direction="column" justifyContent="start">
      <Typography variant="body2" color="error">
        Missing
      </Typography>
    </Grid>
  )
}
export default function ContactCell({ contact, className }: ContactCellProps): JSX.Element {
  // TODO: we should use a library and internationalize address rendering.
  const { classes, cx } = useStyle()

  return (
    <Grid container wrap="nowrap" direction="column" className={cx(classes.root, className)} justifyContent="start">
      <Grid item>
        <Typography variant="body2">{`${contact.firstName} ${contact.lastName}`.trim()}</Typography>
      </Grid>
      <Grid item>
        <TooltipText variant="body2" text={`<${contact.email}>`} maxLength={12} />
      </Grid>
      <Grid item>
        <Typography variant="body2">
          <AddressCell address={contact.address} />
        </Typography>
      </Grid>
    </Grid>
  )
}
