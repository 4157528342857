import { WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { CardContent, CardHeader, Grid } from '@mui/material'
import BillyCard from '../../../../../components/card/billyCard'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'
import { BillingAnchorDateField } from './BillingAnchorDateField'
import { NewOrderBillingCycleStartDatePicker } from './NewOrderBillingCycleStartDatePicker'
import { NewOrderPaymentTermSelect } from './NewOrderPaymentTermSelect'
import { PurchaseOrderNumberField } from './PurchaseOrderNumberField'
import { PurchaseOrderRequiredInvoicingField } from './PurchaseOrderRequiredInvoicingField'

export function OrderEditBillingCard({ parentLocator }: WithCustomizationLocator) {
  const { classes } = useOrderPageFormStyles()
  return (
    <BillyCard aria-label="order-edit-billing-card">
      <CardHeader title="Billing" />
      <CardContent>
        <Grid container direction="row" spacing={2} className={classes.formRow}>
          <BillingAnchorDateField />
          <NewOrderBillingCycleStartDatePicker parentLocator={parentLocator} />
          <NewOrderPaymentTermSelect />
          <PurchaseOrderNumberField />
          <PurchaseOrderRequiredInvoicingField />
        </Grid>
      </CardContent>
    </BillyCard>
  )
}
