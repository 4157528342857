import { NextRouter, useRouter } from 'next/router'
import { useMemo } from 'react'
import { ES_WAIT_TIME_IN_MS } from '../../util/searchUtil'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'

export interface BillyRouter extends Omit<NextRouter, 'push' | 'replace'> {
  esDelay: (...args: any[]) => Promise<void>
  push: (...args: Parameters<NextRouter['push']>) => void
  replace: (...args: Parameters<NextRouter['replace']>) => void
}
export const useBillyRouter = (): BillyRouter => {
  const router = useRouter()
  const errorHandler = useErrorHandler()

  return useMemo(
    () => ({
      ...router,
      esDelay: (delay: number = ES_WAIT_TIME_IN_MS): Promise<void> => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, delay)
        })
      },
      push: (...args: Parameters<NextRouter['push']>) => {
        return router.push(...args).catch(errorHandler)
      },
      replace: (...args: Parameters<NextRouter['replace']>) => {
        return router.replace(...args).catch(errorHandler)
      },
    }),
    [router, errorHandler]
  )
}
