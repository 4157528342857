import MoreVertIcon from '@mui/icons-material/MoreVert'
import ActionMenu from '../../../../components/menu/actionMenu'
import { useLineItemEditActions } from '../LineItemEditContextProvider'
import { OrderItemRowProps } from '../orderChargeRow'
import { useLineItemActions } from '../useLineItemActions'

export function LineItemsActionMenuCell({ lineItemIndex, orderType, currency }: OrderItemRowProps) {
  const { onClickDiscount, onClickPriceOverride, onClickCustomField, onClickArrOverride } = useLineItemEditActions()

  const { amendmentMenuItems, actionMenuItems } = useLineItemActions({
    lineItemIndex,
    orderType,
    onClickCustomField,
    onClickArrOverride,
    currency,
    onClickDiscount,
    onClickPriceOverride,
  })

  return orderType !== 'CANCELLATION' ? (
    orderType === 'AMENDMENT' ? (
      <ActionMenu variant="icon-button" menuItems={amendmentMenuItems} buttonProps={{ title: 'More' }}>
        <MoreVertIcon />
      </ActionMenu>
    ) : (
      <ActionMenu variant="icon-button" menuItems={actionMenuItems} buttonProps={{ title: 'More' }}>
        <MoreVertIcon />
      </ActionMenu>
    )
  ) : (
    <></>
  )
}
