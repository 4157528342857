import BillyGridCell from '@/components/grid/billyGridCell'
import { BillyMuiDateField } from '@/components/input/JotaiMuiDateField'
import { BillyMuiSelect } from '@/components/input/JotaiMuiSelect'
import { RampScheduleView } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderTimelineCard/RampScheduleView'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'
import buildLogger from '@/util/logger'
import { AddCircleOutlineOutlined, CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material'
import { Box, Button, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Stack } from '@mui/material'
import {
  RampIntervalSelectId,
  RampIntervalSelectIds,
  RampSettingViewProps,
  formatRampIntervalType,
} from '../../hooks/useRampIntervalReducer'
import { DisplayStartDateType } from './EditTimelineDialog'
import { InfoTooltip } from '@/components/typography/InfoTooltip'
export const logger = buildLogger('RampSettingView')

export function RampSettingView({
  rampIntervalType,
  intervals,
  deleteInterval,
  addInterval,
  disableSelect,
  updateInterval,
  updateIntervalType,
  isRampLineLevel,
  disableRamp,
  intervalProps,
  startDateType,
}: RampSettingViewProps) {
  const { classes } = useOrderPageFormStyles()

  return (
    <BillyGridCell
      label={
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 0.5 }}>
          Ramp Deal Interval
          {startDateType === DisplayStartDateType.EXECUTION_DATE && (
            <InfoTooltip description='Ramp deals are not supported when "Order Start Date Type" is set to "Order Execution Date"' />
          )}
        </Box>
      }
    >
      <Grid container direction="row" gap={2}>
        {disableSelect ? (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <RadioGroup value={rampIntervalType}>
                <Grid container direction="row" spacing={0}>
                  <Grid item xs={4}>
                    <FormControlLabel value="None" control={<Radio color="primary" />} label="None" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel value="everyYear" control={<Radio color="primary" />} label="Every Year" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      value="specifiedDate"
                      control={<Radio color="primary" />}
                      label="Specified Dates"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : (
          <BillyMuiSelect
            items={RampIntervalSelectIds.map((id) => ({ id, label: formatRampIntervalType(id) }))}
            textFieldProps={{
              value: rampIntervalType,
              label: 'Ramp Interval Type',
              placeholder: 'Ramp Interval Type',
              disabled: disableRamp,
              onChange: (event) => {
                updateIntervalType?.(event.target.value as RampIntervalSelectId)
              },
            }}
          />
        )}
        <Grid item xs={'auto'}>
          {rampIntervalType === 'specifiedDate' && (
            <Stack gap={2}>
              {intervals?.map((interval, i) => {
                const enableDeleteButton = i > 0
                const error = intervalProps[i].error
                const disableStartInterval = isRampLineLevel && i === 0

                return (
                  <Box key={`${i}-${interval}`} sx={{ display: 'flex' }}>
                    <BillyMuiDateField
                      onChange={(newInterval) => updateInterval?.(i, newInterval)}
                      value={interval}
                      datePickerProps={{
                        label: `Interval ${i + 1} Starts`,
                      }}
                      disabled={disableRamp || disableStartInterval}
                      error={error}
                    />
                    <IconButton
                      onClick={() => {
                        deleteInterval?.(i)
                      }}
                      sx={{
                        width: 56,
                        height: 56,
                        ml: 1,
                      }}
                      size="large"
                      disabled={disableRamp || !enableDeleteButton || disableStartInterval}
                    >
                      <CancelOutlinedIcon
                        color="primary"
                        style={{
                          visibility: enableDeleteButton ? undefined : 'hidden',
                        }}
                      />
                    </IconButton>
                  </Box>
                )
              })}
              <Box>
                <Button onClick={addInterval} color="primary">
                  <AddCircleOutlineOutlined color="primary" fontSize="small" style={{ marginRight: '0.5rem' }} />
                  Add Interval
                </Button>
              </Box>
            </Stack>
          )}
          {rampIntervalType === 'everyYear' && <RampScheduleView intervals={intervals} />}
        </Grid>
      </Grid>
    </BillyGridCell>
  )
}
