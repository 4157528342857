import { TextFieldProps } from '@mui/material'
import { memo } from 'react'
import JotaiMuiNumberField from '../../../../components/input/JotaiMuiNumberField'
import { JotaiForm } from '../../../../components/state/useJotaiForm'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { useOrderChargeRowStyles } from '../orderChargeRow'

type JotaiNumberQuantitySelectCellProps = {
  lineItemIndex: number
  jotaiForm: JotaiForm<NewOrderFormData>
  textFieldProps?: TextFieldProps
}

function JotaiNumberQuantityEditCellWithoutMemo({
  jotaiForm,
  lineItemIndex,
  textFieldProps,
  ...props
}: JotaiNumberQuantitySelectCellProps) {
  const { classes } = useOrderChargeRowStyles()
  return (
    <JotaiMuiNumberField
      atomSelector={(form) => form.orderDetail.lineItems[lineItemIndex]?.quantity}
      atomUpdater={(value, draft) => {
        const lineItem = draft.orderDetail.lineItems[lineItemIndex]

        if (lineItem) {
          lineItem.quantity = value
          lineItem.arrOverride = undefined
        }
      }}
      errorPath={`orderDetail.lineItems[${lineItemIndex}].quantity`}
      form={jotaiForm}
      textFieldProps={{
        className: classes.fieldShrink,
        inputProps: {
          'aria-label': 'number-quantity-edit-cell',
        },
        size: 'small',
        ...textFieldProps,
      }}
      {...props}
    />
  )
}

export const JotaiNumberQuantityEditCell = memo(JotaiNumberQuantityEditCellWithoutMemo)
