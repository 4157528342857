import React, { forwardRef } from 'react'
import { Alert, CircularProgress, useTheme } from '@mui/material'
import styled from '@emotion/styled'

type Props = Readonly<{
  message: string
}>

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
  }
`

export default forwardRef<HTMLDivElement, Props>(function InProgressAlert(props: Props, ref) {
  const { message } = props
  const theme = useTheme()
  return (
    <StyledAlert ref={ref} severity="info" style={{ marginBottom: theme.spacing(2), width: '100%' }}>
      {message}
      <CircularProgress size={14} style={{ marginLeft: theme.spacing(1) }} />
    </StyledAlert>
  )
})
