import { GraphQLError } from 'graphql'
import { TError } from '../ErrorHandler/ErrorHandler'
import buildLogger from '@/util/logger'

const logger = buildLogger('GraphQLErrors')

export namespace GraphQLErrors {
  type IGraphQLError = Pick<GraphQLError, 'extensions'>

  /**
   * Response type that defines 'status' because the GraphQL types define this
   * as 'any' which is not helpful for our use.
   */
  interface IResponse {
    readonly status?: number
  }

  /**
   * An extended version of the URQL CombinedError that has a few other fields
   * we need but are normally overkill for typical usage.
   */
  interface ICombinedErrorExtended {
    readonly message: string
    readonly graphQLErrors: ReadonlyArray<IGraphQLError>
    readonly response?: IResponse
  }

  export const HTTP_STATUS_CODE_ENTITY_NOT_FOUND = 432
  export const HTTP_UNAVAILABLE_FOR_LEGAL_REASONS = 451

  export function isAuthenticationRequired(error: ICombinedErrorExtended) {
    return error.graphQLErrors.some((e) => e.extensions?.errorId === 'unauthorized')
  }

  export function isEntitySelectionRequired(error: ICombinedErrorExtended) {
    return error.response?.status === HTTP_STATUS_CODE_ENTITY_NOT_FOUND
  }

  export function requestFailedForLegalReasons(error: ICombinedErrorExtended) {
    return error.response?.status === HTTP_UNAVAILABLE_FOR_LEGAL_REASONS
  }

  /**
   * Return true if this error is normally ignorable by our error handling.
   */
  export function isIgnorable(error: TError) {
    if (!isGraphQLError(error)) {
      return false
    }

    return isAuthenticationRequired(error) || requestFailedForLegalReasons(error) || isEntitySelectionRequired(error)
  }

  export function isGraphQLError(error: TError): error is ICombinedErrorExtended {
    return Array.isArray((error as any).graphQLErrors)
  }
}
