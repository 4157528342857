import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useCallback, useEffect, useMemo } from 'react'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import ActionButton from '../../components/button/actionButton'
import JotaiMuiDateField from '../../components/input/JotaiMuiDateField'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import { useJotaiFormContext } from '../../components/state/jotaiFormProvider'
import useJotaiForm from '../../components/state/useJotaiForm'
import { CompositeOrderType, OrderStartDateType, OrderType, useGetAccountQuery } from '../../generated/graphql'
import { unixTimeSecondsNow } from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import { useMarkAsExecuted, UseMarkAsExecutedProps } from '@/pageComponents/orders/ApprovalDialog/useMarkAsExecuted'
import { useConfirmExecuteOrderDialog } from './ConfirmExecuteOrderDialog'
const logger = buildLogger('ExecuteOrderDialog')
type ExecuteOrderDialogProps = {
  onSubmit?: (value: ExecuteOrderJotaiForm) => void
  orderType?: OrderType | CompositeOrderType
  refresh?: () => void
  startDateType?: OrderStartDateType
}

type DialogProps = Omit<WithModalParams, 'onSubmit'> & ExecuteOrderDialogProps
const formID = 'ExecuteOrderDialog'

export type ExecuteOrderJotaiForm = {
  executeTime?: number
}

function ExecuteOrderDialog({ open, onClose, onSubmit, orderType, refresh }: DialogProps): JSX.Element {
  const jotaiForm = useJotaiFormContext<ExecuteOrderJotaiForm>()
  const formValue = jotaiForm.useSelect(useCallback((form) => form, []))
  const isCancel = orderType === OrderType.Cancel
  const mainActionLabel = isCancel ? 'Execute Cancel Order' : 'Execute Order'

  useEffect(() => {
    if (open) {
      refresh?.()
    }
    // only depends on open state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>{mainActionLabel}</DialogTitle>
      <DialogContent dividers>
        <form
          id={formID}
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit?.(formValue)
            onClose?.()
          }}
        >
          <Stack rowGap={2}>
            {isCancel && (
              <Typography>
                Are you sure you want to execute a cancel order for this subscription? An executed cancel order cannot
                be reverted.
              </Typography>
            )}
            <JotaiMuiDateField
              atomSelector={(form) => form.executeTime}
              atomUpdater={(value, draft) => {
                draft.executeTime = value ?? undefined
              }}
              datePickerProps={{
                label: 'Order Execution Date',
              }}
              errorPath="executeTime"
              form={jotaiForm}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={mainActionLabel}
          buttonData={{
            label: mainActionLabel,
            buttonProps: {
              type: 'submit',
              form: formID,
              color: isCancel ? 'error' : 'primary',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useExecuteOrderDialogWithAddressValidation(
  modalProps: DialogProps &
    Omit<UseMarkAsExecutedProps, 'accountDetail'> & {
      accountId: string
    }
) {
  const [accountQueryResponse, refreshAccountQueryResponse] = useGetAccountQuery({
    variables: { id: modalProps.accountId },
    pause: !modalProps.accountId,
  })

  const accountDetail = accountQueryResponse?.data?.accountDetail

  const handleMarkAsExecuted = useMarkAsExecuted({ ...modalProps, accountDetail })

  const toggleConfirmExecuteOrderDialog = useConfirmExecuteOrderDialog({
    onSubmit: handleMarkAsExecuted,
  })

  const toggleExecuteOrderDialog = useExecuteOrderDialog({
    orderType: modalProps.orderType,
    onSubmit: ({ executeTime }) => {
      if (modalProps.startDateType === OrderStartDateType.ExecutionDate) {
        toggleConfirmExecuteOrderDialog(executeTime)
      } else {
        handleMarkAsExecuted(executeTime)
      }
    },
    refresh: refreshAccountQueryResponse,
  })

  return toggleExecuteOrderDialog
}

export function useExecuteOrderDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const jotaiForm = useJotaiForm<ExecuteOrderJotaiForm>(
    useMemo(() => {
      return deepMutable({
        defaultValue: {
          executeTime: unixTimeSecondsNow(),
        },
      })
    }, [])
  )

  useModal<DialogProps>({
    component: ExecuteOrderDialog,
    schema: {
      key: formID,
      modalProps,
      jotaiForm,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
