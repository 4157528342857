import { useMemo } from 'react'
import {
  DEFAULT_DATE_PICKER_FORMAT,
  deriveDateTimeFromUnixWithTZ as getDateTimeFromUnix,
} from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import { DateTime } from 'luxon'

const logger = buildLogger('useDatePickerControlledValue')

const MASK = '__/__/____'

export function useDatePickerControlledValue({
  unixtime,
  timezone,
  maxDate,
  minDate,
}: {
  unixtime?: number
  timezone: string
  maxDate?: DateTime
  minDate?: DateTime
}) {
  const pickerValue = useMemo(() => {
    const dayToSet = getDateTimeFromUnix({ timezone, unixtime })
    if (dayToSet) {
      if (minDate && dayToSet < minDate) {
        return minDate
      }
      if (maxDate && dayToSet > maxDate) {
        return maxDate
      }
    }
    return dayToSet
  }, [unixtime, timezone, maxDate, minDate])

  const datePickerControlledValues = useMemo(() => {
    return {
      picker: {
        mask: MASK,
        format: DEFAULT_DATE_PICKER_FORMAT,
        value: pickerValue,
      },
      textInput: {
        value: pickerValue?.toFormat(DEFAULT_DATE_PICKER_FORMAT) ?? '',
      },
    }
  }, [pickerValue])

  return datePickerControlledValues
}
