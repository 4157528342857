import Big from 'big.js'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { getDiscountRatio } from '../../../util/currencyUtil'
import AmendmentOrderPageDrawer from '../AmendmentOrderPageDrawer'
import { AmendSubscriptionPageState } from './AmendmentOrderPage'

export function AmendmentRightDrawerWithData() {
  const jotaiForm = useJotaiFormContext<AmendSubscriptionPageState>()
  const { orderDetail, discount } = jotaiForm.useSelect(
    useCallback((form) => {
      const orderDetail = form.orderDetail

      return {
        orderDetail: {
          currentSubscription: orderDetail.currentSubscription,
          subscriptionId: orderDetail.subscriptionId,
          currency: orderDetail.currency,
          totalListAmount: orderDetail.totalListAmount,
          totalAmount: orderDetail.totalAmount,
          orderMetrics: orderDetail.orderMetrics,
          resoldBy: orderDetail.resoldBy,
          endDate: orderDetail.currentSubscription?.endDate,
          recurringDiscountPercent: orderDetail.recurringDiscountPercent,
          nonRecurringDiscountPercent: orderDetail.nonRecurringDiscountPercent,
        },
        discount: getDiscountRatio(form.orderDetail.totalAmount ?? 0, form.orderDetail.totalListAmount ?? 0) ?? Big(0),
      }
    }, [])
  )
  return <AmendmentOrderPageDrawer discount={discount} orderDetail={orderDetail} />
}
