import Head from 'next/head'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import InnerPageContainer from '../../../components/layout/innerPageContainer'
import { NavLayout } from '../../../components/layout/navLayout'
import PageLoadingPlaceholder from '../../../components/placeholder/pageLoadingPlaceholder'
import { JotaiUrqlBillySnackBar } from '../../../components/snackBar/useJotaiUrqlBillySnackBar'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { UpsertOrderDocument, UpsertRenewalOrderDocument } from '../../../generated/graphql'
import { NewOrderRightDrawer } from './NewOrderRightDrawer'
import { useOrderPageReducer } from './hooks/useOrderPageReducer'
import { NewOrderFormData } from './NewOrderPage'

export function NewOrderPageNavLayout({ children }: { children: React.ReactNode }): JSX.Element {
  const { orderTitle, buttonDataList, isLoading, breadcrumbs } = useOrderPageReducer()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()

  return (
    <>
      <Head>
        <title>{`${orderTitle} | Subskribe`}</title>
      </Head>
      <NavLayout actionButtons={buttonDataList} breadcrumbs={breadcrumbs} rightDrawer={NewOrderRightDrawer}>
        <InnerPageContainer>
          <JotaiUrqlErrors jotaiForm={jotaiForm} />
          <JotaiUrqlBillySnackBar document={UpsertOrderDocument} jotaiForm={jotaiForm} />
          <JotaiUrqlBillySnackBar document={UpsertRenewalOrderDocument} jotaiForm={jotaiForm} />
          <PageLoadingPlaceholder isLoading={isLoading}>{children}</PageLoadingPlaceholder>
        </InnerPageContainer>
      </NavLayout>
    </>
  )
}
