import { BillyDateTextField } from '@/components/DesktopDatePickerWithTimezone/BillyDateTextField'
import { DesktopDatePicker } from '@mui/x-date-pickers/'
import { DateTime } from 'luxon'
import React, { ReactNode, useCallback, useMemo } from 'react'
import { TimeZoneStr, UnixtimeSecondsAsNumber } from '../../util/datetime/datetimeTypes'
import { formatLuxonDatePickerProps } from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import { ForwardedSchemaFormLuxonDatePickerProps } from '../SchemaForm/types/DateType'
import { IUnixtimeWithTimezone } from './IUnixtimeWithTimezone'
import { useDatePickerControlledValue } from './useDatePickerControlledValue'
const logger = buildLogger('DesktopDatePickerWithTimezoneAbsoluteLuxon')

export interface DesktopDatePickerWithTimezoneAbsoluteLuxonProps {
  readonly unixtime: UnixtimeSecondsAsNumber | undefined
  readonly timezone: TimeZoneStr

  readonly onChange?: (datetime: IUnixtimeWithTimezone) => void

  // main properties for configuring the date picker
  readonly label: ReactNode

  readonly disabled?: boolean
  readonly required?: boolean
  readonly placeholder?: string
  readonly datePickerProps?: ForwardedSchemaFormLuxonDatePickerProps
  readonly error?: boolean
  readonly helperText?: string
  readonly inputRef?: React.Ref<HTMLInputElement>
}

export default function DesktopDatePickerWithTimezoneAbsoluteLuxon(
  props: DesktopDatePickerWithTimezoneAbsoluteLuxonProps
) {
  const { label, timezone, unixtime, onChange } = props
  const datePickerProps = formatLuxonDatePickerProps(props.datePickerProps)
  const { minDate, maxDate } = datePickerProps
  const datePickerControlledValues = useDatePickerControlledValue(
    useMemo(
      () => ({
        unixtime,
        timezone,
        maxDate,
        minDate,
      }),
      [unixtime, timezone, maxDate, minDate]
    )
  )

  const handleChange = useCallback(
    (newDataObj: DateTime | null) => {
      if (newDataObj) {
        const dayStart = newDataObj.setZone(timezone).startOf('day')
        if (dayStart.isValid) {
          logger.trace({
            dayStart,
            isMin: minDate && dayStart < minDate,
            isMax: maxDate && dayStart > maxDate,
          })
          if (minDate && dayStart < minDate) {
            return
          }
          if (maxDate && dayStart > maxDate) {
            return
          }
          const dayStartUnix = dayStart.toUnixInteger()
          onChange?.({ unixtime: dayStartUnix, timezone: dayStart.zoneName })
        }
      } else {
        onChange?.({ timezone } as IUnixtimeWithTimezone)
      }
    },
    [timezone, onChange, minDate, maxDate]
  )
  return (
    <DesktopDatePicker
      inputRef={props.inputRef}
      onChange={handleChange}
      disabled={props.disabled}
      {...datePickerControlledValues.picker}
      {...datePickerProps}
      slots={{
        field: BillyDateTextField,
      }}
      slotProps={{
        field: () => ({
          value: datePickerControlledValues.picker.value,
          timezone,
          onChange: handleChange,
          label: label,
          error: props.error,
          helperText: props.helperText,
          required: props.required,
        }),
      }}
    />
  )
}
