import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { notEmpty } from '@/util/array'
import { Grid } from '@mui/material'
import JotaiMuiAutocomplete from '../../../../../components/input/JotaiMuiAutocomplete'
import { useCallback } from 'react'
import { useBillyRouter } from '@/components/route/useBillyRouter'
import { CancelAndRestructureFormData } from '@/pageComponents/orders/CancelAndRestructureOrderForm'

export function CRMOpportunityAutocomplete() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const crmId = jotaiForm.useSelect(useCallback((form) => form.orderDetail.account?.crmId, []))

  return (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={(form: CommonOrderFormPageState) =>
          [...(form.opportunities?.filter(notEmpty) ?? [])].sort(
            (a, b) => -(a.stage ?? '').localeCompare(b.stage ?? '')
          )
        }
        atomUpdater={(value, draft) => {
          draft.orderDetail.sfdcOpportunityId = value?.crmId
          draft.orderDetail.sfdcOpportunityName = value?.name
          draft.orderDetail.sfdcOpportunityStage = value?.stage
          draft.orderDetail.sfdcOpportunityType = value?.type
        }}
        atomValueSelector={(form) => {
          if (!form.orderDetail.sfdcOpportunityId) {
            return null
          }
          return {
            id: form.orderDetail.sfdcOpportunityId ?? '',
            name: form.orderDetail.sfdcOpportunityName ?? '',
            stage: form.orderDetail.sfdcOpportunityStage ?? '',
            type: form.orderDetail.sfdcOpportunityType ?? '',
          }
        }}
        autocompleteProps={{
          getOptionLabel: (option) => `${option.name}`,
        }}
        disabledExplanation={crmId ? undefined : 'This account is not linked to a CRM'}
        errorPath="orderDetail.sfdcOpportunityId"
        form={jotaiForm}
        textFieldProps={{ label: 'CRM Opportunity' }}
      />
    </Grid>
  )
}

export function CancelRestructureCRMAutocomplete() {
  const router = useBillyRouter()
  const accountCrmId = router.query.accountCrmId as string | undefined
  const jotaiForm = useJotaiFormContext<CancelAndRestructureFormData>()
  return (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={(form: CancelAndRestructureFormData) =>
          [...(form.opportunities?.filter(notEmpty) ?? [])].sort(
            (a, b) => -(a.stage ?? '').localeCompare(b.stage ?? '')
          )
        }
        autocompleteProps={{
          getOptionLabel: (option) => `${option.name}`,
        }}
        errorPath="salesforceOpportunity"
        atomUpdater={(value, draft) => {
          draft.orderDetail.crmOpportunityId = value?.crmId
          draft.orderDetail.crmOpportunityName = value?.name
          draft.orderDetail.crmOpportunityStage = value?.stage
          draft.orderDetail.crmOpportunityType = value?.type
        }}
        atomValueSelector={(form) => ({
          id: form.orderDetail.crmOpportunityId ?? '',
          name: form.orderDetail.crmOpportunityName ?? '',
          stage: form.orderDetail.crmOpportunityStage ?? '',
          type: form.orderDetail.crmOpportunityType ?? '',
        })}
        textFieldProps={{ label: 'CRM Opportunity' }}
        disabledExplanation={accountCrmId ? undefined : 'This account is not linked to a CRM'}
        form={jotaiForm}
      />
    </Grid>
  )
}
