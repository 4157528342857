import { atom } from 'jotai'
import { useAtomValue, useReducerAtom } from 'jotai/utils'
import { createContext, useContext, useMemo } from 'react'
import { useDebouncedActionEffect } from '../hooks/useDebouncedActionEffect'
import buildLogger from '@/util/logger'
const logger = buildLogger('DryRunActionsContext')

const DryRunActionsContext = createContext({
  triggerDryRun: () => {
    logger.warn({ msg: 'triggerDryRun not initialized' })
  },
  clearDryRun: () => {
    logger.warn({ msg: 'clearDryRun not initialized' })
  },
  queueDryRun: () => {
    logger.warn({ msg: 'queueDryRun not initialized' })
  },
})

export const useDryRunActions = () => {
  return useContext(DryRunActionsContext)
}

const DangerousDryRunCounterAtom = atom(0)
const countReducer = (
  prev: number,
  action: {
    type: 'inc' | 'clear'
  }
) => {
  switch (action.type) {
    case 'inc':
      return prev + 1
    case 'clear':
      return 0
    default:
      throw new Error('unknown action type')
  }
}
const booleanReducer = (
  prev: boolean,
  action: {
    type: 'toggle' | 'set' | 'clear'
  }
) => {
  switch (action.type) {
    case 'toggle':
      return !prev
    case 'set':
      return true
    case 'clear':
      return false
    default:
      throw new Error('unknown action type')
  }
}
const ShouldRecalculateTotalsAtom = atom(false)

export const useShouldRecalculateTotals = () => {
  return useAtomValue(ShouldRecalculateTotalsAtom)
}

export const DryRunProvider = function ({ children }: { children: React.ReactNode }) {
  const [dangerousDryRunCounter, dispatchDangerousDryRunCounter] = useReducerAtom(
    DangerousDryRunCounterAtom,
    countReducer
  )
  const [, dispatchShouldRecalculateTotalsAtom] = useReducerAtom(ShouldRecalculateTotalsAtom, booleanReducer)
  const actions = useMemo(
    () => ({
      triggerDryRun: () => {
        logger.debug('triggerDryRun')
        dispatchDangerousDryRunCounter({ type: 'clear' })
        dispatchShouldRecalculateTotalsAtom({ type: 'set' })
      },
      queueDryRun: () => {
        logger.debug('queueDryRun')
        dispatchDangerousDryRunCounter({ type: 'inc' })
        dispatchShouldRecalculateTotalsAtom({ type: 'clear' })
      },
      clearDryRun: () => {
        logger.debug('clearDryRun')
        dispatchDangerousDryRunCounter({ type: 'clear' })
        dispatchShouldRecalculateTotalsAtom({ type: 'clear' })
      },
    }),
    [dispatchDangerousDryRunCounter, dispatchShouldRecalculateTotalsAtom]
  )

  useDebouncedActionEffect(dangerousDryRunCounter, actions.triggerDryRun)

  return <DryRunActionsContext.Provider value={actions}>{children}</DryRunActionsContext.Provider>
}
