import { Grid, StandardTextFieldProps } from '@mui/material'
import { useDescription, useTsController } from '@ts-react/form'
import { BillyMuiNumberField } from '../../input/JotaiMuiNumberField'
import { LayoutProps } from '../BillyTsForm'

export type NumberFieldProps = Omit<Omit<StandardTextFieldProps, 'value'>, 'onChange'> & LayoutProps

export function NumberField({ layout, hidden, ...props }: NumberFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<number>()
  const { label, placeholder } = useDescription()
  if (hidden) {
    return <></>
  }
  return (
    <Grid container item xs {...layout}>
      <BillyMuiNumberField
        {...props}
        isLoading={isLoading || isSubmitting}
        error={error?.errorMessage}
        textFieldProps={{
          ...props,
          value: value,
          label,
          placeholder: placeholder,
          onChange: (value) => {
            if (typeof value === 'number') {
              onChange(value)
            }
          },
        }}
      />
    </Grid>
  )
}
