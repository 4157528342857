import { CustomFieldParentType } from '../../../generated/graphql'

export function getLineItemViewCustomFieldVariables({
  mode,
  rowId,
}: {
  mode: 'order' | 'subscription'
  rowId?: string | null
}) {
  switch (mode) {
    case 'order':
      return {
        parentObjectType: CustomFieldParentType.OrderItem,
        parentObjectId: rowId ?? '',
      }
    case 'subscription':
      return {
        parentObjectType: CustomFieldParentType.SubscriptionItem,
        parentObjectId: rowId ?? '',
      }
    default:
      return {
        parentObjectType: CustomFieldParentType.OrderItem,
        parentObjectId: '',
      }
  }
}
