import {
  CustomFieldDialogProps,
  OrderEditCustomFieldsDialogWrapper,
} from '@/components/CustomFields/EditCustomFieldsDialog'
import { deepImmutable } from '@/components/SchemaForm/DeepImmutable'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { useModalsContext, useModal } from '@/components/state/context/modalsContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { OrderLevelCustomFieldEntryFragment, CustomFieldParentType } from '@/generated/graphql'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import { useCallback } from 'react'

export function useOrderLevelCustomFieldEditDialog(
  { onSubmit, ...modalProps }: Omit<CustomFieldDialogProps, 'parentObjectId' | 'parentObjectType'> = {
    canEditCustomFields: true,
  }
) {
  const [, , toggleModal] = useModalsContext()
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const key = `editCustomFieldsDialog-order--order-level-custom-field-edits`

  const updatedValue: CustomFieldDialogProps['updatedValue'] = jotaiForm.useSelect(
    useCallback((form) => deepImmutable(form.orderDetail.customFields?.filter(notEmpty) ?? []), [])
  )

  const loading = useShouldRecalculateTotals()

  useModal({
    component: OrderEditCustomFieldsDialogWrapper,
    schema: {
      key,
      modalProps: {
        onSubmit: async (data) => {
          jotaiForm.set((form) => {
            const newCustomFields = data.customFields.filter(notEmpty).map((newField) => ({
              ...(form.orderDetail.customFields?.find((oldField) => newField.id === oldField?.id) ?? {}),
              ...newField,
            })) as OrderLevelCustomFieldEntryFragment[]

            form.orderDetail.customFields = deepMutable(newCustomFields)
          })
          await onSubmit?.(data)
        },
        updatedValue,
        parentObjectType: CustomFieldParentType.Order,
        loading,
        ...modalProps,
      },
    },
  })

  const toggleDialog = useCallback(() => {
    toggleModal(key, true)
  }, [toggleModal, key])
  return [toggleDialog] as [typeof toggleDialog]
}
