import { Grid, TextFieldProps } from '@mui/material'
import { useDescription, useTsController } from '@ts-react/form'
import { DisabledProps, LayoutProps } from '../BillyTsForm'
import { BillyMuiField } from '../../input/JotaiMuiField'

export type StringFieldProps = TextFieldProps & LayoutProps & DisabledProps

export function StringField({ layout, disabledExplanation, ...textFieldProps }: StringFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<string>()
  const { label, placeholder } = useDescription()

  return (
    <Grid container item xs {...layout}>
      <BillyMuiField
        isLoading={isLoading || isSubmitting}
        error={error?.errorMessage}
        disabledExplanation={disabledExplanation}
        textFieldProps={{
          value: value ?? '',
          label,
          placeholder: placeholder,
          onChange: (event) => onChange(event.target.value),
          onBlur: (event) => {
            onChange(event.target.value.trim())
          },
          ...textFieldProps,
        }}
      />
    </Grid>
  )
}
