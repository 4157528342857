import { createTheme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import _ from 'lodash'
import commonTheme from './commonTheme'

let options: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#5350ED',
      dark: '#4227DD',
      light: '#7573F1',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4DD9D9',
      dark: '#009494',
      light: '#6DD0D0',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#D93651',
      dark: '#D31737',
      light: '#F88078',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4CAF50',
      dark: '#3B873E',
      light: '#7BC67E',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FBBC05',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: '#FFFFFF',
    },
    text: {},
    background: {
      paper: '#FFFFFF',
      default: '#FAFAFA',
    },
  },
  customPalette: {
    textDark: '#262626',
    textGray: '#595959',
    backgroundColor1: '#F3F5F7',
    backgroundColor2: '#F9FAFB',
    backgroundColor3: '#FFFFFF',
    backgroundColor4: '#EDF0F3',
    codeblockBackgroundColor: 'rgba(135, 131, 120, 0.15)',
    cardInputBackgroundColor1: 'rgba(232, 232, 248, 0.35)',
    landingPageBackgroundColor: '#d9eafe',
    hoverColor1: '#E5F2F8',
    hoverColor2: 'rgba(232, 232, 248, 0.35)',
    selectedBackgroundColor1: '#EFEEFB',
    selectedColor1: '#3633CC',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    tableHeaderBackgroundColor: '#EDF0F3',
    tableRowBackgroundColor: '#F9FAFB',
    queryBuilderBackgroundColor: 'rgba(240, 240, 240, 0.5)',
    chargeDetailsTieredBackgroundColor: 'rgba(70, 156, 243, 0.08)',
    statusChipBackgrounds: {
      primary:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #4744E2, #4744E2)',
      secondary:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #44E2E2, #44E2E2)',
      info: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #2196F3, #2196F3)',
      error:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #F44336, #F44336)',
      success:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #4CAF50, #4CAF50) ',
      warning:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #ED6C02, #ED6C02)',
    },
    disabledBorder: '#C0C0C0',
  },
}

options = _.merge(_.cloneDeep(commonTheme), options)

const lightTheme = createTheme(options)

export default lightTheme
