import { Breakpoint, Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

const drawerWidth = 255

export const drawerHiddenBreakpoints: Breakpoint[] = ['xs', 'sm', 'md']
export const drawerShownBreakpoints: Breakpoint[] = ['lg', 'xl']
export const allBreakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl']
export const drawerDisplayBreakpoints: ('none' | 'block')[] = allBreakpoints.map((breakpoint) =>
  drawerHiddenBreakpoints.includes(breakpoint) ? 'none' : 'block'
)

export const breadCrumbHiddenBreakpoints: Breakpoint[] = ['xs']

const useStyles = makeStyles()((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.customPalette.backgroundColor3,
    borderBottom: `1px solid ${theme.customPalette.borderColor}`,
    boxShadow: theme.shadows[0],
    color: theme.customPalette.textDark,
  },
  drawerOffset: {
    width: '100%',
    [theme.breakpoints.up(drawerShownBreakpoints[0])]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
}))
const topBarHeight = (theme: Theme): number => {
  return 64
}
export default {
  allBreakpoints,
  breadCrumbHiddenBreakpoints,
  drawerDisplayBreakpoints,
  drawerHiddenBreakpoints,
  drawerShownBreakpoints,
  drawerWidth,
  styles: useStyles,
  topBarHeight,
}
