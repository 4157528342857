import { deepImmutable } from '@/components/SchemaForm/DeepImmutable'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import JotaiReadValue from '@/components/state/jotaiReadValue'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Grid } from '@mui/material'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useForm } from 'react-hook-form'
import z from 'zod'
import {
  CustomFieldEntryFragment,
  CustomFieldParentType,
  CustomFieldType,
  UpdateCustomFieldsMutationVariables,
  useGetCustomFieldsQuery,
  useUpdateCustomFieldsMutation,
} from '../../generated/graphql'
import { toTitleCase } from '../../util/string'
import { BillyTsDialogForm } from '../BillyTSForm/BillyTsForm'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { createBillyTsCustomFieldsSchema } from '../SalesRoom/SalesRoomCustomFields'
import { IMenuItem, ISchema } from '../SchemaForm/SchemaFormEngine'
import ActionButton from '../button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../state/context/modalsContext'
import { createCustomFieldsSchema } from './createCustomFieldsSchema'
const logger = buildLogger('EditCustomFieldsDialog')

export interface EditCustomFieldsInstanceType {
  [key: string]: string | IMenuItem | IMenuItem[]
}

export type UpdatedCustomFields = Omit<UpdateCustomFieldsMutationVariables, 'customFields'> & {
  customFields: {
    selections?: string[] | undefined
    value?: string | undefined
    id: string
  }[]
}

export type CustomFieldDialogProps = Omit<WithModalParams, 'onSubmit'> & {
  parentObjectType: CustomFieldParentType
  parentObjectId?: string
  canEditCustomFields?: boolean
  onSubmit?: (variable: UpdatedCustomFields) => Promise<void> | void
  onRefresh?: () => void
  updatedValue?: readonly CustomFieldEntryFragment[]
  onPrevious?: () => void
  onNext?: () => void
  children?: React.ReactNode
  variant?: 'default' | 'order-edit'
  loading?: boolean
}

type CustomFieldDialogPropsWithSchema = CustomFieldDialogProps & {
  customFieldsSchema: ISchema<EditCustomFieldsInstanceType>
  customFields: readonly CustomFieldEntryFragment[]
  refreshCustomFields?: () => void
}

function EditCustomFieldsDialog({
  open,
  onClose,
  onSubmit,
  onRefresh,
  parentObjectType,
  parentObjectId,
  customFields,
  refreshCustomFields,
  onPrevious,
  onNext,
  children,
  variant = 'default',
  loading,
}: CustomFieldDialogPropsWithSchema) {
  const errorHandler = useErrorHandler()
  const [_loading, _setLoading] = useState(false)
  const showPrev = variant === 'order-edit'
  const showNext = variant === 'order-edit'
  const showApply = variant === 'order-edit'
  const showLoading = loading || _loading

  const { schema, props, defaultValues } = createBillyTsCustomFieldsSchema({ customFields })
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  })

  async function handleSubmit(data: Record<string, string | Array<string>>) {
    _setLoading(true)
    try {
      const updatedCustomFields = Object.entries(data)
        .filter(([key]) => {
          const customField = customFields.find((customField) => customField.id === key)
          return !!customField?.id
        })
        .map(([key, value]) => {
          const customField = customFields.find((customField) => customField.id === key)
          const type = customField?.type
          const picklistValue = value as string
          const multiPicklistValue = value as string[]
          return {
            id: customField?.id as string,
            ...(type === CustomFieldType.String && { value: value as string }),
            ...((type === CustomFieldType.MultiselectPicklist || type === CustomFieldType.Picklist) && {
              selections:
                type === CustomFieldType.Picklist ? (picklistValue ? [picklistValue] : []) : multiPicklistValue,
            }),
          }
        })
      await onSubmit?.({
        parentObjectType,
        parentObjectId: parentObjectId ?? '',
        customFields: updatedCustomFields,
      })
      refreshCustomFields?.()
      onRefresh?.()
      if (!showApply) {
        onClose?.()
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      _setLoading(false)
    }
  }

  return (
    <BillyTsDialogForm
      key={parentObjectId}
      schema={schema}
      props={props}
      onSubmit={handleSubmit}
      form={form}
      formProps={{
        id: 'edit-custom-fields-form',
      }}
      defaultValues={defaultValues}
      renderBeforeForm={<>{children}</>}
      open={!!open}
      onClose={onClose}
      dialogTitle={`Custom Fields for ${toTitleCase(parentObjectType)}`}
      CustomDialogActions={() => (
        <>
          <Button variant="outlined" disabled={showLoading} onClick={onClose} color="inherit">
            Cancel
          </Button>
          <div style={{ flexGrow: 1 }} />
          {showPrev && (
            <ActionButton
              buttonData={{
                label: 'Previous',
                buttonProps: {
                  disabled: showLoading || !onPrevious,
                  variant: 'outlined',
                  onClick: onPrevious,
                },
              }}
            />
          )}
          {showNext && (
            <ActionButton
              buttonData={{
                label: 'Next',
                buttonProps: {
                  disabled: showLoading || !onNext,
                  variant: 'outlined',
                  onClick: onNext,
                },
              }}
            />
          )}
          <ActionButton
            buttonData={{
              loading: showLoading,
              label: showApply ? 'Apply' : 'Save',
              buttonProps: {
                'aria-label': `${showApply ? 'Apply' : 'Save'} button for custom fields edit dialog`,
                type: 'submit',
              },
            }}
          />
        </>
      )}
    />
  )
}

function EditCustomFieldsDialogWrapper(modalProps: CustomFieldDialogProps) {
  const [customFieldsResponse, refreshCustomFields] = useGetCustomFieldsQuery({
    variables: { parentObjectType: modalProps.parentObjectType, parentObjectId: modalProps.parentObjectId },
  })
  if (!customFieldsResponse.fetching && !customFieldsResponse.stale) {
    const customFields =
      customFieldsResponse.data?.customFields.map((field, index) => {
        return {
          ...field,
          value: modalProps.updatedValue?.[index]?.value ?? field.value,
        }
      }) ?? []

    const customFieldsSchema = createCustomFieldsSchema({
      customFieldDefinitions:
        customFields.map((field) => ({
          fieldName: field.name,
          fieldType: field.type as CustomFieldType,
          fieldLabel: field.label,
          options: field.options,
        })) ?? [],
    })

    return (
      <EditCustomFieldsDialog
        {...modalProps}
        customFieldsSchema={customFieldsSchema}
        customFields={customFields}
        refreshCustomFields={refreshCustomFields}
      />
    )
  } else return null
}

function EditCustomFieldsDialogContainer({ canEditCustomFields = true, ...modalProps }: CustomFieldDialogProps) {
  if (canEditCustomFields && !!modalProps.open)
    return <EditCustomFieldsDialogWrapper {...modalProps} key={modalProps.parentObjectId} />

  return null
}

export function useEditCustomFieldsDialog(modalProps: CustomFieldDialogProps) {
  const [, , toggleModal] = useModalsContext()
  const key = `editCustomFieldsDialog-${modalProps.parentObjectType}-${modalProps.parentObjectId}`
  const [, updateCustomFields] = useUpdateCustomFieldsMutation()

  useModal({
    component: EditCustomFieldsDialogContainer,
    schema: {
      key,
      modalProps: {
        onSubmit: async (data) => {
          await updateCustomFields(data)
        },
        ...modalProps,
      },
    },
  })
  return useCallback(() => {
    toggleModal(key)
  }, [toggleModal, key])
}

export type CustomFieldsParentObject = Pick<CustomFieldDialogProps, 'parentObjectId' | 'parentObjectType'> & {
  lineItemIndex: number
}

export function OrderEditCustomFieldsDialogWrapper(modalProps: CustomFieldDialogProps) {
  const [customFieldsSchema, setCustomFieldsSchema] = useState<ISchema<EditCustomFieldsInstanceType> | undefined>(
    undefined
  )

  const [key, forceUpdate] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    const customFieldsSchema = createCustomFieldsSchema({
      customFieldDefinitions:
        modalProps.updatedValue?.map((field) => ({
          fieldName: field.name,
          fieldType: field.type as CustomFieldType,
          fieldLabel: field.label,
          options: field.options,
        })) ?? [],
    })
    setCustomFieldsSchema(customFieldsSchema)
    forceUpdate()
  }, [modalProps.updatedValue])

  return customFieldsSchema ? (
    <EditCustomFieldsDialog
      key={key}
      {...modalProps}
      customFieldsSchema={customFieldsSchema}
      customFields={modalProps.updatedValue ?? []}
    >
      {modalProps.children}
    </EditCustomFieldsDialog>
  ) : (
    <></>
  )
}

export function useOrderQueueBatchCustomFieldEditsDialog(
  modalProps: Omit<CustomFieldDialogProps, 'parentObjectId' | 'parentObjectType'> = {
    canEditCustomFields: true,
  }
) {
  const [, , toggleModal] = useModalsContext()
  const [lineItemIndex, setLineItemIndex] = useState<number>(0)
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const key = `editCustomFieldsDialog-order--queue-batch-custom-field-edits`

  const updatedValue: CustomFieldDialogProps['updatedValue'] = jotaiForm.useSelect(
    useCallback(
      (form) => deepImmutable(form.orderDetail.lineItems?.[lineItemIndex]?.customFields?.filter(notEmpty) ?? []),
      [lineItemIndex]
    )
  )

  const lineItemLength = jotaiForm.useSelect(useCallback((form) => form.orderDetail.lineItems?.length ?? 0, []))
  const loading = useShouldRecalculateTotals()

  useModal({
    component: OrderEditCustomFieldsDialogWrapper,
    schema: {
      key,
      modalProps: {
        onSubmit: (data) => {
          jotaiForm.set((form) => {
            const lineItem = form.orderDetail.lineItems[lineItemIndex]
            if (lineItem) {
              const newCustomFields = data.customFields.filter(notEmpty).map((newField) => ({
                ...(lineItem.customFields?.find((oldField) => newField.id === oldField?.id) ?? {}),
                ...newField,
              })) as CustomFieldEntryFragment[]
              lineItem.customFields = deepMutable(newCustomFields)
            } else {
              logger.warn({ message: 'lineItem or customFields not found', parentObject: lineItemIndex })
            }
          })
        },
        updatedValue,
        onPrevious:
          lineItemIndex !== 0
            ? () => {
                setLineItemIndex((index) => (index === 0 ? 0 : index - 1))
              }
            : undefined,
        onNext:
          lineItemIndex < lineItemLength - 1
            ? () => {
                setLineItemIndex((index) => (lineItemLength > index + 1 ? index + 1 : index))
              }
            : undefined,
        parentObjectType: CustomFieldParentType.OrderItem,
        children: (
          <JotaiReadValue
            atomSelector={useCallback(
              (form: NewOrderFormData) => {
                const { lineItems } = form.orderDetail
                const lineItem = lineItems?.[lineItemIndex]
                return {
                  cells: [
                    {
                      label: 'Plan Name',
                      description: lineItem?.plan?.name,
                      xs: 6,
                    },
                    {
                      label: 'Charge Name',
                      description: lineItem?.charge.name,
                      xs: 6,
                    },
                  ] as GridCellProps[],
                }
              },
              [lineItemIndex]
            )}
            form={jotaiForm}
            render={({ cells }) => (
              <Grid container columnSpacing={1} rowGap={2}>
                {cells.map((cell, index) => (
                  <BillyGridCell xs={'auto'} {...cell} key={index} />
                ))}
              </Grid>
            )}
          />
        ),
        loading,
        ...modalProps,
      },
    },
  })

  const openOrderQueueBatchEditCustomFieldsDialog = useCallback(
    (index: number) => {
      setLineItemIndex(index)
      toggleModal(key, true)
    },
    [toggleModal, key]
  )
  return [openOrderQueueBatchEditCustomFieldsDialog] as [typeof openOrderQueueBatchEditCustomFieldsDialog]
}
