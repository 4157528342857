import { Alert, Button, Grid } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import z from 'zod'
import { BillyMuiField } from '@/components/input/JotaiMuiField'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { FieldError } from 'react-hook-form'

export const MultiValueCreateSchema = createUniqueFieldSchema(
  z
    .array(z.string({ required_error: 'Value cannot be empty' }).trim().nonempty('Value cannot be empty'))
    .nonempty({ message: 'Please create at least one value' }),
  'multivalue-create'
)

export type MultiValueCreateProps = { addButtonMessage?: string; hidden?: boolean; max?: number }

export function MultiValueCreateField({
  addButtonMessage,
  hidden,
  max = Number.MAX_SAFE_INTEGER,
}: MultiValueCreateProps) {
  const {
    fieldState,
    field: { value = [], onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<Array<string>>()

  // This is due to a bug in react-ts-form where errors are not being sent along properly for array type but are still available in the field state
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const fieldStateWithAny: any = fieldState
  // Function to add a new option to the array
  const handleCreateOption = () => {
    onChange([...value, undefined])
  }

  const handleRemoveOption = (index: number) => {
    const updatedArray = [...value.slice(0, index), ...value.slice(index + 1)]
    onChange(updatedArray)
  }

  function onChangeField(fieldValue: string, index: number) {
    const newValue = [...value]
    newValue[index] = fieldValue
    onChange(newValue)
  }

  const { label, placeholder } = useDescription()

  if (hidden) return <></>

  return (
    <>
      {value?.map((element: string, index: number) => (
        <Grid container item xs={12} key={index}>
          <Grid container item xs={6}>
            <BillyMuiField
              isLoading={isLoading || isSubmitting}
              error={(fieldStateWithAny?.error?.[index] as FieldError)?.message}
              textFieldProps={{
                value: element ?? '',
                label: `${label} ${index + 1}`,
                placeholder: placeholder,
                onChange: (event) => onChangeField(event.target.value, index),
              }}
            />
          </Grid>
          <Grid container item xs={6}>
            <Button
              style={{ marginLeft: '1rem' }}
              size="small"
              startIcon={<RemoveCircleOutline />}
              onClick={() => handleRemoveOption(index)}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      ))}
      {value.length < max && (
        <Grid container item xs={12}>
          <Button startIcon={<AddCircleOutline />} onClick={handleCreateOption}>
            {addButtonMessage || 'Add'}
          </Button>
        </Grid>
      )}
      <Grid container item xs={12}>
        {error?.errorMessage && (
          <Alert style={{ width: '100%' }} severity="error">
            {error?.errorMessage}
          </Alert>
        )}
      </Grid>
    </>
  )
}
