import { Autocomplete, AutocompleteProps, TextField, Tooltip } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Theme } from '@mui/material/styles'
import { Draft } from 'immer'
import { useAtom } from 'jotai'
import { selectAtom, useUpdateAtom } from 'jotai/utils'
import React, { ForwardedRef, MutableRefObject, useCallback, useContext, useEffect, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useYupError } from '../../util/jotai'
import buildLogger from '../../util/logger'
import { PageLoadingContext } from '../placeholder/pageLoadingPlaceholder'
import { JotaiForm } from '../state/useJotaiForm'

const logger = buildLogger('JotaiMuiAutocomplete')

export type JotaiMuiAutocompleteProps<T, V> = {
  atomMultiUpdater?: (value: V[] | null, draft: Draft<T>) => void
  atomMultiValueSelector?: (form: T) => V[] | undefined | null
  atomOptionsSelector: (form: T) => V[] | undefined | null
  atomUpdater?: (value: V | null, draft: Draft<T>) => void
  atomValueSelector?: (form: T) => V | undefined | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autocompleteProps?: Partial<AutocompleteProps<V, any, any, false>>
  disableSort?: boolean
  disabledExplanation?: string | false
  errorPath: string
  form: JotaiForm<T>
  textFieldProps: TextFieldProps
}

const useStyles = makeStyles()((_theme: Theme) => ({
  textField: {
    width: '100%',
  },
}))

function useJotaiAutoCompleteFormValue<T, V>(
  form: JotaiForm<T>,
  {
    atomMultiValueSelector,
    atomValueSelector,
  }: Pick<JotaiMuiAutocompleteProps<T, V>, 'atomMultiValueSelector' | 'atomValueSelector'>
) {
  const { atom } = form
  const setAtom = useUpdateAtom(atom) as (updater: (draft: Draft<T>) => void) => void
  const valueAtom = useMemo(() => {
    // can be single for multi
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return selectAtom(atom, atomMultiValueSelector ? atomMultiValueSelector : (atomValueSelector as any))
  }, [atom, atomMultiValueSelector, atomValueSelector])
  const [formValue] = useAtom(valueAtom)

  return useMemo(() => {
    return {
      formValue,
      setAtom,
    }
  }, [formValue, setAtom])
}

function useJotaiAutoCompleteOptions<T, V>(
  form: JotaiForm<T>,
  {
    atomOptionsSelector,
    disableSort,
    autocompleteProps,
  }: Pick<
    JotaiMuiAutocompleteProps<T, V>,
    'atomValueSelector' | 'atomOptionsSelector' | 'disableSort' | 'autocompleteProps'
  >
) {
  const { atom } = form
  const optionsAtom = useMemo(() => selectAtom(atom, atomOptionsSelector), [atom, atomOptionsSelector])
  const [originalOptions] = useAtom(optionsAtom)

  const options = useMemo(() => {
    const getOptionLabel = autocompleteProps?.getOptionLabel
    const originalCopy = [...(originalOptions ?? [])]
    if (!disableSort && getOptionLabel) {
      return originalCopy.sort((a, b) => getOptionLabel(a).localeCompare(getOptionLabel(b)))
    } else {
      return originalCopy
    }
  }, [originalOptions, disableSort, autocompleteProps])

  return useMemo(() => {
    return {
      options,
    }
  }, [options])
}

function JotaiMuiAutocomplete<T, V>(
  {
    atomMultiUpdater,
    atomMultiValueSelector,
    atomOptionsSelector,
    atomUpdater,
    /**
     * this triggers infinite loop when updated to react 18
     */
    atomValueSelector,
    autocompleteProps,
    disableSort,
    disabledExplanation,
    errorPath,
    form,
    textFieldProps,
  }: JotaiMuiAutocompleteProps<T, V>,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const { classes, cx } = useStyles()
  const { isLoading } = useContext(PageLoadingContext)

  const { options } = useJotaiAutoCompleteOptions(form, {
    atomOptionsSelector,
    disableSort: disableSort || false,
    autocompleteProps,
  })

  const { formValue, setAtom } = useJotaiAutoCompleteFormValue(form, {
    atomMultiValueSelector,
    atomValueSelector,
  })

  const handleChange = useCallback(
    (e, value) => {
      setAtom((draft) => {
        atomMultiUpdater ? atomMultiUpdater(value, draft) : atomUpdater && atomUpdater(value, draft)
      })
    },
    [atomMultiUpdater, atomUpdater, setAtom]
  )

  const error = useYupError(form, errorPath)

  const { className: autocompleteClassName, ...otherAutocompleteProps } = autocompleteProps || {}

  const refObj = ref as MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (error) {
      refObj?.current?.focus()
    }
  }, [error, refObj])

  return (
    <Tooltip title={disabledExplanation || ''} ref={ref}>
      <Autocomplete
        className={cx(classes.textField, autocompleteClassName)}
        disabled={!!disabledExplanation}
        onChange={handleChange}
        loading={!options}
        options={options}
        value={(formValue as any) || null}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            variant="outlined"
            name={textFieldProps.name ? textFieldProps.name : (textFieldProps.label as string)?.toLowerCase()}
            {...textFieldProps}
            InputProps={{ ...params.InputProps, ...textFieldProps.InputProps }}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...textFieldProps.InputLabelProps,
              disableAnimation: isLoading,
            }}
          />
        )}
        isOptionEqualToValue={useCallback((option, value) => {
          return (option as any).id === (value as any)?.id
        }, [])}
        {...otherAutocompleteProps}
      />
    </Tooltip>
  )
}

export default React.forwardRef(JotaiMuiAutocomplete)

export type BillyMuiAutocompleteProps<
  DataType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> = {
  autocompleteProps?: Partial<AutocompleteProps<DataType, Multiple, DisableClearable, false>>
  disableSort?: boolean
  disabledExplanation?: string | false
  error: string
  textFieldProps: TextFieldProps
}

export interface DataTypeWithIdAndLabel {
  id?: string
  label?: string
}

export function BillyMuiAutocomplete<
  DataType extends DataTypeWithIdAndLabel,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  autocompleteProps,
  disableSort,
  disabledExplanation,
  error,
  textFieldProps,
}: BillyMuiAutocompleteProps<DataType, Multiple, DisableClearable>) {
  const { classes, cx } = useStyles()
  const { isLoading } = useContext(PageLoadingContext)
  let options = autocompleteProps?.options || []

  const { className: autocompleteClassName, ...otherAutocompleteProps } = autocompleteProps || {}

  const getOptionLabel = autocompleteProps?.getOptionLabel

  if (!disableSort && getOptionLabel && options) {
    options = [...options].sort((a, b) => getOptionLabel(a).localeCompare(getOptionLabel(b)))
  }

  return (
    <Tooltip title={disabledExplanation || ''}>
      <Autocomplete
        className={cx(classes.textField, autocompleteClassName)}
        disabled={!!disabledExplanation}
        onChange={autocompleteProps?.onChange}
        loading={!options}
        options={options || []}
        value={autocompleteProps?.value}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            variant="outlined"
            name={textFieldProps.name ? textFieldProps.name : (textFieldProps.label as string)?.toLowerCase()}
            {...textFieldProps}
            InputProps={{ ...params.InputProps, ...textFieldProps.InputProps }}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...textFieldProps.InputLabelProps,
              disableAnimation: isLoading,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value?.id
        }}
        {...otherAutocompleteProps}
      />
    </Tooltip>
  )
}
