import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModalsContext, useModal } from '../../../components/state/context/modalsContext'

type AddressIsRequiredDialogProps = {
  accountId: string
}

type DialogProps = WithModalParams & AddressIsRequiredDialogProps
const formID = 'AddressIsRequiredDialog'
export function AddressIsRequiredDialog({ accountId, open, onClose }: DialogProps): JSX.Element {
  return (
    <>
      <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
        <DialogTitle>Address Required</DialogTitle>
        <DialogContent dividers>
          <Typography>Billing or shipping contacts do not contain an address.</Typography>
        </DialogContent>
        <DialogActions>
          <ActionButton
            key={'Cancel'}
            buttonData={{
              label: 'Cancel',
              onClick: onClose,
              color: 'inherit',
              buttonProps: { variant: 'outlined' },
            }}
          />
          <ActionButton
            key={'View Address'}
            buttonData={{
              label: 'View Account',
              buttonProps: {
                href: `/accounts/${accountId}`,
              },
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export function useAddressIsRequiredDialog(modalProps: AddressIsRequiredDialogProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: AddressIsRequiredDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
