import React, { useCallback, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { BillyTsForm } from '../BillyTSForm/BillyTsForm'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
  AccountContactFragment,
  ContactType,
  ElectronicSignatureFragment,
  EmailContactFragment,
  OrderDetailFragment,
  SigningOrder,
  TenantSignatory,
  useEmailContactsForAccountQuery,
  useGetEsignDetailsQuery,
  useGetSigningOrderQuery,
  useGetTenantSignatoriesQuery,
  useUpsertEsignDetailsMutation,
} from '@/generated/graphql'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { notEmpty } from '@/util/array'
import ActionButton from '../button/actionButton'
import { SendEsignEmailSchemaType, useGetSendEsignEmailSchema } from '@/pageComponents/esign/SendEsignEmailDrawer'

type DialogProps = WithModalParams & SaveEsignatureDetailsDialogInterface

export interface SaveEsignatureDetailsDialogInterface {
  orderId: string
  eSignData?: ElectronicSignatureFragment
  tenantSignatories?: TenantSignatory[]
  billingContact?: AccountContactFragment | null
  emailContacts?: EmailContactFragment[]
  signingOrder?: SigningOrder | null
  onSuccessCallback?: () => void
}

function SaveEsignatureDetailsDialog({
  open,
  orderId,
  eSignData,
  tenantSignatories,
  billingContact,
  emailContacts,
  onSuccessCallback,
  onClose,
  signingOrder,
}: DialogProps) {
  const [, saveEsignDetails] = useUpsertEsignDetailsMutation()
  const [esignDetailsResponse, refreshEsignDetails] = useGetEsignDetailsQuery({ variables: { orderId: orderId ?? '' } })
  const savedEsignDetails = esignDetailsResponse.data?.esignDetails
  const errorHandler = useErrorHandler()

  const SendEsignEmailSchema = useGetSendEsignEmailSchema()

  const form = useForm<z.infer<SendEsignEmailSchemaType>>({
    resolver: zodResolver(SendEsignEmailSchema),
  })

  const { reset, watch } = form
  const selectedCustomerSignatory = watch('customerSignatory')
  const selectedHostSignatory = watch('hostSignatory')

  useEffect(() => {
    const customerSignatory = savedEsignDetails?.accountSignatory
      ? { id: savedEsignDetails?.accountSignatory?.id, label: savedEsignDetails?.accountSignatory?.name }
      : {
          id: billingContact?.id || undefined,
          label: billingContact?.firstName ? billingContact?.firstName + ' ' + billingContact?.lastName : undefined,
        }
    reset({
      customerSignatory: customerSignatory,
      additionalRecipients: savedEsignDetails?.additionalRecipients?.map((recipient) => {
        return {
          id: recipient?.id,
          label: recipient?.name,
        }
      }),
      hostSignatory: savedEsignDetails?.tenantSignatory?.id
        ? {
            id: savedEsignDetails?.tenantSignatory?.id,
            label: savedEsignDetails?.tenantSignatory?.name,
          }
        : undefined,
    })
  }, [reset, savedEsignDetails, billingContact])

  async function onFormSave(data: z.infer<SendEsignEmailSchemaType>) {
    try {
      const accountSignatory = emailContacts
        ?.filter((contact) => contact.type === ContactType.AccountContact)
        .find((contact) => contact.id === data.customerSignatory.id)
      const tenantSignatory = tenantSignatories?.find((signatory) => signatory.user.id === data.hostSignatory?.id)?.user
      const additionalRecipients = data.additionalRecipients
        ?.map((recipient) => emailContacts?.find((contact) => contact.id === recipient.id))
        .filter(notEmpty)
      await saveEsignDetails?.({
        esignRequest: {
          accountSignatory,
          tenantSignatory: tenantSignatory && {
            name: tenantSignatory.displayName,
            email: tenantSignatory.email,
            id: tenantSignatory.id,
            type: ContactType.User,
          },
          isComposite: false,
          orderId: orderId ?? '',
          additionalRecipients,
        },
      })
      refreshEsignDetails()
      onClose?.()
      onSuccessCallback?.()
    } catch (error) {
      errorHandler(error)
    }
  }

  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogTitle>Please Save Your Electronic Signature Details</DialogTitle>
      <DialogContent dividers>
        <BillyTsForm
          form={form}
          formProps={{ id: 'save-esignature-dialog-form' }}
          onSubmit={onFormSave}
          schema={SendEsignEmailSchema}
          props={{
            customerSignatory: {
              disabledExplanation: eSignData?.status ? "Signatory can't be changed after sending" : '',
              items:
                emailContacts
                  ?.filter((contact) => contact.type === ContactType.AccountContact)
                  .map((contact) => {
                    return {
                      id: contact.id ?? '',
                      label: `${contact.name.trim()} <${contact.email}>`,
                    }
                  }) ?? [],
              layout: { xs: 12 },
            },
            hostSignatory: {
              disabledExplanation: eSignData?.status ? "Signatory can't be changed after sending" : '',
              items:
                tenantSignatories?.map((signatory) => {
                  return {
                    id: signatory.user.id ?? '',
                    label: `${signatory.user.displayName} <${signatory.user.email}>`,
                  }
                }) ?? [],
              layout: { xs: 12 },
              hidden: signingOrder === SigningOrder.AccountOnly,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            additionalRecipients: {
              disabledExplanation: eSignData?.status ? "Signatory can't be changed after sending" : '',
              items:
                emailContacts
                  ?.filter((contact) => {
                    if (selectedCustomerSignatory && contact.id === selectedCustomerSignatory.id) {
                      return false
                    }
                    if (selectedHostSignatory && contact.id === selectedHostSignatory.id) {
                      return false
                    }
                    return true
                  })
                  .map((contact) => {
                    return {
                      id: contact.id ?? '',
                      label: `${contact.name.trim()} <${contact.email}>`,
                    }
                  }) ?? [],
              layout: { xs: 12 },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <ActionButton
          key={'Close'}
          buttonData={{
            label: 'Close',
            onClick: onClose,
            color: 'inherit',
            buttonProps: {
              variant: 'outlined',
              style: { marginLeft: 0 },
            },
          }}
        />
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Save'}
          buttonData={{
            loading: form.formState.isSubmitting,
            label: 'Save',
            buttonProps: {
              type: 'submit',
              form: 'save-esignature-dialog-form',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useSaveEsignatureDetailsDialog(
  modalProps: Pick<SaveEsignatureDetailsDialogInterface, 'eSignData' | 'orderId' | 'onSuccessCallback'> & {
    orderData: OrderDetailFragment
  }
) {
  const [, , toggleModal] = useModalsContext()

  const orderData = modalProps.orderData
  const [tenantSignatoriesResponse] = useGetTenantSignatoriesQuery()
  const tenantSignatories = tenantSignatoriesResponse.data?.tenantSignatories.filter(notEmpty) ?? []

  const accountId = orderData?.resoldBy?.id ?? orderData?.account?.id ?? ''

  const [emailContactsForAccountResponse] = useEmailContactsForAccountQuery({
    variables: {
      accountId: accountId,
    },
    pause: accountId.length === 0,
  })

  const emailContacts = emailContactsForAccountResponse.data?.emailContactsForAccount.filter(notEmpty)

  const [signingOrderResponse] = useGetSigningOrderQuery()

  useModal<DialogProps>({
    component: SaveEsignatureDetailsDialog,
    schema: {
      key: `saveEsignatureDetailsDialog`,
      modalProps: {
        eSignData: modalProps.eSignData,
        orderId: modalProps.orderId,
        onSuccessCallback: modalProps.onSuccessCallback,
        emailContacts,
        tenantSignatories,
        billingContact: orderData?.billingContact,
        signingOrder: signingOrderResponse?.data?.signingOrder,
      },
    },
  })
  return useCallback(() => {
    toggleModal('saveEsignatureDetailsDialog')
  }, [toggleModal])
}
