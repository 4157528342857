import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import {
  AutocompleteSchema,
  OptionalAutocompleteMultiSchema,
  OptionalAutocompleteSchema,
} from '@/components/BillyTSForm/fields/AutocompleteSelect'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import {
  EsignDetailsDrawerContent,
  EsignDetailsDrawerContentProps,
} from '@/pageComponents/esign/EsignDetailsDrawerContent'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { zodResolver } from '@hookform/resolvers/zod'
import { Alert, Box, DialogActions, DialogContent, DialogTitle, Drawer } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import StatusChip from '../../components/table/cells/statusChip'
import {
  AccountContactFragment,
  ContactType,
  ElectronicSignatureFragment,
  ElectronicSignatureStatus,
  EmailContactFragment,
  SigningOrder,
  TenantSignatory,
  useGetElectronicSignatureAuditLogsQuery,
  useGetEsignDetailsQuery,
  useGetSigningOrderQuery,
} from '../../generated/graphql'
import { useConfirmVoidEsignEmailDialog } from './ConfirmVoidEsignEmailDialog'
import { canMutateOrder } from '@/util/roleUtils'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { ALL_ENTITIES } from '@/util/entity'

const logger = buildLogger('SendEsignEmailDrawer')

export function useGetSendEsignEmailSchema() {
  const [signingOrderResponse] = useGetSigningOrderQuery()
  const signingOrder = signingOrderResponse?.data?.signingOrder

  const hostSignatoryRequired = useMemo(() => signingOrder !== SigningOrder.AccountOnly, [signingOrder])

  const baseSchema = z.object({
    customerSignatory: AutocompleteSchema.describe('Customer Signatory'),
  })
  if (hostSignatoryRequired) {
    return baseSchema.merge(
      z.object({
        hostSignatory: AutocompleteSchema.describe('Host Signatory'),
        additionalRecipients: OptionalAutocompleteMultiSchema.describe('Additional Recipients'),
      })
    )
  } else {
    return baseSchema.merge(
      z.object({
        hostSignatory: OptionalAutocompleteSchema.describe('Host Signatory'),
        additionalRecipients: OptionalAutocompleteMultiSchema.describe('Additional Recipients'),
      })
    )
  }
}

export type SendEsignEmailSchemaType = ReturnType<typeof useGetSendEsignEmailSchema>

export type SendEsignEmailFormData = z.infer<SendEsignEmailSchemaType>
export type SendEsignEmailDrawerProps = {
  onVoid?: () => void
  onResend?: () => void
  data?: ElectronicSignatureFragment
  onSubmit?: (data: SendEsignEmailFormData) => Promise<void>
  eSignData?: ElectronicSignatureFragment
  tenantSignatories?: TenantSignatory[]
  billingContact?: AccountContactFragment | null
  emailContacts?: EmailContactFragment[]
  refresh?: () => void
  orderId?: string
  signingOrder?: SigningOrder | null
  orderEntityId?: string | null
} & Partial<EsignDetailsDrawerContentProps>

type DialogProps = Omit<WithModalParams, 'onSubmit'> & SendEsignEmailDrawerProps
const formID = 'SendEsignEmailDrawer'

function SendEsignEmailDrawer({
  open,
  onSubmit,
  onClose,
  onModalClose,
  onVoid,
  onResend,
  eSignData,
  tenantSignatories,
  emailContacts,
  refresh,
  electronicSignatureAuditLogs,
  billingContact,
  orderId,
  signingOrder,
  orderEntityId,
}: DialogProps): JSX.Element {
  const needToSetTenantSignatory = useMemo(
    () => signingOrder !== SigningOrder.AccountOnly && !tenantSignatories?.length,
    [signingOrder, tenantSignatories]
  )

  const [esignDetailsResponse] = useGetEsignDetailsQuery({ variables: { orderId: orderId ?? '' } })
  const savedEsignDetails = esignDetailsResponse.data?.esignDetails

  const handleVoid = useCallback(() => {
    onVoid?.()
    onClose?.()
    refresh?.()
  }, [onVoid, onClose, refresh])

  const handleResend = useCallback(() => {
    onResend?.()
    onClose?.()
  }, [onResend, onClose])

  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const SendEsignEmailSchema = useGetSendEsignEmailSchema()

  const form = useForm<z.infer<SendEsignEmailSchemaType>>({
    resolver: zodResolver(SendEsignEmailSchema),
  })

  const { watch, reset } = form
  useEffect(() => {
    const customerSignatory = savedEsignDetails?.accountSignatory
      ? {
          id: savedEsignDetails.accountSignatory.id,
          label: `${savedEsignDetails.accountSignatory.name.trim()} <${savedEsignDetails.accountSignatory.email}>`,
        }
      : {
          id: billingContact?.id || undefined,
          label: billingContact?.firstName
            ? `${billingContact?.firstName.trim() + ' ' + billingContact?.lastName?.trim()} <${billingContact?.email}>`
            : undefined,
        }
    reset({
      customerSignatory: customerSignatory,
      additionalRecipients: savedEsignDetails?.additionalRecipients?.map((recipient) => {
        return {
          id: recipient?.id,
          label: recipient?.name,
        }
      }),
      hostSignatory: savedEsignDetails?.tenantSignatory?.id
        ? {
            id: savedEsignDetails?.tenantSignatory?.id,
            label: savedEsignDetails?.tenantSignatory?.name,
          }
        : undefined,
    })
  }, [reset, savedEsignDetails, billingContact])

  const selectedCustomerSignatory = watch('customerSignatory')
  const selectedHostSignatory = watch('hostSignatory')

  const onFormSubmit = useCallback(
    (data: z.infer<SendEsignEmailSchemaType>) => {
      async function doAsync() {
        setIsSubmitting(true)
        await onSubmit?.(data)
        setIsSubmitting(false)
        onClose?.()
        refresh?.()
      }
      doAsync().catch((error) => {
        setIsSubmitting(false)
        errorHandler(error)
      })
    },
    [onSubmit, onClose, errorHandler, refresh]
  )
  const handleSubmitCallback = useCallback(async () => await form.handleSubmit(onFormSubmit)(), [form, onFormSubmit])

  const { currentUser } = useUserTenantSession()

  return (
    <Drawer anchor={'right'} open={!!open} onClose={onModalClose} PaperProps={{ sx: { width: '600px' } }}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>{!!eSignData && eSignData.status === 'COMPLETED' ? 'eSignature Details' : 'Send Order Form'}</span>
          {!!eSignData && <StatusChip value={eSignData.status} />}
        </Box>
      </DialogTitle>
      {eSignData?.status ? (
        <EsignDetailsDrawerContent eSignData={eSignData} electronicSignatureAuditLogs={electronicSignatureAuditLogs} />
      ) : (
        <DialogContent dividers>
          {needToSetTenantSignatory && (
            <Alert severity={'warning'} sx={{ mb: 2 }}>
              Please ask an admin to designate an authorized signatory for your company
            </Alert>
          )}
          <BillyTsForm
            form={form}
            onSubmit={handleSubmitCallback}
            schema={SendEsignEmailSchema}
            props={{
              customerSignatory: {
                disabledExplanation: eSignData?.status ? "Signatory can't be changed after sending" : '',
                items:
                  emailContacts
                    ?.filter((contact) => contact.type === ContactType.AccountContact)
                    .map((contact) => {
                      return {
                        id: contact.id ?? '',
                        label: `${contact.name.trim()} <${contact.email}>`,
                      }
                    }) ?? [],
                layout: { xs: 12 },
              },
              hostSignatory: {
                disabledExplanation: eSignData?.status ? "Signatory can't be changed after sending" : '',
                items:
                  tenantSignatories
                    ?.filter((signatory) => {
                      if (!signatory.user.entityIds) {
                        return false
                      }
                      return (
                        !orderEntityId ||
                        signatory.user.entityIds.includes(orderEntityId) ||
                        signatory.user.entityIds.includes(ALL_ENTITIES)
                      )
                    })
                    .map((signatory) => {
                      return {
                        id: signatory.user.id ?? '',
                        label: `${signatory.user.displayName} <${signatory.user.email}>`,
                      }
                    }) ?? [],
                layout: { xs: 12 },
                hidden: signingOrder === SigningOrder.AccountOnly,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any,
              additionalRecipients: {
                disabledExplanation: eSignData?.status ? "Signatory can't be changed after sending" : '',
                items:
                  emailContacts
                    ?.filter((contact) => {
                      if (selectedCustomerSignatory && contact.id === selectedCustomerSignatory.id) {
                        return false
                      }
                      if (selectedHostSignatory && contact.id === selectedHostSignatory.id) {
                        return false
                      }
                      return (
                        !orderEntityId ||
                        (contact.type === ContactType.AccountContact
                          ? true
                          : contact.entityIds?.includes(orderEntityId) || contact.entityIds?.includes(ALL_ENTITIES))
                      )
                    })
                    .map((contact) => {
                      return {
                        id: contact.id ?? '',
                        label: `${contact.name.trim()} <${contact.email}>`,
                      }
                    }) ?? [],
                layout: { xs: 12 },
              },
            }}
          />
        </DialogContent>
      )}
      {eSignData ? (
        eSignData.status !== ElectronicSignatureStatus.Completed && (
          <DialogActions>
            {canMutateOrder(currentUser.role) && (
              <ActionButton
                key={'Void'}
                buttonData={{
                  label: 'Void',
                  onClick: handleVoid,
                  color: 'error',
                  buttonProps: {
                    variant: 'outlined',
                    style: { marginLeft: 0 },
                  },
                }}
              />
            )}
            <div style={{ flexGrow: 1 }} />
            <ActionButton
              key={'Close'}
              buttonData={{
                label: 'Close',
                onClick: onClose,
                color: 'inherit',
                buttonProps: { variant: 'outlined' },
              }}
            />
            {eSignData.status !== ElectronicSignatureStatus.Pending && canMutateOrder(currentUser.role) && (
              <ActionButton
                key={'Resend'}
                buttonData={{
                  label: 'Resend',
                  onClick: handleResend,
                }}
              />
            )}
          </DialogActions>
        )
      ) : (
        <DialogActions>
          <ActionButton
            key={'Close'}
            buttonData={{
              label: 'Close',
              onClick: onClose,
              color: 'inherit',
              buttonProps: {
                variant: 'outlined',
                style: { marginLeft: 0 },
              },
            }}
          />
          <div style={{ flexGrow: 1 }} />
          <ActionButton
            key={'Send'}
            buttonData={{
              label: 'Send',
              onClick: () => {
                handleSubmitCallback().catch(errorHandler)
              },
              loading: isSubmitting,
            }}
          />
        </DialogActions>
      )}
    </Drawer>
  )
}

export function useSendEsignEmailDrawer(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  const { onVoid, ...restModalProps } = modalProps
  const toggleConfirmVoidEsignEmailDialog = useConfirmVoidEsignEmailDialog({
    onVoid,
  })

  const [electronicSignatureAuditLogsRes, refreshElectronicSignatureAuditLogs] =
    useGetElectronicSignatureAuditLogsQuery({
      variables: {
        electronicSignatureId: modalProps.eSignData?.id ?? '',
      },
      pause: !modalProps.eSignData?.id,
    })

  const [signingOrderResponse] = useGetSigningOrderQuery()

  useModal<DialogProps>({
    component: SendEsignEmailDrawer,
    schema: {
      key: formID,
      modalProps: {
        onVoid: toggleConfirmVoidEsignEmailDialog,
        electronicSignatureAuditLogs:
          electronicSignatureAuditLogsRes?.data?.electronicSignatureAuditLogs.filter(notEmpty),
        signingOrder: signingOrderResponse?.data?.signingOrder,
        ...restModalProps,
      },
    },
  })
  return useCallback(
    (setTo?: boolean) => {
      if (setTo && modalProps.eSignData?.id) {
        refreshElectronicSignatureAuditLogs?.()
        modalProps.refresh?.()
      }
      toggleModal(formID, setTo)
    },
    [toggleModal, modalProps, refreshElectronicSignatureAuditLogs]
  )
}
