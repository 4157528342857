import React from 'react'
import { Box, Drawer } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import navCommon from './navCommon'
import { getEnv } from '@/util/isEnv'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  drawerPaper: {
    width: `${navCommon.drawerWidth}px`,
    backgroundColor: theme.customPalette.backgroundColor3,
    borderRight: `1px solid ${theme.customPalette.borderColor}`,
  },
  fixed: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
  },
  sandboxBanner: {
    ['& .MuiDrawer-paper']: {
      marginTop: 40,
    },
  },
}))

const LeftDrawerWithoutMemo: React.FC<{
  children?: JSX.Element
  onClose?: () => void
  mobileOpen?: boolean
}> = ({ children, onClose, mobileOpen }) => {
  const { classes, cx } = useStyles()
  const envInfo = getEnv()

  return (
    <Box component="aside">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onClose}
        classes={{ paper: classes?.drawerPaper }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {children}
      </Drawer>
      <Drawer
        variant="permanent"
        classes={{ paper: cx(classes?.drawerPaper, classes?.fixed) }}
        className={envInfo === 'sandbox' ? cx(classes.sandboxBanner) : ''}
        sx={{
          display: navCommon.drawerDisplayBreakpoints,
        }}
        open
      >
        {children}
      </Drawer>
    </Box>
  )
}

const LeftDrawer = React.memo(LeftDrawerWithoutMemo) as typeof LeftDrawerWithoutMemo
export default LeftDrawer
