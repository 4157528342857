import { OrderEditLineItemPopoverLink } from '@/pageComponents/orders/LineItemsEditTable/LineItemPopoverLink'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { useLineItemEditActions } from '../LineItemEditContextProvider'
import { OrderItemRowProps, useOrderChargeRowStyles } from '../orderChargeRow'
import { useOrderEditPlanRowStyles } from '../useOrderEditPlanRowStyles'
import StatusChip from '@/components/table/cells/statusChip'

export function LineItemsNameCell({ lineItemIndex, orderType, currency }: OrderItemRowProps) {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { classes } = useOrderChargeRowStyles()
  const { classes: planClasses } = useOrderEditPlanRowStyles()
  const { onClickCustomField, onClickChargeName } = useLineItemEditActions()
  const { chargeName, showDate, formattedDate, isChargeDuplicated } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          id: reduced.lineItem.id,
          chargeName: reduced.chargeName,
          showDate: reduced.showDate,
          formattedDate: reduced.formattedDate,
          isChargeDuplicated: reduced.isChargeDuplicated,
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )

  return (
    <div>
      <Box display="flex" alignItems="flex-start">
        <OrderEditLineItemPopoverLink
          lineItemIndex={lineItemIndex}
          onEdit={() => {
            onClickCustomField?.(lineItemIndex)
          }}
          onClickLink={() => {
            onClickChargeName?.(lineItemIndex)
          }}
          jotaiForm={jotaiForm}
        >
          {chargeName}
        </OrderEditLineItemPopoverLink>
        {isChargeDuplicated && (
          <span className={planClasses.statusChip}>
            <StatusChip value={'DUPLICATE'} color="primary" />
          </span>
        )}
      </Box>
      {showDate && <Typography className={classes.chargeDate}>{formattedDate}</Typography>}
    </div>
  )
}
