import { Opportunity } from '../../../generated/graphql'
import { CancellationOrderPageState } from './CancellationOrderPage'

export function preselectOpportunityByCrmId(
  draft: CancellationOrderPageState,
  opportunities: ReadonlyArray<Opportunity>,
  opportunityCrmId?: string
) {
  if (opportunityCrmId) {
    const selectedOpportunity = opportunities?.find((o) => o.crmId === opportunityCrmId)
    if (selectedOpportunity) {
      draft.orderDetail.sfdcOpportunityId = selectedOpportunity?.crmId
      draft.orderDetail.sfdcOpportunityName = selectedOpportunity?.name
      draft.orderDetail.sfdcOpportunityStage = selectedOpportunity?.stage
      draft.orderDetail.sfdcOpportunityType = selectedOpportunity?.type
    }
  }
}
