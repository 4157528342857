import { CheckCircle, Error } from '@mui/icons-material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import BillyDataGridPro from '../../../components/table/billyDataGridPro'
import TableEmptyContent from '../../../components/table/tableEmptyContent'
import { CrmContactSyncAction, UpsertCrmContactResponseFragment } from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
const logger = buildLogger('ImportContactTable')

export type ImportContactSummaryTableProps = {
  upsertResponse: readonly UpsertCrmContactResponseFragment[]
}

export function ImportContactSummaryTable({ upsertResponse }: ImportContactSummaryTableProps): JSX.Element {
  const modifiedContacts = useMemo(
    () =>
      [...upsertResponse]
        .map((response, index) => {
          const contact = response.crmContact
          return {
            ...response,
            ...contact,
            action: contact?.action ?? CrmContactSyncAction.Insert,
            id: contact?.id ?? index,
            fullName: `${contact?.firstName} ${contact?.lastName}`.trim(),
            reason: response.upserted ? 'Added' : response.error,
          }
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .sort((a, b) => (a.upserted ? 1 : 0) - (b.upserted ? 1 : 0)),
    [upsertResponse]
  )
  const contactColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'upserted',
        flex: 0.5,
        align: 'center',
        renderCell: (
          params: GridRenderCellParams<UpsertCrmContactResponseFragment, UpsertCrmContactResponseFragment['upserted']>
        ) => {
          return params.value ? <CheckCircle color="success" /> : <Error color="error" />
        },
        resizable: false,
        sortable: false,
      },
      {
        headerName: 'Name',
        field: 'fullName',
        minWidth: 80,
        flex: 4,
        align: 'left',
        headerAlign: 'left',
      },
      {
        headerName: 'Email',
        field: 'email',
        minWidth: 80,
        flex: 4,
        align: 'left',
        hideSortIcons: true,
      },
      {
        headerName: 'Result',
        field: 'reason',
        minWidth: 80,
        flex: 4,
        align: 'left',
        hideSortIcons: true,
      },
    ],
    []
  )

  return modifiedContacts?.length ? (
    <BillyDataGridPro rows={modifiedContacts} columns={contactColumns} disableColumnResize={false} />
  ) : (
    <TableEmptyContent message="No contact to import." />
  )
}
