import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Collapse, Dialog, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { memo, useCallback, useContext, useState } from 'react'
import BillyLink from '../../../components/link/billyLink'
import StatusChip from '../../../components/table/cells/statusChip'
import buildLogger from '../../../util/logger'
import PlanDialogBody from '../planDialogBody'
import { OrderEditPlanRowHeader } from './CustomColumn/OrderEditPlanRowHeader'
import OrderChargeRow from './orderChargeRow'
import { useOrderEditPlanRowStyles } from './useOrderEditPlanRowStyles'
import { LineItemTotal } from './LineItemTotal'
import { PlanRowProps } from './OrderEditPlanRow'
import { JotaiFormContext } from '@/components/state/jotaiFormProvider'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { ActionType } from '@/generated/graphql'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { LineItemState } from '../LineItemsViewTable/LineItemsViewTable'

const logger = buildLogger('PlanRow')

const RampedAndRemovedOrderEditPlanRow = memo(function OrderEditPlanRowWithoutMemo({
  orderType,
  plan,
  index,
  isPlanRampedAndRemoved,
}: PlanRowProps): JSX.Element {
  const { classes } = useOrderEditPlanRowStyles()
  const [isOpen, setIsOpen] = useState(true)
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)

  const lineItemIndexesMatchesPlan = jotaiForm.useSelect(
    useCallback(
      (form) =>
        form.orderDetail.lineItems
          .map((lineItem, lineItemIndx) => {
            return {
              ...lineItem,
              lineItemIndx,
            }
          })
          .filter((lineItem) => lineItem.plan?.id === plan.id && lineItem.action === ActionType.Remove)
          .map((lineItem) => lineItem.lineItemIndx),
      [plan.id]
    )
  )

  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false)

  const handlePlanDialogClose = (): void => {
    setIsPlanDialogOpen(false)
  }

  const handlePlanClick = (): void => {
    setIsPlanDialogOpen(true)
  }

  return (
    <>
      <Dialog open={isPlanDialogOpen} onClose={handlePlanDialogClose} maxWidth={'md'} scroll={'paper'}>
        <PlanDialogBody onClose={handlePlanDialogClose} id={plan.id || ''} />
      </Dialog>
      <TableRow>
        <TableCell>
          <Typography component="span" variant="body2" sx={{ mr: 1 }}>
            {index + 1}.
          </Typography>
          <BillyLink linkProps={{ onClick: () => handlePlanClick() }} style={{ display: 'inline' }}>
            {plan.name}
          </BillyLink>
          <span className={classes.statusChip}>
            <StatusChip value={LineItemState.Removed} />
          </span>
        </TableCell>
        <TableCell align="left">
          <LineItemTotal plan={plan} isPlanRampedAndRemoved={isPlanRampedAndRemoved} />
        </TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <IconButton aria-label="expand plan" size="small" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={3}
          style={{
            padding: 0,
            height: 'unset',
          }}
        >
          <Collapse in={isOpen} timeout="auto">
            <Table>
              <OrderEditPlanRowHeader orderType={orderType} />
              <TableBody className={classes.orderPlanChargesBody}>
                {lineItemIndexesMatchesPlan.map((lineItemIndex) => {
                  return <OrderChargeRow key={lineItemIndex} lineItemIndex={lineItemIndex} orderType={orderType} />
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
})

export default RampedAndRemovedOrderEditPlanRow
