import { PlatformFeature } from '@/generated/graphql'
import { useUserTenantSessionWithoutThrowing } from '../UserTenantSessionProvider/UserTenantSessionContext'
import { useMemo } from 'react'

export function useEnabledPlatformFeature(platformFeature: PlatformFeature): boolean {
  const userTenantSession = useUserTenantSessionWithoutThrowing()

  return useMemo(() => {
    return platformFeature
      ? !!userTenantSession?.enabledPlatformFeatures.find((feat) => {
          return feat.platformFeature === platformFeature
        }) ?? false
      : false
  }, [userTenantSession?.enabledPlatformFeatures, platformFeature])
}
