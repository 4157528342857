import {
  useDryRunActions,
  useShouldRecalculateTotals,
} from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { useMemo } from 'react'

/**
 * TODO: will become a config from backend
 */
export const IS_MANUAL_DRY_RUN_ENABLED = false

export function useManualDryRunTrigger() {
  const shouldNotifyManualDryRun = useShouldRecalculateTotals()

  const { triggerDryRun } = useDryRunActions()

  return useMemo(
    () => ({
      shouldNotifyManualDryRun,
      onRecalculateTotals: triggerDryRun,
      isManualDryRunEnabled: IS_MANUAL_DRY_RUN_ENABLED,
    }),
    [shouldNotifyManualDryRun, triggerDryRun]
  )
}
