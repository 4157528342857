import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { useCallback } from 'react'
import { parseJson } from '../../util/stringToJSONSchema'
import buildLogger from '@/util/logger'
const logger = buildLogger('useParseCustomizationMetadata')

export enum CustomizationFieldWithSpecialCondition {
  ProductCategory = 'product_category',
}

import { z } from 'zod'
import { GridLogicOperator } from '@mui/x-data-grid-pro'

export const EnumFilterOperator = ['is'] as const
export const StringFilterOperator = [
  'contains',
  'equals',
  'startsWith',
  'endsWith',
  'isEmpty',
  'isNotEmpty',
  'isAnyOf',
] as const
export const FilterOperator = [...EnumFilterOperator, ...StringFilterOperator] as const

const customizationSchema = z
  .object({
    defaultFilterModel: z.object({
      items: z.array(
        z.object({
          id: z.string().optional(),
          field: z.string(),
          operator: z.enum(FilterOperator, {
            invalid_type_error: 'Invalid operator',
            required_error: 'Operator is required',
          }),
          value: z.string(),
        })
      ),
      logicOperator: z.nativeEnum(GridLogicOperator),
    }),
  })
  .transform((data, ctx) => {
    data.defaultFilterModel.items.forEach((item) => {
      if (
        item.field === CustomizationFieldWithSpecialCondition.ProductCategory &&
        !(EnumFilterOperator as readonly string[]).includes(item.operator)
      ) {
        const message = `"${item.field}" can only be used with operator ${JSON.stringify(EnumFilterOperator)}`
        ctx.addIssue({ code: 'custom', message })
        return z.NEVER
      }
    })

    return {
      ...data,
      defaultFilterModel: {
        ...data.defaultFilterModel,
        items: data.defaultFilterModel.items.map((item, index) => ({
          ...item,
          id: item.id ?? `generated-item-id-${index}`,
        })),
      },
    }
  })

export function useCustomizationParser() {
  const errorHandler = useErrorHandler()
  const parseSafe = useCallback(
    (data: string) => {
      try {
        const jsonData = parseJson(data)
        const parsed = customizationSchema.parse(jsonData)
        return parsed
      } catch (err) {
        logger.warn({ err })
        if (err instanceof z.ZodError) {
          const message = err.issues?.[0].message ?? err.message
          errorHandler({
            message,
          })
        } else {
          errorHandler(err)
        }
        return null
      }
    },
    [errorHandler]
  )
  return {
    parseSafe,
  }
}
