import { LinearProgress, useTheme } from '@mui/material'

export default function AsyncLoadingIndicator() {
  const theme = useTheme()
  return (
    <LinearProgress
      data-testid={AsyncLoadingIndicator.name}
      style={{ position: 'fixed', top: 0, left: 0, zIndex: theme.zIndex.snackbar, width: '100%' }}
      variant="indeterminate"
    />
  )
}
