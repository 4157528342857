import { JotaiForm } from '@/components/state/useJotaiForm'
import { CompositeOrderType, CustomFieldType, LineItemFragment, OrderType } from '@/generated/graphql'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { useCallback, useMemo } from 'react'
import { useUIConfiguration } from '../../../components/UserTenantSessionProvider/useUIConfiguration'
const logger = buildLogger('useLineItemCustomFieldColumns')

export function useLineItemCustomFieldColumns<T extends Pick<NewOrderFormData, 'orderDetail'>>(
  form: JotaiForm<T>,
  lineItemIndex: number
) {
  const customColumnsProps = form.useSelect(
    useCallback(
      (form) => {
        const orderType = form.orderDetail.orderType
        const lineItem = form.orderDetail.lineItems?.[lineItemIndex]
        return {
          orderType,
          lineItem,
        }
      },
      [lineItemIndex]
    )
  )
  return useConfiguredCustomColumns(customColumnsProps)
}

type CustomColumnsGetterProps = {
  orderType?: OrderType | CompositeOrderType
  lineItem?: LineItemFragment
  customColumnsToShow: string[]
}

export const getCustomColumns = ({ orderType, lineItem, customColumnsToShow }: CustomColumnsGetterProps) => {
  if (orderType && ([OrderType.New, OrderType.Amendment, OrderType.Renewal] as string[]).includes(orderType)) {
    return (
      lineItem?.customFields
        ?.filter((customField) => customColumnsToShow.includes(customField?.name ?? ''))
        .filter(notEmpty) ?? []
    )
  } else {
    return []
  }
}

export const useConfiguredCustomColumns = ({
  orderType,
  lineItem,
}: Pick<CustomColumnsGetterProps, 'lineItem' | 'orderType'>) => {
  const uiConfiguration = useUIConfiguration()

  const customColumnsToShow = useMemo(
    () =>
      uiConfiguration?.orderPageConfiguration?.orderLineItemsConfiguration?.customFields
        ?.map((customField) => customField?.name)
        .filter(notEmpty) ?? [],
    [uiConfiguration]
  )

  const definitions = getCustomColumns({
    orderType,
    lineItem,
    customColumnsToShow,
  }).map((customField) => ({
    ...customField,
    fieldType: customField.type as CustomFieldType,
    fieldName: customField.name,
    fieldLabel: customField.label,
  }))

  return useMemo(
    () => ({
      customColumns: definitions.map((customField) => customField.fieldLabel ?? customField.fieldName),
      definitions,
    }),
    [definitions]
  )
}
