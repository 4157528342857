import { useCallback, useEffect, useMemo } from 'react'
import { useErrorHandler } from '../../../../components/ErrorHandler/ErrorHandler'
import { deepMutable } from '../../../../components/SchemaForm/DeepMutable'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import {
  useGetCrmContactsForAccountQuery,
  useListAccountContactsQuery,
  useUpsertCrmContactMutation,
} from '../../../../generated/graphql'
import { useImportCrmContactDialog } from '../../../accounts/SalesforceContactSync/ImportCrmContactDialog'
import { useImportCrmContactSummaryDialog } from '../../../accounts/SalesforceContactSync/ImportSalesforceContactSummaryDialog'
import { NewOrderFormData } from '../NewOrderPage'
import { CommonOrderFormPageState } from '../CommonOrderFormPageState'

export type UseOrderFormCrmReducerForm = Pick<
  CommonOrderFormPageState,
  'orderDetail' | 'billingContactList' | 'contactList' | 'hasHubSpotIntegration' | 'hasSalesforceIntegration'
>

export function useOrderFormCrmReducer<F extends UseOrderFormCrmReducerForm = NewOrderFormData>() {
  const jotaiForm = useJotaiFormContext<F>()
  const errorHandler = useErrorHandler()
  const accountId = jotaiForm.useSelect(useCallback((form) => form.orderDetail.account.id, []))
  const hasCrmIntegration = jotaiForm.useSelect(
    useCallback((form) => form.hasHubSpotIntegration || form.hasSalesforceIntegration, [])
  )

  const [, upsertCrmContact] = useUpsertCrmContactMutation()

  const [toggleImportCrmContactSummaryDialog, setImportCrmContactSummaryResponse] = useImportCrmContactSummaryDialog()

  const [refreshCrmContactsResponse, refreshCrmContacts] = useGetCrmContactsForAccountQuery({
    variables: {
      accountId: accountId ?? '',
    },
    pause: !accountId || !hasCrmIntegration,
  })

  const [refreshAccountContactsResponse, refreshAccountContacts] = useListAccountContactsQuery({
    variables: {
      accountId: accountId ?? '',
    },
    pause: !accountId,
  })

  const crmContacts = refreshCrmContactsResponse.data?.crmContactsForAccount
  const showContactImport = !!crmContacts?.length
  const onImportContact = async (crmIds: readonly string[]) => {
    const { data } = await upsertCrmContact({ accountId: accountId ?? '', crmIds })
    if (data) {
      setImportCrmContactSummaryResponse(data.upsertCrmContacts)
      refreshCrmContacts()
      refreshAccountContacts()
      toggleImportCrmContactSummaryDialog()
    }
  }
  const toggleImportCrmContactDialog = useImportCrmContactDialog({
    crmContacts,
    onSubmit: async (crmId: readonly string[]) => {
      await onImportContact(crmId).catch(errorHandler)
    },
  })

  useEffect(() => {
    if (refreshAccountContactsResponse.data?.accountContacts) {
      jotaiForm.set((draft) => {
        draft.contactList = deepMutable(refreshAccountContactsResponse.data?.accountContacts)
        if (!draft.orderDetail.resoldBy?.id) {
          draft.billingContactList = deepMutable(refreshAccountContactsResponse.data?.accountContacts)
        }
      })
    }
  }, [jotaiForm, refreshAccountContactsResponse.data?.accountContacts])

  return useMemo(
    () => ({
      toggleImportCrmContactDialog,
      showContactImport,
    }),
    [toggleImportCrmContactDialog, showContactImport]
  )
}
