import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { JotaiForm } from '../../../../components/state/useJotaiForm'
import { NewOrderFormData, logger } from '../NewOrderPage'
import { calculateOrderEndDate } from '../utils'
import { Recurrence } from '@/generated/graphql'
import { unixToLuxonWithTz } from '@/util/datetime/luxon/dateUtil'

export function calculateRampIntervals(
  startDate: number,
  billingAnchorDate: number | null | undefined,
  termLength: Recurrence,
  endDate?: number | null | undefined
): number[] {
  const startDateObj = unixToLuxonWithTz(startDate)
  const billingAnchorDateObj = unixToLuxonWithTz(billingAnchorDate ?? startDate)

  const intervals: number[] = []

  const endDateObj = endDate ? unixToLuxonWithTz(endDate) : calculateOrderEndDate(startDate, termLength)

  let nextInterval = startDateObj

  if (billingAnchorDateObj > startDateObj) {
    intervals.push(nextInterval.toUnixInteger())
    nextInterval = billingAnchorDateObj
  }

  while (nextInterval < endDateObj) {
    intervals.push(nextInterval.toUnixInteger())
    nextInterval = nextInterval.plus({ year: 1 })
  }

  if (intervals.length === 0) {
    intervals.push(startDate)
  }

  logger.trace({ msg: 'calculating ramp intervals', intervals })

  return intervals
}

export function useJotaiApplyRampProps(jotaiForm: JotaiForm<NewOrderFormData>) {
  const deleteInterval = useCallback(
    (index: number) => (event: React.MouseEvent) => {
      jotaiForm.set((form: NewOrderFormData) => {
        event.preventDefault()
        if (form.orderDetail.rampInterval) {
          const intervals = form.orderDetail.rampInterval
          form.orderDetail.rampInterval = intervals.slice(0, index).concat(intervals.slice(index + 1, intervals.length))
        }
      })
    },
    [jotaiForm]
  )

  const cycle = jotaiForm.useSelect(useCallback((form) => form.orderDetail.termLength?.cycle, []))
  const addInterval = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      jotaiForm.set((form: NewOrderFormData) => {
        if (form.orderDetail.rampInterval) {
          const intervals = form.orderDetail.rampInterval
          const lastInterval = intervals[intervals.length - 1]
          if (intervals.length > 0 && lastInterval !== undefined) {
            form.orderDetail.rampInterval = [
              ...intervals,
              DateTime.fromSeconds(lastInterval || 0)
                .plus({ days: 1 })
                .toUnixInteger(),
            ]
          }
        }
      })
    },
    [jotaiForm]
  )
  return {
    deleteInterval,
    addInterval,
    cycle,
  }
}
