import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import JotaiMuiAutocomplete from '../../components/input/JotaiMuiAutocomplete'
import buildLogger from '../../util/logger'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'

const logger = buildLogger('OrderApprovalSegmentSelect')

export type OrderApprovalSegmentSelectJotaiState = Pick<CommonOrderFormPageState, 'approvalSegments' | 'orderDetail'>

export const OrderApprovalSegmentSelect = <F extends OrderApprovalSegmentSelectJotaiState>(): JSX.Element => {
  const jotaiForm = useJotaiFormContext<F>()
  const approvalSegments = jotaiForm.useSelect(useCallback((form) => form.approvalSegments, []))

  return approvalSegments && approvalSegments.length > 0 ? (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={(form: F) => deepMutable(form.approvalSegments)}
        atomUpdater={(value, draft) => {
          draft.orderDetail.approvalSegment = value
        }}
        autocompleteProps={{
          getOptionLabel: (option) => option?.name || '',
          disableClearable: true,
        }}
        atomValueSelector={(form) => form.orderDetail.approvalSegment}
        errorPath="orderDetail.approvalSegment"
        form={jotaiForm}
        textFieldProps={{
          label: 'Approval Segment',
        }}
      />
    </Grid>
  ) : (
    <></>
  )
}
