import { useCallback } from 'react'
import produce from 'immer'
import { atom } from 'jotai'
import buildLogger from '../../util/logger'
import JotaiReadValue from '../state/jotaiReadValue'
import { JotaiForm } from '../state/useJotaiForm'
import { WithRestApi } from '../state/useJotaiRest'
import BillySnackBar, { BillySnackBarProps } from './billySnackBar'
import useJotaiBillySnackBar, { UseJotaiBillySnackBarProps } from './useJotaiBillySnackBar'

const logger = buildLogger('useRestSnackBar')

export type JotaiBillyRestErrorProps<T> = Partial<BillySnackBarProps> & {
  jotaiForm: JotaiForm<T & WithRestApi>
}

export type UseJotaiRestBillySnackBarReturnType<T> = [
  JotaiBillyRestError: ({ jotaiForm, handleClose, ...props }: JotaiBillyRestErrorProps<T>) => JSX.Element,
  JotaiBillyRestSnackBar: (props: UseJotaiBillySnackBarProps<T & WithRestApi>) => JSX.Element
]
export default function useJotaiRestBillySnackBar<T>(
  jotaiForm: JotaiForm<T & WithRestApi>,
  key: string
): UseJotaiRestBillySnackBarReturnType<T> {
  const showSuccessAtom = atom<boolean, boolean>(
    (get) => {
      return get(jotaiForm.atom).restIsSuccess[key]
    },
    (get, set, update) => {
      const formData = get(jotaiForm.atom)
      const immerUpdate = produce(formData, (formDataDraft) => {
        if (!formDataDraft.restIsSuccess[key]) {
          formDataDraft.restIsSuccess[key] = false
        }
        formDataDraft.restIsSuccess[key] = update
      })
      set(jotaiForm.atom, immerUpdate)
    }
  )

  const isWorkingAtomSelector = (form: T & WithRestApi): boolean => !form.restError[key] && form.restIsFetching[key]

  const JotaiBillyRestSnackBar = (props: UseJotaiBillySnackBarProps<T & WithRestApi>): JSX.Element =>
    useJotaiBillySnackBar({
      isWorkingAtomSelector,
      showSuccessAtom,
      ...props,
    }).jsxElement

  const JotaiBillyRestError = ({ jotaiForm, handleClose, ...props }: JotaiBillyRestErrorProps<T>): JSX.Element => {
    return (
      <JotaiReadValue
        atomSelector={useCallback((form: T & WithRestApi) => form.restError[key], [])}
        form={jotaiForm}
        render={(restError?: string) => (
          <>
            {restError && (
              <BillySnackBar
                isOpen={!!restError}
                type="warning"
                message={restError}
                handleClose={() => {
                  jotaiForm.set((draft) => {
                    draft.restError[key] = undefined
                  })
                  !!handleClose && handleClose()
                }}
                {...props}
              />
            )}
          </>
        )}
      />
    )
  }

  return [JotaiBillyRestError, JotaiBillyRestSnackBar]
}
