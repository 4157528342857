import { Logger } from 'pino'
import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLoggerEffect(logger: Logger, ...args: any[]) {
  useEffect(() => {
    logger.debug({
      msg: `Component mounted`,
      context: args,
    })
    return () => {
      logger.debug({
        msg: `Component unmounted`,
        context: args,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
