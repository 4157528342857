import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Hidden, Slide, Toolbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import BreadcrumbNav, { BreadcrumbDataList } from './breadcrumbNav'
import navCommon from './navCommon'

type Props = {
  children?: React.ReactNode
  breadcrumbs?: BreadcrumbDataList
  handleDrawerToggle?: () => void
  showMenuToggle?: boolean
}

export default function SecondaryBar({
  children,
  breadcrumbs,
  handleDrawerToggle,
  showMenuToggle,
}: Props): JSX.Element {
  React.useEffect(() => {
    // Shift the scroll position back and forth to trigger the sticky header to render on page load
    const timeout = setTimeout(() => window.scrollTo(window.scrollX, window.scrollY - 1))
    return () => clearTimeout(timeout)
  })

  const commonStyles = navCommon.styles().classes

  return (
    <Hidden only={children ? undefined : navCommon.breadCrumbHiddenBreakpoints}>
      <Box className={commonStyles.appBar}>
        <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
          <Hidden only={navCommon.drawerShownBreakpoints}>
            {!!showMenuToggle && (
              <Slide direction="down" in={true} timeout={50}>
                <IconButton edge="start" onClick={handleDrawerToggle} size="large">
                  <MenuIcon />
                </IconButton>
              </Slide>
            )}
          </Hidden>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>{breadcrumbs && <BreadcrumbNav breadcrumbs={breadcrumbs} />}</Box>
          {children}
        </Toolbar>
      </Box>
    </Hidden>
  )
}
