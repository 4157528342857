import { ButtonData } from '@/components/button/actionButton'
import { useCustomizationsContext } from '@/components/state/context/customizationContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useManualDryRunTrigger } from '@/pageComponents/orders/useManualDryRunTrigger'
import Image from 'next/image'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorHandler } from '../../../../components/ErrorHandler/ErrorHandler'
import { deepMutable } from '../../../../components/SchemaForm/DeepMutable'
import { BreadcrumbDataList } from '../../../../components/nav/breadcrumbNav'
import { useBillyRouter } from '../../../../components/route/useBillyRouter'
import useJotaiOnSubmit from '../../../../components/state/useJotaiOnSubmit'
import { useJotaiUrqlMutation } from '../../../../components/state/useJotaiUrqlMutation'
import {
  DryRunOrderDocument,
  DryRunRenewalOrderDocument,
  UpsertOrderDocument,
  UpsertRenewalOrderDocument,
} from '../../../../generated/graphql'
import { getOrderSchema } from '../../../../util/validationSchemas'
import usePopulateOrder from '../../usePopulateOrder'
import { useUpdateCustomFieldsBatchAsync } from '../../useUpdateCustomFieldsBatchAsync'
import { CommonOrderFormPageState } from '../CommonOrderFormPageState'
import { NewOrderFormData, logger } from '../NewOrderPage'
import { useDryRunActions, useShouldRecalculateTotals } from '../context/DryRunActionsContext'
import { projectUpsertArgs, useNewOrderPageJotaiQueries } from '../utils'
import { mapOnDryRunData } from '@/util/order'

export function useOrderPageReducer() {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { hasLoaded, orderId, isNew, isRenewal } = usePopulateOrder({ jotaiForm })
  useNewOrderPageJotaiQueries()
  const router = useBillyRouter()
  const errorHandler = useErrorHandler()

  const breadcrumbs = useMemo(() => {
    const crumbs: BreadcrumbDataList = [
      {
        label: '',
        link: '/',
        icon: <Image src="/icons/home-sm.svg" width={20} height={20} />,
      },
      { label: 'Orders', link: '/orders' },
    ]

    if (isNew) {
      crumbs.push({ label: isRenewal ? 'Renew Subscription' : 'New Order' })
    } else {
      crumbs.push({ label: orderId, link: `/orders/${orderId}` })
      crumbs.push({ label: 'Edit Order' })
    }

    return crumbs
  }, [isRenewal, isNew, orderId])
  const { updateCustomFieldsBatchAsync } = useUpdateCustomFieldsBatchAsync()

  const upsertOrder = useJotaiUrqlMutation({ document: UpsertOrderDocument, jotaiForm })
  const upsertRenewalOrder = useJotaiUrqlMutation({ document: UpsertRenewalOrderDocument, jotaiForm })

  const [isSubmitting, setIsSubmitting] = useIsOrderPageSubmitting<NewOrderFormData>()

  const onSave = useCallback(
    (form: NewOrderFormData) => {
      setIsSubmitting(true)
      const args = projectUpsertArgs(form.orderDetail, form.orderPlans)
      logger.debug({ msg: 'Upserting renewal', order: form.orderDetail, args, isRenewal })
      if (args) {
        if (isRenewal) {
          logger.debug({ msg: 'Upserting renewal', args })
          return upsertRenewalOrder({
            variables: args,
            onData: (data) => {
              async function doAsync() {
                await updateCustomFieldsBatchAsync(deepMutable(data.upsertRenewalOrder.lineItems))
                logger.debug({ msg: 'Upserted renewal', data })
                const resultOrderId = data.upsertRenewalOrder.id
                if (resultOrderId) {
                  if (isNew) {
                    history.replaceState(null, '', `/orders/${resultOrderId}/edit`)
                    router.push(`/orders/${resultOrderId}`)
                  } else {
                    router.push(`/orders/${orderId}`)
                  }
                }
              }
              doAsync().catch(errorHandler)
            },
          })
        } else {
          logger.debug({ msg: 'Upserting', args })
          return upsertOrder({
            variables: args,
            onData: (data) => {
              async function doAsync() {
                const resultOrderId = data.upsertOrder.id
                await updateCustomFieldsBatchAsync(deepMutable(data.upsertOrder.lineItems))
                if (resultOrderId) {
                  if (isNew) {
                    history.replaceState(null, '', `/orders/${resultOrderId}/edit`)
                    router.push(`/orders/${resultOrderId}`)
                  } else {
                    router.push(`/orders/${orderId}`)
                  }
                }
              }
              doAsync().catch(errorHandler)
            },
          })
        }
      }
      setIsSubmitting(false)
    },
    [
      isNew,
      orderId,
      router,
      isRenewal,
      upsertOrder,
      upsertRenewalOrder,
      errorHandler,
      updateCustomFieldsBatchAsync,
      setIsSubmitting,
    ]
  )

  const customizations = useCustomizationsContext()
  const schema = useMemo(() => getOrderSchema({ customizations, parentLocator: 'order::new::' }), [customizations])
  const onSubmitSave = useJotaiOnSubmit({ atom: jotaiForm.atom, onSubmit: onSave, schema })

  const { isManualDryRunEnabled, shouldNotifyManualDryRun, onRecalculateTotals } = useManualDryRunTrigger()

  const buttonDataList: ButtonData[] = useMemo(
    () =>
      [
        {
          label: 'Cancel',
          onClick: orderId ? `/orders/${orderId}` : '/orders',
          color: 'inherit' as const,
          buttonProps: { variant: 'outlined' as const },
        },
        {
          label: 'Apply Line Items Change',
          onClick: onRecalculateTotals,
          disabledExplanation: !shouldNotifyManualDryRun
            ? 'Order items are up to date.'
            : isSubmitting
            ? 'Please wait while the request is in progress'
            : '',
          hide: !isManualDryRunEnabled || !shouldNotifyManualDryRun,
        },
        {
          label: 'Save Draft',
          onClick: onSubmitSave,
          disabledExplanation:
            isManualDryRunEnabled && shouldNotifyManualDryRun
              ? 'Please apply order items change before saving'
              : isSubmitting
              ? 'Please wait while the request is in progress'
              : '',
        },
      ].filter((buttonData) => !buttonData.hide),
    [onSubmitSave, orderId, isSubmitting, shouldNotifyManualDryRun, onRecalculateTotals, isManualDryRunEnabled]
  )

  const dryRunUpsertOrder = useJotaiUrqlMutation({ document: DryRunOrderDocument, jotaiForm })
  const dryRunUpsertRenewalOrder = useJotaiUrqlMutation({ document: DryRunRenewalOrderDocument, jotaiForm })

  const { clearDryRun } = useDryRunActions()

  const dryRun = useCallback(
    (form: NewOrderFormData) => {
      const args = projectUpsertArgs(form.orderDetail, form.orderPlans)
      logger.debug({ msg: 'Dry Running', args, form })
      if (args) {
        if (isRenewal) {
          dryRunUpsertRenewalOrder({
            variables: args,
            onData: (data, draft) => {
              const dryRunOrderDetail = data.upsertRenewalOrder
              draft.orderDetail = mapOnDryRunData({
                orderDetail: { ...draft.orderDetail },
                data: dryRunOrderDetail,
              })
              clearDryRun()
            },
          })
        } else {
          dryRunUpsertOrder({
            variables: args,
            onData: (data, draft) => {
              const dryRunOrderDetail = data.upsertOrder
              draft.orderDetail = deepMutable(dryRunOrderDetail)
              clearDryRun()
            },
          })
        }
      }
    },
    [dryRunUpsertOrder, dryRunUpsertRenewalOrder, isRenewal, clearDryRun]
  )

  const onDryRun = useJotaiOnSubmit({
    atom: jotaiForm.atom,
    onSubmit: useCallback((value: NewOrderFormData) => dryRun(value), [dryRun]),
    schema,
    onError: clearDryRun,
  })

  const shouldRecalculateTotalsDebounce = useShouldRecalculateTotals()
  const shouldDryRun = hasLoaded && shouldRecalculateTotalsDebounce

  useEffect(() => {
    logger.trace({ shouldDryRun })
    if (shouldDryRun) {
      onDryRun()
    }
  }, [jotaiForm, onDryRun, shouldDryRun, errorHandler])

  const orderTitle = isNew ? (isRenewal ? 'Renew Subscription' : 'New Order') : `Edit ${orderId}`

  return useMemo(
    () => ({
      orderTitle,
      buttonDataList,
      onSubmitSave,
      breadcrumbs,
      isLoading: !isNew && !hasLoaded,
      isManualDryRunEnabled,
    }),
    [orderTitle, buttonDataList, onSubmitSave, breadcrumbs, isNew, hasLoaded, isManualDryRunEnabled]
  )
}

/**
 * TODO: consider using global context for submitting state
 */
export const useIsOrderPageSubmitting = <T extends Omit<CommonOrderFormPageState, 'orderDetail'>>() => {
  const jotaiForm = useJotaiFormContext<T>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { yupErrors = {}, urqlErrors = {} } = jotaiForm.useSelect(
    useCallback((form) => {
      return { yupErrors: form.yupErrors, urqlErrors: form.urqlErrors }
    }, [])
  )
  const yupErrorsCount = Object.keys(yupErrors).length
  const urqlErrorsCount = Object.keys(urqlErrors).length

  useEffect(() => {
    if (yupErrorsCount === 0) {
      setIsSubmitting(false)
    }
  }, [yupErrorsCount, urqlErrorsCount])

  return [isSubmitting, setIsSubmitting] as [typeof isSubmitting, typeof setIsSubmitting]
}
