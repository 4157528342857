import { LineItemFragment, Opportunity } from '@/generated/graphql'
import { AmendSubscriptionPageState } from '@/pageComponents/orders/AmendmentOrderPage/AmendmentOrderPage'

// false if a new line item is not in the amendment period, otherwise true.
export function lineItemInAmendmentPeriod(lineItem: LineItemFragment, startDate: number): boolean {
  if (!lineItem.effectiveDate || !lineItem.endDate) {
    return true
  }
  return lineItem.effectiveDate <= lineItem.endDate && lineItem.endDate > startDate
}

// Returns the correct effective date for a line item upon a change in the order start date
export function alignEffectiveDateToRampPeriodOrOrderStart(
  currentEffectiveDate: number,
  rampIntervals: (number | null)[] | null | undefined,
  newOrderStartDate: number
): number {
  // check if order is ramped or if new start date is after current effective date
  if (!rampIntervals || rampIntervals.length === 0 || newOrderStartDate >= currentEffectiveDate) {
    return newOrderStartDate
  }
  for (let i = rampIntervals.length - 1; i >= 0; i--) {
    const rampInterval = rampIntervals[i]
    if (rampInterval && rampInterval < currentEffectiveDate) {
      if (rampInterval <= newOrderStartDate) {
        return newOrderStartDate
      } else {
        return rampInterval
      }
    }
  }
  return newOrderStartDate
}

export function preselectOpportunityByCrmId(
  draft: AmendSubscriptionPageState,
  opportunities: ReadonlyArray<Opportunity>,
  opportunityCrmId?: string
) {
  if (opportunityCrmId) {
    const selectedOpportunity = opportunities?.find((o) => o.crmId === opportunityCrmId)
    if (selectedOpportunity) {
      draft.orderDetail.sfdcOpportunityId = selectedOpportunity?.crmId
      draft.orderDetail.sfdcOpportunityName = selectedOpportunity?.name
      draft.orderDetail.sfdcOpportunityStage = selectedOpportunity?.stage
      draft.orderDetail.sfdcOpportunityType = selectedOpportunity?.type
    }
  }
}
