import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'

type ConfirmExecuteOrderDialogProps = {
  onSubmit?: () => void
}

type DialogProps = WithModalParams & ConfirmExecuteOrderDialogProps
const formID = 'ConfirmExecuteOrderDialog'
function ConfirmExecuteOrderDialog({ open, onClose, onSubmit }: DialogProps): JSX.Element {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Confirm Execute Order</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Order Start Date will change to the Order Execution Date once the order is executed. Are you sure you want to
          continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Continue',
            onClick: () => {
              onSubmit?.()
              onClose?.()
            },
            color: 'primary',
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useConfirmExecuteOrderDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  const [statusUpdatedOn, setStatusUpdatedOn] = useState<number | undefined>(undefined)

  useModal<DialogProps>({
    component: ConfirmExecuteOrderDialog,
    schema: {
      key: formID,
      modalProps: {
        ...modalProps,
        onSubmit: () => {
          // `modalProps.onSubmit` only holds a reference to the `handleMarkAsExecuted` function
          // as the execution date is not yet known when the hook is called
          // so we need to call it with the updated date
          modalProps.onSubmit?.(statusUpdatedOn)
        },
      },
    },
  })
  return useCallback(
    (updatedDate?: number) => {
      setStatusUpdatedOn(updatedDate)
      toggleModal(formID)
    },
    [toggleModal]
  )
}
