import { Box, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { memo } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { LineItemsTableOrderType } from '../LineItemsEditTable'
import { useLineItemCustomFieldColumns } from '@/pageComponents/orders/LineItemsEditTable/useLineItemCustomFieldColumns'
import { useOrderEditPlanRowStyles } from '../useOrderEditPlanRowStyles'
import { CustomColumnHeader } from '@/pageComponents/orders/LineItemsEditTable/CustomColumn/CustomColumnHeader'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'

function OrderEditPlanRowHeaderWithoutMemo({ orderType }: { orderType: LineItemsTableOrderType }) {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { classes } = useOrderEditPlanRowStyles()
  const { customColumns } = useLineItemCustomFieldColumns(jotaiForm, 0)

  const isOrderAnnualizedAmountEnabled = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)

  return (
    <TableHead>
      <TableRow className={classes.orderPlanChargesHeaderRow}>
        <TableCell style={{ paddingLeft: 36 }}>Charges</TableCell>
        {customColumns.map((column, index) => (
          <CustomColumnHeader name={column} key={`custom-column-header-${index}}`} />
        ))}
        <TableCell className={classes.numberHeader}>Quantity</TableCell>
        <TableCell className={classes.currencyHeader}>
          <Box>
            <Typography variant="body2">List Total</Typography>
            {isOrderAnnualizedAmountEnabled && <Typography variant="caption">(List Unit Price)</Typography>}
          </Box>
        </TableCell>
        <TableCell className={classes.currencyHeader}>
          <Box>
            <Typography variant="body2">Discount Total</Typography>
            {isOrderAnnualizedAmountEnabled && <Typography variant="caption">(Discount Per Unit)</Typography>}
          </Box>
        </TableCell>
        <TableCell className={classes.currencyHeader}>Sell Price</TableCell>
        <TableCell className={classes.currencyHeader}>
          <Box>
            <Typography variant="body2">{orderType === 'AMENDMENT' ? 'Amendment Total' : 'Total'}</Typography>
            {isOrderAnnualizedAmountEnabled && <Typography variant="caption">(Annual Total)</Typography>}
          </Box>
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}
export const OrderEditPlanRowHeader = memo(
  OrderEditPlanRowHeaderWithoutMemo
) as typeof OrderEditPlanRowHeaderWithoutMemo
