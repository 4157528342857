import { CustomFieldEntry } from '@/generated/graphql'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { notEmpty } from '@/util/array'

export function formatCustomFieldEntry(customField: CustomFieldEntry) {
  const content = customField.value || customField.selections?.join(', ') || '-'
  return {
    label: customField.label || customField.name,
    content,
    description: content,
  }
}

export const populateCustomFieldsWithDefault = (customFields) => {
  return (
    customFields?.filter(notEmpty).map((customField) => {
      return {
        ...customField,
        value: customField.value ?? customField.defaultValue?.value ?? '',
        selections:
          customField.selections?.length > 0 ? customField.selections : customField.defaultValue?.selections ?? [],
      }
    }) ?? []
  )
}

export const populateOrderCustomFieldDefault = (orderDetail) => {
  return deepMutable(populateCustomFieldsWithDefault(orderDetail?.customFields))
}
