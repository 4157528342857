import React from 'react'
import Script from 'next/script'
import env from '../../util/env'

function Usersnap(): JSX.Element {
  return (
    <Script
      src={`https://widget.usersnap.com/global/load/${env.NEXT_PUBLIC_USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`}
      strategy="lazyOnload"
      onLoad={() => {
        window.onUsersnapCXLoad = (api) => {
          api.init()
          api.show(env.NEXT_PUBLIC_USERSNAP_API_KEY)
        }
      }}
    />
  )
}

export default Usersnap
