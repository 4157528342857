import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const TableEmptyContent: React.FC<{ message?: string; children?: JSX.Element }> = ({
  message = 'No data.',
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '105px',
      }}
    >
      <Typography variant="body1" color={(theme) => theme.palette.action.disabled}>
        {message}
      </Typography>
      {children}
    </Box>
  )
}
export default TableEmptyContent
