import JotaiFormProvider from '@/components/state/jotaiFormProvider'
import { OrderApprovalFlowCollapseList } from '@/pageComponents/orders/OrderApprovalFlowView/components/ApprovalFlowCollapse'
import { OrderApprovalDialog } from '@/pageComponents/orders/OrderApprovalFlowView/components/ApprovalFlowDialog'
import {
  ApprovalFlowInstance,
  ApprovalState,
} from '@/pageComponents/orders/OrderApprovalFlowView/components/ApprovalFlowInstance'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import { deepMutable } from '../../../components/SchemaForm/DeepMutable'
import useJotaiForm from '../../../components/state/useJotaiForm'
import { useJotaiUrqlQuery, WithUrql } from '../../../components/state/useJotaiUrqlQuery'
import {
  GetCurrentUserDocument,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetOrderDetailDocument,
  GetOrderDetailQuery,
  GetOrderDetailQueryVariables,
  OrderDetailFragment,
} from '../../../generated/graphql'
import buildLogger from '../../../util/logger'

const logger = buildLogger('OrderApprovalFlowsView')

export const useOrderApprovalFlowViewStyles = makeStyles()((theme, _params, _classes) => ({
  accordionContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
  },
  approvalFlowsContainer: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.015) 0 0)', // Make 1.5% darker
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  approvalStatus: {
    color: theme.palette.text.secondary,
    paddingLeft: '2rem',
  },
  disabledIcon: {
    color: theme.palette.action.disabled,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}))

export interface OrderApprovalFlowsViewForm extends WithUrql {
  approvalDialogData?: {
    approvalFlowInstance: ApprovalFlowInstance
    approvalState: ApprovalState
    orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  }
  approvalDialogRadio: 'Approve' | 'Reject'
  approvalDialogNote?: string
  currentUser?: GetCurrentUserQuery['currentUser']
  orderDetail?: OrderDetailFragment
}

export function OrderApprovalFlowsView({ orderId }: { orderId?: string }): JSX.Element {
  const { classes } = useOrderApprovalFlowViewStyles()

  const jotaiForm = useJotaiForm<OrderApprovalFlowsViewForm>(
    React.useMemo(
      () => ({
        defaultValue: {
          approvalDialogRadio: 'Approve',
        },
      }),
      []
    )
  )

  useJotaiUrqlQuery<OrderApprovalFlowsViewForm, GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    useMemo(
      () => ({
        document: GetCurrentUserDocument,
        jotaiForm,
        onData: (data, draft) => (draft.currentUser = data.currentUser),
        queryOpts: { requestPolicy: 'cache-first' },
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<OrderApprovalFlowsViewForm, GetOrderDetailQuery, GetOrderDetailQueryVariables>(
    useMemo(
      () => ({
        document: GetOrderDetailDocument,
        jotaiForm,
        onData: (data, draft) => (draft.orderDetail = deepMutable(data.orderDetail)),
        pause: () => !orderId,
        queryOpts: { requestPolicy: 'cache-first' },
        variables: () => ({ id: orderId || '' }),
      }),
      [jotaiForm, orderId]
    )
  )

  const showApprovalFlows = jotaiForm.useSelect(
    useCallback((form) => !!form.approvalDialogData || !!form.orderDetail?.approvalFlows.length, [])
  )
  return (
    <JotaiFormProvider form={jotaiForm}>
      {showApprovalFlows && (
        <div className={classes.approvalFlowsContainer}>
          <OrderApprovalFlowCollapseList />
          <OrderApprovalDialog />
        </div>
      )}
    </JotaiFormProvider>
  )
}
