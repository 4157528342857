import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import { getEnv } from './isEnv'
const envType = getEnv()
let defaultPrivacyLevel: DefaultPrivacyLevel = 'mask'
if (envType === 'dev2' || envType === 'qa01') {
  defaultPrivacyLevel = 'allow'
}
const devCredentials = {
  applicationId: '1207b742-8528-476d-9476-bb53099de4f5',
  clientToken: 'pubd95cb08f1181e5903fa5a9323d0a5399',
  service: 'billy-ui',
}

const prodCredentials = {
  applicationId: '1ad1c216-ee6c-4b94-bae0-1474a5a933af',
  clientToken: 'pub6d28894a2cdb95b60cb77268303bbd91',
  service: 'billy-ui-prod',
}

const ignoreErrors = [
  'ResizeObserver',
  'Abort fetching',
  'csp_violation',
  'Failed to lookup route',
  'Request failed with status code 404',
  'Request failed with status code 401',
  'Failed to load static props',
  'Loading initial props cancelled',
  'Failed to load script',
  '[GSI_LOGGER]',
  'Route did not complete loading',
  'Error: Invariant: attempted to hard navigate to the same URL',
  'Uncaught "Script error."',
  'TypeError: Failed to fetch',
  'CombinedError: [GraphQL]',
  'Minified React error #418',
  'Minified React error #423',
  'MUI: useResizeContainer',
]

if (envType && envType !== 'local') {
  datadogRum.init({
    ...(envType === 'prod' ? prodCredentials : devCredentials),
    env: envType,
    site: 'datadoghq.com',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel,
    proxy: '/api/datadog/proxy',
    beforeSend: (event) => {
      if (event.type === 'error' && ignoreErrors.some((message) => event.error.message.includes(message))) {
        return false
      }
    },
  })

  datadogLogs.init({
    ...(envType === 'prod' ? prodCredentials : devCredentials),
    env: envType,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
    proxy: 'api/datadog/proxy',
  })
  datadogRum.startSessionReplayRecording()
}
