import produce from 'immer'
import { WritableAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import { useCallback, useRef } from 'react'
import { ValidationError } from 'yup'
import ObjectSchema from 'yup/lib/object'
import buildLogger from '../../util/logger'
import { useHasChangedAfterInit } from '../../util/useHasChanged'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { notifyJotaiRenderCount } from '@/components/state/notifyJotaiRenderCount'
import { datadogLogs } from '@datadog/browser-logs'

type UseJotaiOnSubmitProps<T> = {
  atom: WritableAtom<T, T>
  onSubmit: (value: T) => void
  schema?: ObjectSchema<any, any, any>
  onError?: (error?: Error) => void
}

const logger = buildLogger('UseJotaiOnSubmit')

const useJotaiOnSubmit = <T>({ atom, onSubmit, schema, onError }: UseJotaiOnSubmitProps<T>): (() => void) => {
  const hasOnSubmitChanged = useHasChangedAfterInit(onSubmit)
  const onSubmitChangedCount = useRef(0)
  const errorHandler = useErrorHandler()

  if (hasOnSubmitChanged) {
    onSubmitChangedCount.current++
    notifyJotaiRenderCount(onSubmitChangedCount.current, 'useJotaiOnSubmit')
  } else {
    onSubmitChangedCount.current = 0
  }

  const atomCallback = useAtomCallback(
    useCallback(
      (get, set) => {
        let form = get(atom)
        try {
          if (schema) {
            form = schema.validateSync(form, { abortEarly: false })
          }
          set(
            atom,
            produce(form, (draftForm) => {
              ;(draftForm as any).yupErrors = {}
              ;(draftForm as any).urqlErrors = {}
            })
          )
          onSubmit(form)
        } catch (e) {
          const error = e as Error
          datadogLogs.logger.info(error.message, { error })
          logger.debug({ error, msg: 'Error during form submission' })

          if (error instanceof ValidationError) {
            const yupErrors = error.inner.reduce<Record<string, unknown>>((result, { message, path }) => {
              if (path) {
                result[path] = message
              }
              return result
            }, {})
            set(
              atom,
              produce(form, (draftForm) => {
                ;(draftForm as any).yupErrors = yupErrors
              })
            )
            logger.debug({ yupErrors })
          }
          if (onError) onError(error)
        }
      },
      [atom, onSubmit, schema]
    )
  )

  return useCallback(() => {
    atomCallback().catch(errorHandler)
  }, [errorHandler, atomCallback])
}
export default useJotaiOnSubmit
