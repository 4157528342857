import DateRangeEnd from '@/components/DateRangeEnd/DateRangeEnd'
import DateRangeStart from '@/components/DateRangeStart/DateRangeStart'
import BillyGridCell from '@/components/grid/billyGridCell'
import BillyLink from '@/components/link/billyLink'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { billableCurrencyFormat, getChangeInTotalAmount, getDiscountedPrice } from '@/util/currencyUtil'
import { Grid, Typography, useTheme } from '@mui/material'
import Big from 'big.js'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'

function AmendmentOrderPageDrawer({
  discount,
  orderDetail: {
    currentSubscription,
    subscriptionId,
    currency,
    totalListAmount,
    totalAmount,
    orderMetrics,
    resoldBy,
    recurringDiscountPercent,
    nonRecurringDiscountPercent,
  },
}: {
  discount: Big
  orderDetail: Pick<
    CommonOrderFormPageState['orderDetail'],
    | 'currentSubscription'
    | 'subscriptionId'
    | 'currency'
    | 'totalListAmount'
    | 'totalAmount'
    | 'orderMetrics'
    | 'resoldBy'
    | 'recurringDiscountPercent'
    | 'nonRecurringDiscountPercent'
  >
}): JSX.Element {
  const shouldRecalculateTotals = useShouldRecalculateTotals()
  const discountedPrice = getDiscountedPrice(totalListAmount ?? 0, discount)
  const discountAmount = getChangeInTotalAmount(totalListAmount, discountedPrice).abs()
  const theme = useTheme()

  const renewalOrderPageDrawerData = [
    {
      label: 'Subscription being Amended',
      description: <BillyLink nextProps={{ href: `/subscriptions/${subscriptionId}` }}>{subscriptionId}</BillyLink>,
    },
    {
      label: 'Current Subscription Term',
      description: (
        <>
          {currentSubscription?.startDate && <DateRangeStart datetime={currentSubscription.startDate} />} -{' '}
          {currentSubscription?.endDate && <DateRangeEnd datetime={currentSubscription.endDate} />}
        </>
      ),
    },
    {
      label: 'Total List Amount',
      description: billableCurrencyFormat({
        currency,
        value: totalListAmount ?? 0,
      }),
    },
    {
      label: 'Total Discount Amount (%)',
      description: `${billableCurrencyFormat({ currency, value: Number(discountAmount) })}(${discount
        .times(100)
        .toFixed(2)}%)`,
    },
    {
      label: 'Recurring Discount %',
      description: `${Big(recurringDiscountPercent ?? 0).toFixed(2)}%`,
    },
    {
      label: 'Non Recurring Discount %',
      description: `${Big(nonRecurringDiscountPercent ?? 0).toFixed(2)}%`,
    },
    {
      label: 'Total Order Amount',
      description: shouldRecalculateTotals ? (
        <LineItemCellLoadingIndicator />
      ) : (
        billableCurrencyFormat({
          currency,
          value: totalAmount ?? 0,
        })
      ),
    },
    {
      label: 'Delta ARR',
      description: (
        <Typography
          variant="body1"
          fontWeight={theme.typography.fontWeightMedium}
          style={{
            color: orderMetrics && orderMetrics.deltaArr > 0 ? theme.palette.success.main : theme.palette.error.main,
          }}
        >
          {orderMetrics && orderMetrics.deltaArr > 0 ? '+' : ''}
          {billableCurrencyFormat({ currency, value: orderMetrics?.deltaArr ?? 0 })}
        </Typography>
      ),
      hide: !orderMetrics?.deltaArr,
    },
    {
      label: 'Resold By',
      description: (
        <BillyLink nextProps={{ href: `/accounts/${resoldBy?.id}` }}>
          <Typography>{resoldBy?.name}</Typography>
        </BillyLink>
      ),
      hide: !resoldBy?.name,
    },
  ]

  const getCellOrder = (length: number, index: number, alwaysLastRow?: boolean): number => {
    const getLastRowOrder = (length: number): number => {
      return length - (length % 4) + 2
    }
    return alwaysLastRow ? getLastRowOrder(length) : index + 1
  }

  return (
    <>
      <Typography variant="overline" fontWeight={theme.typography.fontWeightRegular}>
        AMENDMENT
      </Typography>
      <Typography variant="h6" fontWeight={theme.typography.fontWeightMedium}>
        Order Summary
      </Typography>
      <Grid container rowGap={2} pt={2}>
        {renewalOrderPageDrawerData.map((cell, index) => (
          <BillyGridCell
            gridRow={1}
            {...cell}
            key={index}
            order={getCellOrder(renewalOrderPageDrawerData.length, index)}
          />
        ))}
      </Grid>
    </>
  )
}

export default AmendmentOrderPageDrawer
