import { CardContent, DialogContent, Grid, Skeleton, Stack } from '@mui/material'
import React from 'react'
import buildLogger from '../../util/logger'

const logger = buildLogger('PageLoadingPlaceholder')

export const PageLoadingContext = React.createContext({ isLoading: false })

export type PageLoadingPlaceholderProps = {
  children?: React.ReactNode
  isLoading: boolean
  mode?: 'card' | 'page' | 'dialog' | 'dialog-table' | 'page-content'
}

function PageLoadingPlaceholder({
  children,
  isLoading,
  mode = 'page-content',
}: PageLoadingPlaceholderProps): JSX.Element {
  return isLoading ? (
    <>
      {mode === 'card' && <CardContentFallback />}
      {mode === 'dialog' && <DialogFallback />}
      {mode === 'dialog-table' && <DialogTableFallback />}
      {mode === 'page-content' && <PageContentFallback />}
      {mode === 'page' && <WholePageFallback />}
    </>
  ) : (
    <>{children}</>
  )
}

export default PageLoadingPlaceholder

function CardContentFallback() {
  return (
    <CardContent>
      <Grid container spacing={2} aria-label="page-loading-placeholder">
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <Grid item xs={3} key={`${index}`}>
              <Stack gap={1}>
                <Skeleton variant="rectangular" width={'25%'} height={16} />
                <Skeleton variant="rectangular" width={'100%'} height={24} />
              </Stack>
            </Grid>
          ))}
      </Grid>
    </CardContent>
  )
}

function DialogFallback() {
  return (
    <DialogContent>
      <Grid container spacing={2} aria-label="page-loading-placeholder">
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <>
              <Grid item xs={6} key={`${index}`}>
                <Stack gap={1}>
                  <Skeleton variant="rectangular" width={'25%'} height={16} />
                  <Skeleton variant="rectangular" width={'100%'} height={48} />
                </Stack>
              </Grid>
              <Grid item xs={6} key={`${index}`}>
                <Stack gap={1}>
                  <Skeleton variant="rectangular" width={'25%'} height={16} />
                  <Skeleton variant="rectangular" width={'100%'} height={48} />
                </Stack>
              </Grid>
              <Grid item xs={12} key={`${index}`}>
                <Stack gap={1}>
                  <Skeleton variant="rectangular" width={'25%'} height={16} />
                  <Skeleton variant="rectangular" width={'100%'} height={48} />
                </Stack>
              </Grid>
            </>
          ))}
      </Grid>
    </DialogContent>
  )
}

function DialogTableFallback() {
  return (
    <DialogContent>
      <Stack rowGap={1}>
        <Skeleton variant="rectangular" width={'100%'} height={48} />
        <Skeleton variant="rectangular" width={'100%'} height={400} />
      </Stack>
    </DialogContent>
  )
}

function WholePageFallback() {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw' }}>
      <div
        style={{
          width: '255px',
          height: '100vh',
        }}
      >
        <Stack gap={0.25} padding={1}>
          {Array(12)
            .fill(null)
            .map((_, i) => (
              <Skeleton variant="rectangular" width={'100%'} height={52} key={i} />
            ))}
        </Stack>
      </div>
      <div
        style={{
          width: 'calc(100vw - 255px)',
          height: '100vh',
        }}
      >
        <Stack gap={2}>
          <Skeleton variant="rectangular" width={'100%'} height={64} sx={{ ml: 0.5 }} />
          <PageContentFallback />
        </Stack>
      </div>
    </div>
  )
}

function PageContentFallback(): JSX.Element {
  return (
    <Stack gap={2} aria-label="page-content-placeholder" role="presentation" paddingX={4} width="100%">
      {Array(2)
        .fill(null)
        .map(() => (
          <>
            <Skeleton variant="rectangular" width={'25%'} height={48} />
            <Skeleton variant="rectangular" width={'100%'} height={300} />
          </>
        ))}
    </Stack>
  )
}
