import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import { EmailContactCell, EmailContactsCell } from '@/components/table/cells/EmailContactCell'
import { ElectronicSignatureFragment, ElectronicSignatureStatus } from '@/generated/graphql'
import {
  EsignEmailAuditTrailTable,
  EsignEmailAuditTrailTableProps,
} from '@/pageComponents/esign/EsignEmailAuditTrailTable'
import { notEmpty } from '@/util/array'
import buildLogger from '@/util/logger'
import { DialogContent, Grid } from '@mui/material'

const logger = buildLogger('ViewEsignDetailsDrawerContent')

export type EsignDetailsDrawerContentProps = {
  eSignData: ElectronicSignatureFragment
} & EsignEmailAuditTrailTableProps

export function EsignDetailsDrawerContent({
  eSignData,
  electronicSignatureAuditLogs,
}: EsignDetailsDrawerContentProps): JSX.Element {
  const esignDetailsGridCells: GridCellProps[] = [
    {
      label: 'Customer Signatory',
      description: <EmailContactCell contact={eSignData.accountSignatory} />,
      xs: 12,
      hide: !eSignData.accountSignatory,
    },
    {
      label: 'Host Signatory',
      description: <EmailContactCell contact={eSignData.tenantSignatory} />,
      xs: 12,
      hide: !eSignData.tenantSignatory,
    },
    {
      label: 'Additional Recipients',
      description: <EmailContactsCell contacts={eSignData.additionalRecipients?.filter(notEmpty) ?? []} />,
      xs: 12,
      hide: !eSignData.additionalRecipients?.length,
    },
    {
      label: 'Link',
      description: eSignData.link ?? '',
      xs: 12,
      hide: !eSignData.link || eSignData.status === ElectronicSignatureStatus.Voided,
      variant: 'copy-description',
    },
    {
      label: 'Audit Trail',
      description: <EsignEmailAuditTrailTable electronicSignatureAuditLogs={electronicSignatureAuditLogs} />,
      xs: 12,
      hide: !eSignData.id,
    },
  ]
  return (
    <DialogContent dividers>
      <Grid container rowGap={2}>
        {esignDetailsGridCells.map((cell, index) => (
          <BillyGridCell key={index} {...cell} />
        ))}
      </Grid>
    </DialogContent>
  )
}
