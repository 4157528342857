import { useMemo } from 'react'
import { SearchBody } from 'elastic-ts'
import { SearchResponse } from 'elasticsearch'
import { UseQueryState } from 'urql'
import {
  SearchInTenantQuery,
  SearchInTenantQueryVariables,
  SearchQuery,
  SearchQueryVariables,
  useSearchInTenantQuery,
  useSearchQuery,
} from '../generated/graphql'
import buildLogger from './logger'
import useHasChanged from './useHasChanged'

const logger = buildLogger('useSearch')

export type UseSearchResponse<T extends Record<string, unknown>> = [
  T[],
  UseQueryState<SearchQuery, object | void> | UseQueryState<SearchInTenantQuery, object | void>,
  SearchResponse<T>?
]

export function useSearch<T extends Record<string, unknown>>(
  searchJson: SearchBody,
  pause?: boolean,
  handleLoading?: boolean,
  tenantId?: string
): UseSearchResponse<T> {
  const jsonInBase64 = Buffer.from(JSON.stringify(searchJson)).toString('base64')
  const hasSearchChanged = useHasChanged(jsonInBase64)

  const searchVariables: SearchQueryVariables = {
    jsonInBase64,
  }
  const searchInTenantVariables: SearchInTenantQueryVariables = {
    tenantId: tenantId || '',
    jsonInBase64,
  }
  const pauseSearchResult = pause || !!tenantId
  const pauseSearchInTenantResult = pause || !tenantId
  const [searchResult] = useSearchQuery({ variables: searchVariables, pause: pauseSearchResult, handleLoading })
  const [searchInTenantResult] = useSearchInTenantQuery({
    variables: searchInTenantVariables,
    pause: pauseSearchInTenantResult,
    handleLoading,
  })

  const response = useMemo(() => {
    let searchResponse: SearchResponse<T> | undefined
    let res: UseSearchResponse<T> = [[], searchResult, searchResponse]
    if (searchResult?.data?.search) {
      searchResponse = JSON.parse(Buffer.from(searchResult?.data?.search, 'base64').toString())
      if (searchResponse) {
        res = [searchResponse.hits.hits.map((hit) => hit._source), searchResult, searchResponse]
      }
    }
    if (searchInTenantResult?.data?.searchInTenant) {
      searchResponse = JSON.parse(Buffer.from(searchInTenantResult?.data?.searchInTenant, 'base64').toString())
      if (searchResponse) {
        res = [searchResponse.hits.hits.map((hit) => hit._source), searchInTenantResult, searchResponse]
      }
    }
    return res
  }, [searchResult, searchInTenantResult])

  if (hasSearchChanged && !pause) {
    logger.trace({ msg: 'Searching with json', searchJson })
  }

  return response
}

export const ES_WAIT_TIME_IN_MS = 2000
