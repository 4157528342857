import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { FullRequestParams } from '../../generated/rest'
import { timeout } from '../../util/async'
import { download } from '../../util/file'
import buildLogger from '../../util/logger'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { billyRestClient, isApiError } from '../data/billyRestClient'
import { JotaiForm } from './useJotaiForm'

const logger = buildLogger('useJotaiRest')
export interface WithRestApi {
  restIsSuccess: Record<string, boolean>
  restIsFetching: Record<string, boolean>
  restError: Record<string, string | undefined>
}

export function initJotaiRestState(keyList: string[]): WithRestApi {
  return {
    restIsSuccess: keyList.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
    restIsFetching: keyList.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
    restError: keyList.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
  }
}

export async function pdfDownloadWithRetry<T>(
  jotaiForm: JotaiForm<T & WithRestApi>,
  pdfPath: string,
  key: string
): Promise<void> {
  const maxRetry = 5
  const config = {
    method: 'GET',
    path: pdfPath,
    headers: {
      'Content-Type': 'application/pdf',
    },
  }
  try {
    let retries = 0
    jotaiForm.set((draft) => {
      draft.restIsFetching[key] = true
    })
    while (retries < maxRetry) {
      const response = await billyRestClient.request(config)
      if (response.status === 200) {
        logger.info({ response })
        download(pdfPath)
        jotaiForm.set((draft) => {
          draft.restIsFetching[key] = false
          draft.restIsSuccess[key] = true
        })
        return
      } else if (response.status === 202) {
        retries++
        await timeout(1000)
      } else {
        throw new Error(`Failed to download Pdf: ${response.status}`)
      }
    }
    jotaiForm.set((draft) => {
      draft.restIsFetching[key] = false
      draft.restError[key] = 'Please try again later'
      logger.warn({ msg: 'PDF download failed multiple retries' })
    })
  } catch (error) {
    jotaiForm.set((draft) => {
      draft.restIsFetching[key] = false
      draft.restError[key] = 'Error downloading PDF'
    })
    logger.warn({ msg: 'Error downloading PDF', error })
  }
}

export async function requestWithJotaiRestState<T>(
  jotaiForm: JotaiForm<T & WithRestApi>,
  config: FullRequestParams,
  key: string
): Promise<AxiosResponse | void> {
  try {
    jotaiForm.set((draft) => {
      draft.restIsFetching[key] = true
    })
    const response = await billyRestClient.request(config)
    if (response.status < 400) {
      jotaiForm.set((draft) => {
        draft.restIsFetching[key] = false
        draft.restIsSuccess[key] = true
      })
    }
    return response
  } catch (error: unknown) {
    if (isApiError(error)) {
      // Handling with jotai
      const data = error.response?.data
      const msg = data?.message
      jotaiForm.set((draft) => {
        draft.restIsFetching[key] = false
        draft.restError[key] = msg
      })
    } else {
      // Unhandled error
      throw new Error(`Error at ${config.method} on ${config.path})`)
    }
  }
}

export const useJotaiRestDownload = <T extends WithRestApi>(
  jotaiForm: JotaiForm<T>,
  path: string,
  key: string = path
) => {
  const errorHandler = useErrorHandler()
  const handleDownload = useCallback(() => {
    const config: FullRequestParams = {
      method: 'GET',
      path,
    }

    requestWithJotaiRestState(jotaiForm, config, key)
      .then(() => download(path))
      .catch(errorHandler)
  }, [jotaiForm, path, key, errorHandler])

  return [handleDownload]
}
