import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { Feature } from '@/generated/graphql'
import { Stack, Typography } from '@mui/material'
import BillyLink from '../../../../components/link/billyLink'

export type DiscountEditCellProps = {
  unitDiscount: string
} & ClickableDiscountDisplayProps

type ClickableDiscountDisplayProps = {
  discount: string
  onClickDiscount?: ((orderLineIndex: number) => void) | undefined
  lineItemIndex?: number
  clickable?: boolean
  discountPercentage?: string
}
export function DiscountEditCell({
  clickable,
  onClickDiscount,
  lineItemIndex,
  discount,
  unitDiscount,
  discountPercentage,
}: DiscountEditCellProps) {
  const showUnitDiscount = useDynamicFeatureFlag(Feature.OrderAnnualizedAmount)

  return (
    <Stack textAlign={'right'}>
      {showUnitDiscount && <ClickableDiscountDisplay discount={discount} discountPercentage={discountPercentage} />}
      <ClickableDiscountDisplay
        discount={showUnitDiscount ? `(${unitDiscount})` : discount}
        discountPercentage={showUnitDiscount ? undefined : discountPercentage}
        onClickDiscount={onClickDiscount}
        lineItemIndex={lineItemIndex}
        clickable={clickable}
      />
    </Stack>
  )
}

export function ClickableDiscountDisplay({
  onClickDiscount,
  lineItemIndex,
  discount,
  discountPercentage,
  clickable,
}: ClickableDiscountDisplayProps) {
  const display = discountPercentage ? `${discount}(${discountPercentage})` : discount
  const onClick = () => {
    if (lineItemIndex === undefined) {
      return
    }
    onClickDiscount?.(lineItemIndex)
  }

  return clickable ? (
    <BillyLink
      linkProps={{
        onClick,
        style: { marginLeft: 20 },
        'aria-label': 'edit-discount-button',
      }}
    >
      {display}
    </BillyLink>
  ) : (
    <Typography variant="caption">{display}</Typography>
  )
}
