import NewOrderPageForm from '@/pageComponents/orders/EditOrderPage/NewOrderPageForm'
import { PageContainer } from '../../../components/PageContainer/PageContainer'
import { NewOrderPageNavLayout } from './NewOrderPageNavLayout'

export function NewOrderPageContent(): JSX.Element {
  return (
    <PageContainer
      slot={{
        Layout: NewOrderPageNavLayout,
      }}
    >
      <NewOrderPageForm />
    </PageContainer>
  )
}
