import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'

type ConfirmVoidEsignEmailDialogProps = {
  onVoid?: () => void
}

type DialogProps = WithModalParams & ConfirmVoidEsignEmailDialogProps
const formID = 'ConfirmVoidEsignEmailDialog'
function ConfirmVoidEsignEmailDialog({ open, onClose, onVoid }: DialogProps): JSX.Element {
  const handleVoid = useCallback(() => {
    onVoid?.()
    onClose?.()
  }, [onVoid, onClose])
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Void Sent Order Form?</DialogTitle>
      <DialogContent dividers>
        <Typography>The document will not be available to the customer anymore.</Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Void'}
          buttonData={{
            label: 'Void',
            onClick: handleVoid,
            color: 'error',
            buttonProps: { variant: 'contained' },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useConfirmVoidEsignEmailDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ConfirmVoidEsignEmailDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
