import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../button/actionButton'
import { useBillyRouter } from '../route/useBillyRouter'
import { WithModalParams, useModal, useModalsContext } from './context/modalsContext'

type DialogProps = WithModalParams & {
  onCancel?: () => void
}
const formID = 'NewUpdateDeployedDialog'
function NewUpdateDeployedDialog({ open, onClose, onCancel }: DialogProps): JSX.Element {
  const router = useBillyRouter()
  function handleSubmit() {
    router.reload()
  }

  function handleCancel() {
    onCancel?.()
    onClose?.()
  }

  return (
    <Dialog open={!!open} onClose={handleCancel} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>New Update Deployed</DialogTitle>
      <DialogContent dividers>
        <Typography>
          New updates have been successfully deployed. To access these updates, please refresh your page.
        </Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: handleCancel,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Refresh'}
          buttonData={{
            label: 'Refresh',
            buttonProps: {
              onClick: handleSubmit,
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useNewUpdateDeployedDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: NewUpdateDeployedDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
