import React, { useMemo } from 'react'
import { RadioGroup, RadioGroupProps } from '@mui/material'
import { Draft } from 'immer'
import { useAtom } from 'jotai'
import { selectAtom, useUpdateAtom } from 'jotai/utils'
import buildLogger from '../../util/logger'
import { JotaiForm } from '../state/useJotaiForm'

const logger = buildLogger('JotaiMuiRadioGroup')

export type JotaiMuiRadioGroupProps<T> = {
  atomSelector: (form: T) => string | undefined | null
  atomUpdater: (value: string, draft: Draft<T>) => void
  children: React.ReactNode
  form: JotaiForm<T>
  radioGroupProps?: RadioGroupProps
}

function JotaiMuiRadioGroup<T>({
  atomSelector,
  atomUpdater,
  children,
  form,
  radioGroupProps,
}: JotaiMuiRadioGroupProps<T>): JSX.Element {
  const { atom } = form
  const setAtom = useUpdateAtom(atom)
  const selectorAtom = useMemo(() => selectAtom(atom, atomSelector), [atom, atomSelector])
  const [atomValue] = useAtom(selectorAtom)

  return (
    <RadioGroup
      onChange={(e) => {
        const value = e.target.value as string
        setAtom((draft) => {
          atomUpdater(value, draft)
        })
      }}
      value={atomValue || ''}
      {...radioGroupProps}
    >
      {children}
    </RadioGroup>
  )
}

export default JotaiMuiRadioGroup
