import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Client, useClient } from 'urql'
import { GetUserAuthInfoDocument, GetUserAuthInfoQuery } from '../../generated/graphql'
import env from '../../util/env'
import buildLogger from '../../util/logger'
import { TError, useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { useBillyRouter } from '../route/useBillyRouter'
import { isCurrentEnvironment } from '../../util/isEnv'

const logger = buildLogger('useLogout')

const cognitoRegion = env.NEXT_PUBLIC_COGNITO_REGION

const getSignOutRedirect = async (urqlClient: Client): Promise<string> => {
  try {
    const idToken = Cookies.get('id_token') ?? ''

    if (idToken) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jwt = jwtDecode<any>(idToken)
      const response = await urqlClient
        .query<GetUserAuthInfoQuery>(GetUserAuthInfoDocument, { email: jwt.email })
        .toPromise()
      const userAuthInfo = response?.data?.userAuthInfo
      Cookies.remove('entity_id')
      if (userAuthInfo) {
        if (isCurrentEnvironment('local')) {
          Cookies.remove('id_token')
          Cookies.remove('access_token')
          return '/login?logout=true'
        } else {
          const origin = window.location.origin
          const loginUrl = new URL(`https://${userAuthInfo.domainName}.auth.${cognitoRegion}.amazoncognito.com/logout`)
          loginUrl.searchParams.append('client_id', userAuthInfo.clientId)
          loginUrl.searchParams.append('logout_uri', `${origin}/api/auth/signout`)
          return loginUrl.toString()
        }
      }
    }
  } catch (e) {
    // this can happen if we try to decode the JWT token, and it's expired
    console.warn(e)
  }

  return `${origin}/api/auth/signout`
}

export function useLogout(): [() => void] {
  const urqlClient = useClient()
  const errorHandler = useErrorHandler()
  const billyRouter = useBillyRouter()
  async function asyncLogout() {
    localStorage.clear()
    const redirectURL = await getSignOutRedirect(urqlClient)
    billyRouter.push(redirectURL)
  }
  return [
    () =>
      asyncLogout().catch((err: TError) => {
        logger.warn({ err })
        billyRouter.replace('/login')
        errorHandler(err)
      }),
  ]
}
