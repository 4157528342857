import React from 'react'
import { DEFAULT_DATE_FORMAT, formatUtcString } from '../../../util/datetime/luxon/dateUtil'

const EndDateCell = ({ value }: { value: string }): JSX.Element => {
  if (value === null || value === undefined) {
    return <div />
  }
  return <div>{formatUtcString(value, DEFAULT_DATE_FORMAT, true)}</div>
}

export default React.memo(EndDateCell)
