import Cookies from 'js-cookie'

export const ALL_ENTITIES = '*'

export function isSubSetOf(
  childEntityIds: ReadonlyArray<string | null> | null = [],
  parentEntityIds: ReadonlyArray<string | null> | null = []
): boolean {
  if (!childEntityIds || !childEntityIds.length) return true
  if (!parentEntityIds || !parentEntityIds.length) return false
  return parentEntityIds.includes(ALL_ENTITIES) || childEntityIds.every((id) => parentEntityIds.includes(id))
}

/**
 * @deprecated Use `useGetEntityContext` instead
 */
export const getDefaultEntityIds = () => {
  const globalSelectedEntityId = Cookies.get('entity_id')
  if (globalSelectedEntityId && globalSelectedEntityId !== ALL_ENTITIES) return [globalSelectedEntityId]
  return []
}
