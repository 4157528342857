import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getExpireOn } from '../../../util/cookieUtil'
import { unixTimeSecondsNow } from '../../../util/datetime/luxon/dateUtil'
import { isExpired } from '../../../util/session'

export type SessionContextType = Readonly<{
  expireOn: number
}>

/**
 * How often to check for session interval.
 */
const HANDLE_SESSION_EXPIRATION_INTERVAL_MILLIS = 1000

const SessionContext = createContext<SessionContextType>({ expireOn: 0 })

/**
 * Export the session context information.
 */
export function useSessionContext() {
  return useContext(SessionContext)
}

interface SessionContextProps {
  readonly children: JSX.Element
  readonly onSessionExpiration: () => void
}
export const SessionProvider = (props: SessionContextProps) => {
  const { children, onSessionExpiration } = props

  const initialExpireOn = useMemo(() => {
    const expireOn = getExpireOn()
    if (expireOn) {
      return parseInt(expireOn)
    }

    return undefined
  }, [])

  const [sessionContext, setSessionContext] = useState<SessionContextType | undefined>(
    initialExpireOn ? { expireOn: initialExpireOn } : undefined
  )
  const [expireOn, setExpireOn] = useState<number | undefined>(undefined)

  const handleSessionExpiration = useCallback(() => {
    const expireOn = getExpireOn()
    const currentUnix = unixTimeSecondsNow()

    if (expireOn) {
      const expireOnAsNumber = parseInt(expireOn)
      const expired = isExpired(currentUnix, expireOnAsNumber)

      if (sessionContext?.expireOn !== expireOnAsNumber) {
        setExpireOn(expireOnAsNumber)
      }

      if (expired) {
        onSessionExpiration()
      }
    }
  }, [onSessionExpiration, sessionContext?.expireOn])

  useEffect(() => {
    // register the main interval timer, and unregister it when the component
    // unmounts.

    const intervalId = window.setInterval(() => {
      handleSessionExpiration()
    }, HANDLE_SESSION_EXPIRATION_INTERVAL_MILLIS)

    return () => {
      window.clearInterval(intervalId)
    }
  }, [handleSessionExpiration])

  useEffect(() => {
    // this avoids a weird react bug where setting the state of sessionContext
    // in the interval timer above causes React to complain about concurrent
    // re-render attempts.  We useEffect here to trigger then re-render from the
    // standard React lifecycle.

    if (expireOn !== undefined) {
      setSessionContext({ expireOn })
    }
  }, [expireOn])

  if (sessionContext === undefined) {
    // the user is not actually authenticated yet
    return children
  }

  return (
    // the 'key' here HAS to be provided so that all the children under this
    // element are remounted so any context caches that could contain
    // user-specific data are expired.
    <SessionContext.Provider key={sessionContext.expireOn} value={sessionContext}>
      {children}
    </SessionContext.Provider>
  )
}
