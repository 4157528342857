import { FC, ReactNode, createContext, memo, useContext, useEffect, useRef, useState, useMemo } from 'react'
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog'

export type ConfirmOpts = Pick<ConfirmDialogProps, 'title' | 'message' | 'color' | 'confirmText'>

export interface DialogHandler {
  readonly confirm: (opts: ConfirmOpts) => Promise<boolean>
}

interface DialogHandlerDelegateProps {
  readonly onDialogHandler: (dialogHandler: DialogHandler) => void
}

interface ConfirmDialogType {
  readonly type: 'confirm'
  readonly props: ConfirmDialogProps
}

const DialogHandlerDelegate = memo(function DialogHandlerDelegate(props: DialogHandlerDelegateProps) {
  const { onDialogHandler } = props
  const [dialogType, setDialogType] = useState<ConfirmDialogType | undefined>(undefined)

  useEffect(() => {
    onDialogHandler({
      confirm: (opts: ConfirmOpts) => {
        return new Promise<boolean>((resolve) => {
          const onCancel = () => {
            setDialogType(undefined)
            resolve(false)
          }

          const onConfirm = () => {
            setDialogType(undefined)
            resolve(true)
          }
          setDialogType({
            type: 'confirm',
            props: {
              ...opts,
              onConfirm,
              onCancel,
            },
          })
        })
      },
    })
  }, [onDialogHandler])

  if (dialogType?.type === 'confirm') {
    return <ConfirmDialog {...dialogType.props} />
  }

  return null
})

const DEFAULT_FALLBACK_DIALOG_HANDLER: DialogHandler = {
  // eslint-disable-next-line @typescript-eslint/require-await
  confirm: async (opts: ConfirmOpts) => {
    console.log('Dialog handler called with confirm: ', opts)
    return true
  },
}

const DialogHandlerContext = createContext<DialogHandler>(DEFAULT_FALLBACK_DIALOG_HANDLER)

export function useDialogHandler() {
  return useContext(DialogHandlerContext)
}

interface DialogHandlerProps {
  readonly children: ReactNode | FC<React.PropsWithChildren<unknown>> | JSX.Element
}

/**
 *
 */
export default memo(function DialogHandler(props: DialogHandlerProps) {
  const delegateRef = useRef<DialogHandler>(DEFAULT_FALLBACK_DIALOG_HANDLER)

  const dialogHandler = useMemo(() => {
    return {
      confirm: (opts: ConfirmOpts) => {
        return delegateRef.current.confirm(opts)
      },
    }
  }, [])

  return (
    <DialogHandlerContext.Provider value={dialogHandler}>
      <>
        <DialogHandlerDelegate onDialogHandler={(dialogHandler) => (delegateRef.current = dialogHandler)} />
        {props.children}
      </>
    </DialogHandlerContext.Provider>
  )
})
