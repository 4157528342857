import { Chip } from '@mui/material'
import { Box } from '@mui/system'
import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { ALL_ENTITIES } from '@/util/entity'

type EntityCellProps = {
  value: Array<string> | string
}

export function SingleEntityCell({ value }: { value: string }): JSX.Element {
  const userTenantSession = useUserTenantSession()
  const tenantEntities = userTenantSession.entities

  const entityName =
    value === ALL_ENTITIES ? 'All Entities' : tenantEntities?.find((entity) => entity.id === value)?.displayId

  return entityName ? <Chip label={entityName} variant="outlined" /> : <>{value}</>
}

function EntityCell({ value }: EntityCellProps): JSX.Element {
  if (!Array.isArray(value)) {
    return <SingleEntityCell value={value} />
  }

  return (
    <Box display="flex" gap={0.5}>
      {value.map((item) => (
        <SingleEntityCell key={item} value={item} />
      ))}
    </Box>
  )
}

export default EntityCell
