import { notifyJotaiRenderCount } from '@/components/state/notifyJotaiRenderCount'
import isEqual from 'fast-deep-equal/es6/react'
import produce, { Draft } from 'immer'
import { Atom, WritableAtom, atom } from 'jotai'
import { SetAtom } from 'jotai/core/atom'
import { atomWithImmer, withImmer } from 'jotai/immer'
import { selectAtom, useAtomCallback, useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import buildLogger from '../../util/logger'
import { useHasChangedAfterInit } from '../../util/useHasChanged'

export const logger = buildLogger('useJotaiForm')

export interface WithYup {
  yupErrors?: Record<string, string>
}

export type UseJotaiFormProps<T> = {
  defaultValue: T
  onSet?: (oldValue: T, newValue: T, draft: Draft<T>) => void
}

export type JotaiForm<T> = {
  atom: WritableAtom<T, T | ((draft: Draft<T>) => void)>
  inner: {
    baseAtom: WritableAtom<T, T | ((draft: Draft<T>) => void)>
    defaultValue: T
    isDirtyAtom: Atom<boolean>
  }
  reset: (callback: (draft: Draft<T>) => void) => void
  set: (...args: Parameters<SetAtom<(draft: Draft<T>) => void>>) => void
  setAll: (...args: Parameters<SetAtom<T>>) => void
  useIsDirty: () => boolean
  useSelect: <V>(callback: (atom: T) => V) => V
}

const useJotaiForm = <T>(props: UseJotaiFormProps<T>): JotaiForm<T> => {
  const propsHaveChanged = useHasChangedAfterInit(props)
  const propsChangedCount = useRef(0)

  if (propsHaveChanged) {
    propsChangedCount.current++
    notifyJotaiRenderCount(propsChangedCount.current, 'useJotaiForm')
  } else {
    propsChangedCount.current = 0
  }

  const { defaultValue, onSet } = props
  const [mainAtom, baseAtom, isDirtyAtom, useIsDirty, useSelect] = useMemo(() => {
    const mainAtomRaw = atom<T>(defaultValue)
    const baseAtom = atomWithImmer<T>(defaultValue)
    const mainAtom = withImmer(
      atom<T, T>(
        (get) => {
          return get(mainAtomRaw)
        },
        (get, set, update) => {
          if (onSet) {
            const proxyUpdate = produce(update, (updateDraft) => {
              onSet(get(mainAtomRaw), update, updateDraft as Draft<T>)
            })
            set(mainAtomRaw, proxyUpdate)
          } else {
            set(mainAtomRaw, update)
          }
        }
      )
    )

    const isDirtyAtom = atom((get) => {
      return !isEqual(get(mainAtom), get(baseAtom))
    })

    const useSelect = <V>(callback: (atom: T) => V): V => {
      const callbackHasChanged = useHasChangedAfterInit(callback)
      const callbackChangedCount = useRef(0)

      if (callbackHasChanged) {
        callbackChangedCount.current++
        notifyJotaiRenderCount(callbackChangedCount.current, 'useSelect')
      } else {
        callbackChangedCount.current = 0
      }
      return useAtomValue(selectAtom(mainAtom, callback, isEqual))
    }

    const useIsDirty = (): boolean => useAtomValue(isDirtyAtom)

    return [mainAtom, baseAtom, isDirtyAtom, useIsDirty, useSelect]
  }, [defaultValue, onSet])

  const reset = useAtomCallback<void, (draft: Draft<T>) => void>(
    useCallback(
      (get, set, callback) => {
        set(mainAtom, (draft) => {
          ;(draft as any).isReset = true
          callback(draft)
        })
        set(mainAtom, (draft) => {
          ;(draft as any).isReset = false
        })
        set(baseAtom, get(mainAtom))
      },
      [mainAtom, baseAtom]
    )
  )

  const setAtom = useUpdateAtom(mainAtom)
  const set = useCallback(
    (setFunc: (draft: Draft<T>) => void) => {
      setAtom((draft) => {
        setFunc(draft)
      })
    },
    [setAtom]
  )
  const setBaseAtom = useUpdateAtom(baseAtom)

  const setAll = useUpdateAtom(mainAtom) as (...args: Parameters<SetAtom<T>>) => void

  useEffect(() => {
    setAtom(defaultValue)
    setBaseAtom(defaultValue)
  }, [defaultValue, setAtom, setBaseAtom])

  return useMemo(() => {
    return {
      atom: mainAtom,
      inner: {
        baseAtom,
        defaultValue,
        isDirtyAtom,
      },
      reset,
      set,
      setAll,
      useIsDirty,
      useSelect,
    }
  }, [mainAtom, baseAtom, defaultValue, isDirtyAtom, reset, set, setAll, useIsDirty, useSelect])
}

export default useJotaiForm
