import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { useTenantTimeZone } from '../../../../../components/UserTenantSessionProvider/useTenantTimeZone'
import JotaiMuiDateField from '../../../../../components/input/JotaiMuiDateField'
import { useJotaiFormContext } from '../../../../../components/state/jotaiFormProvider'
import { Cycle, Feature, OrderStartDateType } from '../../../../../generated/graphql'
import { billingCycleToLuxonDuration, unixTimeSecondsToLuxonWithTz } from '../../../../../util/datetime/luxon/dateUtil'
import buildLogger from '../../../../../util/logger'

const logger = buildLogger('NewOrderPaymentTermSelect')

export const NewOrderBillingCycleStartDatePicker = ({ parentLocator }: WithCustomizationLocator) => {
  const customizationProps = useCustomizationProps({
    displayName: 'billingCycleStartDate',
    parentLocator,
  })
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const startDate = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => form.orderDetail?.startDate, [])
  )
  const billingCycle = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => form.orderDetail.billingCycle, [])
  )
  const startDateType = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => form.orderDetail.startDateType, [])
  )
  const disabledExplanation = !startDate
    ? 'Requires order start date to config billing cycle start date'
    : billingCycle.cycle === Cycle.PaidInFull
    ? 'Billing cycle start date is not applicable for orders paid upfront'
    : startDateType === OrderStartDateType.ExecutionDate
    ? 'Editing "Billing Cycle Start Date" is not supported when "Order Start Date Type" is set to "Order Execution Date." It will be set to order execution date when this order is executed.'
    : undefined

  const tenantTZ = useTenantTimeZone()

  return (
    <Grid item xs={4} {...customizationProps}>
      <JotaiMuiDateField
        atomSelector={(form) => form.orderDetail.billingAnchorDate}
        atomUpdater={(value, draft) => {
          draft.orderDetail.billingAnchorDate = value ?? undefined
        }}
        datePickerProps={{
          label: 'Billing Cycle Start Date (Optional)',
          minDate: unixTimeSecondsToLuxonWithTz(startDate, tenantTZ).toISO(),
          maxDate: unixTimeSecondsToLuxonWithTz(startDate, tenantTZ)
            .plus(
              useDynamicFeatureFlag(Feature.FlexibleBillingAnchorDate)
                ? { years: 1 }
                : billingCycleToLuxonDuration(billingCycle)
            )
            .toISO(),
        }}
        disabledExplanation={disabledExplanation}
        errorPath="orderDetail.billingAnchorDate"
        form={jotaiForm}
      />
    </Grid>
  )
}
