import React, { ForwardedRef } from 'react'
import { Card } from '@mui/material'
import { CardProps } from '@mui/material/Card/Card'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../util/logger'

const logger = buildLogger('BillyCard')

const useStyles = makeStyles()((theme, _params, _classes) => ({
  card: {
    flex: 1,
    '& .MuiCardHeader-root': {
      borderBottom: `1px solid ${theme.customPalette.borderColor}`,
    },
  },
}))

export type BillyCardProps = CardProps

const BillyCard = React.forwardRef(function BillyCardWithForwarding(
  { className, children, ...props }: BillyCardProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
  const { classes, cx } = useStyles()

  return (
    <Card ref={ref} className={cx(classes.card, className)} role="presentation" elevation={1} {...props}>
      {children}
    </Card>
  )
})

export default BillyCard
