import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react'
import AsyncLoadingIndicator from './AsyncLoadingIndicator'
import buildLogger from '@/util/logger'
const logger = buildLogger('AsyncLoadingIndicatorProvider')

export interface AsyncLoadingHandler {
  showLoading: () => void
  hideLoading: () => void
  setLoading: (loading: boolean) => void
  loading: boolean
}

const DEFAULT_NOTIFIER_CONTEXT = {
  showLoading: () => {
    return null
  },
  hideLoading: () => {
    return null
  },
  setLoading: () => {
    return null
  },
  loading: false,
}

const AsyncLoadingNotifierContext = createContext<AsyncLoadingHandler>(DEFAULT_NOTIFIER_CONTEXT)

export function useAsyncLoadingNotifier() {
  return useContext(AsyncLoadingNotifierContext)
}

interface AsyncLoadingIndicatorProviderProps {
  readonly children: ReactNode | FC<React.PropsWithChildren<unknown>> | JSX.Element
}

export function AsyncLoadingIndicatorProvider(props: AsyncLoadingIndicatorProviderProps) {
  const [loading, setLoading] = useState(false)
  const showLoading = useCallback(() => {
    setLoading(true)
  }, [])

  const hideLoading = useCallback(() => {
    setLoading(false)
  }, [])

  const value = { showLoading, hideLoading, setLoading, loading }

  return (
    <AsyncLoadingNotifierContext.Provider value={value}>
      <>
        {loading ? <AsyncLoadingIndicator /> : <></>}
        {props.children}
      </>
    </AsyncLoadingNotifierContext.Provider>
  )
}
