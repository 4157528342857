import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '../../EditOrderPage/CommonOrderFormPageState'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { OrderItemRowProps } from '../orderChargeRow'

export function LineItemEditSellUnitPriceCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  orderType,
  currency,
}: OrderItemRowProps) {
  const jotaiForm = useJotaiFormContext<F>()
  const shouldRecalculateTotals = useShouldRecalculateTotals()
  const { sellUnitPrice } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          sellUnitPrice: reduced.sellUnitPrice,
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )
  return <>{shouldRecalculateTotals ? <LineItemCellLoadingIndicator /> : sellUnitPrice}</>
}
