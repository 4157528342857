import { REST_BASE_URL } from '../components/data/billyRestClient'
import buildLogger from './logger'

const logger = buildLogger('fileUtil')
export const convertFileSize = (totalBytes: number): string => {
  if (totalBytes < 1000000) {
    return Math.floor(totalBytes / 1000) + 'KB'
  } else {
    return Math.floor(totalBytes / 1000000) + 'MB'
  }
}
export function download(path: string): void {
  const element = document.createElement('a')
  element.setAttribute('href', REST_BASE_URL + path)
  element.setAttribute('download', '')
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export function downloadEncodedUri({ encodedUri, fileName }: { encodedUri: string; fileName: string }): void {
  const element = document.createElement('a')
  element.setAttribute('href', encodedUri)
  element.setAttribute('download', fileName)
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export async function getImageSize(file: File): Promise<{ naturalWidth: number; naturalHeight: number }> {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onabort = function () {
      reject('Image reading was aborted.')
    }
    reader.onerror = function (event) {
      logger.warn({ msg: 'Failed to load image:', error: event.target?.error })
      reject('Failed to load image')
    }
    reader.onloadend = function () {
      const img = new Image()
      img.src = reader.result as string

      img.onload = function (): void {
        const size = {
          naturalWidth: Infinity,
          naturalHeight: Infinity,
        }
        size.naturalWidth = img.naturalWidth ?? Infinity
        size.naturalHeight = img.naturalHeight ?? Infinity
        logger.debug({ img })

        resolve(size)
      }
    }
    reader.readAsDataURL(file)
  })
}
