import BillyAlert from '@/components/alerts/alert'
import BillyResponsiveGridContainer from '@/components/grid/billyResponsiveGridContainer'
import { useLoggerEffect } from '@/components/hooks/useLoggerEffect'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { ReadMore } from '@/components/typography/ReadMore'
import {
  ApprovalFlowInstanceStatus,
  ApprovalFlowInstanceWorkflowStatus,
  OrderDetailFragment,
} from '@/generated/graphql'
import { useApprovalFlowSubmitterNoteDialog } from '@/pageComponents/orders/ApprovalSubmitterNoteDialog'
import {
  OrderApprovalFlowsViewForm,
  useOrderApprovalFlowViewStyles,
} from '@/pageComponents/orders/OrderApprovalFlowView/OrderApprovalFlowsView'
import { ApprovalFlowInstanceView } from '@/pageComponents/orders/OrderApprovalFlowView/components/ApprovalFlowInstance'
import { getOrderApprovalFlowError } from '@/pageComponents/settings/approvals/utils'
import { formatUnixDate } from '@/util/datetime/luxon/dateUtil'
import buildLogger from '@/util/logger'
import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, IconButton, Stack, Typography } from '@mui/material'
import { useCallback } from 'react'
const logger = buildLogger('ApprovalFlowCollapse')

type approvalDisplayStatus = 'Approved' | 'Rejected' | 'Cancelled' | 'Needs Approval' | 'Submitted'

export const OrderApprovalFlowCollapseList = () => {
  const jotaiForm = useJotaiFormContext<OrderApprovalFlowsViewForm>()

  const [onEditSubmitterNote] = useApprovalFlowSubmitterNoteDialog({
    jotaiForm,
  })

  const { approvalFlows, ...commonProps } = jotaiForm.useSelect(
    useCallback((form: OrderApprovalFlowsViewForm) => {
      return {
        approvalFlows: form.orderDetail?.approvalFlows,
        currentUser: form.currentUser,
        submitterName: form.orderDetail?.submittedBy?.displayName,
      }
    }, [])
  )
  return (
    <>
      {!!approvalFlows?.length &&
        approvalFlows?.map((approvalFlow, index) => (
          <OrderApprovalFlowCollapse
            key={index}
            index={index}
            orderApprovalFlow={approvalFlow}
            onEditSubmitterNote={onEditSubmitterNote}
            {...commonProps}
          />
        ))}
    </>
  )
}

export function OrderApprovalFlowCollapse({
  index,
  orderApprovalFlow,
  submitterName,
  currentUser,
  onEditSubmitterNote,
}: {
  index: number
  orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  submitterName?: string
  currentUser?: OrderApprovalFlowsViewForm['currentUser']
  onEditSubmitterNote?: () => void
}): JSX.Element {
  useLoggerEffect(logger)
  const { classes } = useOrderApprovalFlowViewStyles()

  const approvalFlowInstances = orderApprovalFlow.approvalFlowInstances.filter(
    (state) => state.status != ApprovalFlowInstanceStatus.NotApplicable
  )

  const isPreview = orderApprovalFlow.workflowStatus == ApprovalFlowInstanceWorkflowStatus.Preview

  let status: approvalDisplayStatus = 'Submitted'
  if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Approved) {
    status = 'Approved'
  } else if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Rejected) {
    status = 'Rejected'
  } else if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Cancelled) {
    status = 'Cancelled'
  } else if (orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.NeedsApproval) {
    status = 'Needs Approval'
  }
  const error = getOrderApprovalFlowError(orderApprovalFlow)

  let displayDate = formatUnixDate(orderApprovalFlow.createdOn)
  if (orderApprovalFlow.approvalStatus != ApprovalFlowInstanceStatus.AwaitingApproval) {
    displayDate = formatUnixDate(orderApprovalFlow.updatedOn)
  }

  const isAutoApprove = approvalFlowInstances.length === 0

  const currentUserCanApprove = orderApprovalFlow.approvalFlowInstances.some((instance) =>
    instance.data.states.some((approvalState) => {
      const usersInState = approvalState.approvalGroup.users
      const currentUserPresentInState = usersInState.find((user) => user?.email == currentUser?.email)
      return (
        currentUserPresentInState &&
        approvalState.status == ApprovalFlowInstanceStatus.AwaitingApproval &&
        orderApprovalFlow.workflowStatus == ApprovalFlowInstanceWorkflowStatus.InProgress
      )
    })
  )

  const showSubmitter = status === 'Submitted'

  let headerText = `${status} ${displayDate}` + (showSubmitter && submitterName ? ` (By : ${submitterName})` : '')

  if (isAutoApprove) {
    headerText = isPreview ? 'Will be auto approved' : `Automatically approved ${displayDate}`
  } else if (isPreview) {
    headerText = 'Preview'
  }

  return (
    <Accordion defaultExpanded={currentUserCanApprove}>
      <AccordionSummary
        expandIcon={isAutoApprove ? undefined : <ExpandMoreIcon />}
        sx={{ paddingLeft: 3, pointerEvents: isAutoApprove ? 'none' : undefined }}
      >
        {error ? (
          <Alert variant="outlined" severity="warning" sx={{ width: '100%' }}>
            {error}
          </Alert>
        ) : (
          <>
            <Typography variant="body1">{index > 0 && <>Past </>}Approvals</Typography>
            <Typography variant="body1" className={classes.approvalStatus}>
              {headerText}
            </Typography>
          </>
        )}
      </AccordionSummary>
      {!isAutoApprove && (
        <AccordionDetails>
          <Stack gap={0.5}>
            {!!orderApprovalFlow.note && (
              <BillyAlert
                alertType="info"
                showIcon
                message={orderApprovalFlow.note}
                alertProps={{
                  style: {
                    marginTop: 0,
                    marginLeft: 32,
                    marginRight: 32,
                  },
                }}
              />
            )}
            <Box pb={2} pl={4}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  <strong>Note for Approvers</strong>
                </Typography>
                {orderApprovalFlow.workflowStatus === ApprovalFlowInstanceWorkflowStatus.Preview && (
                  <IconButton
                    aria-label="edit approval note"
                    size="small"
                    color="primary"
                    onClick={onEditSubmitterNote}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>

              <ReadMore lineClampCount={1} fontSize="small" className={classes.textSecondary} pr={2}>
                {orderApprovalFlow.submitterNote?.note}
              </ReadMore>
            </Box>
          </Stack>
          <BillyResponsiveGridContainer
            autoWidthByXs={300}
            gridProps={{ spacing: 2, className: classes.accordionContainer }}
          >
            {approvalFlowInstances.map((approvalFlowInstance) => (
              <ApprovalFlowInstanceView
                approvalFlowInstance={approvalFlowInstance}
                key={approvalFlowInstance.id}
                orderApprovalFlow={orderApprovalFlow}
                workflowStatus={orderApprovalFlow.workflowStatus}
                currentUser={currentUser}
              />
            ))}
          </BillyResponsiveGridContainer>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
