import env from './env'
import buildLogger from './logger'

const logger = buildLogger('isEnv')

/**
 * @deprecated Please use one of isCurrentEnvironment(), isCurrentEnvironmentOneOf(), or perform your own checks
 * against getEnv()
 */
export function isLocalEnv(): boolean {
  return !env.NEXT_PUBLIC_ENV_TYPE
}

/**
 * @deprecated Please use one of isCurrentEnvironment(), isCurrentEnvironmentOneOf(), or perform your own checks
 * against getEnv()
 */
export function isDevEnv(): boolean {
  return !env.NEXT_PUBLIC_ENV_TYPE || env.NEXT_PUBLIC_ENV_TYPE === 'dev'
}

/**
 * All enums for process.env.NEXT_PUBLIC_BILLY_UI_ENVIRONMENT, defined in infra/helm/environments.yaml
 */
export const ALL_ENVS = ['local', 'dev2', 'qa01', 'devops1', 'sandbox', 'prod'] as const

export type EnvType = typeof ALL_ENVS[number]

export function getEnv(): EnvType | undefined {
  const ENV = env.NEXT_PUBLIC_BILLY_UI_ENVIRONMENT
  if (ENV) {
    if (isTypeOfEnvType(ENV)) {
      return ENV
    } else {
      logger.warn({ message: `NEXT_PUBLIC_BILLY_UI_ENVIRONMENT is not a valid env type: ${ENV}` })
      return undefined
    }
  } else {
    return undefined
  }
}

function isTypeOfEnvType(env: string): env is EnvType {
  return ALL_ENVS.includes(env as EnvType)
}

export function isCurrentEnvironment(env: EnvType) {
  return getEnv() === env
}

export function isCurrentEnvironmentOneOf(...envs: (EnvType | undefined)[]) {
  return envs.includes(getEnv())
}
