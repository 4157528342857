import React from 'react'
import { EntitySelectionPage } from '../Entity/EntitySelectionPage'
import { useEntitySession } from '../Entity/UseEntitySession'

function EntitySelectionRoute(children: React.ReactNode): JSX.Element {
  const { hasFullSelection, availableEntities } = useEntitySession()
  if (hasFullSelection && availableEntities && availableEntities.length > 1) {
    return <EntitySelectionPage availableEntities={availableEntities} />
  }

  return <>{children}</>
}

export default EntitySelectionRoute
