import React from 'react'
import { DocumentNode } from 'graphql'
import { CombinedError } from 'urql'
import { notEmpty } from '../../util/array'
import { getDocumentName } from '../../util/gqlUtil'
import buildLogger from '../../util/logger'
import JotaiReadValue from '../state/jotaiReadValue'
import { JotaiForm } from '../state/useJotaiForm'
import { WithUrql } from '../state/useJotaiUrqlQuery'
import GqlErrorDisplay from './gqlErrorDisplay'

const logger = buildLogger('JotaiUrqlErrors')

type JotaiUrqlErrorsProps<T extends WithUrql> = {
  jotaiForm: JotaiForm<T>
  documents?: DocumentNode[]
}

export default function JotaiUrqlErrors<T extends WithUrql>({
  jotaiForm,
  documents,
}: JotaiUrqlErrorsProps<T>): JSX.Element {
  const documentNames = documents?.map((document) => getDocumentName(document)).filter(notEmpty)
  return (
    <JotaiReadValue
      atomSelector={(form) => form.urqlErrors}
      form={jotaiForm}
      render={(errors?: Record<string, CombinedError>) => (
        <>
          {Object.keys(errors || {})
            .filter((key) => !documentNames || documentNames?.includes(key))
            .map((key) => (
              <GqlErrorDisplay key={key} error={errors?.[key]} />
            ))}
        </>
      )}
    />
  )
}
