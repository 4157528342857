import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { SessionProvider } from './SessionProvider'

function useRedirectToSignout() {
  const router = useRouter()

  return useCallback(() => {
    router.push('/api/auth/signout').catch((err) => console.error(err))
  }, [router])
}

interface SessionProviderWithSignoutProps {
  readonly children: JSX.Element
}

export default function SessionProviderWithSignout(props: SessionProviderWithSignoutProps) {
  const redirectToSignout = useRedirectToSignout()

  return <SessionProvider onSessionExpiration={redirectToSignout}>{props.children}</SessionProvider>
}
