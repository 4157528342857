import { ContentCopy } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../util/logger'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { copyTextAsync } from '../../util/string'

const logger = buildLogger('CopyButton')

export interface CopyButtonProps {
  message: string
  value: string
  className?: string
}

const useStyles = makeStyles()((theme) => ({
  copyButton: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
  },
  copyIcon: {
    fontSize: theme.typography.htmlFontSize,
  },
}))

export function useCopy({ onSuccess, onError }: { onSuccess?: () => void; onError?: (error: Error) => void } = {}) {
  const errorhandler = useErrorHandler()

  const handleCopy = (toCopy: string): void => {
    copyTextAsync(toCopy)
      .then(onSuccess)
      .catch((error) => {
        onError?.(error)
        errorhandler(error)
      })
  }
  return [handleCopy]
}

export const CopyButton: React.FC<React.PropsWithChildren<CopyButtonProps>> = ({ value, message, className }) => {
  const [isCopied, setIsCopied] = useState(false)

  const [handleCopy] = useCopy()

  function resetIsCopied(): void {
    setIsCopied(false)
  }

  const { classes, cx } = useStyles()
  return (
    <Tooltip title={isCopied ? 'Copied!' : message} onMouseEnter={resetIsCopied}>
      <IconButton
        className={cx([classes.copyButton, className])}
        edge="end"
        aria-label="copy"
        onClick={() => {
          value && handleCopy(value)
          setIsCopied(true)
        }}
        size="small"
      >
        <ContentCopy className={classes.copyIcon} />
      </IconButton>
    </Tooltip>
  )
}
