import { Grid, Typography } from '@mui/material'
import BillyLink from '../../../components/link/billyLink'
import { useOrderPageFormStyles } from './hooks/useOrderPageFormStyles'
import { UseOrderFormCrmReducerForm, useOrderFormCrmReducer } from './hooks/useOrderFormCrmReducer'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'

// TODO: This component should eventually be apart of account autocomplete as an action in dropdown
export function ImportCRMContactLinkInRow<F extends UseOrderFormCrmReducerForm = NewOrderFormData>() {
  const { showContactImport, toggleImportCrmContactDialog } = useOrderFormCrmReducer<F>()
  const { classes } = useOrderPageFormStyles()
  return showContactImport ? (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={4} className={classes.fieldGridItem}>
        <BillyLink
          linkProps={{
            onClick: toggleImportCrmContactDialog,
          }}
        >
          <Typography className={classes.linkText}>Import CRM Contacts</Typography>
        </BillyLink>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}
