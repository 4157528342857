import React, { SyntheticEvent } from 'react'
import { Button, ButtonProps, IconButton, Menu, MenuItem, MenuItemProps, PopoverOrigin, Tooltip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../util/logger'

const logger = buildLogger('ActionMenu')

export type CustomMenuItemProps = MenuItemProps<any, any> & {
  disabledExplanation?: string
}

type Props = {
  buttonProps?: ButtonProps
  children: React.ReactNode
  menuItems: CustomMenuItemProps[]
  variant?: 'app-bar' | 'default' | 'icon-button'
  anchorOrigin?: PopoverOrigin
}

const useStyles = makeStyles()((theme, _params, _classes) => ({
  actionButton: {
    padding: theme.spacing(0, 3),
    '&:hover': {
      backgroundColor: theme.customPalette.hoverColor2,
    },
  },
}))

function ActionMenu({ buttonProps, children, menuItems, variant = 'default', anchorOrigin }: Props): JSX.Element {
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      {variant === 'icon-button' && (
        <IconButton size="large" {...buttonProps} onClick={handleClick}>
          {children}
        </IconButton>
      )}

      {variant === 'app-bar' && (
        <Button color="inherit" className={classes.actionButton} {...buttonProps} onClick={handleClick}>
          {children}
        </Button>
      )}

      {variant === 'default' && (
        <Button {...buttonProps} onClick={handleClick}>
          {children}
        </Button>
      )}
      <Menu
        anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
        anchorEl={anchorEl}
        keepMounted={true}
        open={!!anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {menuItems.map(({ disabledExplanation, onClick, ...menuItemProps }, i) => (
          <Tooltip key={i} title={disabledExplanation || ''} placement="left">
            <div>
              <MenuItem
                onClick={(e: SyntheticEvent) => {
                  onClick && onClick(e)
                  handleClose()
                }}
                disabled={!!disabledExplanation}
                {...menuItemProps}
              />
            </div>
          </Tooltip>
        ))}
      </Menu>
    </>
  )
}

export default ActionMenu
