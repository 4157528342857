import React from 'react'
import { formatUnixDate, formatUtcString } from '../../../util/datetime/luxon/dateUtil'

const DateCell = ({ value }: { value: string }): JSX.Element => {
  if (value === null || value === undefined) {
    return <div />
  }
  return <div>{formatUtcString(value)}</div>
}

export const NumberDateCell = ({ value }: { value: number }): JSX.Element => {
  if (value === null || value === undefined) {
    return <div />
  }
  return <div>{formatUnixDate(value)}</div>
}

export default React.memo(DateCell)
