import JotaiMuiEsAutocomplete from '@/components/input/JotaiMuiEsAutocomplete'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { getEsSearchUpdateAccounts } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/AccountAutocomplete'
import { Grid } from '@mui/material'
import { Draft } from 'immer'
import { useCallback } from 'react'
import { deepMutable } from '../../../../../components/SchemaForm/DeepMutable'
import JotaiMuiAutocomplete from '../../../../../components/input/JotaiMuiAutocomplete'
import { NewOrderFormData } from '../../NewOrderPage'

const atomOptionsSelector = (fom: NewOrderFormData) => deepMutable(fom.resellerAccountList)
const atomUpdater = (value: NewOrderFormData['orderDetail']['resoldBy'], draft: Draft<NewOrderFormData>) => {
  draft.orderDetail.resoldBy = value
  draft.orderDetail.billingContact = null
  draft.billingContactList = undefined
}
const atomValueSelector = (form: NewOrderFormData) => form.orderDetail.resoldBy

export function ResellerAutocomplete(): JSX.Element {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const resellerAccounts = jotaiForm.useSelect(useCallback((form) => form.resellerAccountList, []))

  return resellerAccounts && resellerAccounts.length > 0 ? (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={atomOptionsSelector}
        atomUpdater={atomUpdater}
        atomValueSelector={atomValueSelector}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
        }}
        errorPath="orderDetail.resellerAccount"
        form={jotaiForm}
        textFieldProps={{ label: 'Reseller' }}
      />
    </Grid>
  ) : (
    <></>
  )
}

export function ResellerESAutocomplete() {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  return (
    <Grid item xs={4}>
      <JotaiMuiEsAutocomplete
        atomOptionsSelector={atomOptionsSelector}
        atomUpdater={atomUpdater}
        atomValueSelector={atomValueSelector}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
        }}
        textFieldProps={{
          label: 'Reseller',
          name: 'reseller',
          placeholder: 'Search',
        }}
        fuzzySearchProps={{
          columns: [{ accessor: 'name' }],
          tableName: 'account',
          term: [
            {
              key: 'is_reseller',
              value: 'true',
            },
          ],
          where: [
            {
              key: 'is_deleted',
              value: 'false',
            },
          ],
        }}
        onSearchUpdate={useCallback((values: unknown, draft: Draft<NewOrderFormData>) => {
          draft.resellerAccountList = getEsSearchUpdateAccounts(values)
        }, [])}
        errorPath="orderDetail.resellerAccount"
        form={jotaiForm}
        disableStringFilterStartWith
      />
    </Grid>
  )
}
