import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { FormEvent, useCallback, useState } from 'react'
import { useErrorHandler } from '../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'

type DialogProps = WithModalParams
const formID = 'OrderConfirmDeleteDialog'
export function OrderConfirmDeleteDialog({ open, onClose, onSubmit }: DialogProps): JSX.Element {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.()
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'}>
      <DialogTitle>Delete Order?</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit} id={formID}>
          <Typography>Draft orders that are deleted cannot be recovered and will need to be recreated.</Typography>
        </form>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Delete Order'}
          buttonData={{
            label: 'Delete Order',
            loading: isSubmitting,
            buttonProps: {
              type: 'submit',
              form: formID,
              color: 'error',
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useOrderConfirmDeleteDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: OrderConfirmDeleteDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
