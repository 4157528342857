import { Grid, InputAdornment, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Big from 'big.js'
import { useCallback } from 'react'
import { DeepMutable } from '../../../components/SchemaForm/DeepMutable'
import JotaiUrqlErrors from '../../../components/error/jotaiUrqlErrors'
import JotaiMuiMultiCheckbox from '../../../components/input/JotaiMuiMultiCheckbox'
import JotaiMuiNumberField from '../../../components/input/JotaiMuiNumberField'
import JotaiReadValue from '../../../components/state/jotaiReadValue'
import {
  ActionType,
  ChargeModel,
  ChargeType,
  Discount,
  DiscountStatus,
  LineItemFragment,
} from '../../../generated/graphql'
import { getLineItemCondition } from '../../../util/charge'
import { currencySymbol, getDiscountedPrice, getDiscountRatio, unitPriceFormatter } from '../../../util/currencyUtil'
import buildLogger from '../../../util/logger'
import { predefinedDiscountToLineItemDiscount } from '../../../util/typeConverters'
import { LineItemDiscountDialogProps, LineItemDiscountDialogState } from './LineItemDiscountDialog'
import { JotaiForm } from '@/components/state/useJotaiForm'

const logger = buildLogger('LineItemDiscountDialogContent')

export const LineItemDiscountDialogContent = <F extends LineItemDiscountDialogState>({
  jotaiForm,
  orderType,
  lineItemIndex,
}: LineItemDiscountDialogProps<F>): JSX.Element => {
  const [, isGoalSeekingDisabled] = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form?.orderDetail?.lineItems?.[lineItemIndex]
        const { allowDiscount, allowGoalSeeking } = getLineItemCondition(lineItem)
        return [!allowDiscount, !allowGoalSeeking]
      },
      [lineItemIndex]
    )
  )

  const [finalSellUnitAmount, totalSellAmount] = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const lineItem = form.orderDetail.lineItems[lineItemIndex]

        // only when both usage and per unit, display goal seeking without no quantity
        const finalSellUnitAmount = Big(
          lineItem?.charge.chargeModel === ChargeModel.PerUnit && lineItem?.charge.type === ChargeType.Usage
            ? lineItem.charge.amount ?? 0
            : lineItem?.listUnitPrice ?? 0
        ).toNumber()

        const totalSellAmount =
          lineItem?.charge.chargeModel === ChargeModel.PerUnit && lineItem?.charge.type === ChargeType.Usage
            ? lineItem?.charge.amount ?? 0
            : lineItem?.listAmount ?? 0

        return [finalSellUnitAmount, totalSellAmount]
      },
      [lineItemIndex]
    )
  )

  const currency = jotaiForm.useSelect(useCallback((form) => form.orderDetail.currency ?? undefined, []))

  return (
    <>
      <JotaiUrqlErrors jotaiForm={jotaiForm} />
      <JotaiReadValue
        atomSelector={useCallback(
          (form: F) => {
            const lineItem = form.orderDetail.lineItems[lineItemIndex]
            return { action: lineItem?.action || ActionType.Add, charge: lineItem?.charge }
          },
          [lineItemIndex]
        )}
        form={jotaiForm}
        render={useCallback(
          ({ action }: { action: ActionType; charge: LineItemFragment['charge'] }) => (
            <>
              {(orderType === 'NEW' ||
                orderType === 'RESTRUCTURE' ||
                (orderType === 'AMENDMENT' && action === ActionType.Add)) && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Charge Discount</Typography>
                      <Typography variant="body2">Apply a manual discount to this charge</Typography>
                    </Grid>
                    <Grid item xs={12} role="gridcell">
                      <Box display="flex">
                        <Box>
                          <JotaiMuiNumberField
                            atomSelector={(form) => {
                              return form.orderDetail.lineItems[lineItemIndex]?.discounts?.[0]?.percent
                            }}
                            atomUpdater={(value, draft) => {
                              const lineItem = draft.orderDetail.lineItems[lineItemIndex]
                              if (!lineItem.discounts) {
                                lineItem.discounts = []
                              }
                              if (lineItem.discounts.length === 0) {
                                lineItem.discounts.push({ name: '' })
                              }
                              const discount = lineItem.discounts[0] as DeepMutable<Discount>
                              discount.percent = value || 0
                              lineItem.arrOverride = undefined
                            }}
                            errorPath={`orderDetail.lineItems[${lineItemIndex}].discounts[0].percent`}
                            form={jotaiForm}
                            percent={true}
                            textFieldProps={{
                              'aria-label': 'Charge Discount',
                              placeholder: '0',
                              size: 'small',
                              label: '',
                              style: { maxWidth: 160 },
                              inputProps: {
                                sx: { height: '2.5rem', textAlign: 'right' },
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6">Expected Sell Unit Amount</Typography>
                      <Typography variant="body2">Apply an expected sell unit amount to calculate discount</Typography>
                    </Grid>
                    <GoalSeekingSellAmountInput
                      label="Expected Sell Unit Amount"
                      jotaiForm={jotaiForm}
                      lineItemIndex={lineItemIndex}
                      currency={currency}
                      amount={finalSellUnitAmount}
                      isGoalSeekingDisabled={isGoalSeekingDisabled}
                    />

                    <Grid item xs={12}>
                      <Typography variant="h6">Expected Sell Total Amount</Typography>
                      <Typography variant="body2">Apply an expected sell total amount to calculate discount</Typography>
                    </Grid>
                    <GoalSeekingSellAmountInput
                      label="Expected Sell Total Amount"
                      jotaiForm={jotaiForm}
                      lineItemIndex={lineItemIndex}
                      currency={currency}
                      amount={totalSellAmount}
                      isGoalSeekingDisabled={!totalSellAmount || isGoalSeekingDisabled}
                      isInputForSellTotalAmount={true}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Predefined Discounts</Typography>
                      <Typography variant="body2">Apply predefined discounts to this charge</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <JotaiMuiMultiCheckbox
                        atomSelector={(form) =>
                          form.orderDetail.predefinedDiscounts?.map((discount) => {
                            return {
                              label: (
                                <>
                                  {discount?.name} (
                                  {Big(discount?.percent || 0)
                                    .times(100)
                                    .toNumber()}
                                  %)
                                </>
                              ),
                              value:
                                form.orderDetail.lineItems[lineItemIndex].predefinedDiscounts?.some(
                                  (orderDiscount) => orderDiscount?.id === discount?.id
                                ) || false,
                              disabledExplanation:
                                !form.orderDetail.lineItems[lineItemIndex].predefinedDiscounts?.some(
                                  (orderDiscount) => orderDiscount?.id === discount?.id
                                ) && discount?.status === DiscountStatus.Deprecated
                                  ? 'Cannot apply deprecated discounts to this charge'
                                  : '',
                            }
                          }) || []
                        }
                        atomUpdater={(index, value, draft) => {
                          if (draft.orderDetail.lineItems[lineItemIndex]) {
                            if (!draft.orderDetail.lineItems[lineItemIndex].predefinedDiscounts) {
                              draft.orderDetail.lineItems[lineItemIndex].predefinedDiscounts = []
                            }
                            if (value) {
                              const orderDiscount = draft.orderDetail.predefinedDiscounts?.[index]
                              if (orderDiscount) {
                                draft.orderDetail.lineItems[lineItemIndex].predefinedDiscounts?.push(
                                  predefinedDiscountToLineItemDiscount(orderDiscount)
                                )
                              }
                            } else {
                              draft.orderDetail.lineItems[lineItemIndex].predefinedDiscounts =
                                draft.orderDetail.lineItems[lineItemIndex].predefinedDiscounts?.filter(
                                  (discount) => discount?.id !== draft.orderDetail.predefinedDiscounts?.[index]?.id
                                )
                            }
                          }
                        }}
                        errorPath="dialogLineItem.predefinedDiscounts"
                        form={jotaiForm}
                        renderEmpty={() => (
                          <Typography>No predefined discounts have been added to this order</Typography>
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ),
          [orderType, lineItemIndex, jotaiForm, currency, finalSellUnitAmount, isGoalSeekingDisabled, totalSellAmount]
        )}
      />
    </>
  )
}

type GoalSeekingSellAmountInputPropsType<F> = {
  label: string
  jotaiForm: JotaiForm<F>
  lineItemIndex: number
  amount: number
  isGoalSeekingDisabled: boolean
  currency: string | undefined
  isInputForSellTotalAmount?: boolean
}

const GoalSeekingSellAmountInput = <F extends LineItemDiscountDialogState>({
  label,
  jotaiForm,
  lineItemIndex,
  amount,
  currency,
  isGoalSeekingDisabled,
  isInputForSellTotalAmount,
}: GoalSeekingSellAmountInputPropsType<F>) => {
  const disabledExplanation = 'Add a charge quantity to set a sell amount.'

  return (
    <Grid item xs={12} style={{ marginBottom: '1rem' }} role="gridcell">
      <Grid item>
        <Box
          sx={{
            flexFlow: 'row',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <JotaiMuiNumberField
              atomSelector={(form) => {
                const discountPercent = form.orderDetail.lineItems[lineItemIndex]?.discounts?.[0]?.percent ?? 0
                let amountAfterPredefinedDiscounts = amount
                form.orderDetail.lineItems[lineItemIndex]?.predefinedDiscounts?.forEach((pd) => {
                  amountAfterPredefinedDiscounts = +getDiscountedPrice(amountAfterPredefinedDiscounts, pd?.percent ?? 0)
                })
                if (isInputForSellTotalAmount) {
                  logger.trace({
                    price: +getDiscountedPrice(amountAfterPredefinedDiscounts, discountPercent).toFixed(2),
                  })
                  return +getDiscountedPrice(amountAfterPredefinedDiscounts, discountPercent).toFixed(2)
                }
                logger.trace({
                  price: +getDiscountedPrice(amountAfterPredefinedDiscounts, discountPercent),
                })
                return +getDiscountedPrice(amountAfterPredefinedDiscounts, discountPercent).toFixed(5)
              }}
              atomUpdater={(expectedSellAmount, draft) => {
                const lineItem = draft.orderDetail.lineItems[lineItemIndex]
                let amountAfterPredefinedDiscounts = amount
                lineItem.predefinedDiscounts?.forEach((pd) => {
                  amountAfterPredefinedDiscounts = +getDiscountedPrice(
                    amountAfterPredefinedDiscounts ?? 0,
                    pd?.percent ?? 0
                  )
                })
                if (!lineItem.discounts) {
                  lineItem.discounts = []
                }
                if (lineItem.discounts.length === 0) {
                  lineItem.discounts.push({ name: '' })
                }
                const discount = lineItem.discounts[0] as DeepMutable<Discount>

                const percent = +(expectedSellAmount === null || expectedSellAmount === undefined || !amount
                  ? '1'
                  : getDiscountRatio(expectedSellAmount, amountAfterPredefinedDiscounts) ?? '1')

                discount.percent = percent < 0 ? 0 : percent
                lineItem.arrOverride = undefined
              }}
              errorPath={`orderDetail.lineItems[${lineItemIndex}].discounts[0].percent`}
              form={jotaiForm}
              textFieldProps={{
                'aria-label': label,
                placeholder: 'Sell Amount',
                size: 'small',
                label: '',
                style: { maxWidth: 160 },
                disabled: isGoalSeekingDisabled,
                'aria-disabled': isGoalSeekingDisabled,
                inputProps: {
                  startAdornment: <InputAdornment position="start">{currencySymbol(currency ?? 'USD')}</InputAdornment>,
                  sx: { height: '2.5rem', textAlign: 'right' },
                },
              }}
              disabledExplanation={isGoalSeekingDisabled ? disabledExplanation : undefined}
            />
          </Box>
          <Typography variant="body2" fontSize="1rem">
            &nbsp;/&nbsp;
          </Typography>
          <Typography variant="body2" fontSize="1rem">
            {unitPriceFormatter({
              currency,
              value: +Big(amount),
            })}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
