import { useMemo } from 'react'
import { Feature, FeatureSetting } from '../../generated/graphql'
import { Arrays } from '../SchemaForm/Arrays'
import { useUserTenantSessionWithoutThrowing } from '../UserTenantSessionProvider/UserTenantSessionContext'

/**
 * Used to normalize feature names to uppercase format.  Unfortunately, we have
 * three feature flag naming conventions.
 *
 * camelCase: Camel case with first char lowercase
 * CamelCase: Camel case with first char uppercase
 * UNDER_SCORE: underscore form
 */
export function toUpperSnakeCase(name: string) {
  return name.replaceAll(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()
}

export function dynamicFeatureFlagEnabled(dynamicFeatures: ReadonlyArray<FeatureSetting>, feature: Feature): boolean {
  const feat = Arrays.first(dynamicFeatures.filter((current) => current.name === feature))
  return feat?.enabled ?? false
}

export default function useDynamicFeatureFlag(feature: Feature): boolean {
  const userTenantSession = useUserTenantSessionWithoutThrowing()

  return useMemo(() => {
    return feature ? dynamicFeatureFlagEnabled(userTenantSession?.dynamicFeatureListV2 || [], feature) : false
  }, [feature, userTenantSession?.dynamicFeatureListV2])
}
