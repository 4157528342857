import BillyCard from '@/components/card/billyCard'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'
import { formatUnixDate } from '@/util/datetime/luxon/dateUtil'
import { Grid, Typography } from '@mui/material'

export function RampScheduleView({ intervals }: { intervals: number[] }) {
  const { classes } = useOrderPageFormStyles()

  return (
    <BillyCard className={classes.rampDatesCard}>
      <Grid container direction="column">
        {intervals.map((interval, index) => (
          <Grid item key={interval} className={classes.ramp}>
            <Typography component="span" className={classes.rampIntervalText}>
              Interval {index + 1} Starts:
            </Typography>
            <Typography component="span" className={classes.rampDateText}>
              {formatUnixDate(interval ?? 0)}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </BillyCard>
  )
}
