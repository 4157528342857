import buildLogger from '@/util/logger'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import BillyLink from '../link/billyLink'
import { BillyColumn } from './baseTable'
import { buildLinkCell } from './cells/LinkCell'
const logger = buildLogger('BaseTableDGPCell')

export function BaseTableDGPCell<DataType extends object>({
  params,
  getLinkTarget,
  column,
}: {
  params: GridRenderCellParams
  getLinkTarget?: (row: GridRenderCellParams['row']) => string
  column: BillyColumn<DataType>
}) {
  const href = getLinkTarget ? getLinkTarget(params.row) : undefined
  const shouldBuildLinkCell = !!column.navigationMode && !!getLinkTarget
  const LinkCell = useMemo(() => {
    return shouldBuildLinkCell
      ? buildLinkCell({
          getLinkTarget: column.getLinkTarget,
          navigationMode: column.navigationMode,
        })
      : undefined
  }, [column, shouldBuildLinkCell])

  return (
    <>
      {href ? (
        <BillyLink nextProps={{ href }}>{params.value}</BillyLink>
      ) : LinkCell ? (
        <LinkCell row={{ original: params.row, id: params.row.id }} value={params.value} />
      ) : (
        <>{params.value}</>
      )}
    </>
  )
}
