import { CustomFieldEntryFragment } from '@/generated/graphql'
import { formatCustomField } from '@/pageComponents/orders/LineItemsEditTable/LineItemPopoverLink'
import { TableCell } from '@mui/material'

export function CustomFieldViewCell({ customField }: { customField: CustomFieldEntryFragment }) {
  return (
    <TableCell
      align="right"
      sx={{
        maxWidth: 80,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {formatCustomField(customField)}
    </TableCell>
  )
}
