import BillyGridCell from '@/components/grid/billyGridCell'
import {
  AttributeReferenceFragment,
  GetPriceAttributesQuery,
  PriceAttributeFragment,
  RateCardFragment,
  useGetPriceAttributesQuery,
} from '@/generated/graphql'
import { unitPriceFormatter } from '@/util/currencyUtil'
import { GridCellProps } from '@mui/x-data-grid-pro'

type ViewDefaultPriceAttributesProps = {
  attributeReferences: ReadonlyArray<AttributeReferenceFragment | null>
  rateCardId?: string
}

export function getSelectedAttributeInRateCard(
  attributeReferences: ViewDefaultPriceAttributesProps['attributeReferences'],
  rateCard?: RateCardFragment
) {
  if (!rateCard || !attributeReferences) return null

  const selectedAttribute = rateCard.priceTable.find((attributes) =>
    attributes.attributeReferences.every((attribute) =>
      attributeReferences.some(
        (selectedAttribute) =>
          selectedAttribute?.attributeDefinitionId === attribute.attributeDefinitionId &&
          selectedAttribute.attributeValue === attribute.attributeValue
      )
    )
  )
  return selectedAttribute
}

export function getDefaultPrice(
  attributeReferences: ViewDefaultPriceAttributesProps['attributeReferences'],
  rateCard?: RateCardFragment
) {
  const selectedAttribute = getSelectedAttributeInRateCard(attributeReferences, rateCard)

  if (!!selectedAttribute && rateCard) {
    const currency = rateCard.currencyCode
    return unitPriceFormatter({ value: selectedAttribute?.price, currency })
  }
  return null
}

function getPriceAttributeById({
  priceAttributeId,
  priceAttributes,
}: {
  priceAttributeId?: string
  priceAttributes?: readonly PriceAttributeFragment[]
}) {
  return priceAttributes?.find((priceAttribute) => priceAttribute.id === priceAttributeId)
}

export function getPriceAttributesNameValuePair({
  attributeReferences,
  priceAttributes,
}: {
  attributeReferences: ReadonlyArray<AttributeReferenceFragment | null>
  priceAttributes?: GetPriceAttributesQuery['priceAttributes']
}) {
  if (attributeReferences && attributeReferences.length > 0) {
    const evaluatedAttributeReferences = attributeReferences.map((attributeReference) => ({
      attributeName: getPriceAttributeById({
        priceAttributeId: attributeReference?.attributeDefinitionId,
        priceAttributes,
      })?.name,
      ...attributeReference,
    }))

    return evaluatedAttributeReferences
  }
}

export function useGetPriceAttributes({ attributeReferences }: ViewDefaultPriceAttributesProps) {
  const [priceAttributesResponse] = useGetPriceAttributesQuery()
  const priceAttributes = priceAttributesResponse.data?.priceAttributes

  return getPriceAttributesNameValuePair({ attributeReferences, priceAttributes })
}

function ViewDefaultPriceAttributes({
  attributeReferences = [],
  ...gridCellProps
}: ViewDefaultPriceAttributesProps & Omit<GridCellProps, 'children'>) {
  const defaultPriceAttributes = useGetPriceAttributes({ attributeReferences })

  if (!defaultPriceAttributes || defaultPriceAttributes.length === 0) return null

  return (
    <>
      {defaultPriceAttributes.map((attributeReference) => (
        <BillyGridCell
          key={attributeReference?.attributeDefinitionId}
          label={attributeReference?.attributeName}
          description={attributeReference?.attributeValue}
          {...gridCellProps}
        />
      ))}
    </>
  )
}

export default ViewDefaultPriceAttributes
