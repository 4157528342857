import React, { MouseEventHandler } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import { Box, Menu, MenuItem, Tooltip } from '@mui/material'
import NextLink from 'next/link'
import ActionButton, { ButtonData } from '../button/actionButton'
import { useStyles } from '../nav/secondaryBarWithButtons'

// TODO: Need to reconcile this function with actionMenu.tsx

export function ActionMenuButton({
  buttonData,
  variant = 'navbar',
}: {
  buttonData: ButtonData
  variant?: 'navbar' | 'card'
}): JSX.Element {
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null)

  const handleClick = (event: React.SyntheticEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const { buttonProps, ...unmodifiedButtonData } = buttonData
  const menuButtonVariant = variant === 'card' ? ('outlined' as const) : ('contained' as const)
  const menuButtonData = {
    onClick: handleClick,
    buttonProps: {
      endIcon: <ArrowDropDown />,
      variant: menuButtonVariant,
      ...buttonProps,
    },
    ...unmodifiedButtonData,
  }

  return (
    <div key={menuButtonData.label}>
      <ActionButton buttonData={menuButtonData} />
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted={true} open={!!anchorEl} onClose={handleClose}>
        {menuButtonData.menu?.map((menuData) => {
          if (typeof menuData.onClick === 'string') {
            const link = menuData.onClick as string
            return (
              <Tooltip title={menuData.disabledExplanation || ''} key={menuData.label} placement="right">
                <Box>
                  <MenuItem onClick={handleClose} disabled={!!menuData.disabledExplanation}>
                    <NextLink href={link} passHref aria-disabled={!!menuData.disabledExplanation}>
                      <a className={classes.toolbarMenuLink} target={menuData.buttonProps?.target}>
                        {menuData.label}
                      </a>
                    </NextLink>
                  </MenuItem>
                </Box>
              </Tooltip>
            )
          } else {
            const onClick = menuData.onClick as MouseEventHandler
            return (
              <Tooltip title={menuData.disabledExplanation || ''} key={menuData.label} placement="right">
                <Box>
                  <MenuItem
                    disabled={!!menuData.disabledExplanation}
                    onClick={(e) => {
                      onClick(e)
                      handleClose()
                    }}
                  >
                    <span style={{ width: '100%' }}>{menuData.label}</span>
                  </MenuItem>
                </Box>
              </Tooltip>
            )
          }
        })}
      </Menu>
    </div>
  )
}
