import React from 'react'
import { CustomFieldEntryFragment, CustomFieldType } from '../../generated/graphql'
import { CardContent, CardHeader, Grid, MenuItem } from '@mui/material'
import { ZodObject, ZodRawShape, ZodTypeAny, z } from 'zod'
import { BillyTsForm, BillyTsFormParams } from '../BillyTSForm/BillyTsForm'
import BillyCard from '../card/billyCard'
import {
  OptionalSelectMultiSchema,
  OptionalSelectSchema,
  SelectMultiSchema,
  SelectSchema,
} from '../BillyTSForm/fields/SelectField'
import ActionButton from '../button/actionButton'
import { Box } from '@mui/system'
import { UseFormReturn } from 'react-hook-form'

interface SalesRoomCustomFieldsProps {
  readonly customFields: ReadonlyArray<CustomFieldEntryFragment>
  schema: ReturnType<typeof createBillyTsCustomFieldsSchema>['schema']
  props: ReturnType<typeof createBillyTsCustomFieldsSchema>['props']
  defaultValues: ReturnType<typeof createBillyTsCustomFieldsSchema>['defaultValues']
  onSubmit: (data: Record<string, string | Array<string>>) => Promise<void>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<Record<string, any>>
}

export function createBillyTsCustomFieldsSchema({
  customFields,
}: {
  customFields: ReadonlyArray<CustomFieldEntryFragment>
}) {
  const schemaObject: ZodRawShape = {}
  const props: BillyTsFormParams<ZodObject<ZodRawShape>>['props'] = {}
  const defaultValues: Record<string, string | ReadonlyArray<string | null> | undefined> = {}
  customFields.forEach((customField) => {
    let zodSchemaField: ZodTypeAny
    const customFieldOptions = (customField.options ?? []) as string[]
    const options = customField.required ? customFieldOptions : [''].concat(customFieldOptions)
    switch (customField.type) {
      case CustomFieldType.String:
        if (customField.required) {
          zodSchemaField = z.string().min(1, 'This field is required')
        } else {
          zodSchemaField = z.string()
        }
        defaultValues[customField.id] = customField.value || customField.defaultValue?.value || ''
        break
      case CustomFieldType.Picklist:
        if (customField.required) {
          zodSchemaField = SelectSchema
        } else {
          zodSchemaField = OptionalSelectSchema
        }
        defaultValues[customField.id] =
          customField.selections?.[0] || customField.defaultValue?.selections?.[0] || undefined
        props[customField.id] = {
          children: options?.map((option) => (
            <MenuItem key={option} value={option || ''}>
              {option || <em>None</em>}
            </MenuItem>
          )),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
        break
      case CustomFieldType.MultiselectPicklist:
        if (customField.required) {
          zodSchemaField = SelectMultiSchema
        } else {
          zodSchemaField = OptionalSelectMultiSchema
        }
        defaultValues[customField.id] =
          customField.selections?.filter((selection) => {
            return customField.options?.includes(selection)
          }) ||
          customField.defaultValue?.selections?.filter((selection) => {
            return customField.options?.includes(selection)
          }) ||
          []
        props[customField.id] = {
          items: customField.options?.map((option) => {
            return {
              label: option,
              id: option,
            }
          }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any
        break
      default:
        zodSchemaField = z.string()
    }
    zodSchemaField = zodSchemaField.describe(customField.label || customField.name)
    if (!customField.required) {
      zodSchemaField = zodSchemaField.optional().describe(`${customField.label ?? customField.label} (Optional)`)
    }
    props[customField.id] = {
      ...props[customField.id],
      layout: { xs: 6, md: 6 },
    }
    schemaObject[customField.id] = zodSchemaField
  })
  return {
    schema: z.object(schemaObject),
    props,
    defaultValues,
  }
}

export function SalesRoomCustomFields({
  customFields,
  schema,
  props,
  onSubmit,
  form,
  defaultValues,
}: SalesRoomCustomFieldsProps) {
  if (customFields.length > 0) {
    return (
      <Grid item>
        <BillyCard>
          <CardHeader title="Additional Information" />
          <CardContent>
            <BillyTsForm
              schema={schema}
              props={props}
              onSubmit={onSubmit}
              form={form}
              defaultValues={defaultValues}
              renderAfter={() => (
                <Box sx={{ display: 'flex', justifyContent: 'center' }} pt="1rem">
                  <ActionButton
                    buttonData={{
                      label: 'Save',
                      buttonProps: { type: 'submit' },
                      loading: form.formState.isSubmitting,
                    }}
                  />
                </Box>
              )}
            />
          </CardContent>
        </BillyCard>
      </Grid>
    )
  } else return null
}
