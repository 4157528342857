import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import BillyDataGridPro from '@/components/table/billyDataGridPro'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { OrderEditPlanRowHeader } from '@/pageComponents/orders/LineItemsEditTable/CustomColumn/OrderEditPlanRowHeader'
import OrderChargeRow, {
  ChargeRowColDefProps,
  useCustomGridColDefsForLineItem,
} from '@/pageComponents/orders/LineItemsEditTable/orderChargeRow'
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material'
import { memo, useCallback, useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useOrderAddPlanDataGridDialog } from '../../../components/AddPlanDataGrid/AddPlanDataGridDialog'
import { deepMutable } from '../../../components/SchemaForm/DeepMutable'
import ActionButton from '../../../components/button/actionButton'
import { JotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { JotaiForm } from '../../../components/state/useJotaiForm'
import {
  ActionType,
  CustomFieldParentType,
  DiscountStatus,
  Feature,
  GetCustomFieldsDocument,
  GetCustomFieldsQuery,
  GetCustomFieldsQueryVariables,
  LineItemFragment,
  PlanFragment,
} from '../../../generated/graphql'
import { getDefaultQuantity } from '../../../util/charge'
import buildLogger from '../../../util/logger'
import { predefinedDiscountToLineItemDiscount } from '../../../util/typeConverters'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import {
  LineItemEditContextProvider,
  LineItemTableContextProps,
  useLineItemTableContext,
} from './LineItemEditContextProvider'
import OrderEditPlanRow from './OrderEditPlanRow'
import RampedAndRemovedOrderEditPlanRow from './RampedAndRemovedOrderEditPlanRow'
import { populateCustomFieldsWithDefault } from '@/components/CustomFields/utils'
import { useJotaiUrqlQuery } from '@/components/state/useJotaiUrqlQuery'
import { WithCustomizationLocator } from '@/components/state/context/customizationContext'
import { LineItemsTabTables } from '@/pageComponents/orders/LineItemsEditTable/LineItemsTabTables'

const logger = buildLogger('LineItemsTable')

export const useLineItemsTableStyles = makeStyles()((theme, _params, _classes) => ({
  table: {
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: 600,
        backgroundColor: '#EDF0F3',
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        transition: 'all 0.15s ease',
        '&:nth-of-type(even)': {
          backgroundColor: '#F9FAFB',
        },
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
        '& .MuiTableCell-root': {
          height: 54,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
            color: '#595959',
            backgroundColor: '#EDECEB',
            borderBottom: `1px solid ${theme.customPalette.borderColor}`,
          },
        },
      },
    },
  },
  addPlanAutocomplete: {
    width: 320,
    margin: theme.spacing(3),
    '& .MuiAutocomplete-inputRoot': {
      borderRadius: 6,
    },
  },
}))

const LineItemsTableOrderTypes = ['NEW', 'AMENDMENT', 'CANCELLATION', 'RESTRUCTURE'] as const
export type LineItemsTableOrderType = typeof LineItemsTableOrderTypes[number]

type LineItemsTableProps = {
  orderType: LineItemsTableOrderType
  hidePlan?: boolean
}

const PlanGroupHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell width="70%" variant="head" align="left">
        Item Name
      </TableCell>
      <TableCell width="30%" variant="head" align="left">
        Total Cost
      </TableCell>
      <TableCell width="0%" variant="head" align="left" />
    </TableRow>
  </TableHead>
)

export function LineItemsEditTableWithoutMemo({
  orderType,
  hidePlan,
  parentLocator,
}: LineItemsTableProps & WithCustomizationLocator): JSX.Element {
  const { classes } = useLineItemsTableStyles()

  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)

  const tableContext: LineItemTableContextProps = jotaiForm.useSelect(
    useCallback(
      (form) => ({
        currency: form.orderDetail.currency ?? undefined,
        orderType,
      }),
      [orderType]
    )
  )

  const showTabs = useDynamicFeatureFlag(Feature.OrderLinePlanGrouping)

  return (
    <LineItemEditContextProvider orderType={orderType} tableContext={tableContext}>
      <TableContainer>
        <Table className={classes.table}>
          {hidePlan ? (
            showTabs ? (
              <LineItemsTabTables orderType={orderType} />
            ) : (
              <LineItemsOnlyTable orderType={orderType} />
            )
          ) : (
            <>
              <PlanGroupHeader />
              {orderType === 'RESTRUCTURE' ? (
                <CancelAndRestructureLineItemsEditTableBody orderType={orderType} />
              ) : (
                <LineItemsEditTableBody orderType={orderType} />
              )}
            </>
          )}
          {orderType !== 'CANCELLATION' && <LineItemsEditTableFooter parentLocator={parentLocator} />}
        </Table>
      </TableContainer>
    </LineItemEditContextProvider>
  )
}

export default memo(LineItemsEditTableWithoutMemo)

function LineItemsEditTableFooter({ onRefresh, parentLocator }: { onRefresh?: () => void } & WithCustomizationLocator) {
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { queueDryRun } = useDryRunActions()

  // save line item custom fields for when we add new line items
  const lineItemCustomFieldsPopulated = jotaiForm.useSelect(
    useCallback((data) => !!data.orderLineCustomFields?.length, [])
  )
  useJotaiUrqlQuery<NewOrderFormData, GetCustomFieldsQuery, GetCustomFieldsQueryVariables>({
    document: GetCustomFieldsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.orderLineCustomFields = deepMutable(data.customFields)
    }, []),
    pause: useCallback(() => lineItemCustomFieldsPopulated, [lineItemCustomFieldsPopulated]),
    variables: useCallback(() => ({ parentObjectType: CustomFieldParentType.OrderItem }), []),
  })

  const addPlan = useCallback(
    (plan?: PlanFragment | null) => {
      jotaiForm.set((form) => {
        function hasLineItemForPlan() {
          if (plan) {
            for (const lineItem of form.orderDetail.lineItems) {
              if (lineItem.plan?.id === plan.id && lineItem.action !== ActionType.Remove) {
                return true
              }
            }
          }
          return false
        }

        if (hasLineItemForPlan()) {
          return
        }

        const newLineItems =
          plan?.charges.map((charge) => {
            const lineItem: LineItemFragment = {
              action: ActionType.Add,
              amount: undefined,
              charge: charge,
              chargeDetail: {
                id: charge.id || '',
              },
              effectiveDate: undefined,
              id: undefined,
              isRamp: false,
              isDryRunItem: true,
              plan: plan,
              predefinedDiscounts: form.orderDetail.predefinedDiscounts
                ?.filter((discount) => discount?.status === DiscountStatus.Active)
                .map((discount) => {
                  if (!discount) {
                    throw new Error('Null discount is not allowed in orderDetail.predefinedDiscounts')
                  }
                  return predefinedDiscountToLineItemDiscount(discount)
                }),
              quantity: getDefaultQuantity(charge),
              listUnitPrice: charge.isCustom ? 0 : undefined,
              customFields: populateCustomFieldsWithDefault(form.orderLineCustomFields),
            }
            return lineItem
          }) || []
        form.orderDetail.lineItems.push(...deepMutable(newLineItems))
        onRefresh?.()
      })
      queueDryRun()
    },
    [jotaiForm, onRefresh, queueDryRun]
  )

  const addPlanDisabledExplanation = jotaiForm.useSelect(
    useCallback((form) => {
      if (!form.orderDetail.account?.id) {
        return 'Account must be set'
      }
      if (!form.orderDetail.startDate) {
        return 'Start date must be set'
      }
      if (!form.orderDetail.termLength?.step && !form.orderDetail.endDate) {
        return 'Term Length must be set'
      }
      return ''
    }, [])
  )

  const accountId = jotaiForm.useSelect(useCallback((form) => form.orderDetail.account?.id ?? '', []))

  const [toggleOpenAddPlanDialog] = useOrderAddPlanDataGridDialog<NewOrderFormData>({ addPlan, parentLocator })

  return (
    <TableFooter
      sx={{
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <TableCell colSpan={3}>
        <ActionButton
          key={'Add Plan'}
          buttonData={{
            label: 'Add Plan',
            onClick: () => toggleOpenAddPlanDialog(accountId),
            buttonProps: { variant: 'outlined', style: { marginLeft: 0 } },
            disabledExplanation: addPlanDisabledExplanation,
          }}
        />
      </TableCell>
    </TableFooter>
  )
}

function LineItemsOnlyEditTableBody({ orderType }: LineItemsTableProps) {
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { lineItems } = jotaiForm.useSelect(
    useCallback((form: NewOrderFormData) => {
      const { orderDetail } = form

      return {
        lineItems: orderDetail.lineItems,
      }
    }, [])
  )

  return (
    <TableBody>
      {lineItems &&
        lineItems.map((lineItems, lineItemIndex: number) => {
          return (
            <OrderChargeRow
              key={`${lineItems.id}-${lineItemIndex}`}
              lineItemIndex={lineItemIndex}
              orderType={orderType}
            />
          )
        })}
    </TableBody>
  )
}

export function LineItemsOnlyTable({ orderType }: LineItemsTableProps) {
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { currency } = useLineItemTableContext()
  const { lineItems } = jotaiForm.useSelect(
    useCallback((form: NewOrderFormData) => {
      const { orderDetail } = form

      return {
        lineItems: orderDetail.lineItems,
      }
    }, [])
  )
  const shouldUseDGP = useDynamicFeatureFlag(Feature.UiLineItemsEditTableDgp)

  const columns = useCustomGridColDefsForLineItem()
  return shouldUseDGP ? (
    <BillyDataGridPro
      variant="line-items-edit-table"
      columns={columns}
      rows={lineItems.map(({ id }, lineItemIndex: number): ChargeRowColDefProps => {
        return {
          id: id ?? lineItemIndex.toString(),
          lineItemIndex,
          orderType,
          currency,
        }
      })}
    />
  ) : (
    <>
      <OrderEditPlanRowHeader orderType={orderType} />
      <LineItemsOnlyEditTableBody orderType={orderType} />
    </>
  )
}

export function LineItemsOnlyByGroupTable({
  orderType,
  groupId,
}: LineItemsTableProps & {
  groupId: string
}) {
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { currency } = useLineItemTableContext()
  const { lineItems } = jotaiForm.useSelect(
    useCallback((form: NewOrderFormData) => {
      const { orderDetail } = form

      return {
        lineItems: orderDetail.lineItems,
      }
    }, [])
  )
  const shouldUseDGP = useDynamicFeatureFlag(Feature.UiLineItemsEditTableDgp)

  const columns = useCustomGridColDefsForLineItem()
  return shouldUseDGP ? (
    <BillyDataGridPro
      variant="line-items-edit-table"
      columns={columns}
      rows={lineItems
        .map(({ id, itemGroupId }, lineItemIndex: number) => {
          return {
            id: id ?? lineItemIndex.toString(),
            lineItemIndex,
            orderType,
            currency,
            itemGroupId,
          }
        })
        .filter(({ itemGroupId }) => itemGroupId === groupId)}
    />
  ) : (
    <>
      <OrderEditPlanRowHeader orderType={orderType} />
      <LineItemsOnlyEditTableBody orderType={orderType} />
    </>
  )
}

function LineItemsEditTableBody({ orderType }: LineItemsTableProps) {
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { orderPlans } = jotaiForm.useSelect(
    useCallback((form: NewOrderFormData) => {
      const { orderPlans } = form

      return {
        orderPlans: orderPlans.map((orderPlan) => ({
          name: orderPlan.name,
          id: orderPlan.id,
          status: orderPlan.status,
        })),
      }
    }, [])
  )

  return (
    <TableBody>
      {orderPlans &&
        orderPlans.map((orderPlan, i: number) => {
          return <OrderEditPlanRow key={`${orderPlan.id}`} plan={orderPlan} orderType={orderType} index={i} />
        })}
    </TableBody>
  )
}

function CancelAndRestructureLineItemsEditTableBody({ orderType }: LineItemsTableProps) {
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { orderPlans } = jotaiForm.useSelect(
    useCallback((form: NewOrderFormData) => {
      const { orderPlans } = form

      return {
        orderPlans: orderPlans.map((orderPlan) => ({
          name: orderPlan.name,
          id: orderPlan.id,
          status: orderPlan.status,
        })),
      }
    }, [])
  )

  const lineItems = jotaiForm.useSelect(useCallback((form) => form.orderDetail.lineItems, []))
  const rampedAndRemovedLineItems = lineItems.filter((li) => li.action === ActionType.Remove && li.isRamp)
  const filteredLineItems = lineItems.filter((li) => !(li.action === ActionType.Remove && li.isRamp))

  const removedRampedOrderPlans = orderPlans.filter((plan) =>
    rampedAndRemovedLineItems.some((li) => li.plan?.id === plan.id)
  )

  const filteredOrderPlans = orderPlans.filter((plan) => {
    return filteredLineItems.some((li) => li.plan?.id === plan.id)
  })

  return (
    <TableBody>
      {filteredOrderPlans &&
        filteredOrderPlans.map((orderPlan, i: number) => {
          return <OrderEditPlanRow key={`${orderPlan.id}`} plan={orderPlan} orderType={orderType} index={i} />
        })}
      {removedRampedOrderPlans &&
        removedRampedOrderPlans.map((orderPlan, i: number) => {
          return (
            <RampedAndRemovedOrderEditPlanRow
              key={`${orderPlan}`}
              plan={orderPlan}
              orderType={orderType}
              index={filteredOrderPlans.length + i}
              isPlanRampedAndRemoved={true}
            />
          )
        })}
    </TableBody>
  )
}
