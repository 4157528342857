import React from 'react'
import { AppBar, Grid } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from 'tss-react/mui'
import navCommon from '../../components/nav/navCommon'
import buildLogger from '../../util/logger'
import { DateTime } from 'luxon'

const logger = buildLogger('CenteredLayout')

export const useCenteredLayoutStyles = makeStyles<{
  hideTopBar?: boolean
}>()((theme, { hideTopBar }, _classes) => ({
  appBar: {
    height: navCommon.topBarHeight(theme) - 1,
    '&&': {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  },
  topBar: {
    borderBottom: `1px solid ${theme.customPalette.borderColor}`,
    display: 'flex',
  },
  bottomBar: {
    borderTop: `1px solid ${theme.customPalette.borderColor}`,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  logo: {
    height: '100%',
    padding: theme.spacing(2, 0),
  },
  container: {
    background: theme.customPalette.backgroundColor2,
    display: 'flex',
    minHeight: `calc(100vh - ${navCommon.topBarHeight(theme) * (hideTopBar ? 1 : 2)}px)`,
  },
}))

interface CenteredLayoutProps {
  children: React.ReactNode
  currentYear?: string
}

function CenteredLayout({ children, currentYear = DateTime.now().year.toString() }: CenteredLayoutProps): JSX.Element {
  const { classes } = useCenteredLayoutStyles({})
  return (
    <div>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.topBar}>
          <img className={classes.logo} src="/Subskribe_Logo_Horizontal_Color.svg" alt="Logo" />
        </Toolbar>
      </AppBar>
      <div className={classes.container}>
        <Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: '100%' }}>
          <Grid item style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {children}
          </Grid>
        </Grid>
      </div>
      <AppBar position="sticky" className={classes.appBar} elevation={0} component="footer">
        <Toolbar className={classes.bottomBar}>
          <span>{`© ${currentYear} Subskribe`}</span>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default CenteredLayout
