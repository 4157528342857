import Big from 'big.js'
import { PriceTier } from '../generated/graphql'

export interface MappedPricedTier extends PriceTier {
  from: string
  to: string
}

export function mapPriceTier(
  priceTiers: readonly PriceTier[],
  minQuantity?: number | null,
  maxQuantity?: number | null
): readonly MappedPricedTier[] {
  return priceTiers.map((tier: PriceTier, index) => {
    if (priceTiers?.length) {
      let from = '0'
      let to = '∞'
      if (index === 0) {
        from = minQuantity ? minQuantity.toString() : '0'
      } else if (priceTiers[index - 1]?.untilQuantity) {
        const prevUntilQuantity: number = +priceTiers[index - 1].untilQuantity
        from = (prevUntilQuantity + 1).toString()
      }
      if (index !== priceTiers.length - 1) {
        to = tier?.untilQuantity.toString()
      } else if (maxQuantity != null) {
        to = maxQuantity.toString()
      }
      return { ...tier, from, to }
    }
    return { ...tier, from: '', to: '' }
  })
}

export function findPriceTier(priceTiers: readonly MappedPricedTier[], quantity: number): MappedPricedTier | undefined {
  return (
    priceTiers.find(
      (tier) => Big(tier.from).toNumber() <= quantity && (tier.to === '∞' ? true : Big(tier.to).toNumber() >= quantity)
    ) ?? priceTiers.slice(-1)[0]
  )
}

export function formatPriceTier(tier: MappedPricedTier): string {
  return tier.to === '∞' ? `${formatQuantity(tier.from)}+` : `${formatQuantity(tier.from)}–${formatQuantity(tier.to)}`
}

export function mapTierToQuantity(tier?: MappedPricedTier): string {
  return tier ? (tier.to === '∞' ? tier.from : tier.to) : ''
}

export function formatQuantity(quantity: number | string): string {
  return Big(quantity ?? 0)
    .toNumber()
    .toLocaleString('en-US')
}
