import { useShouldRecalculateTotals } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { JotaiLineItemCustomListPriceEditCell } from '@/pageComponents/orders/LineItemsEditTable/Cells/JotaiLineItemCustomListPriceEditCell'
import { LineItemCellLoadingIndicator } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemCellLoadingIndicator'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '../../EditOrderPage/CommonOrderFormPageState'
import { NewOrderFormData } from '../../EditOrderPage/NewOrderPage'
import { useLineItemEditActions } from '../LineItemEditContextProvider'
import { OrderItemRowProps, useOrderChargeRowStyles } from '../orderChargeRow'
import { ListPriceCell } from './ListPriceCell'

export function LineItemListPriceCell<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  orderType,
  currency,
}: OrderItemRowProps) {
  const { classes } = useOrderChargeRowStyles()
  const jotaiForm = useJotaiFormContext<F>()
  const { onClickPriceOverride, onEditRateAttribute } = useLineItemEditActions()

  const shouldRecalculateTotals = useShouldRecalculateTotals()
  const { isCustomListPriceEditEnabled, ...listPriceProps } = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const reduced = reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        })
        return {
          currency,
          isCustomListPriceEditEnabled: reduced.isCustomListPriceEditEnabled,
          isListPriceOverrideEnabled: reduced.isListPriceOverrideEnabled,
          lineItem: reduced.lineItem,
          listPrice: reduced.listPrice,
        }
      },
      [lineItemIndex, orderType, currency]
    )
  )

  return isCustomListPriceEditEnabled ? (
    <JotaiLineItemCustomListPriceEditCell
      {...{
        lineItemIndex,
        currency,
        orderType,
      }}
    />
  ) : (
    <div className={classes.total}>
      {shouldRecalculateTotals ? (
        <LineItemCellLoadingIndicator />
      ) : (
        <ListPriceCell
          {...{
            ...listPriceProps,
            lineItemIndex,
            onClickPriceOverride,
            onEditRateAttribute,
          }}
        />
      )}
    </div>
  )
}
