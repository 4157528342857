import BillyResponsiveGridItem from '@/components/grid/billyResponsiveGridItem'
import { useLoggerEffect } from '@/components/hooks/useLoggerEffect'
import BillyLink from '@/components/link/billyLink'
import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { ReadMore } from '@/components/typography/ReadMore'
import {
  ApprovalFlowInstanceGroupFragment,
  ApprovalFlowInstanceStatus,
  ApprovalFlowInstanceWorkflowStatus,
  OrderDetailFragment,
  Status,
} from '@/generated/graphql'
import {
  OrderApprovalFlowsViewForm,
  useOrderApprovalFlowViewStyles,
} from '@/pageComponents/orders/OrderApprovalFlowView/OrderApprovalFlowsView'
import { formatUnixDate } from '@/util/datetime/luxon/dateUtil'
import buildLogger from '@/util/logger'
import { isAdminRole } from '@/util/roleUtils'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import PendingIcon from '@mui/icons-material/Pending'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import QuizIcon from '@mui/icons-material/Quiz'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { Box, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import List from '@mui/material/List'

const logger = buildLogger('ApprovalFlowInstance')

const DISABLED_USER = ' [Disabled]'

export type ApprovalFlowInstance = OrderDetailFragment['approvalFlows'][0]['approvalFlowInstances'][0]

export function ApprovalFlowInstanceView({
  approvalFlowInstance,
  orderApprovalFlow,
  workflowStatus,
  currentUser,
}: {
  approvalFlowInstance: ApprovalFlowInstance
  orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  workflowStatus: ApprovalFlowInstanceWorkflowStatus
  currentUser: OrderApprovalFlowsViewForm['currentUser']
}): JSX.Element {
  useLoggerEffect(logger)
  const jotaiForm = useJotaiFormContext<OrderApprovalFlowsViewForm>()

  return (
    <BillyResponsiveGridItem autoSize>
      <ShowApprovalFlowInstanceNameWithToolTip approvalFlowInstance={approvalFlowInstance} />
      <List>
        {approvalFlowInstance?.data.states.map((state) => (
          <ApprovalInstanceStateChip
            approvalState={state}
            key={state.name}
            orderApprovalFlow={orderApprovalFlow}
            workflowStatus={workflowStatus}
            onOpenDialog={() =>
              jotaiForm.set((form) => {
                form.approvalDialogData = deepMutable({
                  approvalFlowInstance,
                  approvalState: state,
                  orderApprovalFlow,
                })
                form.approvalDialogRadio = 'Approve'
              })
            }
            currentUser={currentUser}
          />
        ))}
      </List>
    </BillyResponsiveGridItem>
  )
}

function ShowApprovalFlowInstanceNameWithToolTip({
  approvalFlowInstance,
}: {
  approvalFlowInstance: ApprovalFlowInstance
}): JSX.Element {
  if (approvalFlowInstance.data.description == null || approvalFlowInstance.data.description == '') {
    return (
      <Typography variant="body2">
        <strong> {approvalFlowInstance.data.name} </strong>
      </Typography>
    )
  }

  return (
    <Tooltip
      title={<div style={{ whiteSpace: 'pre-line' }}>{approvalFlowInstance.data.description}</div>}
      placement="top"
    >
      <Typography variant="body2">
        <strong> {approvalFlowInstance.data.name}</strong>
      </Typography>
    </Tooltip>
  )
}

export type ApprovalState = ApprovalFlowInstance['data']['states'][0]

export function ApprovalInstanceStateChip({
  onOpenDialog,
  currentUser,
  approvalState,
  orderApprovalFlow,
  workflowStatus,
}: {
  approvalState: ApprovalState
  orderApprovalFlow: OrderDetailFragment['approvalFlows'][0]
  workflowStatus: ApprovalFlowInstanceWorkflowStatus
  onOpenDialog: () => void
  currentUser: OrderApprovalFlowsViewForm['currentUser']
}): JSX.Element {
  const { classes } = useOrderApprovalFlowViewStyles()

  const usersInState = approvalState.approvalGroup.users
  const currentUserPresentInState = usersInState.find((user) => user?.email && user?.email === currentUser?.email)
  const currentUserCanApproveState =
    (currentUserPresentInState || isAdminRole(currentUser?.role)) &&
    approvalState.status == ApprovalFlowInstanceStatus.AwaitingApproval &&
    workflowStatus == ApprovalFlowInstanceWorkflowStatus.InProgress

  const { approvedBy } = approvalState

  const sortedApproveBys =
    approvedBy &&
    approvedBy.length > 0 &&
    [...approvedBy].sort((userA, userB) => (userA?.time ?? Infinity) - (userB?.time ?? Infinity))

  const approvedTime = sortedApproveBys && sortedApproveBys.length > 0 ? formatUnixDate(sortedApproveBys[0]?.time) : ''

  const isFlowRejected = orderApprovalFlow.approvalStatus == ApprovalFlowInstanceStatus.Rejected
  const isPreview = orderApprovalFlow.workflowStatus == ApprovalFlowInstanceWorkflowStatus.Preview

  // default: pending
  const { icon, caption } = useApprovalInstanceIconAndCaption({
    isPreview,
    currentUserCanApproveState,
    onOpenDialog,
    approvalState,
    approvedTime,
    orderApprovalFlow,
    isFlowRejected,
  })

  const usersString = usersInState
    ?.map((user) => {
      if (user) {
        return user.displayName + (user.state === Status.Disabled ? DISABLED_USER : '')
      }
    })
    .join(', ')

  const approvedByNames = approvedBy.map((a) => a?.user?.displayName).join(',')
  const rejectedByNames = approvalState.rejectedBy.map((a) => a?.user?.displayName).join(',')

  const approvedOrRejectedByName = approvedByNames || rejectedByNames
  const groupName = approvalState.approvalGroup.name
  const userName =
    approvalState.approvalGroup.name +
    (approvalState.approvalGroup.isUser && approvalState.approvalGroup.users[0]?.state === Status.Disabled
      ? DISABLED_USER
      : '')
  const approvalComment = approvalState.approvedBy[0]?.note || approvalState.rejectedBy[0]?.note

  return (
    <>
      <ListItem sx={{ paddingLeft: 0 }} dense>
        <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>{icon}</ListItemIcon>
        <ListItemText>
          {approvalState.approvalGroup.isUser ? (
            userName
          ) : (
            <Tooltip title={usersString} placement="top">
              <label>
                {approvedOrRejectedByName ? (
                  <>
                    {approvedOrRejectedByName} <Box display="inline-block">({groupName})</Box>
                  </>
                ) : (
                  groupName
                )}
              </label>
            </Tooltip>
          )}
          <br />
          {caption}
        </ListItemText>
      </ListItem>
      {approvalComment && (
        <ListItem dense sx={{ paddingLeft: 0 }}>
          <ListItemText sx={{ marginLeft: '48px' }}>
            <ReadMore fontSize="small" className={classes.textSecondary}>
              {approvalComment}
            </ReadMore>
          </ListItemText>
        </ListItem>
      )}
    </>
  )
}

type IconAndCaptionProps = {
  isPreview: boolean
  currentUserCanApproveState: boolean
  onOpenDialog: () => void
  approvalState: { readonly status: ApprovalFlowInstanceStatus }
  approvedTime: string
  orderApprovalFlow: ApprovalFlowInstanceGroupFragment
  isFlowRejected: boolean
}

function useApprovalInstanceIconAndCaption({
  isPreview,
  currentUserCanApproveState,
  onOpenDialog,
  approvalState,
  approvedTime,
  orderApprovalFlow,
  isFlowRejected,
}: IconAndCaptionProps) {
  const { classes } = useOrderApprovalFlowViewStyles()
  let icon = <WatchLaterIcon />
  let caption = (
    <Typography className={classes.textSecondary} variant="body2">
      {'Approval Requested'}
    </Typography>
  )

  if (isPreview) {
    // preview
    icon = <PendingActionsIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Approver`}
      </Typography>
    )
  } else if (currentUserCanApproveState) {
    // requested
    icon = (
      <BillyLink linkProps={{ onClick: onOpenDialog }} noHover={true} noUnderline={true}>
        <QuizIcon color="primary" />
      </BillyLink>
    )

    caption = (
      <BillyLink
        linkProps={{
          onClick: onOpenDialog,
        }}
      >
        <Typography color="primary" variant="body2">
          Approve or Reject
        </Typography>
      </BillyLink>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.Approved) {
    // approved / vetoed
    icon = <CheckCircleIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Approved ${approvedTime}`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.AdminBypass) {
    icon = <CheckCircleIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Bypassed by Admin`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.Rejected) {
    // rejected
    icon = <CancelIcon color="error" />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Rejected ${formatUnixDate(orderApprovalFlow.updatedOn)}`}
      </Typography>
    )
  } else if (
    approvalState.status == ApprovalFlowInstanceStatus.Cancelled ||
    (approvalState.status == ApprovalFlowInstanceStatus.Inactive && isFlowRejected) ||
    (approvalState.status == ApprovalFlowInstanceStatus.AwaitingApproval && isFlowRejected)
  ) {
    // cancelled
    icon = <CancelIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Cancelled`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.Inactive) {
    // pending other
    icon = <PendingIcon className={classes.disabledIcon} />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Up Next`}
      </Typography>
    )
  } else if (approvalState.status == ApprovalFlowInstanceStatus.NotApplicable) {
    // not applicable
    icon = <NotInterestedIcon />
    caption = (
      <Typography className={classes.textSecondary} variant="body2">
        {`Not Applicable`}
      </Typography>
    )
  }
  return { icon, caption }
}
