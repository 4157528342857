import { useMemo } from 'react'
import { selectAtom, useAtomValue } from 'jotai/utils'
import { JotaiForm } from '../components/state/useJotaiForm'

export function useYupError<T>(form: JotaiForm<T>, errorPath: string): string {
  const errorAtom = useMemo(
    () =>
      selectAtom(form.atom, (form: any) => {
        const yupErrors = form.yupErrors
        if (yupErrors) {
          return yupErrors[errorPath]
        }
      }),
    [errorPath, form.atom]
  )
  return useAtomValue(errorAtom)
}
