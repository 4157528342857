import { useCallback } from 'react'
import {
  CustomFieldParentType,
  LineItemFragment,
  UpdateCustomFieldsMutationVariables,
  useUpdateCustomFieldsMutation,
} from '../../generated/graphql'
import buildLogger from '@/util/logger'

const logger = buildLogger('useUpdateCustomFieldsBatchAsync')

export const useUpdateCustomFieldsBatchAsync = () => {
  const [, updateCustomFields] = useUpdateCustomFieldsMutation()
  const updateCustomFieldsBatchAsync = useCallback(
    async (updateLineItems: LineItemFragment[]) => {
      const batchEditCustomFieldRecord = updateLineItems
        .map((lineItem) => {
          return {
            customFields:
              lineItem.customFields?.map((customField) => {
                const selections = customField?.selections?.filter((selection) => selection) ?? []
                return {
                  id: customField?.id ?? '',
                  selections: selections?.length > 0 ? selections : customField?.defaultValue?.selections ?? [],
                  value: customField?.value ?? customField?.defaultValue?.value ?? '',
                }
              }) ?? [],
            parentObjectId: lineItem.id ?? '',
            parentObjectType: CustomFieldParentType.OrderItem,
          }
        })
        .filter((variable) => {
          return !!variable.parentObjectId && !!variable.customFields.length
        }) as UpdateCustomFieldsMutationVariables[]

      //TODO: batch edit custom fields using real gql call
      await Promise.all(
        Object.values(batchEditCustomFieldRecord).map(async (variable) => {
          if (variable) {
            await updateCustomFields(variable)
          }
        })
      )
    },
    [updateCustomFields]
  )
  return {
    updateCustomFieldsBatchAsync,
  }
}
