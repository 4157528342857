import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import useJotaiForm, { JotaiForm } from '../../../components/state/useJotaiForm'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { WithUrql } from '../../../components/state/useJotaiUrqlQuery'
import buildLogger from '../../../util/logger'
import { LineItemsTableOrderType } from '../LineItemsEditTable/LineItemsEditTable'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { LineItemPriceOverrideDialogContent } from './LineItemPriceOverrideContent'

// todo: generify and combine dialog with LineItemDiscountDialog

const logger = buildLogger('LineItemPriceOverrideDialog')

export type LineItemPriceOverrideDialogState = Pick<NewOrderFormData, 'orderDetail'> & WithUrql
export type LineItemPriceOverrideDialogProps<F> = {
  jotaiForm: JotaiForm<F>
  orderType: LineItemsTableOrderType
  lineItemIndex: number
}

type DialogProps<F> = WithModalParams & LineItemPriceOverrideDialogProps<F>

const formID = 'LineItemPriceOverrideDialog'
const LineItemPriceOverrideDialog = <F extends LineItemPriceOverrideDialogState>({
  open,
  onClose,
  onSubmit,
  jotaiForm,
  orderType,
  lineItemIndex,
}: DialogProps<F>): JSX.Element => {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  function handleSubmit() {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.()
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'}>
      <DialogTitle>Price Override</DialogTitle>
      <DialogContent dividers={true}>
        <LineItemPriceOverrideDialogContent jotaiForm={jotaiForm} orderType={orderType} lineItemIndex={lineItemIndex} />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Apply',
            color: 'primary',
            loading: isSubmitting,
            onClick: handleSubmit,
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export const useLineItemPriceOverrideDialog = <F extends LineItemPriceOverrideDialogState>({
  orderType,
}: {
  orderType: LineItemsTableOrderType
}) => {
  const orderJotaiForm = useJotaiFormContext<F>()
  const [, , toggleModal] = useModalsContext()
  const [lineItemIndex, setLineItemIndex] = useState<number>(0)

  const orderDetail = orderJotaiForm.useSelect(useCallback((form) => form.orderDetail, []))

  const { queueDryRun } = useDryRunActions()

  const priceOverrideDraftForm = useJotaiForm<LineItemPriceOverrideDialogState>(
    useMemo(
      () => ({
        defaultValue: {
          orderDetail,
        },
      }),
      [orderDetail]
    )
  )

  const onSavePriceOverride = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: priceOverrideDraftForm.atom,
        onSubmit: (value: LineItemPriceOverrideDialogState) => {
          orderJotaiForm.set((form) => {
            form.orderDetail.lineItems = value.orderDetail.lineItems
          })
          queueDryRun()
        },
      }),
      [priceOverrideDraftForm.atom, orderJotaiForm, queueDryRun]
    )
  )

  useModal<DialogProps<LineItemPriceOverrideDialogState>>({
    component: LineItemPriceOverrideDialog,
    schema: {
      key: formID,
      modalProps: {
        jotaiForm: priceOverrideDraftForm,
        orderType,
        onSubmit: onSavePriceOverride,
        lineItemIndex,
      },
    },
  })

  const toggleCurrentModal = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleCurrentModal, setLineItemIndex] as [typeof toggleCurrentModal, typeof setLineItemIndex]
}
