import { LayoutProps } from '@/components/BillyTSForm/BillyTsForm'
import { OptionalItemsType } from '@/components/input/JotaiMuiSelect'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import { z } from 'zod'

export type RadioGroupFieldProps = LayoutProps &
  Omit<RadioGroupProps, 'value' | 'onChange'> &
  OptionalItemsType & { disabledExplanation?: string }

export const RadioGroupSchema = createUniqueFieldSchema(z.string().trim().min(1), 'radio-group')

export function RadioGroupField({
  layout,
  items,
  children,
  disabledExplanation,
  ...rest
}: RadioGroupFieldProps): JSX.Element {
  const {
    field: { value, onChange, name },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<string>()

  const isRadioDisabled = !!disabledExplanation || isLoading || isSubmitting

  const { label } = useDescription()
  if (rest.hidden) return <></>

  return (
    <Grid container item xs {...layout}>
      <FormControl error={!!error?.errorMessage}>
        <FormLabel aria-label="radio-group-label">
          <Typography variant="body2">{label}</Typography>
        </FormLabel>
        <RadioGroup
          aria-label="radio-group"
          name={name}
          onChange={(event, value) => {
            onChange(value)
          }}
          value={value}
          {...rest}
        >
          {children}
          {items?.map((item) => (
            <Tooltip key={`${item.id}-disable-explanation`} title={disabledExplanation}>
              <FormControlLabel value={item.id} control={<Radio />} label={item.label} disabled={isRadioDisabled} />
            </Tooltip>
          ))}
        </RadioGroup>
        {!!error && <FormHelperText>{error.errorMessage}</FormHelperText>}
      </FormControl>
    </Grid>
  )
}
