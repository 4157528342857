import React from 'react'
import { CellProps } from 'react-table'
import StatusChip from '../../../components/table/cells/statusChip'
import { BulkInvoiceRunPhase, BulkInvoiceRunStatus } from '../../../generated/graphql'

export enum BulkInvoiceDisplayStatus {
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Review = 'READY FOR REVIEW',
  Complete = 'COMPLETED',
  Failed = 'FAILED',
}

export function dynamicBulkInvoiceStatusChip(
  phaseField: string,
  statusField: string
): (props: React.PropsWithChildren<CellProps<any, any>>) => JSX.Element {
  return function DynamicCurrencyCell({ row }) {
    return <BulkInvoiceStatusChip phase={row.values[phaseField]} status={row.values[statusField]} />
  }
}

const BulkInvoiceStatusChip = ({ phase, status }: { phase?: string | null; status?: string | null }): JSX.Element => {
  if (!status || !phase) {
    // this should never happen, if no phase or status, we show PENDING
    return <StatusChip value={BulkInvoiceDisplayStatus.Pending} />
  }
  if (status == BulkInvoiceRunStatus.Completed) {
    return <StatusChip value={BulkInvoiceDisplayStatus.Complete} />
  } else if (status == BulkInvoiceRunStatus.Failed) {
    return <StatusChip value={BulkInvoiceDisplayStatus.Failed} />
  } else {
    if (phase == BulkInvoiceRunPhase.InvoicesGenerated) {
      return <StatusChip value={BulkInvoiceDisplayStatus.Review} />
    } else if (phase == BulkInvoiceRunPhase.InvoiceGenerationNotStarted) {
      return <StatusChip value={BulkInvoiceDisplayStatus.Pending} />
    } else {
      return <StatusChip value={BulkInvoiceDisplayStatus.Processing} />
    }
  }
}

export default React.memo(BulkInvoiceStatusChip)
