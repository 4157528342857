import { useOrderEditPlanRowStyles } from '@/pageComponents/orders/LineItemsEditTable/useOrderEditPlanRowStyles'
import buildLogger from '@/util/logger'
import { TableCell, Tooltip } from '@mui/material'
const logger = buildLogger('CustomColumnHeader')

export type CustomColumnHeaderProps = {
  name: string
}

export function CustomColumnHeader({ name }: CustomColumnHeaderProps): JSX.Element {
  const { classes } = useOrderEditPlanRowStyles()
  return (
    <TableCell className={classes.currencyHeader}>
      <Tooltip title="This is a custom field" placement="top">
        <div>{name}</div>
      </Tooltip>
    </TableCell>
  )
}
