import { TabBar } from '@/components/tab/TabBar'
import {
  LineItemsTableOrderType,
  LineItemsOnlyTable,
  LineItemsOnlyByGroupTable,
} from '@/pageComponents/orders/LineItemsEditTable/LineItemsEditTable'
import { useLineItemsTableNewTabDialog } from '@/pageComponents/orders/LineItemsEditTable/LineItemsTableNewTabDialog'
import { TabContext, TabPanel } from '@mui/lab'
import { useState } from 'react'

const TAB_ALL_ID = 'all'

export function LineItemsTabTables({ orderType }: { orderType: LineItemsTableOrderType }) {
  const [tabPanels, setTabPanels] = useState<{ id: string; name: string }[]>([
    {
      id: TAB_ALL_ID,
      name: 'All Order Items',
    },
  ])

  const [selectedTab, setSelectedTab] = useState(TAB_ALL_ID)

  const addNewTab = (
    newTab: { id: string; name: string } = {
      id: `${tabPanels.length}`,
      name: `Order Items ${tabPanels.length}`,
    }
  ) => {
    setTabPanels((prev) => {
      return [...prev, newTab]
    })
  }

  const [onAdd] = useLineItemsTableNewTabDialog({
    tabIds: tabPanels.map((p) => p.id),
    onSubmit: (data) => {
      addNewTab({
        id: data.id,
        name: data.label || data.id,
      })
    },
  })

  return (
    <TabContext value={selectedTab}>
      <TabBar
        tabs={tabPanels.map((panel) => ({
          label: panel.name,
          value: panel.id,
        }))}
        onTabChange={(e, value) => setSelectedTab(value)}
        onAdd={onAdd}
      />
      {tabPanels.map((panel) => (
        <TabPanel key={panel.id} value={panel.id}>
          {panel.id === TAB_ALL_ID ? (
            <LineItemsOnlyTable orderType={orderType} />
          ) : (
            <LineItemsOnlyByGroupTable orderType={orderType} groupId={panel.id} />
          )}
        </TabPanel>
      ))}
    </TabContext>
  )
}
