import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    // this is not correct but, it was part of our current loading dialog and
    // I didn't want a regression
    color: '#fff',
  },
}))

export default function Loading() {
  const { classes } = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={true} data-testid={Loading.name}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
