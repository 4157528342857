import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid, InputAdornment } from '@mui/material'
import JotaiMuiField from '../../../../../components/input/JotaiMuiField'
import { useJotaiFormContext } from '../../../../../components/state/jotaiFormProvider'

export function PurchaseOrderNumberField() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  return (
    <Grid item xs={6}>
      <JotaiMuiField
        atomSelector={(form) => form.orderDetail.purchaseOrderNumber}
        atomUpdater={(value, draft) => (draft.orderDetail.purchaseOrderNumber = value)}
        errorPath="orderDetail.purchaseOrderNumber"
        form={jotaiForm}
        textFieldProps={{
          label: 'Purchase Order Number',
          InputProps: {
            startAdornment: <InputAdornment position="start">PO #</InputAdornment>,
          },
        }}
      />
    </Grid>
  )
}
