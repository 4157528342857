import { CardContent, CardHeader, Grid, GridSize, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import BillyLink from '../link/billyLink'
import StatusChip from '../table/cells/statusChip'
import BillyCard from './billyCard'

export interface CellProps {
  label: string
  content: JSX.Element | string | null | undefined
  width?: GridSize | any
  hidden?: boolean
}

export interface RowProps {
  cells: CellProps[]
}

export interface BillyDetailsProps {
  titleLabel?: string
  title: string
  titleLink?: string
  subtitle?: string | null
  status?: string
  action?: JSX.Element
  rows: RowProps[]
  header?: JSX.Element
  isMainCard?: boolean
}

export const useBillyCardDetailStyles = makeStyles()((theme, _params, _classes) => ({
  cardHeader: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.customPalette.borderColor}`,
  },
  cardLabel: {
    color: theme.customPalette.textGray,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    display: 'inline-block',
    color: theme.customPalette.textDark,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.375rem',
    overflowWrap: 'break-word',
  },
  cardTitleLink: {
    color: theme.palette.primary.main,
  },
  cardSubtitle: {
    color: theme.customPalette.textGray,
    fontSize: 16,
    '& a': {
      color: theme.customPalette.textDark,
    },
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rowLabel: {
    color: theme.customPalette.textDark,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  gridCell: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    wordWrap: 'break-word',
  },
  actionLabel: {
    paddingLeft: theme.spacing(3),
  },
  linkText: {
    fontWeight: 550,
    fontSize: '0.875rem',
  },
  collapsibleRow: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    borderTop: `1px solid ${theme.customPalette.borderColor}`,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
}))

const BillyDetailsCard: React.FC<React.PropsWithChildren<BillyDetailsProps>> = ({
  titleLabel,
  title,
  titleLink,
  subtitle,
  status,
  action,
  rows,
  header,
  isMainCard = true,
}): JSX.Element => {
  return (
    <BillyCard hidden={!rows.length}>
      {isMainCard && (
        <BillyDetailsCardHeader
          {...{
            header,
            titleLabel,
            title,
            titleLink,
            subtitle,
            action,
            status,
          }}
        />
      )}
      {!isMainCard && <CardHeader title={title} action={action} />}
      <CardContent>
        <CardDetails rows={rows} />
      </CardContent>
    </BillyCard>
  )
}

export default BillyDetailsCard

export function CardDetails({ rows }: { rows: RowProps[] }) {
  const { classes } = useBillyCardDetailStyles()
  return (
    <Grid container direction="row" role="grid">
      {rows
        .filter((row) => row.cells.some((cell) => cell.content))
        .map((row: RowProps, y: number) => (
          <Grid container key={`col-${y}`}>
            {row.cells
              .filter((cell) => !cell.hidden)
              .map(
                (cell: CellProps, x: number) =>
                  cell.content && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      key={`row-${x}`}
                      style={{}}
                      role="gridcell"
                      aria-label={cell.label}
                      className={classes.gridCell}
                    >
                      <Typography component="h3" className={classes.rowLabel}>
                        {cell.label}
                      </Typography>
                      {cell.content}
                    </Grid>
                  )
              )}
          </Grid>
        ))}
    </Grid>
  )
}

function TitleView({ title, titleLink }: Pick<BillyDetailsProps, 'title' | 'titleLink'>) {
  const { classes, cx } = useBillyCardDetailStyles()
  if (titleLink) {
    return (
      <div>
        <BillyLink nextProps={{ href: titleLink }}>
          <Typography className={cx(classes.cardTitle, titleLink && classes.cardTitleLink)}>{title}</Typography>
        </BillyLink>
      </div>
    )
  }
  return <Typography className={classes.cardTitle}>{title}</Typography>
}

export function BillyDetailsCardHeader({
  header,
  title,
  titleLabel,
  titleLink,
  subtitle,
  action,
  status,
}: Pick<BillyDetailsProps, 'header' | 'title' | 'titleLabel' | 'titleLink' | 'subtitle' | 'action' | 'status'>) {
  const { classes } = useBillyCardDetailStyles()
  if (header) {
    return header
  }
  return (
    <Grid container direction="row" className={classes.cardHeader}>
      <Grid item sm={6} xs={12} className={classes.titleWrapper}>
        {titleLabel && (
          <Typography component="span" className={classes.cardLabel}>
            {titleLabel}
          </Typography>
        )}
        {!!title && <TitleView title={title} titleLink={titleLink} />}
        {subtitle && (
          <Typography component="p" className={classes.cardSubtitle}>
            {subtitle}
          </Typography>
        )}
      </Grid>
      <Grid item sm={6} xs={12} container direction="row" justifyContent="flex-end" alignItems="center">
        {action && <>{action}</>}
        {status && (
          <Typography component="div" className={classes.actionLabel}>
            {typeof status === 'string' ? <StatusChip value={status} /> : status}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
