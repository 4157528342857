import { PageContainer } from '@/components/PageContainer/PageContainer'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { Feature } from '@/generated/graphql'
import { CancellationOrderLayout } from '@/pageComponents/orders/CancellationOrderPage/CancellationOrderLayout'
import { EditOrderCustomFieldsCard } from '@/pageComponents/orders/EditOrderPage/cards/EditOrderCustomFieldsCard/EditOrderCustomFieldsCard'
import { OrderEditItemsCard } from '@/pageComponents/orders/EditOrderPage/cards/OrderEditItemsCard'
import { Stack } from '@mui/material'
import { memo } from 'react'
import { IncludedTermsCard } from '../IncludedTerms/IncludedTermsCard'
import DocumentMasterTemplateCard from '../documentMasterTemplateCard'
import { CancellationOrderDetailsCard } from './CancellationOrderDetailsCard'

export const CancellationOrderContent = memo(function CancellationOrderContentWithoutMemo() {
  return (
    <PageContainer
      slot={{
        Layout: CancellationOrderLayout,
      }}
    >
      <CancellationOrderCardStack />
    </PageContainer>
  )
})

export function CancellationOrderCardStack() {
  return (
    <Stack spacing={3}>
      <CancellationOrderDetailsCard />
      <WithDynamicFeatureFlag feature={Feature.OrderCustomFields} status="enabled">
        <EditOrderCustomFieldsCard />
      </WithDynamicFeatureFlag>
      <OrderEditItemsCard orderType={'CANCELLATION'} />
      <DocumentMasterTemplateCard />
      <IncludedTermsCard />
    </Stack>
  )
}
