import React from 'react'
import { formatUnixDate, formatUtcString } from '../../../util/datetime/luxon/dateUtil'
import { makeStyles } from 'tss-react/mui'

const DISPLAY_TIME_FORMAT = 'MM/dd/yyyy HH:mm:ss'

const useStyles = makeStyles()(() => ({
  noWrapCell: {
    whiteSpace: 'nowrap',
  },
}))

const TimeCell = ({ value }: { value: string }): JSX.Element => {
  const { classes } = useStyles()
  if (value === null || value === undefined) {
    return <div />
  }
  return <div className={classes.noWrapCell}>{formatUtcString(value, DISPLAY_TIME_FORMAT)}</div>
}

export const NumberTimeCell = ({ value }: { value: number }): JSX.Element => {
  const { classes } = useStyles()
  if (value === null || value === undefined) {
    return <div />
  }
  return <div className={classes.noWrapCell}>{formatUnixDate(value, DISPLAY_TIME_FORMAT)}</div>
}

export default React.memo(TimeCell)
