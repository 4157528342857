import { Box, Typography, useTheme } from '@mui/material'

export const TopBanner = ({ env }: { env: string | undefined }) => {
  const theme = useTheme()

  if (!env) {
    return <></>
  }

  return (
    <Box
      position="sticky"
      sx={{
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: 8,
        top: 0,
      }}
    >
      <Typography color="white" variant="body1" paddingY={1}>
        {env}
      </Typography>
    </Box>
  )
}
