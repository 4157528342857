import { Grid, List, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { QuickCastOption } from './QuickCastBarManager'

type SearchResultListProps = {
  options: QuickCastOption[]
  selectedIndex: number
  setSelectedIndex: (index: number) => void
  onDoubleClick?: () => void
}
export function SearchResultList({ options, setSelectedIndex, selectedIndex, onDoubleClick }: SearchResultListProps) {
  return options.length ? (
    <List>
      {options.map((option, index) => {
        const { label, Icon, domain, type, id } = option
        return (
          <ListItem
            key={id}
            onClick={() => {
              setSelectedIndex(index)
            }}
            onDoubleClick={onDoubleClick}
            sx={{
              ':hover': {
                backgroundColor: (theme) =>
                  selectedIndex === index ? theme.customPalette.hoverColor1 : theme.customPalette.hoverColor2,
              },
              backgroundColor: (theme) => (selectedIndex === index ? theme.customPalette.hoverColor1 : 'inherited'),
              cursor: 'default',
            }}
          >
            <Box alignItems="center" display={'flex'} flex="1">
              {!!Icon && <Icon color="disabled" />}
              <Grid flex="1" pl={1} container gap={1} alignItems="baseline">
                <Grid item>
                  <Typography>{label}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="GrayText">
                    {domain}
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Typography variant="body1" color="GrayText">
                  {type}
                </Typography>
              </Box>
            </Box>
          </ListItem>
        )
      })}
    </List>
  ) : (
    <></>
  )
}
