import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import ActionButton from '../../components/button/actionButton'
import buildLogger from '../../util/logger'
import BillyGridCell, { GridCellProps } from '../../components/grid/billyGridCell'
import { useGetPlanQuery } from '../../generated/graphql'
import BillyLink from '../../components/link/billyLink'

const logger = buildLogger('planDialogBody')

const useStyles = makeStyles()((theme, _params, _classes) => ({
  planHeader: {
    marginBottom: theme.spacing(2),
  },
  copyButton: {
    marginLeft: theme.spacing(0.5),
  },
  detailRow: {
    marginBottom: theme.spacing(2),
  },
  dialogTitle: {
    color: theme.customPalette.textDark,
    fontSize: 20,
  },
  label: {
    display: 'flex',
    alignSelf: 'bottom',
    color: theme.customPalette.textDark,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

export interface PlanDialogBodyProps {
  id: string
  onClose?: () => void
}

const cellsPerRow = 4
const gridCellSize = { xs: 12, sm: 12 / cellsPerRow }

const getCellOrder = (length: number, index: number, alwaysLastRow?: boolean): number => {
  const getLastRowOrder = (length: number): number => {
    return length - (length % cellsPerRow) + 2
  }
  return alwaysLastRow ? getLastRowOrder(length) : index + 1
}

type WithPlanDetailCellDisplayParams = { hide?: boolean; alwaysLastRow?: boolean }
type PlanDetailCell = GridCellProps & WithPlanDetailCellDisplayParams

export const PlanDialogBody: React.FC<React.PropsWithChildren<PlanDialogBodyProps>> = ({ id, onClose }) => {
  const handleClose = (): void => {
    onClose && onClose()
  }
  const { classes } = useStyles()

  const [planQueryResponse] = useGetPlanQuery({ variables: { id: id } })
  const planDetail = planQueryResponse.data?.planDetail

  const planDetailCells: readonly PlanDetailCell[] = [
    {
      label: 'Plan ID',
      description: planDetail?.id ?? '',
      variant: 'copy-description' as const,
    },
    {
      label: 'Plan Name',
      description: (
        <Grid item container xs={12}>
          {planDetail?.id && planDetail?.name && (
            <Grid item key={planDetail.id} xs={12}>
              <BillyLink
                linkProps={{
                  href: `/products/${planDetail?.product.id}/plans/${planDetail.id}`,
                }}
              >
                <div>{planDetail.name}</div>
              </BillyLink>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      label: 'Plan Description',
      description: planDetail?.description ?? '',
    },
    {
      label: 'Plan Currency',
      description: planDetail?.currency ?? '',
    },
    {
      label: 'Product',
      description: (
        <Grid item container xs={12}>
          {planDetail?.product.name && planDetail?.product.name && (
            <Grid item key={planDetail.id} xs={12}>
              <BillyLink
                linkProps={{
                  href: `/products/${planDetail?.product.id}`,
                }}
              >
                <div>{planDetail.product.name}</div>
              </BillyLink>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      label: 'Require All Plans',
      description: (
        <Grid item container xs={12}>
          {!!planDetail?.requireAllPlans?.length &&
            planDetail.requireAllPlans.map((requireAllPlan) => {
              return (
                <Grid item key={requireAllPlan.id} xs={12}>
                  <BillyLink
                    linkProps={{
                      href: `/products/${requireAllPlan.productId}/plans/${requireAllPlan.id}`,
                    }}
                  >
                    <div>{requireAllPlan.name}</div>
                  </BillyLink>
                </Grid>
              )
            })}
        </Grid>
      ),
      sm: 3,
      hide: !planDetail?.requireAllPlans,
    },
    {
      label: 'Require Any Plan',
      description: (
        <Grid item container xs={12}>
          {!!planDetail?.requireAnyPlans?.length &&
            planDetail.requireAnyPlans.map((requireAnyPlan) => {
              return (
                <Grid item key={requireAnyPlan.id} xs={12}>
                  <BillyLink
                    linkProps={{
                      href: `/products/${requireAnyPlan.productId}/plans/${requireAnyPlan.id}`,
                    }}
                  >
                    <div>{requireAnyPlan.name}</div>
                  </BillyLink>
                </Grid>
              )
            })}
        </Grid>
      ),
      sm: 3,
      hide: !planDetail?.requireAnyPlans,
    },
    {
      label: 'Excluded Plans',
      description: (
        <Grid item container xs={12}>
          {!!planDetail?.exclusionPlans?.length &&
            planDetail.exclusionPlans.map((exclusionPlans) => {
              return (
                <Grid item key={exclusionPlans.id} xs={12}>
                  <BillyLink
                    linkProps={{
                      href: `/products/${exclusionPlans.productId}/plans/${exclusionPlans.id}`,
                    }}
                  >
                    <div>{exclusionPlans.name}</div>
                  </BillyLink>
                </Grid>
              )
            })}
        </Grid>
      ),
      sm: 3,
      hide: !planDetail?.exclusionPlans,
    },
  ]

  return (
    <>
      {planDetail && (
        <>
          <DialogTitle>
            <Grid display="flex" justifyContent={'space-between'} alignItems="center">
              <Typography className={classes.dialogTitle} component="p" fontWeight={600}>
                {'Plan Details'}
              </Typography>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid container className={classes.planHeader}>
                <Grid container spacing={1}>
                  {planDetailCells.map((cell, index) => (
                    <BillyGridCell
                      {...gridCellSize}
                      {...cell}
                      key={index}
                      order={getCellOrder(planDetailCells.length, index, cell.alwaysLastRow)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ActionButton
              buttonData={{
                label: 'Close',
                onClick: handleClose,
                color: 'primary',
              }}
            />
          </DialogActions>
        </>
      )}
    </>
  )
}

export default PlanDialogBody
