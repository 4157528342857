// eslint-disable-next-line @typescript-eslint/ban-types
type MutablePrimitive = undefined | null | boolean | string | number | Function

export type DeepMutable<T> = T extends MutablePrimitive
  ? T
  : T extends ReadonlyArray<infer U>
  ? MutableArray<U>
  : T extends ReadonlyMap<infer K, infer V>
  ? MutableMap<K, V>
  : T extends ReadonlySet<infer M>
  ? MutableSet<M>
  : MutableObject<T>

export type MutableArray<T> = Array<DeepMutable<T>>
export type MutableMap<K, V> = Map<DeepMutable<K>, DeepMutable<V>>
export type MutableSet<T> = Set<DeepMutable<T>>
export type MutableObject<T> = { -readonly [K in keyof T]: DeepMutable<T[K]> }

/**
 * Wrapper function that takes a parameterized type to allow us to cast
 * from T to DeepMutable<T> without accidentally casting to an incompatible
 * type since 'as' is a cast and can be dangerous.
 */
export function deepMutable<T>(instance: T): DeepMutable<T> {
  return instance as DeepMutable<T>
}
