import { useEditCustomFieldsDialog } from '@/components/CustomFields/EditCustomFieldsDialog'
import { useViewCustomFieldsDialog } from '@/components/CustomFields/ViewCustomFieldsDialog'
import ActionMenu from '@/components/menu/actionMenu'
import { CustomFieldParentType } from '@/generated/graphql'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type CustomFieldsMenuProps = {
  parentObjectId: string
  parentObjectType: CustomFieldParentType
  canEditCustomFields?: boolean
  onRefresh?: () => void
}

export function CustomFieldsMenu({
  parentObjectId,
  canEditCustomFields,
  parentObjectType,
  onRefresh,
}: CustomFieldsMenuProps) {
  const toggleEditCustomFieldsModal = useEditCustomFieldsDialog({
    parentObjectType,
    parentObjectId,
    onRefresh,
  })

  const toggleViewCustomFieldsModal = useViewCustomFieldsDialog({
    parentObjectType,
    parentObjectId,
  })

  return (
    <ActionMenu
      variant="icon-button"
      menuItems={[
        {
          children: 'Custom Fields',
          onClick: canEditCustomFields ? toggleEditCustomFieldsModal : toggleViewCustomFieldsModal,
        },
      ]}
    >
      <MoreVertIcon aria-label="more" />
    </ActionMenu>
  )
}
