import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback } from 'react'
import { TermEdited } from './IncludedTerms/IncludedTermDialog'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'

type DialogProps = WithModalParams & { onSubmit: () => void }

type RevertPredefinedTermDialog = {
  termDraft: TermEdited | undefined
  onSubmit?: (term: TermEdited) => void | Promise<void>
  onRevert?: (term: TermEdited) => void | Promise<void>
  onClose?: () => void
  refresh?: () => void
}

const RevertPredefinedTermDialog: React.FC<DialogProps> = ({ open, onClose, onSubmit }): JSX.Element => {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Revert Predefined Term</DialogTitle>
      <DialogContent dividers>
        Are you sure you want to revert all the changes done to the predefined term for this order?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit()
            onClose?.()
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useRevertPredefinedTermDialog = ({ termDraft, onRevert, onClose, refresh }: RevertPredefinedTermDialog) => {
  const [, , toggleModal] = useModalsContext()
  const key = 'revertPredefinedTerm'
  const errorHandler = useErrorHandler()
  const snackbarHandler = useSnackbarHandler()

  const handleSubmit = useCallback(() => {
    async function doAsync() {
      if (termDraft) {
        await onRevert?.(termDraft)
        onClose?.()
        refresh?.()
        snackbarHandler.successAlert('Reverted successfully')
      }
    }
    doAsync().catch((error) => {
      errorHandler(error)
    })
  }, [onRevert, termDraft, onClose, refresh, snackbarHandler, errorHandler])

  useModal({
    component: RevertPredefinedTermDialog,
    schema: {
      key,
      modalProps: { onSubmit: handleSubmit },
    },
  })

  return useCallback(() => {
    toggleModal(key)
  }, [toggleModal, key])
}

export default useRevertPredefinedTermDialog
