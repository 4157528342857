import React, { useRef } from 'react'
import { Box, Popover, Typography, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { formatUnixDate } from '../../../util/datetime/luxon/dateUtil'

type PurchaseOrders = Array<{
  __typename?: 'PurchaseOrder'
  purchaseOrderNumber: string
  originOrderId?: string | null
  addedOn: number
} | null>

const useStyles = makeStyles()((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    borderRadius: '4px',
  },
  textSecondary: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

export function PurchaseOrderPopover({ purchaseOrders }: { purchaseOrders: PurchaseOrders }) {
  const [openedPopover, setOpenedPopover] = React.useState<boolean>(false)
  const anchorEl = useRef(null)
  const { classes } = useStyles()

  const handlePopoverOpen = () => {
    setOpenedPopover(true)
  }

  const handlePopoverClose = () => {
    setOpenedPopover(false)
  }

  const purchaseOrderNumber = purchaseOrders[0]?.purchaseOrderNumber

  return (
    <>
      <Typography
        ref={anchorEl}
        sx={{ fontSize: 'inherit' }}
        aria-owns={openedPopover ? 'purchase-order-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        #{purchaseOrderNumber}
      </Typography>
      <Popover
        id="purchase-order-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{ onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose }}
      >
        <Box p={2}>
          <Typography mb={1} className={classes.textSecondary}>
            P.O. History
          </Typography>
          {purchaseOrders.map((purchaseOrder) => (
            <Box
              key={`${purchaseOrder?.purchaseOrderNumber}${purchaseOrder?.addedOn}${purchaseOrder?.purchaseOrderNumber}`}
              mb={1}
              display="flex"
              flexDirection="row"
            >
              <Typography mr={2}>{formatUnixDate(purchaseOrder?.addedOn)}</Typography>
              <Typography mr={2}>{purchaseOrder?.originOrderId}</Typography>
              <Typography>#{purchaseOrder?.purchaseOrderNumber}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export function PurchaseOrderCellContent({
  purchaseOrderNumber,
  purchaseOrderRequired,
  purchaseOrders,
}: {
  purchaseOrderNumber?: string | null
  purchaseOrderRequired: boolean | undefined | null
  purchaseOrders?: PurchaseOrders | null
}) {
  const theme = useTheme()
  if (purchaseOrders && purchaseOrders.length > 0) {
    return <PurchaseOrderPopover purchaseOrders={purchaseOrders} />
  } else if (purchaseOrderRequired && !purchaseOrderNumber) {
    return <Typography sx={{ color: theme.palette.error.main, fontSize: 'inherit' }}>Missing</Typography>
  } else if (purchaseOrderNumber) {
    return <>{'#' + purchaseOrderNumber}</>
  }
  return null
}
