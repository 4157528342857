import { MonogramAvatar } from '@/components/avatar/MonogramAvatar'
import { Theme, useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  avatar: {
    display: 'inline-flex',
    alignSelf: 'center',
    marginRight: theme.spacing(1.5),
  },
}))

export type BillyAvatarProps = {
  name: string
}

export default function BillyAvatar({ name }: BillyAvatarProps): JSX.Element {
  const theme = useTheme()

  const { classes } = useStyles()

  return (
    <span className={classes.avatar}>
      <MonogramAvatar name={name} variant="user" />
    </span>
  )
}
