import { getSizeByRatio } from '@/components/table/baseTable'
import { LineItemGroupCell } from '@/pageComponents/orders/LineItemsEditTable/Cells/LineItemGroupCell'
import { CustomColumnEditField } from '@/pageComponents/orders/LineItemsEditTable/CustomColumn/CustomColumnEditField'
import { useLineItemTableContext } from '@/pageComponents/orders/LineItemsEditTable/LineItemEditContextProvider'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import { useLineItemCustomFieldColumns } from '@/pageComponents/orders/LineItemsEditTable/useLineItemCustomFieldColumns'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { Box } from '@mui/system'
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, GridValidRowModel } from '@mui/x-data-grid-pro'
import { memo, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { Charge, ChargeModel, ChargeType, Feature } from '../../../generated/graphql'
import { CommonOrderFormPageState } from '../EditOrderPage/CommonOrderFormPageState'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { ChargeRowQuantityCell } from './Cells/ChargeRowQuantityCell'
import { LineItemEditCurrencyCell } from './Cells/LineItemEditCurrencyCell'
import { LineItemEditDiscountCell } from './Cells/LineItemEditDiscountCell'
import { LineItemEditSellUnitPriceCell } from './Cells/LineItemEditSellUnitPriceCell'
import { LineItemListPriceCell } from './Cells/LineItemListPriceCell'
import { LineItemsActionMenuCell } from './Cells/LineItemsActionMenuCell'
import { LineItemsNameCell } from './Cells/LineItemsNameCell'
import { LineItemsTableOrderType } from './LineItemsEditTable'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

export const useOrderChargeRowStyles = makeStyles()((theme: Theme) => ({
  colShrink: {
    width: 0,
    whiteSpace: 'nowrap',
  },
  fieldShrink: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 120,
  },
  total: {
    minWidth: 120,
  },
  fieldShrinkSmaller: {
    minWidth: 80,
  },
  chargeDate: {
    fontSize: theme.typography.fontSize - 2,
    fontWeight: 700,
  },
  removedRow: {
    '& *': {
      color: theme.palette.text.disabled,
    },
  },
  numberCell: {
    textAlign: 'right',
  },
  currencyCell: {
    textAlign: 'right',
  },
  strikethrough: {
    textDecoration: 'line-through',
    color: 'GrayText',
    textAlign: 'right',
  },
  overrideRatio: {
    color: 'GrayText',
    textAlign: 'right',
  },
}))

export type OrderItemRowProps = {
  lineItemIndex: number
  orderType: LineItemsTableOrderType
  currency?: string | null
}

export function makeRampDisabledExplanation(
  isRamp: boolean,
  startDate: number,
  charge: Pick<Charge, 'type'>,
  rampIntervalLength: number,
  isOnlyInstanceOfCharge: boolean,
  orderType: LineItemsTableOrderType
): string {
  let rampDisabledExplanation = ''
  if (isRamp) {
    rampDisabledExplanation = 'This charge is already ramped'
  }
  if (orderType !== 'AMENDMENT') {
    if (rampIntervalLength === 0) {
      rampDisabledExplanation = 'Ramp intervals not enabled'
    } else if (rampIntervalLength < 2) {
      rampDisabledExplanation = 'Not enough intervals to ramp'
    }
  }
  if (!startDate) {
    rampDisabledExplanation = 'Subscription Start Date must be set'
  }
  if (charge.type === 'ONE_TIME') {
    rampDisabledExplanation = 'One time charges cannot be ramped'
  }
  if (charge.type === 'USAGE') {
    rampDisabledExplanation = 'Usage charges cannot be ramped'
  }
  if (!isOnlyInstanceOfCharge) {
    rampDisabledExplanation = 'Cannot apply ramp if multiple instances of a charge exist'
  }
  return rampDisabledExplanation
}

export function chargeHasNoQuantity(charge: Pick<Charge, 'type'>): boolean {
  return charge.type === ChargeType.Usage
}

export function chargeQuantityEditable(
  charge: Pick<Charge, 'minQuantity' | 'maxQuantity' | 'defaultQuantity' | 'type' | 'chargeModel'>
): boolean {
  const isFixedQuantity =
    charge.minQuantity != null &&
    charge.minQuantity === charge.maxQuantity &&
    charge.minQuantity === charge.defaultQuantity
  return charge.type !== ChargeType.Usage && charge.chargeModel !== ChargeModel.FlatFee && !isFixedQuantity
}

export function ChargeRowEditIconButton({
  tooltipTitle,
  label,
  onClick,
  disabled,
}: {
  tooltipTitle?: string
  label: string
  onClick?: () => void
  disabled?: boolean
}) {
  return (
    <Box
      sx={{
        mr: '-40px',
        ml: '0px',
      }}
    >
      <Tooltip title={tooltipTitle}>
        <span>
          <IconButton disabled={disabled} aria-label={label} onClick={onClick}>
            <EditIcon color={disabled ? 'disabled' : 'primary'} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  )
}

const OrderChargeRow = memo(function OrderChargeRowWithoutMemo<F extends CommonOrderFormPageState = NewOrderFormData>({
  orderType,
  lineItemIndex,
}: OrderItemRowProps): JSX.Element {
  const { classes, cx } = useOrderChargeRowStyles()

  const jotaiForm = useJotaiFormContext<F>()
  const { currency } = useLineItemTableContext()

  const { showRemovedRowStyle } = jotaiForm.useSelect(
    useCallback(
      (form) =>
        reduceLineItem({
          orderDetail: form.orderDetail,
          lineItemIndex,
          orderType,
          currency,
        }),
      [lineItemIndex, orderType, currency]
    )
  )

  return (
    <TableRow className={cx(showRemovedRowStyle ? classes.removedRow : undefined)} aria-label="lineItem-edit-row">
      <TableCell style={{ paddingLeft: 48 }}>
        <LineItemsNameCell currency={currency} lineItemIndex={lineItemIndex} orderType={orderType} />
      </TableCell>

      <CustomColumnEditCells<F> lineItemIndex={lineItemIndex} CellWrapper={TableCell} />
      <TableCell className={cx(classes.colShrink, classes.numberCell, 'order-charge-row--quantity')}>
        <ChargeRowQuantityCell currency={currency} lineItemIndex={lineItemIndex} orderType={orderType} />
      </TableCell>

      <TableCell className={cx(classes.colShrink, classes.currencyCell)}>
        <LineItemListPriceCell lineItemIndex={lineItemIndex} orderType={orderType} currency={currency} />
      </TableCell>
      <TableCell className={cx(classes.colShrink, classes.currencyCell)} aria-label="lineItem-edit-discount-cell">
        <div className={classes.total}>
          <LineItemEditDiscountCell<F> lineItemIndex={lineItemIndex} orderType={orderType} currency={currency} />
        </div>
      </TableCell>
      <TableCell className={cx(classes.colShrink, classes.currencyCell)}>
        <div className={classes.total}>
          <LineItemEditSellUnitPriceCell<F> lineItemIndex={lineItemIndex} orderType={orderType} currency={currency} />
        </div>
      </TableCell>
      <TableCell className={cx(classes.colShrink, classes.currencyCell)}>
        <div className={classes.total}>
          <LineItemEditCurrencyCell<F> lineItemIndex={lineItemIndex} orderType={orderType} currency={currency} />
        </div>
      </TableCell>
      <TableCell className={classes.colShrink}>
        <LineItemsActionMenuCell currency={currency} lineItemIndex={lineItemIndex} orderType={orderType} />
      </TableCell>
    </TableRow>
  )
})

export default OrderChargeRow

const DefaultCellWrapper = (props: { children?: JSX.Element }) => <>{props.children}</>

function CustomColumnEditCells<F extends CommonOrderFormPageState = NewOrderFormData>({
  lineItemIndex,
  ...props
}: {
  lineItemIndex: number
  CellWrapper?: (props: { children?: JSX.Element }) => JSX.Element
}) {
  const jotaiForm = useJotaiFormContext<F>()
  const customColumnProps = useLineItemCustomFieldColumns(jotaiForm, lineItemIndex)

  const CellWrapper = props.CellWrapper || DefaultCellWrapper
  return (
    <>
      {customColumnProps.definitions.map((definition, index) => (
        <CellWrapper key={`custom-column-field-${index}`}>
          <CustomColumnEditField<F>
            definition={definition}
            lineItemIndex={lineItemIndex}
            atomSelector={(form) =>
              form.orderDetail.lineItems[lineItemIndex]?.customFields?.find((customField) => {
                return customField?.name === definition.fieldName
              })
            }
          />
        </CellWrapper>
      ))}
    </>
  )
}

export type ChargeRowColDefProps = {
  id: string
  lineItemIndex: number
  orderType: LineItemsTableOrderType
  currency?: string | null
}
export function useCustomGridColDefs<F extends CommonOrderFormPageState = NewOrderFormData>() {
  const jotaiForm = useJotaiFormContext<F>()
  const customColumnProps = useLineItemCustomFieldColumns(jotaiForm, 0)
  return customColumnProps.definitions.map((definition, index) => {
    const colDef: GridColDef<ChargeRowColDefProps> = {
      field: definition.fieldName,
      headerName: definition.name,
      ...getSizeByRatio(1),
      renderCell: ({ row }) => (
        <CustomColumnEditField<F>
          definition={definition}
          key={`custom-column-field-${index}`}
          lineItemIndex={row.lineItemIndex}
          atomSelector={(form) =>
            form.orderDetail.lineItems[row.lineItemIndex]?.customFields?.find((customField) => {
              return customField?.name === definition.fieldName
            })
          }
        />
      ),
    }
    return colDef
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withColDefParams<R extends GridValidRowModel = any>(Component: (props: R) => JSX.Element) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const RenderCell = ({ row }: GridRenderCellParams<R, any, any, GridTreeNodeWithRender>) => <Component {...row} />
  return RenderCell
}

export function useCustomGridColDefsForLineItem<F extends CommonOrderFormPageState = NewOrderFormData>() {
  const customCofDefs = useCustomGridColDefs<F>()
  const isGroupEnabled = useDynamicFeatureFlag(Feature.OrderLinePlanGrouping)
  const OrderChargeColumns: (GridColDef<ChargeRowColDefProps> & { hidden?: boolean })[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      ...getSizeByRatio(3),
      renderCell: withColDefParams(LineItemsNameCell),
      sortable: false,
    },
    ...customCofDefs,
    //add overflow setting for fields that support pencil icons
    ///src/styles/commonTheme.ts
    {
      field: 'itemGroupId',
      headerName: 'Group',
      headerAlign: 'left',
      align: 'left',
      ...getSizeByRatio(1),
      renderCell: withColDefParams(LineItemGroupCell),
      hidden: !isGroupEnabled,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      headerAlign: 'right',
      align: 'right',
      ...getSizeByRatio(1),
      renderCell: withColDefParams(ChargeRowQuantityCell),
      sortable: false,
      hideable: false,
    },
    {
      field: 'listPrice',
      headerName: 'List Total (List Unit Price)',
      headerAlign: 'right',
      align: 'right',
      ...getSizeByRatio(1.5),
      renderCell: withColDefParams(LineItemListPriceCell),
      sortable: false,
    },
    {
      field: 'discount',
      headerName: 'Discount Total (Discount Per Unit)',
      headerAlign: 'right',
      align: 'right',
      ...getSizeByRatio(2),
      renderCell: withColDefParams(LineItemEditDiscountCell),
      sortable: false,
    },
    {
      field: 'sellUnitPrice',
      headerName: 'Sell Price',
      headerAlign: 'right',
      align: 'right',
      ...getSizeByRatio(1),
      renderCell: withColDefParams(LineItemEditSellUnitPriceCell),
      sortable: false,
    },
    {
      field: 'total',
      headerName: 'Total (Annual Total)',
      headerAlign: 'right',
      align: 'right',
      ...getSizeByRatio(1.5),
      renderCell: withColDefParams(LineItemEditCurrencyCell),
      sortable: false,
    },
    {
      field: 'Actions',
      headerName: '',
      align: 'left',
      ...getSizeByRatio(0.25),
      renderCell: withColDefParams(LineItemsActionMenuCell),
      sortable: false,
    },
  ]

  return OrderChargeColumns.filter((col) => !col.hidden)
}
