import { useTenantTimeZone } from '@/components/UserTenantSessionProvider/useTenantTimeZone'
import { unixToLuxonWithTz } from '@/util/datetime/luxon/dateUtil'
import { Grid } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import { z } from 'zod'
import { BaseDateFieldProps, BillyMuiDateField } from '../../input/JotaiMuiDateField'
import { LayoutProps } from '../BillyTsForm'

export type DateFieldProps = BaseDateFieldProps & LayoutProps

export const DateSchema = createUniqueFieldSchema(z.number(), 'date')
export const OptionalEndOfDateFieldSchema = createUniqueFieldSchema(z.number().optional(), 'optional-end-of-date')
export const OptionalDateSchema = createUniqueFieldSchema(z.number().optional(), 'optional-date')

export function DateField({ layout, hidden, ...dateFieldProps }: DateFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<number>()
  const { label } = useDescription()

  return hidden ? (
    <></>
  ) : (
    <Grid container item xs {...layout}>
      <BillyMuiDateField
        onChange={onChange}
        value={value}
        error={error?.errorMessage}
        disabledExplanation={dateFieldProps.disabledExplanation}
        disabled={isLoading || isSubmitting || dateFieldProps.disabled}
        datePickerProps={{
          label,
          ...dateFieldProps.datePickerProps,
        }}
      />
    </Grid>
  )
}

export function EndOfDateField({ layout, hidden, ...dateFieldProps }: DateFieldProps) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<number>()
  const { label } = useDescription()
  const tenantTZ = useTenantTimeZone()
  return hidden ? (
    <></>
  ) : (
    <Grid container item xs {...layout}>
      <BillyMuiDateField
        onChange={(unixtime) => {
          onChange(unixToLuxonWithTz(unixtime, tenantTZ).endOf('day').toUnixInteger())
        }}
        value={value}
        error={error?.errorMessage}
        disabledExplanation={dateFieldProps.disabledExplanation}
        disabled={isLoading || isSubmitting || dateFieldProps.disabled}
        datePickerProps={{
          label,
          ...dateFieldProps.datePickerProps,
        }}
      />
    </Grid>
  )
}
