import { getDiscountRatio } from '@/util/currencyUtil'
import Big from 'big.js'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { OrderType } from '../../../generated/graphql'
import NewOrderPageDrawer from '../NewOrderPageDrawer'
import RenewalOrderPageDrawer from '../RenewalOrderPageDrawer'
import { NewOrderFormData } from './NewOrderPage'
import { CommonOrderFormPageState } from './CommonOrderFormPageState'

export function NewOrderRightDrawer() {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { orderDetail, orderType, discount } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      const orderDetail = form.orderDetail
      return {
        orderDetail: {
          currency: orderDetail.currency,
          totalAmount: orderDetail.totalAmount,
          totalListAmount: orderDetail.totalListAmount,
          orderMetrics: orderDetail.orderMetrics,
          resoldBy: orderDetail.resoldBy,
          renewalForSubscription: orderDetail.renewalForSubscription,
          recurringDiscountPercent: orderDetail.recurringDiscountPercent,
          nonRecurringDiscountPercent: orderDetail.nonRecurringDiscountPercent,
        },
        orderType: form.orderDetail.orderType,
        discount: getDiscountRatio(form.orderDetail.totalAmount ?? 0, form.orderDetail.totalListAmount ?? 0) ?? Big(0),
      }
    }, [])
  )

  if (orderType === OrderType.Renewal) {
    return <RenewalOrderPageDrawer orderDetail={orderDetail} discount={discount} />
  }

  return <NewOrderPageDrawer orderDetail={orderDetail} discount={discount} />
}
