import { DocumentNode } from 'graphql'
import { useCallback, useEffect } from 'react'
import {
  AnyVariables,
  OperationContext,
  OperationResult,
  TypedDocumentNode,
  useMutation,
  useQuery,
  UseQueryArgs as UrqlUseQueryArgs,
  UseQueryResponse,
} from 'urql'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { useAsyncLoadingNotifier } from '../AsyncLoadingIndicator/AsyncLoadingIndicatorProvider'
import { UseQueryState } from 'urql/dist/types/hooks/useQuery'
import { UseMutationState } from 'urql/dist/types/hooks/useMutation'
import { CombinedError } from '@urql/core/dist/types/utils/error'
import buildLogger from '@/util/logger'

type ExtraParams = {
  handleError?: boolean
  handleLoading?: boolean
}

const logger = buildLogger('billyUrql')

export type UseQueryArgs<Variables extends AnyVariables = AnyVariables> = UrqlUseQueryArgs<Variables> & ExtraParams

export type UseQueryResponseWithoutError<Data, Variables extends AnyVariables = AnyVariables> = [
  Omit<UseQueryState<Data, Variables>, 'error'>,
  (opts?: Partial<OperationContext>) => void
]

/**
 * Custom type for useQuery so that we can optionally omit the 'error' to track
 * down/block code that might be incorrectly handling errors.
 */
export type UseQueryResponseBilly<Data, Variables extends AnyVariables> = UseQueryResponse<Data, Variables>
// export type UseQueryResponseBilly<Data, Variables extends AnyVariables> = UseQueryResponseWithoutError<Data, Variables>

function useQueryBilly<Data, Variables extends AnyVariables = AnyVariables>(
  useQueryArgs: UseQueryArgs<Variables>
): UseQueryResponseBilly<Data, Variables> {
  const { handleError = false, handleLoading = true } = useQueryArgs
  const [queryResponse, refreshQuery] = useQuery(useQueryArgs)

  const errorHandler = useErrorHandler()
  useEffect(() => {
    if (handleError && queryResponse?.error?.message && queryResponse?.error?.message !== '') {
      errorHandler(queryResponse.error)
    }
  }, [queryResponse, handleError, errorHandler])

  const { showLoading, hideLoading } = useAsyncLoadingNotifier()

  useEffect(() => {
    if (handleLoading) {
      if (queryResponse.fetching) {
        showLoading()
      } else if (!queryResponse.stale) {
        hideLoading()
      }
    }
  }, [handleLoading, queryResponse.fetching, queryResponse.stale, showLoading, hideLoading])

  return [queryResponse, refreshQuery]
}

// OperationResult without the error for tracking down error issues.
export type OperationResultBilly<Data, Variables extends AnyVariables> = Omit<OperationResult<Data, Variables>, 'error'>

export type UseMutationStateBilly<Data, Variables extends AnyVariables> = {
  /**
   * @deprecated Do not use as we will be removing in the future but this is
   * still used by some legacy code.
   */
  readonly error?: CombinedError
} & Omit<UseMutationState<Data, Variables>, 'error'>

export declare type UseMutationResponseWithoutError<Data, Variables extends AnyVariables> = [
  UseMutationStateBilly<Data, Variables>,
  (
    variables: Variables,
    context?: Partial<OperationContext>
  ) => Promise<Omit<OperationResultBilly<Data, Variables>, 'error'>>
]

function useMutationBilly<Data, Variables extends AnyVariables = AnyVariables>(
  query: DocumentNode | TypedDocumentNode<Data, Variables> | string
): UseMutationResponseWithoutError<Data, Variables> {
  const [mutationResponse, mutate] = useMutation(query)

  const { hideLoading, showLoading } = useAsyncLoadingNotifier()

  const mutateAsync = useCallback(
    async (variables: Variables, context?: Partial<OperationContext> | undefined) => {
      try {
        showLoading()

        const response = await mutate(variables, context)

        if (response.error?.message && response.error?.message !== '') {
          throw response.error
        }

        return response
      } finally {
        hideLoading()
      }
    },
    [hideLoading, showLoading, mutate]
  )

  return [mutationResponse, mutateAsync]
}

export * from 'urql'
export { useQueryBilly as useQuery }
export { useMutationBilly as useMutation }
