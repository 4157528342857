import ActionMenu, { CustomMenuItemProps } from '@/components/menu/actionMenu'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, ButtonGroup, ButtonProps, Stepper as MuiStepper, Step, StepLabel, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { ReactElement } from 'react'
import buildLogger from '../../util/logger'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import ActionButton from '../button/actionButton'
const logger = buildLogger('StepperFormProvider')
export type StepperFormCallbackHandler = (event?: React.MouseEvent) => void
type AsyncStepperFormCallbackHandler = (event?: React.MouseEvent) => Promise<void>

export type Step = {
  id: string
  label: string
  onNext?: StepperFormCallbackHandler | AsyncStepperFormCallbackHandler
  onBack?: StepperFormCallbackHandler | AsyncStepperFormCallbackHandler
  optional?: boolean
  children?: React.ReactNode | ((props: WithStepperFormProps & { stepIndex: number }) => React.ReactNode)
}
export type StepperFormRootProps = {
  steps: Step[]
  /**
   * stepper form children that which referenced by id
   */
  children?: React.ReactNode | ((props: StepperFormChildren) => React.ReactNode)
}

export type StepperFormChildren = {
  stepperFormProps?: WithStepperFormProps
}

export type WithStepperFormProps = {
  activeId: string
  isLoading: boolean
  activeStepIndex: number
  steps: Step[]
  isStepOptional: (index: number) => boolean
  isStepSkipped: (index: number) => boolean
  handleBack: StepperFormCallbackHandler
  handleNext: StepperFormCallbackHandler
  handleReset: StepperFormCallbackHandler
  handleSkip: StepperFormCallbackHandler
}

function useStepperFormProvider(steps: Step[]) {
  const [activeStepIndex, setActiveStepIndex] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const errorHandler = useErrorHandler()
  const [isLoading, setIsLoading] = React.useState(false)

  const isStepOptional = (index: number) => {
    return !!steps[index]?.optional
  }

  const isStepSkipped = (index: number) => {
    return skipped.has(index)
  }

  const handleNext = (event?: React.MouseEvent) => {
    let newSkipped = skipped
    if (isStepSkipped(activeStepIndex)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStepIndex)
    }

    async function handleNextAsync(event?: React.MouseEvent) {
      try {
        setIsLoading(true)
        await steps[activeStepIndex]?.onNext?.(event)
        setActiveStepIndex((prevActiveStep) => {
          return steps.length === prevActiveStep + 1 ? prevActiveStep : prevActiveStep + 1
        })
        setSkipped(newSkipped)
      } finally {
        setIsLoading(false)
      }
    }
    handleNextAsync(event).catch(errorHandler)
  }

  const handleBack = (event?: React.MouseEvent) => {
    async function handleNextAsync(event?: React.MouseEvent) {
      try {
        setIsLoading(true)
        await steps[activeStepIndex]?.onBack?.(event)
        setActiveStepIndex((prevActiveStep) => prevActiveStep - 1)
      } finally {
        setIsLoading(false)
      }
    }
    handleNextAsync(event).catch(errorHandler)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStepIndex)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStepIndex)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStepIndex(0)
  }

  const restContext = {
    steps,
    isLoading,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    activeStepIndex,
    activeId: steps[activeStepIndex].id,
  }
  const stepsWithContext = steps.map((step, index) => ({
    ...step,
    children: (
      <WithFunctionalChildren<WithStepperFormProps & { stepIndex: number }>
        props={{
          ...restContext,
          stepIndex: index,
        }}
      >
        {step.children}
      </WithFunctionalChildren>
    ),
  }))

  return {
    ...restContext,
    steps: stepsWithContext,
  }
}

const DEFAULT_STEPPER_FORM_PROPS = {
  activeId: '',
  activeStepIndex: 0,
  steps: [],
  isStepOptional: () => false,
  isStepSkipped: () => false,
  handleNext: () => {
    console.warn('handleNext is not implemented')
  },
  handleBack: () => {
    console.warn('handleBack is not implemented')
  },
  handleSkip: () => {
    console.warn('handleSkip is not implemented')
  },
  handleReset: () => {
    console.warn('handleReset is not implemented')
  },
  isLoading: false,
}
// type guard
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTypeOfStepperFormChildren(children: ReactElement<any>): children is React.ReactElement<StepperFormChildren> {
  return children.type === Stepper || children.type === Action || children.type === Content
}

function Action({
  stepperFormProps = DEFAULT_STEPPER_FORM_PROPS,
  submitButtonProps,
  moreButtons,
}: StepperFormChildren & {
  submitButtonProps?: ButtonProps
  moreButtons?: CustomMenuItemProps[]
}) {
  const { activeStepIndex, steps, handleBack, handleNext, handleSkip, isStepOptional, isLoading } = stepperFormProps
  const moreButtonItems = moreButtons?.filter((button) => !button.hide) ?? []

  return activeStepIndex === steps.length ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {isStepOptional(activeStepIndex) && (
          <ActionButton
            buttonData={{
              label: 'Skip',
              onClick: handleSkip,
              loading: isLoading,
              color: 'inherit',
              buttonProps: { variant: 'outlined', style: { marginLeft: 0 } },
            }}
          />
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        <ActionButton
          buttonData={{
            label: 'Back',
            onClick: handleBack,
            loading: isLoading,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
            disabledExplanation: activeStepIndex === 0 ? 'You are at the first step' : undefined,
          }}
        />

        <ButtonGroup variant="contained" sx={{ ml: 1 }}>
          {!!moreButtonItems?.length && (
            <ActionMenu
              menuItems={moreButtonItems}
              buttonProps={{
                size: 'small',
              }}
            >
              <ArrowDropDown />
            </ActionMenu>
          )}
          <Button
            color="primary"
            onClick={submitButtonProps?.type === 'submit' ? undefined : handleNext}
            disabled={isLoading}
            {...submitButtonProps}
          >
            {activeStepIndex === steps.length - 1 ? 'Save' : 'Next'}
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  )
}

function Stepper({ stepperFormProps = DEFAULT_STEPPER_FORM_PROPS }: StepperFormChildren) {
  const { activeStepIndex, steps, isStepOptional, isStepSkipped } = stepperFormProps
  return (
    <Box sx={{ width: '100%' }}>
      <MuiStepper activeStep={activeStepIndex} sx={{ padding: 0 }}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={step.id} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          )
        })}
      </MuiStepper>
    </Box>
  )
}

function Content({ stepperFormProps = DEFAULT_STEPPER_FORM_PROPS }: StepperFormChildren) {
  const { activeStepIndex, steps } = stepperFormProps
  return (
    <>
      {steps.map((step, index) => (
        <Box sx={{ width: '100%' }} hidden={index !== activeStepIndex} key={index}>
          <>{step.children}</>
        </Box>
      ))}
    </>
  )
}

function Form({ steps, children }: StepperFormRootProps): JSX.Element {
  const stepperFormProps = useStepperFormProvider(steps)
  return (
    <WithFunctionalChildren<{
      stepperFormProps: typeof stepperFormProps
    }>
      props={{ stepperFormProps }}
    >
      {children}
    </WithFunctionalChildren>
  )
}

export const BillyStepperForm = { Form, Stepper, Action, Content }

// HOC function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function WithFunctionalChildren<T extends Record<string, any>>({
  children,
  props,
}: {
  children?: React.ReactNode | ((props: T) => React.ReactNode)
  props: T
}) {
  const isChildrenFunction = typeof children === 'function'
  return (
    <div>
      {isChildrenFunction && children({ ...props })}
      {!isChildrenFunction &&
        React.Children.map(React.Children.toArray(children), (child) => {
          if (React.isValidElement(child)) {
            if (isTypeOfStepperFormChildren(child)) {
              return React.cloneElement(child as React.ReactElement<StepperFormChildren>, { ...props })
            } else {
              return child
            }
          }
        })}
    </div>
  )
}
