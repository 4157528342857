import { billableCurrencyFormat } from '@/util/currencyUtil'
import React from 'react'

type CurrencyContextType = {
  currency: string | undefined | null
  formatter: (amount: number | undefined | null) => string
}
const CurrencyContext = React.createContext<CurrencyContextType>({
  currency: undefined,
  formatter: (value) => billableCurrencyFormat({ value }),
})

export const useCurrencyContext = () => {
  const { currency, formatter } = React.useContext(CurrencyContext)
  return [currency, formatter] as [string | undefined | null, (amount: number | undefined | null) => string]
}

export const CurrencyProvider = ({
  children,
  currency,
}: {
  children: React.ReactNode
  currency: string | undefined | null
}) => {
  return (
    <CurrencyContext.Provider
      value={{
        currency,
        formatter: (value) => billableCurrencyFormat({ currency, value }),
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}
