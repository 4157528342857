import { IconButton, TableCell, TableRow } from '@mui/material'
import { BaseTableProps, BillyColumn, ColumnSort, useStyles } from './baseTable'
import { HeaderGroup } from 'react-table'
import { North, South } from '@mui/icons-material'

const sortIcon = (field: string, columnSort?: ColumnSort) => {
  const iconStyle = { color: columnSort?.key === field ? 'ButtonText' : 'GrayText', height: '14px', width: '14px' }
  return (
    <>
      {columnSort?.order === 'asc' || columnSort?.key !== field ? (
        <North style={iconStyle} />
      ) : (
        <South style={iconStyle} />
      )}
    </>
  )
}

const BaseTableHeader = <DataType extends object>({
  headerGroup,
  isRowClick,
  columnSort,
  onChangeColumnSort,
}: {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  headerGroup: HeaderGroup<any>
  isRowClick: boolean
  columnSort?: BaseTableProps<DataType>['columnSort']
  onChangeColumnSort?: BaseTableProps<DataType>['onChangeColumnSort']
}) => {
  const headerGroupProps = headerGroup.getHeaderGroupProps()
  const { classes, cx } = useStyles({ isRowClick })

  const onSortIconClick = (field: string) => {
    if (onChangeColumnSort) onChangeColumnSort(field, columnSort?.order === 'asc' ? 'desc' : 'asc')
  }

  return (
    <TableRow {...headerGroupProps} key={headerGroupProps.key}>
      {headerGroup.headers
        .filter((header: BillyColumn) => !header.hidden)
        .map((column) => {
          const headerProps = column.getHeaderProps()
          const billyColumn: BillyColumn = column
          const shouldAlignRight =
            billyColumn.dataType === 'number' ||
            billyColumn.dataType === 'currency' ||
            billyColumn.dataType === 'datetime' ||
            billyColumn.dataType === 'quantity'
          return (
            <TableCell
              {...headerProps}
              key={headerProps.key}
              className={cx(classes.headerCell, shouldAlignRight && classes.textAlignRight)}
            >
              {column.render('Header')}
              {billyColumn.sort && (
                <IconButton
                  sx={{
                    marginLeft: '2px',
                    padding: '5px',
                  }}
                  onClick={() => onSortIconClick(billyColumn.id)}
                >
                  {sortIcon(billyColumn.id, columnSort)}
                </IconButton>
              )}
            </TableCell>
          )
        })}
    </TableRow>
  )
}

export default BaseTableHeader
