import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import { setWasAtUrl } from '@/util/cookieUtil'
import { useRouter } from 'next/router'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'

type DocusignRefreshTokenExpiredDialogProps = {
  redirectUri?: string
}

type DialogProps = WithModalParams & DocusignRefreshTokenExpiredDialogProps
const formID = 'DocusignRefreshTokenExpiredDialog'
function DocusignRefreshTokenExpiredDialog({ open, onClose, redirectUri }: DialogProps): JSX.Element {
  const router = useRouter()
  const errorHandler = useErrorHandler()

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Authentication Expired</DialogTitle>
      <DialogContent dividers>
        <Typography>
          We noticed that your authentication has expired. Please click the button below to proceed to DocuSign and
          re-authenticate.
        </Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Re-Authenticate'}
          buttonData={{
            label: `Re-Authenticate`,
            onClick: () => {
              setWasAtUrl()
              router.replace(redirectUri ?? '/').catch(errorHandler)
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useDocusignRefreshTokenExpiredDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()
  const [redirectUri, setRedirectUri] = useState<string>('')

  useModal<DialogProps>({
    component: DocusignRefreshTokenExpiredDialog,
    schema: {
      key: formID,
      modalProps: {
        redirectUri,
        ...modalProps,
      },
    },
  })
  const toggleDocusignRefreshTokenExpiredDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
  return { toggleDocusignRefreshTokenExpiredDialog, setRedirectUri }
}
