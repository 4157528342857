import { Autocomplete, InputAdornment, TextField, createFilterOptions } from '@mui/material'
import React, { MutableRefObject, useEffect, useState } from 'react'
import { EsSearchOption } from '../../components/input/JotaiMuiEsAutocomplete'
import buildLogger from '../../util/logger'
import { getPaymentTermInputValue } from '../../util/paymentTermUtil'

const logger = buildLogger('CustomPaymentSelect')

type PaymentTermOption = EsSearchOption & {
  inputValue?: string
}
export type PaymentTermSelectProps = {
  options: readonly PaymentTermOption[]
  onChange?: (option: PaymentTermOption) => void
  custom?: boolean
  defaultOptionId?: string
  error?: string
}

export const PaymentTermSelect: React.FC<React.PropsWithChildren<PaymentTermSelectProps>> = React.forwardRef(
  function PaymentTermSelectWithRef({ options, onChange, defaultOptionId, custom: isCustom, error }, ref) {
    const refObj = ref as MutableRefObject<HTMLDivElement>
    const [inputValue, setInputValue] = useState('')
    const [selected, setSelected] = useState('')
    const shouldClearInputOnBlur = !options
      .map((option) => getPaymentTermInputValue(option?.id))
      .concat([selected])
      .includes(inputValue)
    useEffect(() => {
      if (error) {
        refObj?.current?.focus()
      }
    }, [error, refObj])
    useEffect(() => {
      const newInputValue = getPaymentTermInputValue(defaultOptionId)
      setInputValue(newInputValue)
      setSelected(newInputValue)
    }, [defaultOptionId])
    return (
      <Autocomplete
        freeSolo
        disableClearable
        autoHighlight
        aria-label="Payment Term Select"
        options={options}
        onBlur={() => {
          if (shouldClearInputOnBlur) {
            setInputValue('')
            onChange?.({ id: '', label: '' })
          }
        }}
        onChange={(event, option) => {
          if (typeof option === 'string') {
            setInputValue(option)
            setSelected(option)
            onChange?.({ id: `NET${option}`, label: option })
          } else {
            const newInputValue = getPaymentTermInputValue(option.id)
            setInputValue(newInputValue)
            setSelected(newInputValue)
            onChange?.(option)
          }
        }}
        value={inputValue}
        filterOptions={(options, params) => {
          const filter = createFilterOptions<PaymentTermOption>()
          const filtered = filter(options, params)
          const { inputValue } = params
          const isExisting = filtered.some((option) => `NET${inputValue}` === option.id)
          const shouldHaveCustom = isCustom && inputValue !== '' && !isExisting
          return shouldHaveCustom
            ? [...filtered, { id: `NET${inputValue}`, inputValue, label: `Select Net ${inputValue}` }]
            : filtered
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.label
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={ref}
            error={!!error}
            helperText={error}
            label="Payment Term"
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              'aria-label': 'Payment Term Search Input',
              role: 'textbox',
              type: 'number',
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  if (!isCustom) e.stopPropagation()
                }
              },
              startAdornment: (
                //TODO: compensate for padding of input container and textInput to get 14px and 10px padding, can we refactor to use theme?
                <InputAdornment sx={{ ml: '5px', mr: 0.5 }} position="start">
                  Net
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    )
  }
)
