import { BillyTsForm } from '@/components/BillyTSForm/BillyTsForm'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import ActionButton from '@/components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { zodResolver } from '@hookform/resolvers/zod'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const LineItemsTableNewTabDialogSchema = z.object({
  id: z.string().describe('Group Item ID'),
  label: z.string().describe('Group Label').optional(),
})

type LineItemsTableNewTabDialogProps = {
  onSubmit?: (data: z.infer<typeof LineItemsTableNewTabDialogSchema>) => Promise<void> | void
  tabIds?: string[]
}

type DialogProps = WithModalParams & LineItemsTableNewTabDialogProps
const formID = 'LineItemsTableNewTabDialog'
function LineItemsTableNewTabDialog({ open, onClose, onSubmit, tabIds }: DialogProps) {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm<z.infer<typeof LineItemsTableNewTabDialogSchema>>({
    resolver: zodResolver(LineItemsTableNewTabDialogSchema),
  })

  useEffect(() => {
    if (open) {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onFormSubmit = (data: z.infer<typeof LineItemsTableNewTabDialogSchema>) => {
    async function doAsync() {
      if (tabIds?.includes(data.id)) {
        throw new Error('Group ID already exists')
      }
      setIsSubmitting(true)
      await onSubmit?.(data)
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  const handleSubmitCallback = async () => await form.handleSubmit(onFormSubmit)()

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>New Item Group</DialogTitle>
      <DialogContent dividers>
        <BillyTsForm
          form={form}
          formProps={{ id: formID }}
          onSubmit={handleSubmitCallback}
          schema={LineItemsTableNewTabDialogSchema}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Save'}
          buttonData={{
            label: 'Save',
            loading: isSubmitting,
            buttonProps: { type: 'submit', form: formID },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useLineItemsTableNewTabDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: LineItemsTableNewTabDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  const toggleLineItemsTableNewTabDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleLineItemsTableNewTabDialog]
}
