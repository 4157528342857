import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LicenseInfo } from '@mui/x-license-pro'
import { Provider } from 'jotai'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'
import * as yup from 'yup'
import 'github-markdown-css/github-markdown.css'
import 'react-quill/dist/quill.snow.css'

import AppAuthRouter from '../components/AppAuthRouter/AppAuthRouter'
import { AsyncLoadingIndicatorProvider } from '../components/AsyncLoadingIndicator/AsyncLoadingIndicatorProvider'
import BillyErrorBoundary from '../components/BillyErrorBoundary/BillyErrorBoundary'
import DialogHandler from '../components/DialogHandler/DialogHandler'
import { ErrorHandlerProvider } from '../components/ErrorHandler/ErrorHandler'
import SnackbarHandler from '../components/SnackbarHandler/SnackbarHandler'
import UserTenantSessionProvider from '../components/UserTenantSessionProvider/UserTenantSessionProvider'
import { withBillyGql } from '../components/data/withBillyGql'
import { LeftFixedBanner } from '../components/nav/LeftFixedBanner'
import SessionProviderWithSignout from '../components/state/context/SessionProviderWithSignout'
import { ModalsProvider } from '../components/state/context/modalsContext'
import { runMocks } from '../mocks/mockServer'
import createEmotionCache from '../styles/createEmotionCache'
import darkTheme from '../styles/darkTheme'
import lightTheme from '../styles/lightTheme'
import { setWasAtUrl } from '../util/cookieUtil'
import globals from '../util/globals'
import { isDevEnv } from '../util/isEnv'
import buildLogger from '../util/logger'
import { LoadingHandler } from '../components/LoadingHandler/LoadingHandler'
import '../util/datadog'
import { NextBuildRefreshManager } from '../components/state/NextBuildRefreshManager'
import { QuickCastBarManager } from '@/components/QuickCastBar/QuickCastBarManager'
import { CustomizationProvider } from '@/components/state/context/customizationContext'

if (process.browser) {
  const logLevel = new URLSearchParams(window.location.search).get('logLevel')
  if (logLevel && ['fatal', 'error', 'warn', 'info', 'debug', 'trace', 'silent'].includes(logLevel)) {
    window.localStorage.setItem('logLevel', logLevel)
  }

  const themeUrl = new URLSearchParams(window.location.search).get('theme')
  if (themeUrl && ['light', 'dark'].includes(themeUrl)) {
    window.localStorage.setItem('theme', themeUrl)
  }

  const mocks = new URLSearchParams(window.location.search).get('mocks')
  if (mocks && ['true', 'false'].includes(mocks)) {
    window.localStorage.setItem('mocks', mocks)
  }
}

const logger = buildLogger('BillyApp')

yup.setLocale({
  mixed: { required: 'Required' },
  string: {
    max: 'Max ${max} characters',
  },
})

if (isDevEnv() && process.browser && window.localStorage.getItem('mocks') === 'true') {
  runMocks()
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

// License key for MuiX Pro
LicenseInfo.setLicenseKey(
  'bf96f48f150cda9acd6186cd231c1373Tz05NjkzMSxFPTE3NTYzMTU4ODcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
)

// WARN: keep this in sync with _app.tsx/StorybookApp.tsx until we have time to
// refactor it without impacting the app stability.

function BillyApp(props: MyAppProps): JSX.Element {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const router = useRouter()

  React.useEffect(() => {
    const handleRouteChange = (url: string): void => {
      logger.debug({ url })
      if (url && !url.includes('/login')) {
        setWasAtUrl()
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  globals.router = useRouter()

  let theme: string | null = 'light'
  if (process.browser) {
    theme = window.localStorage.getItem('theme')
  }

  return (
    <Provider>
      <Script src="/__ENV.js" strategy="beforeInteractive" />
      <Script src="https://accounts.google.com/gsi/client" strategy="lazyOnload" />
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <BillyErrorBoundary>
              <SnackbarHandler>
                <ErrorHandlerProvider>
                  <AsyncLoadingIndicatorProvider>
                    <LoadingHandler>
                      <SessionProviderWithSignout>
                        <AppAuthRouter
                          authenticated={
                            <>
                              <LeftFixedBanner authenticated />
                              <UserTenantSessionProvider>
                                <CustomizationProvider>
                                  <ModalsProvider>
                                    <NextBuildRefreshManager />
                                    <DialogHandler>
                                      <QuickCastBarManager />
                                      <Component {...pageProps} />
                                    </DialogHandler>
                                  </ModalsProvider>
                                </CustomizationProvider>
                              </UserTenantSessionProvider>
                            </>
                          }
                          unauthenticated={
                            <>
                              <LeftFixedBanner />
                              <ModalsProvider>
                                <DialogHandler>
                                  <Component {...pageProps} />
                                </DialogHandler>
                              </ModalsProvider>
                            </>
                          }
                        />
                      </SessionProviderWithSignout>
                    </LoadingHandler>
                  </AsyncLoadingIndicatorProvider>
                </ErrorHandlerProvider>
              </SnackbarHandler>
            </BillyErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  )
}

export default withBillyGql(BillyApp)
