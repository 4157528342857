import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  Box,
  Collapse,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { memo, useCallback, useContext, useState } from 'react'
import BillyLink from '../../../components/link/billyLink'
import { JotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { JotaiForm } from '../../../components/state/useJotaiForm'
import StatusChip from '../../../components/table/cells/statusChip'
import { ActionType, LineItemFragment, PlanFragment, PlanStatus } from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import PlanDialogBody from '../planDialogBody'
import { OrderEditPlanRowHeader } from './CustomColumn/OrderEditPlanRowHeader'
import { LineItemsTableOrderType } from './LineItemsEditTable'
import OrderChargeRow from './orderChargeRow'
import { useOrderEditPlanRowStyles } from './useOrderEditPlanRowStyles'
import { LineItemTotal } from './LineItemTotal'
import { LineItemState } from '../LineItemsViewTable/LineItemsViewTable'
import { useDryRunActions } from '../EditOrderPage/context/DryRunActionsContext'

const logger = buildLogger('PlanRow')

export type PlanRowProps = {
  orderType: LineItemsTableOrderType
  plan: Pick<PlanFragment, 'id' | 'name' | 'status'>
  index: number
  isPlanRampedAndRemoved?: boolean
}

function mapNormalizeLineItemIndexes({
  lineItems,
  planId,
  orderType,
}: {
  lineItems: LineItemFragment[]
  planId?: string
  orderType: LineItemsTableOrderType
}) {
  return lineItems
    .map((lineItem, lineItemIndex) => {
      return {
        ...lineItem,
        lineItemIndex: lineItemIndex,
      }
    })
    .filter((lineItem) =>
      orderType === 'RESTRUCTURE' && lineItem.plan?.id === planId && lineItem.action === ActionType.Remove
        ? false
        : lineItem.plan?.id === planId
    )
    .map((lineItem) => lineItem.lineItemIndex)
}

const OrderEditPlanRow = memo(function OrderEditPlanRowWithoutMemo({
  orderType,
  plan,
  index,
}: PlanRowProps): JSX.Element {
  const { classes } = useOrderEditPlanRowStyles()
  const [isOpen, setIsOpen] = useState(true)
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { queueDryRun } = useDryRunActions()
  const isOrderTypeAmendmentOrRestructure = orderType === 'AMENDMENT' || orderType === 'RESTRUCTURE'

  const lineItemIndexesMatchesPlan = jotaiForm.useSelect(
    useCallback(
      (form: NewOrderFormData) =>
        mapNormalizeLineItemIndexes({
          orderType,
          lineItems: form.orderDetail.lineItems || [],
          planId: plan.id ?? undefined,
        }),
      [orderType, plan.id]
    )
  )

  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false)

  const handlePlanDialogClose = (): void => {
    setIsPlanDialogOpen(false)
  }

  const handlePlanClick = (): void => {
    setIsPlanDialogOpen(true)
  }

  const isNewPlan = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const filteredLineItems = form.orderDetail.lineItems?.filter(
          (lineItem) => lineItem.plan?.id === plan.id && lineItem.action !== ActionType.Remove
        )

        return filteredLineItems.length > 0
          ? filteredLineItems.every((lineItem) => lineItem.action === ActionType.Add)
          : false
      },
      [plan]
    )
  )

  const removePlan = useCallback(() => {
    jotaiForm.set((form) => {
      form.orderDetail.lineItems = form.orderDetail.lineItems.filter((lineItem) =>
        lineItem.action === ActionType.Remove && lineItem.isRamp ? true : lineItem.plan?.id !== plan.id
      )
    })
    queueDryRun()
  }, [jotaiForm, plan.id, queueDryRun])

  const removeAmendmentPlan = useCallback(() => {
    if (isNewPlan) {
      removePlan()
    } else {
      jotaiForm.set((form) => {
        // remove new line items
        form.orderDetail.lineItems = form.orderDetail.lineItems.filter(
          (li) => li.plan?.id !== plan.id || li.action !== ActionType.Add
        )

        // remove existing line items
        form.orderDetail.lineItems
          .filter((li) => li.plan?.id === plan.id)
          .forEach((li) => {
            li.action = ActionType.Remove
            li.quantity = 0
          })
      })
      queueDryRun()
    }
  }, [isNewPlan, jotaiForm, plan.id, queueDryRun, removePlan])

  const revertAmendmentPlan = useCallback(() => {
    jotaiForm.set((form) => {
      // remove new line items
      form.orderDetail.lineItems = form.orderDetail.lineItems.filter(
        (li) => li.plan?.id !== plan.id || li.action !== ActionType.Add
      )

      // revert existing line items
      form.orderDetail.lineItems
        .filter((lineItem) => lineItem.plan?.id === plan.id)
        .forEach((lineItem) => {
          lineItem.action = ActionType.None
          lineItem.quantity = form.orderDetail.currentSubscription?.charges.find(
            (charge) => charge?.id === lineItem.subscriptionChargeId
          )?.quantity
          lineItem.arrOverride = undefined
        })
    })
    queueDryRun()
  }, [jotaiForm, plan.id, queueDryRun])

  const isPlanRemoved = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const restructuredLineItems = form.orderDetail.lineItems?.filter(
          (lineItem) => lineItem.plan?.id === plan.id && lineItem.action !== ActionType.Remove && lineItem.isRamp
        )
        const filteredLineItems = form.orderDetail.lineItems?.filter((lineItem) => lineItem.plan?.id === plan.id)

        return restructuredLineItems.length > 0
          ? restructuredLineItems.every((lineItem) => lineItem.action === ActionType.Remove)
          : filteredLineItems.every((lineItem) => lineItem.action === ActionType.Remove)
      },
      [plan]
    )
  )

  if (lineItemIndexesMatchesPlan.length === 0) {
    return <></>
  }

  return (
    <>
      <Dialog open={isPlanDialogOpen} onClose={handlePlanDialogClose} maxWidth={'md'} scroll={'paper'}>
        <PlanDialogBody onClose={handlePlanDialogClose} id={plan.id || ''} />
      </Dialog>
      <TableRow>
        <TableCell>
          <Typography component="span" variant="body2" sx={{ mr: 1 }}>
            {index + 1}.
          </Typography>
          <BillyLink linkProps={{ onClick: () => handlePlanClick() }} style={{ display: 'inline' }}>
            {plan.name}
          </BillyLink>
          {isPlanRemoved && isOrderTypeAmendmentOrRestructure && (
            <span className={classes.statusChip}>
              <StatusChip value={LineItemState.Removed} />
            </span>
          )}
          {isNewPlan && isOrderTypeAmendmentOrRestructure && (
            <span className={classes.statusChip}>
              <StatusChip value={LineItemState.New} />
            </span>
          )}
          {plan.status === PlanStatus.Deprecated && (
            <span className={classes.statusChip}>
              <StatusChip value={LineItemState.Deprecated} />
            </span>
          )}
        </TableCell>
        <TableCell align="left">
          <LineItemTotal plan={plan} />
        </TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {!['CANCELLATION', 'RESTRUCTURE'].includes(orderType) && isPlanRemoved && (
              <IconButton aria-label="Revert" size="medium" onClick={revertAmendmentPlan}>
                <ReplayIcon />
              </IconButton>
            )}
            {orderType !== 'CANCELLATION' && !isPlanRemoved && (
              <Tooltip title="Delete Plan">
                <IconButton
                  aria-label="delete plan"
                  size="medium"
                  onClick={orderType === 'AMENDMENT' ? removeAmendmentPlan : removePlan}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton aria-label="expand plan" size="small" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={3}
          style={{
            padding: 0,
            height: 'unset',
          }}
        >
          <Collapse in={isOpen} timeout="auto">
            <Table>
              <OrderEditPlanRowHeader orderType={orderType} />
              <TableBody className={classes.orderPlanChargesBody}>
                {lineItemIndexesMatchesPlan.map((lineItemIndex) => {
                  return <OrderChargeRow key={lineItemIndex} lineItemIndex={lineItemIndex} orderType={orderType} />
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
})

export default OrderEditPlanRow
