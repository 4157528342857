import {
  isTypeofSupportedNumberOperators,
  isTypeOfSupportedStringOperators,
  itemOperatorToEsOperatorBuilder,
  mapNumberOperatorToEsOperator,
} from '@/components/search/mapFilterItemsToEsQuery'
import { getGridNumericOperators, getGridStringOperators, GridLogicOperator } from '@mui/x-data-grid-pro'
import { BoolQuery, Query, SearchBody, Sort, TermFieldConfig } from 'elastic-ts'
import buildLogger from '../../util/logger'
import { useSearch, UseSearchResponse } from '../../util/searchUtil'
import { SearchFieldDataType } from '../table/baseTable'

const logger = buildLogger('useFuzzySearch')

export const ES_SPECIAL_CHARACTERS = [
  '\\',
  '+',
  '=',
  '&&',
  '||',
  '>',
  '<',
  '!',
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  '^',
  '"',
  '~',
  '*',
  '?',
  ':',
  '/',
]

/**
 * Tables supported by the backend for search. This isn't an exhaustive list and
 * if you need to add a table that isn't supported just add it.  It's just that
 * this list was currently used in the code and more discoverable and type safe
 * this way.
 */
export type ElasticsearchTableNameStr =
  | 'account'
  | 'account_order'
  | 'api_key'
  | 'approval_flow'
  | 'bulk_invoice_run'
  | 'composite_order'
  | 'credit_memo'
  | 'invoice'
  | 'ledger_account'
  | 'opportunity'
  | 'payment'
  | 'plan'
  | 'product'
  | 'product_category'
  | 'settlement_application'
  | 'subscription'
  | 'tenant_user'
  | 'user_group'
  | 'approval_role'
  | 'plan_group'
  | 'tenant_job_queue'
  | 'notification_instance'
  | 'document_template'
  | 'payment_bank_account'
  | 'transactional_exchange_rate'

export type FuzzySearchProps = Readonly<{
  operator?: GridLogicOperator
  columns: Array<{ dataType?: SearchFieldDataType; accessor: string }>
  fillWithNonMatchingResults?: boolean
  pageIndex: number
  pageSize: number
  pause?: boolean
  querySearchString: string
  sortField?: string | Sort
  tableName?: ElasticsearchTableNameStr
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  where?: { key: string; value: any; isFuzzy?: boolean }[]
  term?: { key: string; value: any }[]
  range?: { key: string; value: any }[]
  not?: { key: string; value: TermFieldConfig['term'] }[]
  tenantId?: string
  strictFilter?: { key: string; value: string; operator?: string }[]
  isEmptyAccessors?: string[]
  isNotEmptyAccessors?: string[]
}>

export function buildQuery({
  tableName,
  querySearchString,
  columns,
  pageIndex,
  pageSize,
  sortField,
  where,
  term,
  range,
  not,
  fillWithNonMatchingResults,
  strictFilter,
  isEmptyAccessors,
  isNotEmptyAccessors,
  operator = GridLogicOperator.And,
}: FuzzySearchProps): SearchBody {
  // TODO: This is inefficient pagination and can create unstable results.  We should use `search_after` and PIT instead
  const query: SearchBody = {
    from: pageIndex * pageSize,
    size: pageSize,
    query: {
      bool: {
        filter: [],
        must: [],
        should: [],
        must_not: [],
      },
    },
  }

  const searchQuery = query.query as BoolQuery
  const searchQueryNotFilter = searchQuery.bool.must_not as Query[]
  const searchQueryShould = searchQuery.bool.should as Query[]

  let searchQueryBoolList = searchQuery.bool.filter as Query[]

  if (operator === GridLogicOperator.And) {
    searchQueryBoolList = searchQuery.bool.must as Query[]
  }

  if (operator === GridLogicOperator.Or) {
    searchQueryBoolList = searchQuery.bool.should as Query[]
    if (searchQueryBoolList.length) {
      searchQuery.bool.minimum_should_match = 1
    }
  }

  if (tableName) {
    //always match must when table name is provided
    ;(searchQuery.bool.must as Query[]).push({
      match: {
        table_name: {
          query: tableName,
        },
      },
    })
  }

  isNotEmptyAccessors?.forEach((field) => {
    searchQueryBoolList.push({
      bool: {
        must: [{ exists: { field: field } }, { regexp: { [field]: '.+' } }],
      },
    })
  })
  isEmptyAccessors?.forEach((field) => {
    searchQueryBoolList.push({
      bool: {
        minimum_should_match: 1,
        should: [
          { bool: { must_not: { exists: { field: field } } } },
          { bool: { must_not: { regexp: { [field]: '.+' } } } },
        ],
      },
    })
  })

  if (strictFilter?.length) {
    strictFilter.forEach((wildCardItem) =>
      searchQueryBoolList.push({
        bool: {
          minimum_should_match: 1,
          should: columnToEsStrictWildcardQuery(
            columns.find((c) => c.accessor === wildCardItem.key),
            wildCardItem.value,
            wildCardItem.operator
          ),
        },
      })
    )
  }

  if (where?.filter((item) => item.isFuzzy).length) {
    const whereFuzzy = where.filter((item) => item.isFuzzy)
    const whereMust = where.filter((item) => !item.isFuzzy)
    searchQueryBoolList.push({
      bool: {
        minimum_should_match: 1,
        must: whereMust.map((item) => ({ match: { [item.key]: item.value } })),
        should: whereFuzzy.reduce(
          (acc, item) =>
            acc.concat(
              buildFuzzyQuery({
                columns,
                fuzzyColumn: [item.key],
                querySearchString: item.value,
              })
            ),
          [] as Query[]
        ),
      },
    })
  } else {
    where?.forEach((item) => {
      searchQueryBoolList.push({
        match: { [item.key]: item.value },
      })
    })
  }

  not?.forEach((item) => {
    searchQueryNotFilter.push({
      term: { [item.key]: item.value },
    })
  })

  term?.forEach((item) => {
    searchQueryBoolList.push({
      term: { [item.key]: item.value },
    })
  })

  range?.forEach((item) => {
    searchQueryBoolList.push({
      range: { [item.key]: item.value },
    })
  })

  if (fillWithNonMatchingResults) {
    searchQueryShould.push({ match_all: {} })
  }

  if (sortField) {
    if (typeof sortField === 'string') {
      query.sort = [{ [sortField]: { order: 'desc' } }, { _score: { order: 'desc' } }]
    } else {
      query.sort = sortField
    }
  }

  if (querySearchString) {
    searchQuery.bool.minimum_should_match = 1
    buildFuzzyQuery({
      querySearchString,
      columns,
      fuzzyColumn: columns.map((column) => column.accessor),
    }).map((query) => searchQueryShould.push(query))
  }

  return query
}

export const buildQueryFromProps = (props: FuzzySearchProps): SearchBody => {
  return buildQuery(props)
}
export const sanitizeSearchString = (searchString: string) => {
  let sanitizedSearchString = searchString
  ES_SPECIAL_CHARACTERS.forEach((char) => {
    sanitizedSearchString = sanitizedSearchString.replaceAll(char, `\\${char}`)
  })

  return sanitizedSearchString
}

function buildFuzzyQuery({
  querySearchString,
  columns,
  fuzzyColumn,
}: {
  querySearchString: string
  columns: { dataType?: string; accessor: string }[]
  fuzzyColumn: string[]
}) {
  const searchQueryShould = [] as Query[]

  const sanitizedSearchString = sanitizeSearchString(querySearchString)

  columns
    .filter(({ accessor }) => {
      return fuzzyColumn.includes(accessor)
    })
    .forEach((column) => {
      columnToEsFuzzyQuery(column ?? undefined, sanitizedSearchString)?.forEach((query) =>
        searchQueryShould.push(query)
      )
    })
  return searchQueryShould
}

export const SupportedFuzzySearchFilterDataTypes = ['string', 'enum', 'id', 'currency', 'number'] as const
type SupportedFuzzySearchFilterDataTypes = typeof SupportedFuzzySearchFilterDataTypes[number]

export function isTypeofSupportedFuzzySearchFilterDataTypes(
  dataType?: string
): dataType is SupportedFuzzySearchFilterDataTypes {
  return dataType ? (SupportedFuzzySearchFilterDataTypes as readonly string[]).includes(dataType) : true
}
export function mapDataTypeToFilterOperators(dataType?: string) {
  switch (dataType) {
    case 'currency':
    case 'number':
      return getGridNumericOperators().filter((op) => isTypeofSupportedNumberOperators(op.value))
    case 'string':
    case 'id':
    case 'enum':
    default:
      return getGridStringOperators().filter((op) => isTypeOfSupportedStringOperators(op.value))
  }
}

function columnToEsStrictWildcardQuery(
  column: { dataType?: string; accessor: string } | undefined,
  sanitizedSearchString: string,
  operator?: string
) {
  if (!column) {
    return []
  }
  const isNumber = /^-?[0-9][0-9,.]+$/.test(sanitizedSearchString)
  const dataType = column.dataType ?? 'string'
  const queryList = [] as Query[]
  const id = column.accessor as string
  if (isTypeofSupportedFuzzySearchFilterDataTypes(dataType)) {
    if (dataType === 'string' || dataType === 'enum') {
      const content = {
        value: itemOperatorToEsOperatorBuilder(operator)(sanitizedSearchString),
        case_insensitive: true,
      }
      queryList.push({ wildcard: { [id]: content } })
      queryList.push({ wildcard: { [`${id}.keyword`]: content } })
    } else if (
      (dataType === 'currency' || dataType === 'number') &&
      isNumber &&
      isTypeofSupportedNumberOperators(operator)
    ) {
      queryList.push({
        range: {
          [id]: {
            [mapNumberOperatorToEsOperator(operator)]: sanitizedSearchString,
          },
        },
      })
    }
  }
  return queryList
}

function columnToEsFuzzyQuery(
  column: { dataType?: string; accessor: string } | undefined,
  sanitizedSearchString: string
) {
  if (!column) {
    return []
  }
  const isNumber = /^-?[0-9][0-9,.]+$/.test(sanitizedSearchString)
  const dataType = column.dataType ?? 'string'
  const queryList = [] as Query[]
  const id = column.accessor as string
  if (isTypeofSupportedFuzzySearchFilterDataTypes(dataType)) {
    if (dataType === 'string' || dataType === 'enum') {
      const searchString = dataType === 'enum' ? sanitizedSearchString.replace(/ /g, '_') : sanitizedSearchString

      const wildcard = {
        wildcard: { [id]: { value: `*${searchString}*`, case_insensitive: true } },
      }
      const wildcard2 = {
        wildcard: { [`${id}.keyword`]: { value: `*${searchString}*`, case_insensitive: true } },
      }
      queryList.push(wildcard)
      queryList.push(wildcard2)
      queryList.push({ fuzzy: { [id]: { value: searchString, fuzziness: 'auto' } } })
      queryList.push({ fuzzy: { [`${id}.keyword`]: { value: searchString, fuzziness: 'auto' } } })
    } else if ((dataType === 'currency' || dataType === 'number') && isNumber) {
      queryList.push({ match: { [id]: sanitizedSearchString } })
    }
  }
  return queryList
}

function buildQueryForMultiple(props: FuzzySearchProps[]): SearchBody {
  return props.reduce(
    // elastic search
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (previousQuery: any, p) => {
      const { query, ...otherProps } = buildQueryFromProps(p)
      previousQuery.query.boosting.positive.dis_max.queries.push(query)
      previousQuery = { ...previousQuery, ...otherProps }
      return previousQuery
    },
    {
      query: {
        boosting: {
          positive: {
            dis_max: { queries: [] },
          },
          negative: {
            bool: {
              filter: props.slice(1, props.length).map((prop) => ({
                match: {
                  table_name: {
                    query: prop.tableName,
                  },
                },
              })),
            },
          },
          negative_boost: 0.5,
        },
      },
    } as SearchBody
  )
}

function useFuzzySearch<T extends Record<string, unknown>>(
  props: FuzzySearchProps[],
  handleLoading?: boolean,
  tenantId?: string
): UseSearchResponse<T> {
  const query = props.length === 1 ? buildQueryFromProps(props[0]) : buildQueryForMultiple(props)
  return useSearch<T>(query, false, handleLoading, tenantId)
}

export default useFuzzySearch
