import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { deepMutable } from '../../../../../components/SchemaForm/DeepMutable'
import JotaiMuiAutocomplete from '../../../../../components/input/JotaiMuiAutocomplete'

export function ShippingContactAutocomplete() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const { account } = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => {
      return {
        account: form.orderDetail.account,
      }
    }, [])
  )
  return (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={(form: CommonOrderFormPageState) => deepMutable(form.contactList)}
        atomUpdater={(value, draft) => {
          draft.orderDetail.shippingContact = value
        }}
        atomValueSelector={(form) => form.orderDetail.shippingContact}
        autocompleteProps={{
          getOptionLabel: (option) => `${option.firstName} ${option.lastName}`,
        }}
        disabledExplanation={!account && 'Account must be selected first'}
        errorPath="orderDetail.shippingContact"
        form={jotaiForm}
        textFieldProps={{ label: 'Ship to' }}
      />
    </Grid>
  )
}
