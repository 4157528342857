import { OrderDetailFragment } from '@/generated/graphql'
import { useCurrencyContext } from '@/components/state/context/CurrencyContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { OrderViewPageForm } from '@/pages/orders/[orderId]'
import React, { useCallback, useMemo } from 'react'
import { billableCurrencyFormat, discountFormat } from '@/util/currencyUtil'
import { Box, CardContent, CardHeader, Grid } from '@mui/material'
import BillyCard from '@/components/card/billyCard'
import PredefinedDiscountsTable from '@/pageComponents/orders/predefinedDiscountsTable'
import { useLoggerEffect } from '@/components/hooks/useLoggerEffect'
import buildLogger from '@/util/logger'
const logger = buildLogger('PredefinedDiscountsViewCard')

type DisplayPredefinedDiscount = {
  id: string
  name: string
  percent: string
  amount: number
}

export function PredefinedDiscountsViewCard({ orderData }: { orderData: OrderDetailFragment }) {
  const [currency] = useCurrencyContext()
  const jotaiForm = useJotaiFormContext<OrderViewPageForm>()
  useLoggerEffect(logger)

  const predefinedDiscountAmounts = useMemo(() => {
    const amounts = new Map()
    orderData.lineItems.forEach((lineItem) => {
      lineItem.predefinedDiscounts?.forEach((predefinedDiscount) => {
        if (predefinedDiscount) {
          const amount = amounts.get(predefinedDiscount.id) || 0
          amounts.set(predefinedDiscount.id, predefinedDiscount.amount + amount)
        }
      })
    })
    return amounts
  }, [orderData.lineItems])
  const predefinedDiscounts: DisplayPredefinedDiscount[] = jotaiForm.useSelect(
    useCallback(
      (form) =>
        form.orderDetail?.predefinedDiscounts?.map((predefinedDiscount) => ({
          id: predefinedDiscount?.id || '',
          name: predefinedDiscount?.name || '',
          percent: discountFormat({ value: predefinedDiscount?.percent }),
          amount: predefinedDiscountAmounts.get(predefinedDiscount?.id || '') || 0,
        })) || [],
      [predefinedDiscountAmounts]
    )
  )
  const isPredefinedDiscountPresent = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail?.predefinedDiscounts && form.orderDetail.predefinedDiscounts.length > 0, [])
  )
  return isPredefinedDiscountPresent ? (
    <Grid item>
      <BillyCard>
        <CardHeader title="Predefined Discounts Applied" />
        <PredefinedDiscountsTable currency={currency ?? 'USD'} predefinedDiscounts={predefinedDiscounts} />
        <CardContent>
          <Grid container alignItems={'flex-end'} direction={'column'}>
            <Grid item display="flex">
              <Box sx={{ fontWeight: 'bold' }}>Total Predefined Discount:&nbsp;</Box>
              <Box>
                {billableCurrencyFormat({
                  currency,
                  value: predefinedDiscounts.reduce((prev, next) => prev + next.amount, 0),
                })}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </BillyCard>
    </Grid>
  ) : (
    <></>
  )
}
