import { Atom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import buildLogger from '../../util/logger'

const logger = buildLogger('JotaiReadAtom')

type JotaiReadAtomProps<T> = {
  atom: Atom<T>
  render: (value: T) => JSX.Element | undefined | null
}

export default function JotaiReadAtom<T>({ atom, render }: JotaiReadAtomProps<T>): JSX.Element {
  const value = useAtomValue(atom)
  return render(value) || <></>
}
