import { DownloadForOffline, ErrorOutline, UploadFileOutlined } from '@mui/icons-material'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'

interface DropzoneMessage {
  topMessage: string
  bottomMessage?: string
}

export interface FileUploadProps extends DropzoneOptions {
  errorMessage?: DropzoneMessage
  idleMessage: DropzoneMessage
  draggingMessage?: DropzoneMessage
  uploadingMessage?: DropzoneMessage
  uploading?: boolean
  error?: boolean
}

const useStyles = makeStyles()((theme: Theme) => ({
  messageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  caption: {
    color: theme.customPalette.textGray,
  },
  messageWrapperError: {
    color: theme.palette.error.main,
  },
  uploadWrapper: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '8px',
    cursor: 'pointer',
  },
  uploadWrapperError: {
    border: `2px dashed ${theme.palette.error.main}`,
  },
  uploadWrapperUploading: {
    border: `2px dashed ${theme.customPalette.textGray}`,
    color: theme.customPalette.textGray,
  },
}))

export function FileUpload({
  onDrop,
  onDropAccepted,
  onDropRejected,
  maxFiles,
  maxSize,
  accept,
  errorMessage = {
    topMessage: 'Something went wrong',
  },
  uploadingMessage = {
    topMessage: 'Uploading File',
    bottomMessage: 'This wont take too long...',
  },
  draggingMessage = {
    topMessage: 'Drop your file here',
  },
  error,
  uploading,
  idleMessage,
}: FileUploadProps) {
  const { classes, cx } = useStyles()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropAccepted,
    onDropRejected: (...args) => {
      onDropRejected && onDropRejected(...args)
      return null
    },
    maxFiles,
    maxSize: maxSize || parseInt(process.env.NEXT_PUBLIC_DOCUMENT_MAX_SIZE_IN_BYTES),
    accept,
  })

  let message = idleMessage
  let icon = <UploadFileOutlined color="primary" fontSize="large" />
  if (isDragActive) {
    message = {
      topMessage: draggingMessage.topMessage,
      bottomMessage: draggingMessage.bottomMessage || idleMessage.bottomMessage,
    }
    icon = <DownloadForOffline color="primary" fontSize="large" />
  } else if (uploading) {
    message = uploadingMessage
    icon = <CircularProgress color="inherit" />
  } else if (error) {
    message = errorMessage
    icon = <ErrorOutline color="error" fontSize="large" />
  }

  return (
    <Grid
      className={cx(
        classes.uploadWrapper,
        uploading ? classes.uploadWrapperUploading : error && classes.uploadWrapperError
      )}
      padding={2}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Grid item>{icon}</Grid>
      <Grid container item className={cx(classes.messageWrapper)}>
        <Typography variant="body1" className={!uploading && error ? classes.messageWrapperError : ''}>
          {message.topMessage}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" className={classes.caption}>
          {message.bottomMessage}
        </Typography>
      </Grid>
    </Grid>
  )
}
