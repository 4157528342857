import { PropsWithChildren } from 'react'

type PageContainerSlot<T> = {
  Layout?: (props: PropsWithChildren<T>) => JSX.Element
}

type PageContainerSlotProps<T> = {
  Layout?: T
}

export function PageContainer<T>({
  children,
  ...props
}: {
  children?: React.ReactNode
  slot?: PageContainerSlot<T>
  slotProps?: PageContainerSlotProps<T>
}) {
  const Slot: PageContainerSlot<T> = {
    ...props.slot,
  }

  const SlotProps: PageContainerSlotProps<T> = {
    ...props.slotProps,
  }

  return Slot.Layout ? (
    <Slot.Layout {...(SlotProps.Layout as PropsWithChildren<T>)}>{children}</Slot.Layout>
  ) : (
    <>{children}</>
  )
}
