import { makeStyles } from 'tss-react/mui'

export const useOrderEditPlanRowStyles = makeStyles()((theme, _params, _classes) => ({
  orderPlanChargesHeaderRow: {
    backgroundColor: theme.customPalette.backgroundColor4,
    '& th': { fontWeight: 'bold' },
  },
  orderPlanChargesBody: {
    backgroundColor: theme.customPalette.backgroundColor2,
  },
  iconCell: {
    textAlign: 'right',
    paddingLeft: 0,
  },
  statusChip: {
    marginLeft: theme.spacing(2),
  },
  numberHeader: {
    textAlign: 'right',
  },
  currencyHeader: {
    textAlign: 'right',
  },
}))
