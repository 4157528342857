import { forwardRef, useEffect, useState } from 'react'
import BillySnackBar from '../snackBar/billySnackBar'
import { SnackPack } from './SnackbarHandler'

interface SnackbarAlertContainerProps {
  snackPack: SnackPack
  readonly onClose?: (key: string) => void
}

export default function SnackbarAlertContainer({ snackPack, onClose }: SnackbarAlertContainerProps) {
  return <ConsecutiveSnackbars snackPack={snackPack} onClose={onClose} />
}

type DelegateProps = Pick<SnackbarAlertContainerProps, 'snackPack' | 'onClose'>

const ConsecutiveSnackbars = forwardRef<HTMLDivElement, DelegateProps>(function Delegate(
  { snackPack, onClose }: DelegateProps,
  ref
) {
  const [open, setOpen] = useState(true)
  const [snack, setSnack] = useState<SnackPack[0] | undefined>(undefined)

  const newSnack = snackPack.slice(-1)?.[0]
  useEffect(() => {
    setOpen(false)
    setSnack(newSnack)
  }, [newSnack])

  useEffect(() => {
    if (snack) {
      setOpen(true)
    }
  }, [snack])

  return (
    <div ref={ref}>
      {snack && (
        <BillySnackBar
          isOpen={open}
          handleClose={() => {
            setOpen(false)
          }}
          onTransitionExited={() => onClose?.(snack.key)}
          {...snack}
        />
      )}
    </div>
  )
})
