import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import JotaiMuiField from '../../../../../components/input/JotaiMuiField'
import { useCustomizationProps, WithCustomizationLocator } from '@/components/state/context/customizationContext'

export function OrderNameField({ parentLocator }: WithCustomizationLocator) {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()

  const customizationProps = useCustomizationProps({
    displayName: 'OrderNameField',
    parentLocator,
  })
  return (
    <Grid item xs={4} {...customizationProps}>
      <JotaiMuiField
        atomSelector={(form) => form.orderDetail.name}
        atomUpdater={(value, draft) => (draft.orderDetail.name = value)}
        errorPath="orderDetail.name"
        form={jotaiForm}
        textFieldProps={{ label: 'Order Name', name: 'Order Name' }}
      />
    </Grid>
  )
}
