import { TabContext } from '@mui/lab'
import { TabCardProps } from './TabCard'
import { WithQueryParam, useQueryParam } from '../route/useQueryParam'

interface WithTabContextProps {
  readonly value: string
  readonly hide?: boolean
  children?: React.ReactNode
}

export const WithTabContext: React.FC<React.PropsWithChildren<WithTabContextProps>> = ({ value, children, hide }) => {
  return !hide ? <TabContext value={value}>{children}</TabContext> : <>{children}</>
}

export function useSetTabValue({
  tabs,
  defaultTab,
  queryParamName,
}: Pick<TabCardProps, 'tabs' | 'defaultTab'> & WithQueryParam): [string, (value: string) => void] {
  const availableTabs = tabs
    ?.filter((tab) => !tab.hide && !tab.disabled && !tab.disableExplanation)
    .map((tab) => tab.value)
  const firstAvailableTab = availableTabs?.[0]
  const firstTab = tabs?.map((tab) => tab.value)?.[0]

  const [tabValue, setTabValue] = useQueryParam(queryParamName)

  const userDefinedTab = tabValue || defaultTab
  const displayTab =
    (availableTabs?.includes(userDefinedTab) ? userDefinedTab : undefined) ?? firstAvailableTab ?? firstTab ?? ''

  return [displayTab, setTabValue]
}
