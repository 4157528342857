import JotaiMuiCheckbox from '@/components/input/JotaiMuiCheckbox'
import { WithCustomizationLocator, useCustomizationProps } from '@/components/state/context/customizationContext'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { useOrderPageFormStyles } from '@/pageComponents/orders/EditOrderPage/hooks/useOrderPageFormStyles'
import { Grid } from '@mui/material'

export function OrderAutoRenewCheckbox({ hidden, parentLocator }: { hidden?: boolean } & WithCustomizationLocator) {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const { classes } = useOrderPageFormStyles()
  const customizationProps = useCustomizationProps({ displayName: 'autoRenew', parentLocator })
  const defaultChecked = customizationProps?.defaultChecked
  return customizationProps?.hidden || hidden ? (
    <></>
  ) : (
    <Grid item className={classes.fieldGridItem} xs={4} lg={4} xl={2}>
      <JotaiMuiCheckbox
        atomSelector={(form) => form.orderDetail.autoRenew}
        form={jotaiForm}
        checkboxProps={{
          defaultChecked,
          'aria-label': 'auto-renew-checkbox',
        }}
        atomUpdater={(value, draft) => (draft.orderDetail.autoRenew = value)}
        fieldLabel="Auto Renew"
      />
    </Grid>
  )
}
