import React from 'react'
import { Avatar, CardHeader } from '@mui/material'
import NextLink from 'next/link'
import { makeStyles } from 'tss-react/mui'
import BillyCard from '../card/billyCard'

export interface LinkCardProps {
  title: string
  subheader: JSX.Element | string
  avatar?: JSX.Element | string
  url?: string
  classNames?: string
}

const useStyles = makeStyles()((theme, _params, _classes) => ({
  card: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
    height: '100%',
  },
  cardHeader: {
    padding: theme.spacing(3),
    borderBottom: 'none !important',
    '& .MuiCardHeader-avatar': {
      alignSelf: 'flex-start',
      margin: theme.spacing(0, 2.5, 0, 0),
    },
    '& .MuiCardHeader-content': {
      '& .MuiCardHeader-title': {
        marginBottom: theme.spacing(1.25),
      },
      '& .MuiCardHeader-subheader': {
        color: theme.customPalette.textDark,
      },
    },
  },
  avatar: {
    boxSizing: 'border-box',
    width: 56,
    height: 56,
    borderRadius: 12,
    border: `1px solid ${theme.customPalette.borderColor}`,
    backgroundColor: 'transparent',
  },
}))

const BillyLinkCard: React.FC<React.PropsWithChildren<LinkCardProps>> = ({
  title,
  subheader,
  avatar,
  url,
  classNames,
}): JSX.Element => {
  const { classes, cx } = useStyles()
  return (
    <NextLink href={url || ''}>
      <BillyCard className={cx(classes.card, classNames)}>
        <CardHeader
          avatar={avatar && <Avatar className={classes.avatar}>{avatar}</Avatar>}
          title={title}
          subheader={subheader}
          className={classes.cardHeader}
        />
      </BillyCard>
    </NextLink>
  )
}

export default BillyLinkCard
