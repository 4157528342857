import { Feature } from '@/generated/graphql'
import useDynamicFeatureFlag from '../state/useDynamicFeatureFlag'
import { useEntitySession } from './UseEntitySession'

export const useDoesMultipleEntitiesExist = () => {
  const isMultiEntityEnabled = useDynamicFeatureFlag(Feature.MultiEntityAuth)
  const { availableEntities } = useEntitySession()

  return isMultiEntityEnabled && availableEntities && availableEntities.length > 1
}
