import React, { useCallback } from 'react'
import { CellProps, Row } from 'react-table'
import buildLogger from '../../../util/logger'
import BillyLink from '../../link/billyLink'
import { useModalsContext } from '../../state/context/modalsContext'
const logger = buildLogger('LinkCell')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SupportedRowProps = Pick<Row<any>, 'original' | 'id'>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LinkCellProps = Pick<CellProps<any, any>, 'value'> & {
  row: SupportedRowProps
}

type WithLinkTargetFn = {
  getLinkTarget: (row: SupportedRowProps) => string
}

export type WithCellNavigation = {
  navigationMode?: 'href' | 'modal'
} & Partial<WithLinkTargetFn>

export const buildLinkCell = ({ getLinkTarget, navigationMode }: WithCellNavigation) => {
  return navigationMode === 'href' && !!getLinkTarget
    ? WithLinkTargetHOC({ getLinkTarget })(LinkCellHref)
    : navigationMode === 'modal' && !!getLinkTarget
    ? WithLinkTargetHOC({ getLinkTarget })(LinkCellModal)
    : LinkCellDefault
}

const WithLinkTargetHOC = ({ getLinkTarget }: WithLinkTargetFn) => {
  return (Component: React.FunctionComponent<LinkCellProps & WithLinkTargetFn>) => {
    return function LinkCellWithLinkTargetFn(props: LinkCellProps) {
      return <Component getLinkTarget={getLinkTarget} {...props} />
    }
  }
}

const LinkCellHref = ({ row, value, getLinkTarget }: LinkCellProps & WithLinkTargetFn) => {
  return <BillyLink nextProps={{ href: getLinkTarget(row) }}>{value}</BillyLink>
}

const LinkCellModal = ({ row, value, getLinkTarget }: LinkCellProps & WithLinkTargetFn) => {
  const [, , toggleModal] = useModalsContext()
  const handleOnClick = useCallback(() => {
    const modalKey = getLinkTarget?.(row)
    if (modalKey) {
      toggleModal(modalKey, true)
    }
  }, [toggleModal, row, getLinkTarget])

  return (
    <BillyLink
      linkProps={{
        onClick: handleOnClick,
      }}
    >
      {value}
    </BillyLink>
  )
}

const LinkCellDefault = ({ value }: LinkCellProps) => {
  return <>{value}</>
}
