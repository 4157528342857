import React from 'react'
import buildLogger from '../../util/logger'
import { PinPosition } from '../../pageComponents/documentCommenting/DocCommentPinRecordingOverlay'
import { Tooltip } from '@mui/material'
const logger = buildLogger('DocCommentPins')

export type DocPinWithUserAvatarProps = {
  pos: Pick<PinPosition, 'x' | 'y'>
}

export const MonogramAvatar = ({
  name,
  variant = 'pin',
  classes,
}: {
  name: string
  variant?: 'chat' | 'pin' | 'user'
  classes?: string
}) => {
  return (
    <Tooltip title={name}>
      <div
        className={classes}
        style={{
          width: 30,
          height: 30,
          borderRadius: variant === 'pin' ? '4px 50% 50% 50%' : '50%',
          backgroundColor: stringToColor(name),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontWeight: 'bold',
          fontSize: 12,
          cursor: 'pointer',
        }}
      >
        {name.slice(0, 2).toUpperCase()}
      </div>
    </Tooltip>
  )
}

const stringToColor = (str: string) => {
  let hash = 0
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
}
