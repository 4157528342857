import { forwardRef, useEffect, useState } from 'react'
import { Alert, LinearProgress, useTheme } from '@mui/material'
import { ES_WAIT_TIME_IN_MS } from '../../util/searchUtil'

type Props = Readonly<{
  message: string
}>

export default forwardRef<HTMLDivElement, Props>(function SuccessAlert(props: Props, ref) {
  const { message } = props
  const theme = useTheme()
  const [startTime] = useState(new Date().valueOf())
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      const timePassed = new Date().valueOf() - startTime
      const progress = (timePassed / ES_WAIT_TIME_IN_MS) * 100
      setProgress(progress)
      if (progress > 120) {
        clearTimeout(timeout)
      }
    }, 200)
    return () => clearTimeout(timeout)
  })

  return (
    <Alert
      ref={ref}
      severity="success"
      style={{ marginBottom: theme.spacing(2), display: progress < 120 ? undefined : 'none' }}
    >
      {message}
      <LinearProgress variant="determinate" value={Math.min(progress, 100)} />
    </Alert>
  )
})
