import { CustomFieldParentType, useGetSalesRoomLinkQuery } from '@/generated/graphql'
import { CustomFieldsCard } from '../CustomFields/CustomFieldsCard'

export function OrderPageSalesRoomCustomFieldsCard({ orderId }: { orderId: string }) {
  const [salesRoomLinkResponse] = useGetSalesRoomLinkQuery({ variables: { orderId } })
  if (salesRoomLinkResponse.data?.salesRoomLink?.linkId) {
    return (
      <CustomFieldsCard
        title="Sales Room Custom Fields"
        customFieldType={CustomFieldParentType.SalesRoom}
        parentObjectId={salesRoomLinkResponse.data?.salesRoomLink?.linkId}
        canEditCustomFields={true}
      />
    )
  } else return null
}
