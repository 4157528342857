import { createTheme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles/createTheme'
import _ from 'lodash'
import commonTheme from './commonTheme'

let options: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      light: '#9896F4',
      main: '#7573F1',
      dark: '#5350ED',
      contrastText: '#DDDCFB',
    },
    secondary: {
      light: '#FFB693',
      main: '#FF9E6F',
      dark: '#FF864B',
      contrastText: '#FFE7DB',
    },
    warning: {
      light: '#FFDD8B',
      main: '#FFD179',
      dark: '#FFC658',
      contrastText: '#FFF4DE',
    },
    info: {
      light: '#90C4F8',
      main: '#6BB0F6',
      dark: '#469CF3',
      contrastText: '#DAEBFD',
    },
    success: {
      light: '#85EAB9',
      main: '#5CE3A1',
      dark: '#4CAF50',
      contrastText: '#D6F8E8',
    },
  },
  customPalette: {
    textDark: '#262626',
    textGray: '#595959',
    backgroundColor1: '#303030',
    backgroundColor2: '#323232',
    backgroundColor3: '#000000',
    backgroundColor4: '#313131',
    codeblockBackgroundColor: 'rgba(135, 131, 120, 0.15)',
    hoverColor1: 'rgba(70,156,243,0.2)',
    hoverColor2: 'rgba(152,150,244,0.2)',
    selectedBackgroundColor1: 'rgba(35,35,38,0.35)',
    cardInputBackgroundColor1: 'rgba(236, 233, 248, 0.35)',
    landingPageBackgroundColor: '#d9eafe',
    selectedColor1: '#9896F4',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    tableHeaderBackgroundColor: '#D1D9E0',
    tableRowBackgroundColor: '#F9FAFB',
    queryBuilderBackgroundColor: 'rgba(135, 131, 120, 0.5)',
    chargeDetailsTieredBackgroundColor: 'rgba(70, 156, 243, 0.08)',
    statusChipBackgrounds: {
      primary:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #4744E2, #4744E2)',
      secondary:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #44E2E2, #44E2E2)',
      info: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #2196F3, #2196F3)',
      error:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #F44336, #F44336)',
      success:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #4CAF50, #4CAF50) ',
      warning:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), linear-gradient(0deg, #ED6C02, #ED6C02)',
    },
    disabledBorder: '#C0C0C0',
  },
}

options = _.merge(_.cloneDeep(commonTheme), options)

const darkTheme = createTheme(options)

export default darkTheme
