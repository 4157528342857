import { ForwardedRef, ReactNode, forwardRef } from 'react'
import buildLogger from '../../util/logger'
import BillyCard from '../card/billyCard'
import { formatQueryName } from '../route/useQueryParam'
import { TabBar, TabDetail } from './TabBar'
import { WithTabContext, useSetTabValue } from './useTabContextBuilder'
import { CardHeader } from '@mui/material'

const logger = buildLogger('TabCard')

export type TabCardProps = {
  tabs?: readonly TabDetail[]
  readonly defaultTab?: TabDetail['value']
  hide?: boolean
  children?: ReactNode
  cardId: string
  title?: string
}

const TabCardWithForwarding = (
  { defaultTab, tabs, children, hide, cardId, title }: TabCardProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const tabCardQueryParamName = formatQueryName(`tab_card_${cardId}`)
  const [tabValue, setTabValue] = useSetTabValue({ queryParamName: tabCardQueryParamName, tabs, defaultTab })
  return (
    <WithTabContext value={tabValue} hide={hide}>
      <BillyCard ref={ref}>
        {title && <CardHeader title={title} />}
        <TabBar tabs={tabs ?? []} onTabChange={(e, value) => setTabValue(value)} />
        {children}
      </BillyCard>
    </WithTabContext>
  )
}
/**
 * Used with TabPanel from @mui/lab to create a tabbed card.
 */
export const TabCard = forwardRef(TabCardWithForwarding)
