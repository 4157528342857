import Cookies from 'js-cookie'
import { useUserTenantSession } from '../UserTenantSessionProvider/UserTenantSessionContext'
import { ALL_ENTITIES } from '@/util/entity'
import { EntityFragment } from '@/generated/graphql'

export type EntityContext = {
  selectedEntities: readonly EntityFragment[]
  hasFullSelection: boolean
  availableEntities: readonly EntityFragment[]
  hasFullAuthorization: boolean
  tenantEntities: readonly EntityFragment[]
}

export const useEntitySession = (): EntityContext => {
  const { currentUser, entities: tenantEntities } = useUserTenantSession()

  const selectedId = Cookies.get('entity_id')
  const hasFullSelection = selectedId === ALL_ENTITIES
  const selectedEntities = hasFullSelection
    ? tenantEntities
    : tenantEntities.filter((entity) => entity.id === selectedId)
  const authorizedEntities = currentUser?.availableEntities || []
  const hasFullAuthorization = currentUser?.hasAllEntitiesAccess || false

  return {
    selectedEntities: selectedEntities as readonly EntityFragment[],
    hasFullSelection,
    availableEntities: authorizedEntities,
    hasFullAuthorization,
    tenantEntities: tenantEntities as readonly EntityFragment[],
  }
}
