import { BillingCycle, Cycle, Recurrence } from '../generated/graphql'
import { toTitleCase } from '@/util/string'

export const getBillingCycleDisplayText = ({ cycle }: { cycle?: Recurrence | null }): string => {
  let outputString = ''
  const monthlyDisplay = 'Monthly'
  const quarterlyDisplay = 'Quarterly'
  const semiAnnualDisplay = 'Semi-Annual'
  const yearlyDisplay = 'Yearly'
  const paidInFullDisplay = 'Upfront'

  if (cycle) {
    if (cycle.cycle === Cycle.Year) {
      if (cycle.step == 1) {
        outputString = yearlyDisplay
      } else {
        outputString = `Every ${cycle.step} years`
      }
    } else if (cycle.cycle === Cycle.Quarter) {
      if (cycle.step == 1) {
        outputString = quarterlyDisplay
      } else if (cycle.step == 2) {
        outputString = semiAnnualDisplay
      } else {
        outputString = `Every ${cycle.step} quarters`
      }
    } else if (cycle.cycle === Cycle.SemiAnnual) {
      if (cycle.step == 1) {
        outputString = semiAnnualDisplay
      } else if (cycle.step == 2) {
        outputString = yearlyDisplay
      } else {
        outputString = `Every ${cycle.step * 6} months`
      }
    } else if (cycle.cycle === Cycle.Month) {
      if (cycle.step == 1) {
        outputString = monthlyDisplay
      } else if (cycle.step == 3) {
        outputString = quarterlyDisplay
      } else {
        outputString = `Every ${cycle.step} months`
      }
    } else if (cycle.cycle === Cycle.PaidInFull) {
      outputString = paidInFullDisplay
    }
  }

  return outputString
}

export const billingCycleSortedOptions = [
  BillingCycle.Month,
  BillingCycle.PaidInFull,
  BillingCycle.Quarter,
  BillingCycle.SemiAnnual,
  BillingCycle.Year,
  BillingCycle.Default,
]

export function formatBillingCycle(billingCycle: BillingCycle) {
  switch (billingCycle) {
    case BillingCycle.Default:
      return "Use Order's Billing Cycle"
    default:
      return toTitleCase(billingCycle)
  }
}
