/**
 * Utilities for working with arrays.  Note that these are primarily needed for
 * mobx due to:
 *
 * - accessing array indexes out of bounds yields a mobx error (which first,
 *   last, and next) resolve.
 *
 * - Swapping in a completely new record can sometimes break MobX so we do
 *   mutation.
 */
export namespace Arrays {
  /**
   * Copy the source dict to the target dict but only for differing values.
   */
  export function copy<T>(source: ReadonlyArray<T>, target: Array<T>) {
    for (const entry of Object.entries(source)) {
      const key = entry[0] as keyof T
      const value = entry[1]

      if ((target as any)[key] !== value) {
        ;(target as any)[key] = value
      }
    }
  }

  /**
   * Remove all the entries in the dict
   */
  export function clear(dict: Record<string | number, unknown>) {
    for (const entry of Object.entries(dict)) {
      const key = entry[0]
      delete dict[key]
    }
  }

  export function first<T>(arr: ReadonlyArray<T>): T | undefined {
    if (arr.length > 0) {
      return arr[0]
    }

    return undefined
  }

  export function last<T>(arr: ReadonlyArray<T>): T | undefined {
    if (arr.length === 0) {
      return undefined
    }

    return arr[arr.length - 1]
  }

  /**
   * Read the previous entry in an array.
   */
  export function prev<T>(arr: ReadonlyArray<T>, idx: number): T | undefined {
    if (arr.length === 0) {
      return undefined
    }

    if (idx > 0 && idx <= arr.length) {
      return arr[idx - 1]
    }

    return undefined
  }

  /**
   * Read the next entry in an array.
   */
  export function next<T>(arr: ReadonlyArray<T>, idx: number): T | undefined {
    if (arr.length === 0) {
      return undefined
    }

    if (idx >= 0 && idx + 1 < arr.length) {
      // make sure there actually IS a next entry...

      return arr[idx + 1]
    }

    return undefined
  }

  /**
   * Read a specific array offset relative to a given position.
   */
  export function read<T>(arr: ReadonlyArray<T>, idx: number, delta: number): T | undefined {
    const ptr = idx + delta

    if (idx < 0) {
      // the relative position is before the array and isn't a valid position.
      return undefined
    }

    if (idx >= arr.length) {
      // the relative position is outside of the array
    }

    if (ptr < 0) {
      // don't attempt to read before the array
      return undefined
    }

    if (ptr >= arr.length) {
      // don't attempt to read past the end of the array.
      return undefined
    }

    return arr[ptr]
  }
}
