import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../../../components/state/jotaiFormProvider'
import { useYupError } from '../../../../../util/jotai'
import buildLogger from '../../../../../util/logger'
import { PaymentTermSelect, PaymentTermSelectProps } from '../../../PaymentTermSelect'

const logger = buildLogger('NewOrderPaymentTermSelect')

export const NewOrderPaymentTermSelect = () => {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const defaultOptionId = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => form.orderDetail?.paymentTerm, [])
  )
  const availablePaymentTerms = jotaiForm.useSelect(
    useCallback(
      (form: CommonOrderFormPageState) =>
        form.paymentTermSettings?.defaultPaymentTerms.map((term) => {
          return {
            id: term,
            label: term.toUpperCase().replace('NET', ''),
          }
        }) ?? [],
      []
    )
  )

  const error = useYupError(jotaiForm, 'orderDetail.paymentTerm')

  const isCustom = jotaiForm.useSelect(
    useCallback((form: CommonOrderFormPageState) => !!form.paymentTermSettings?.customPaymentTermsAllowed, [])
  )

  function onChange(option: PaymentTermSelectProps['options'][number]) {
    jotaiForm.set((draft) => {
      draft.orderDetail.paymentTerm = option.id
    })
  }

  return (
    <Grid item xs={6}>
      <PaymentTermSelect
        options={availablePaymentTerms}
        onChange={onChange}
        custom={isCustom}
        defaultOptionId={defaultOptionId}
        error={error}
      />
    </Grid>
  )
}
