import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import EntitySelectionRoute from '@/components/route/EntitySelectionRoute'
import { CancellationOrderContent } from '@/pageComponents/orders/CancellationOrderPage/CancellationOrderContent'
import { NewOrderPageContent } from '@/pageComponents/orders/EditOrderPage/NewOrderPageContent'
import { DryRunProvider } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { ALL_ENTITIES } from '@/util/entity'
import { Alert } from '@mui/material'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import JotaiUrqlErrors from '../../components/error/jotaiUrqlErrors'
import InnerPageContainer from '../../components/layout/innerPageContainer'
import { NavLayout } from '../../components/layout/navLayout'
import ProtectedRoute from '../../components/route/protectedRoute'
import useJotaiForm from '../../components/state/useJotaiForm'
import { WithUrql, useJotaiUrqlQuery } from '../../components/state/useJotaiUrqlQuery'
import {
  CompositeOrderDetailFragment,
  GetOrderDetailDocument,
  GetOrderDetailQuery,
  GetOrderDetailQueryVariables,
  GetSubscriptionDocument,
  GetSubscriptionQuery,
  GetSubscriptionQueryVariables,
  GetUserTenantSessionQuery,
  OrderDetailFragment,
  OrderStatus,
  OrderType,
  Role,
  SubscriptionFragment,
} from '../../generated/graphql'
import buildLogger from '../../util/logger'
import AmendmentOrderPage, { AmendmentOrderContent } from './AmendmentOrderPage/AmendmentOrderPage'
import CancellationOrderPage from './CancellationOrderPage/CancellationOrderPage'
import NewOrderPage from './EditOrderPage/NewOrderPage'

const logger = buildLogger('OrderPageDelegator')

interface OrderPageDelegatorState extends WithUrql {
  orderDetail?: OrderDetailFragment
  compositeOrder?: CompositeOrderDetailFragment
  subscription?: SubscriptionFragment
}

export function enforceSingleEntityContext(
  entityId: string | null | undefined,
  currentUser: GetUserTenantSessionQuery['currentUser']
) {
  if (!entityId || entityId === ALL_ENTITIES) {
    return
  }
  if (currentUser?.availableEntities?.length === 1) {
    return
  }

  const currentEntityId = Cookies.get('entity_id')
  if (!currentEntityId || currentEntityId === ALL_ENTITIES) {
    Cookies.set('entity_id', entityId)
    window.location.reload()
  }
}

function OrderPageDelegator(props): JSX.Element {
  const router = useRouter()
  const subscriptionId = router.query.subscriptionId as string | undefined
  const type = router.query.type as 'amendment' | 'cancel' | 'renewal' | 'composite' | undefined
  const orderId = router.query.orderId as string | undefined
  const userTenantSession = useUserTenantSession()
  const currentUser = userTenantSession.currentUser

  const jotaiForm = useJotaiForm<OrderPageDelegatorState>(
    useMemo(
      () => ({
        defaultValue: {},
      }),
      []
    )
  )

  useJotaiUrqlQuery<OrderPageDelegatorState, GetOrderDetailQuery, GetOrderDetailQueryVariables>({
    document: GetOrderDetailDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.orderDetail = deepMutable(data.orderDetail)), []),
    pause: useCallback(() => !orderId || orderId === 'new', [orderId]),
    variables: useCallback(() => ({ id: orderId || '' }), [orderId]),
  })

  useJotaiUrqlQuery<OrderPageDelegatorState, GetSubscriptionQuery, GetSubscriptionQueryVariables>({
    document: GetSubscriptionDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.subscription = deepMutable(data.subscriptions[0])), []),
    pause: useCallback(() => !subscriptionId, [subscriptionId]),
    variables: useCallback(() => ({ id: subscriptionId || '' }), [subscriptionId]),
  })

  const { orderType, status, orderEntityId, subscriptionEntityId } = jotaiForm.useSelect(
    useCallback(
      (form) => ({
        orderType: form.orderDetail?.orderType,
        status: form.orderDetail?.status,
        orderEntityId: form.orderDetail?.entityId,
        subscriptionEntityId: form.subscription?.entityId,
      }),
      []
    )
  )

  if (type) {
    if (!subscriptionId) {
      throw new Error('Type is in the query string but subscriptionId is missing')
    }
    if (orderId) {
      throw new Error('Type is in the query string along with orderId')
    }
  }

  if (subscriptionId) {
    if (!type) {
      throw new Error('subscriptionId is in the query string but type is missing')
    }
    if (orderId) {
      throw new Error('subscriptionId is in the query string along with orderId')
    }
  }

  // enforce single entity context
  enforceSingleEntityContext(orderEntityId || subscriptionEntityId, currentUser)

  if (status && status !== OrderStatus.Draft && status !== OrderStatus.Expired) {
    return (
      <NavLayout>
        <InnerPageContainer>
          <Alert severity="error">{"This order can't be edited because it's not a draft"}</Alert>
        </InnerPageContainer>
      </NavLayout>
    )
  }

  if (type === 'amendment' || orderType === OrderType.Amendment) {
    return ProtectedRoute(
      <DryRunProvider>
        <AmendmentOrderPage {...props}>
          <AmendmentOrderContent />
        </AmendmentOrderPage>
      </DryRunProvider>,
      [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
    )
  }

  if (type === 'cancel' || orderType === OrderType.Cancel) {
    return ProtectedRoute(
      <DryRunProvider>
        <CancellationOrderPage {...props}>
          <CancellationOrderContent />
        </CancellationOrderPage>
      </DryRunProvider>,

      [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
    )
  }

  if (!router.isReady || (orderId && !orderType)) {
    return (
      <NavLayout>
        <InnerPageContainer>
          <JotaiUrqlErrors jotaiForm={jotaiForm} />
        </InnerPageContainer>
      </NavLayout>
    )
  }

  const isRenewal = type === 'renewal' || orderType === OrderType.Renewal

  if (isRenewal) {
    return ProtectedRoute(
      <DryRunProvider>
        <NewOrderPage {...props} isRenewal={isRenewal}>
          <NewOrderPageContent />
        </NewOrderPage>
      </DryRunProvider>,
      [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
    )
  }

  return EntitySelectionRoute(
    ProtectedRoute(
      <DryRunProvider>
        <NewOrderPage {...props} isRenewal={isRenewal}>
          <NewOrderPageContent />
        </NewOrderPage>
      </DryRunProvider>,
      [Role.Admin, Role.Finance, Role.Sales, Role.SalesManager]
    )
  )
}

export default OrderPageDelegator
