import React from 'react'
import { CheckCircleOutline, ErrorOutlineOutlined, InfoOutlined, ReportProblemOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, CircularProgress, IconButton, Snackbar, SnackbarContent, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { toTitleCase } from '../../util/string'

const ALERT_COLORS = ['success', 'info', 'warning', 'error'] as const

type AlertColorType = typeof ALERT_COLORS[number]

export const SNACKBAR_TYPES = [...ALERT_COLORS, 'in-progress'] as const
export type SnackbarTypes = typeof SNACKBAR_TYPES[number]
function instanceOfAlertColor(obj: string): obj is AlertColorType {
  return (ALERT_COLORS as readonly string[]).indexOf(obj) >= 0
}

export const SNACKBAR_ACTIONS = ['download', 'view', 'proceed', 'retry'] as const
type SnackbarActionType = typeof SNACKBAR_ACTIONS[number]
export type BillySnackBarProps = Readonly<{
  handleClose?: () => void
  handleAction?: () => void
  onTransitionExited?: () => void
  isOpen: boolean
  message: string
  action?: SnackbarActionType
  type: SnackbarTypes
}>

const useStyles = makeStyles<{ type: SnackbarTypes }>()((theme, params, _classes) => ({
  root: {
    padding: theme.spacing(1, 2),
    borderRadius: 6,
    backgroundColor: instanceOfAlertColor(params.type) ? theme.palette?.[params.type].dark : undefined,
    color: instanceOfAlertColor(params.type) ? theme.palette?.[params.type].contrastText : undefined,
  },
}))

const MIN_AUTO_CLOSE_TIMEOUT_IN_MS = 2500
const MAX_AUTO_CLOSE_TIMEOUT_IN_MS = 7000

const BillySnackBar: React.FC<React.PropsWithChildren<BillySnackBarProps>> = ({
  action,
  handleAction,
  handleClose,
  onTransitionExited: onExited,
  isOpen,
  message,
  type,
}): JSX.Element => {
  const { classes } = useStyles({ type })

  const autoCloseTimeoutInMs = Math.min(
    Math.max(message.length * 50, MIN_AUTO_CLOSE_TIMEOUT_IN_MS),
    MAX_AUTO_CLOSE_TIMEOUT_IN_MS
  )

  const showCloseButton = type === 'warning' || type === 'error' || type === 'info' || (type === 'success' && !!action)
  const showAction = !!action && type !== 'in-progress'
  const stayOpen = showAction || type === 'in-progress' || type === 'warning' || type === 'info'

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={stayOpen ? null : autoCloseTimeoutInMs}
      onClose={!stayOpen && handleClose ? handleClose : () => null}
      open={isOpen}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      TransitionProps={{ onExited }}
    >
      <SnackbarContent
        className={`${classes.root}`}
        message={
          <Box sx={{ display: 'flex', flex: 'row nowrap', gap: 1.5, alignItems: 'center' }}>
            {type === 'success' && <CheckCircleOutline />}
            {type === 'info' && <InfoOutlined />}
            {type === 'warning' && <ReportProblemOutlined />}
            {type === 'error' && <ErrorOutlineOutlined />}
            {type === 'in-progress' && <CircularProgress color="inherit" aria-label="spinner" size={22} />}
            <Typography variant="body2">{message}</Typography>
          </Box>
        }
        action={
          <>
            {showAction && (
              <Button size="medium" onClick={handleAction} color="inherit" variant="text">
                {toTitleCase(action)}
              </Button>
            )}
            {showCloseButton && (
              <>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </>
        }
      />
    </Snackbar>
  )
}

export default BillySnackBar
