import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { OperationContext } from '@urql/core/dist/types/types'
import { Draft } from 'immer'
import { useCallback, useEffect } from 'react'
import { CombinedError, useClient } from 'urql'
import { getDocumentName } from '../../util/gqlUtil'
import buildLogger from '../../util/logger'
import useHasChanged from '../../util/useHasChanged'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { JotaiForm } from './useJotaiForm'

const logger = buildLogger('useJotaiUrqlQuery')

export interface WithUrql {
  urqlErrors?: Record<string, CombinedError>
  urqlIsFetching?: Record<string, boolean>
  urqlIsPaused?: Record<string, boolean>
  urqlRefreshCounter?: Record<string, number>
  urqlSuccesses?: Record<string, boolean>
}

export type UseJotaiUrqlQueryProps<T extends WithUrql, Q, V> = {
  document: TypedDocumentNode<Q, V>
  jotaiForm: JotaiForm<T>
  onData: (data: Q, draft: Draft<T>) => void
  pause?: (formData: T) => boolean
  queryOpts?: Partial<OperationContext>
  variables?: (formData: T) => V
}

const dummyFunc = (): any => null as any

export function useJotaiUrqlQuery<T extends WithUrql, Q, V>({
  document,
  jotaiForm,
  onData,
  pause,
  queryOpts,
  variables,
}: UseJotaiUrqlQueryProps<T, Q, V>): void {
  const urql = useClient()
  const pauseQueryResult = jotaiForm.useSelect(pause || dummyFunc)
  const selectedVariables = jotaiForm.useSelect(variables || dummyFunc)
  const name = getDocumentName(document)

  const refreshCounter = jotaiForm.useSelect(useCallback((form) => form.urqlRefreshCounter?.[name], [name]))

  const documentHasChanged = useHasChanged(document)
  const jotaiFormHasChanged = useHasChanged(jotaiForm)
  const nameHasChanged = useHasChanged(name)
  const onDataHasChanged = useHasChanged(onData)
  const pauseQueryResultHasChanged = useHasChanged(pauseQueryResult)
  const selectedVariablesHasChanged = useHasChanged(selectedVariables)
  const queryOptsHasChanged = useHasChanged(queryOpts)
  const urqlHasChanged = useHasChanged(urql)
  const refreshCounterHasChanged = useHasChanged(refreshCounter)

  if (
    !pauseQueryResult &&
    (documentHasChanged ||
      jotaiFormHasChanged ||
      nameHasChanged ||
      onDataHasChanged ||
      pauseQueryResultHasChanged ||
      selectedVariablesHasChanged ||
      selectedVariablesHasChanged ||
      urqlHasChanged ||
      refreshCounterHasChanged)
  ) {
    const logObj = {
      msg: `Querying URQL ${name} because`,
      documentHasChanged,
      jotaiFormHasChanged,
      nameHasChanged,
      onDataHasChanged,
      pauseQueryResultHasChanged,
      selectedVariablesHasChanged,
      queryOptsHasChanged,
      urqlHasChanged,
      refreshCounterHasChanged,
    }
    logger.trace(Object.fromEntries(Object.entries(logObj).filter(([_ket, value]) => !!value)))
  }

  const errorHandler = useErrorHandler()

  useEffect(() => {
    if (!pauseQueryResult) {
      urql
        .query<Q>(document, selectedVariables, { ...queryOpts, requestPolicy: 'cache-only' })
        .toPromise()
        .then((response) => {
          const data = response.data
          if (data && refreshCounter && refreshCounter > 0) {
            jotaiForm.reset((draft) => {
              onData(data, draft)
            })
          }
        })
        .then(() => {
          return urql
            .query<Q>(document, selectedVariables, {
              ...queryOpts,
              requestPolicy: refreshCounter && refreshCounterHasChanged ? 'network-only' : queryOpts?.requestPolicy,
            })
            .toPromise()
            .then((response) => {
              jotaiForm.reset((form) => {
                if (form.urqlIsFetching?.[name]) {
                  form.urqlIsFetching[name] = false
                }
              })
              if (response.error && response.error.message) {
                jotaiForm.set((form) => {
                  if (!form.urqlErrors) {
                    form.urqlErrors = {}
                  }
                  form.urqlErrors[name] = response.error as any
                })
                return
              }
              if (response.data) {
                const data = response.data
                jotaiForm.reset((draft) => {
                  if (!draft.urqlSuccesses) {
                    draft.urqlSuccesses = {}
                  }
                  draft.urqlSuccesses[name] = true
                  onData(data, draft)
                })
              }
            })
        })
        .catch(errorHandler)

      jotaiForm.reset((form) => {
        if (!form.urqlIsFetching) {
          form.urqlIsFetching = {}
        }
        form.urqlIsFetching[name] = true
        if (!form.urqlIsPaused) {
          form.urqlIsPaused = {}
        }
        form.urqlIsPaused[name] = false
      })
    } else {
      jotaiForm.reset((form) => {
        if (!form.urqlIsPaused) {
          form.urqlIsPaused = {}
        }
        form.urqlIsPaused[name] = true
      })
    }
  }, [
    document,
    jotaiForm,
    name,
    onData,
    pauseQueryResult,
    queryOpts,
    selectedVariables,
    refreshCounter,
    urql,
    refreshCounterHasChanged,
    errorHandler,
  ])
}
