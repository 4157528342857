import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid, MenuItem } from '@mui/material'
import JotaiMuiSelect from '../../../../../components/input/JotaiMuiSelect'
import { useJotaiFormContext } from '../../../../../components/state/jotaiFormProvider'
import { Cycle } from '../../../../../generated/graphql'

export function BillingAnchorDateField() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  return (
    <Grid item xs={2}>
      <JotaiMuiSelect
        atomSelector={(form) => form.orderDetail.billingCycle?.cycle}
        atomUpdater={(value, draft) => {
          if (!draft.orderDetail.billingCycle) {
            draft.orderDetail.billingCycle = {
              step: 1,
              cycle: Cycle.Year,
            }
          }
          draft.orderDetail.billingCycle.cycle = value as Cycle
          draft.orderDetail.billingAnchorDate = undefined
        }}
        errorPath="orderDetail.billingCycle.cycle"
        form={jotaiForm}
        textFieldProps={{
          label: 'Billing Cycle',
        }}
      >
        <MenuItem value={'MONTH'}>Monthly</MenuItem>
        <MenuItem value={'QUARTER'}>Quarterly</MenuItem>
        <MenuItem value={'SEMI_ANNUAL'}>Semi-Annual</MenuItem>
        <MenuItem value={'YEAR'}>Yearly</MenuItem>
        <MenuItem value={'PAID_IN_FULL'}>Upfront</MenuItem>
      </JotaiMuiSelect>
    </Grid>
  )
}
