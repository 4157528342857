import React from 'react'
import { getEnv } from '../../util/isEnv'
import Usersnap from '../feedback/usersnap'
import { EnvInfoBanner } from './EnvInfoBanner'

type LeftFixedBannerProps = {
  authenticated?: boolean
}
export const LeftFixedBanner: React.FC<React.PropsWithChildren<LeftFixedBannerProps>> = ({ authenticated }) => {
  const envInfo = getEnv()
  return (
    <div role="banner">
      {envInfo === 'prod' ? !!authenticated && <Usersnap /> : !!envInfo && <EnvInfoBanner env={envInfo} />}
    </div>
  )
}
