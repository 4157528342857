import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { useDryRunError } from '@/pageComponents/orders/useDryRunError'
import isEqual from 'fast-deep-equal/es6/react'
import { useMemo } from 'react'
import { DeepMutable, deepMutable } from '../../../components/SchemaForm/DeepMutable'
import { useBillyRouter } from '../../../components/route/useBillyRouter'
import JotaiFormProvider from '../../../components/state/jotaiFormProvider'
import useJotaiForm from '../../../components/state/useJotaiForm'
import { ActionType, LineItemFragment } from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { getDefaultOrderDetail } from '../../../util/order'
import { CommonOrderFormPageState } from '../EditOrderPage/CommonOrderFormPageState'

export const logger = buildLogger('CancellationOrderPage')

export type CancellationOrderPageState = Omit<
  CommonOrderFormPageState,
  'isLineItemDialogOpen' | 'isReset' | 'autoRenew'
>

function CancellationOrderPage({ children }: { children: React.ReactNode }): JSX.Element {
  const router = useBillyRouter()

  const subscriptionId = router.query.subscriptionId as string | undefined

  const { queueDryRun } = useDryRunActions()

  const jotaiForm = useJotaiForm<CancellationOrderPageState>(
    useMemo(
      () => ({
        defaultValue: {
          orderDetail: deepMutable(getDefaultOrderDetail()),
          orderPlans: [],
          orderPlansForTerms: [],
          approvalSegments: [],
          shipToBilling: true,
          subscription: { id: subscriptionId ?? '', account: { id: '', name: '' }, charges: [] },
          shouldRecalculateTotals: false,
          _dangerousInputCounterWhichCausesRerender: 0,
          templateList: [],
          documentMasterTemplates: [],
          hasHubSpotIntegration: false,
          hasSalesforceIntegration: false,
          prepaidRampEnabled: false,
        },
        onSet: (oldValue, newValue, draft) => {
          // Calculate the plans which have been added to the order from the line items
          if (oldValue.orderDetail.lineItems !== newValue.orderDetail.lineItems) {
            draft.orderPlans = deepMutable(
              newValue.orderDetail.lineItems.reduce((result, lineItem) => {
                const { plan } = lineItem
                if (plan) {
                  if (!result.some((resultPlan) => resultPlan?.id === plan.id)) {
                    deepMutable(result).push(plan)
                  }
                }
                return result
              }, [] as DeepMutable<NonNullable<LineItemFragment['plan']>[]>)
            )
            // For amendments, only add plans from line items with an action of neither None nor Remove
            draft.orderPlansForTerms = deepMutable(
              newValue.orderDetail.lineItems
                .filter((lineItem) => lineItem.action !== ActionType.None && lineItem.action !== ActionType.Remove)
                .reduce((result, lineItem) => {
                  const { plan } = lineItem
                  if (plan) {
                    if (!result.some((resultPlan) => resultPlan?.id === plan.id)) {
                      deepMutable(result).push(plan)
                    }
                  }
                  return result
                }, [] as DeepMutable<NonNullable<LineItemFragment['plan']>[]>)
            )
          }

          // Do totals need to be recalculated?
          if (!isEqual(oldValue.orderDetail.startDate, newValue.orderDetail.startDate)) {
            queueDryRun()
          }
        },
      }),
      [subscriptionId, queueDryRun]
    )
  )

  useDryRunError({ jotaiForm }, { silent: true })

  return <JotaiFormProvider form={jotaiForm}>{children}</JotaiFormProvider>
}

export default CancellationOrderPage
