import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'

type EsignInProgressDialogProps = {
  isOrderDocumentSealed?: boolean
}

type DialogProps = WithModalParams & EsignInProgressDialogProps
const formID = 'EsignInProgressDialog'
function EsignInProgressDialog({ open, onClose, isOrderDocumentSealed }: DialogProps): JSX.Element {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>eSignature {isOrderDocumentSealed ? 'Completed' : 'In Progress'}</DialogTitle>
      <DialogContent dividers>
        {isOrderDocumentSealed ? (
          <Typography>
            Cannot revert order to draft once the eSignature process has been finalized. Please note that the process
            has been completed and cannot be reversed.
          </Typography>
        ) : (
          <Typography>
            Cannot revert order to draft while eSignature is in progress. Please void the eSignature request in View
            eSignature Details &gt; Void and try again.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Close'}
          buttonData={{
            label: 'Close',
            onClick: onClose,
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useEsignInProgressDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: EsignInProgressDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
