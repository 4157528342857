import { Skeleton, Tooltip, useTheme } from '@mui/material'

export function LineItemCellLoadingIndicator() {
  const theme = useTheme()
  return (
    <Tooltip title="Recalculating...">
      <Skeleton
        aria-label="line-item-cell-loading-indicator"
        variant="rectangular"
        width={'100%'}
        height={theme.typography.fontSize}
      />
    </Tooltip>
  )
}
