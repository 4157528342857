import React from 'react'
import { CustomFieldParentType, useGetCustomFieldsQuery } from '../../generated/graphql'
import BillyDetailsCard from '../card/billyDetailsCard'
import { Button, Grid } from '@mui/material'
import { useEditCustomFieldsDialog } from './EditCustomFieldsDialog'
import { formatCustomFieldEntry } from './utils'

interface CustomFieldsCardProps {
  customFieldType: CustomFieldParentType
  parentObjectId: string
  canEditCustomFields: boolean
  title?: string
}

export function CustomFieldsCard({
  customFieldType,
  parentObjectId,
  canEditCustomFields,
  title,
}: CustomFieldsCardProps) {
  const [customFieldsResponse] = useGetCustomFieldsQuery({
    variables: { parentObjectType: customFieldType, parentObjectId },
  })

  const customFields = customFieldsResponse.data?.customFields ?? []
  const toggleEditCustomFieldsModal = useEditCustomFieldsDialog({
    parentObjectType: customFieldType,
    parentObjectId,
  })
  if (customFields.length > 0) {
    return (
      <Grid item xs={12}>
        <BillyDetailsCard
          title={title || 'Custom Fields'}
          isMainCard={false}
          action={
            canEditCustomFields ? (
              <Button onClick={toggleEditCustomFieldsModal} variant="outlined">
                Modify
              </Button>
            ) : undefined
          }
          rows={[
            {
              cells: customFields.map(formatCustomFieldEntry),
            },
          ]}
        />
      </Grid>
    )
  } else return null
}
