import { useEffect, useRef } from 'react'

// legacy
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePrevious = (value): any => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useHasChanged = <T>(val: T): boolean => {
  const prevVal = usePrevious(val)
  return prevVal !== val
}

export const useHasChangedAfterInit = <T>(val: T): boolean => {
  const prevVal = usePrevious(val)
  if (prevVal === undefined) {
    return false
  }
  return prevVal !== val
}

export default useHasChanged
