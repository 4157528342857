import { CustomizationActionType, Feature, usePlanAdditionCustomizationQuery } from '../../generated/graphql'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { WithCustomizationSlot } from './AddPlanDataGridDialog'
import buildLogger from '@/util/logger'
import { useCustomizationParser } from './useCustomizationParser'

export const logger = buildLogger('useAddPlanCustomizationSlotProps')
export function useAddPlanCustomizationSlotProps(accountId: string): WithCustomizationSlot {
  const isCustomizationEnabled = useDynamicFeatureFlag(Feature.PlanAdditionCustomization)
  const [planAdditionCustomizationResponse] = usePlanAdditionCustomizationQuery({
    variables: {
      planCustomizationInput: {
        accountId,
      },
    },
    pause: !accountId || !isCustomizationEnabled,
  })

  const { parseSafe } = useCustomizationParser()

  const encodedMetadata =
    planAdditionCustomizationResponse.data?.runPlanAdditionCustomization.find(
      (action) => action.customizationActionType === CustomizationActionType.AddPlanApplyFilter
    )?.actionMetadata ?? undefined

  const decodedMetadata = encodedMetadata ? parseSafe(Buffer.from(encodedMetadata, 'base64').toString()) : undefined
  logger.trace({ decodedMetadata })

  return {
    slot: isCustomizationEnabled
      ? {
          defaultFilterModel: decodedMetadata?.defaultFilterModel,
        }
      : undefined,
  }
}
