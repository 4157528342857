import React, { useContext } from 'react'
import buildLogger from '../../util/logger'
import { JotaiForm } from './useJotaiForm'

const logger = buildLogger('JotaiFormProvider')

export const JotaiFormContext = React.createContext({} as JotaiForm<any>)

type JotaiFormContextProps<T> = {
  children?: React.ReactNode
  form: JotaiForm<T>
}

export default function JotaiFormProvider<T>({ children, form }: JotaiFormContextProps<T>): JSX.Element {
  return <JotaiFormContext.Provider value={form as any}>{children}</JotaiFormContext.Provider>
}

export const useJotaiFormContext = <T,>() => {
  return useContext<JotaiForm<T>>(JotaiFormContext)
}
