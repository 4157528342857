import React from 'react'
import { Breadcrumbs, Link, Typography } from '@mui/material'
import NextLink from 'next/link'
import { makeStyles } from 'tss-react/mui'

type Props = {
  breadcrumbs: BreadcrumbDataList
}

type BreadcrumbData = {
  label: string
  link?: string
  icon?: JSX.Element | string
}

const useStyles = makeStyles()(() => ({
  root: {
    minWidth: 0,
    cursor: 'default',
  },
  container: {
    flexFlow: 'row nowrap',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
  },
  icon: {
    minWidth: '18px',
    display: 'flex',
  },
}))

export type BreadcrumbDataList = BreadcrumbData[]

export default function BreadcrumbNav({ breadcrumbs }: Props): JSX.Element {
  const { classes } = useStyles()
  return (
    <Breadcrumbs
      className={classes.root}
      classes={{
        ol: classes.container,
      }}
      sx={{
        visibility: ['hidden', 'unset'],
      }}
    >
      {breadcrumbs.map((breadcrumbData, index) => {
        if (breadcrumbData.link) {
          return (
            <NextLink key={`i-${index}`} href={breadcrumbData.link} passHref>
              <Link
                color="primary"
                style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem', fontWeight: 600 }}
              >
                {breadcrumbData.icon && <span className={classes.icon}>{breadcrumbData.icon}</span>}
                {breadcrumbData.label}
              </Link>
            </NextLink>
          )
        }
        return (
          <Typography key={`i-${index}`} color="textPrimary" style={{ fontSize: '0.875rem', fontWeight: 600 }}>
            {breadcrumbData.icon && <span className={classes.icon}>{breadcrumbData.icon}</span>}
            {breadcrumbData.label}
          </Typography>
        )
      })}
    </Breadcrumbs>
  )
}
