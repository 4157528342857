import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { OrderDetailFragment, useGetCompositeOrderQuery } from '@/generated/graphql'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

type DialogProps = WithModalParams & { onSubmit: () => void }

type RenewalOrderPageRedirectModal = {
  orderData:
    | ({
        readonly __typename?: 'OrderDetail' | undefined
      } & OrderDetailFragment)
    | undefined
}

const RenewalOrderPageRedirectModal: React.FC<DialogProps> = ({ open, onClose, onSubmit }): JSX.Element => {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Edit Renewal Order</DialogTitle>
      <DialogContent dividers>Do you want to update the associated renewal order as well?</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onSubmit()
            onClose?.()
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useRenewalOrderPageRedirectModalEffect = ({ orderData }: RenewalOrderPageRedirectModal) => {
  const [, , toggleModal] = useModalsContext()
  const key = 'renewalOrderPageRedirectModal'

  const router = useRouter()
  const amendmentData = router.query.amendmentData
  const orderId = orderData?.id
  const compositeOrderId = orderData?.compositeOrderId ?? ''
  const [compositeOrderResponse] = useGetCompositeOrderQuery({
    variables: { id: compositeOrderId },
    pause: !compositeOrderId,
  })
  const compositeOrderData = compositeOrderResponse.data?.compositeOrder
  const renewalOrderId = compositeOrderData?.orderIds.find((id) => id !== orderId)

  const onSubmit = () => {
    void router.push(`/orders/${renewalOrderId}/edit`)
  }
  useModal({
    component: RenewalOrderPageRedirectModal,
    schema: {
      key,
      modalProps: { onSubmit },
    },
  })
  useEffect(() => {
    if (amendmentData && renewalOrderId) {
      toggleModal(key)
    }
  }, [amendmentData, renewalOrderId, toggleModal])
}

export default useRenewalOrderPageRedirectModalEffect
