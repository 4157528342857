import { z } from 'zod'

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])
type Literal = z.infer<typeof literalSchema>
type Json = Literal | { [key: string]: Json } | Json[]
const jsonSchema: z.ZodType<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))
/**
 * https://github.com/JacobWeisenburger/zod_utilz/blob/4093595e5a6d95770872598ba3bc405d4e9c963b/src/json.ts
 */
export const json = () => jsonSchema
/**
 * https://github.com/JacobWeisenburger/zod_utilz/blob/4093595e5a6d95770872598ba3bc405d4e9c963b/src/stringToJSON.ts#LL4-L12C8
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringToJSONSchema = z.string().transform((str, ctx): z.infer<ReturnType<() => any>> => {
  try {
    return JSON.parse(str)
  } catch (e) {
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' })
    return z.NEVER
  }
})

export const parseJson = (data: string) => {
  try {
    return stringToJSONSchema.parse(data)
  } catch (err) {
    if (err instanceof z.ZodError) {
      throw new Error(err.issues?.[0].message ?? err.message)
    } else {
      throw err
    }
  }
}
