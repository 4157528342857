import { AutocompleteProps, Grid, TextFieldProps } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import { LayoutProps } from '../BillyTsForm'
import { BillyMuiEsAutocomplete } from '../../input/JotaiMuiEsAutocomplete'
import { FuzzySearchProps } from '../../search/useFuzzySearch'
import { z } from 'zod'
import { DataTypeWithIdAndLabel } from '../../input/JotaiMuiAutocomplete'
import { useCallback, useState } from 'react'

export type EsAutocompleteFieldProps<Multi extends boolean> = LayoutProps & {
  fuzzySearchProps: Partial<FuzzySearchProps> | Partial<FuzzySearchProps>[]
  initialOptions?: Array<DataTypeWithIdAndLabel>
  mapSearchResultsToOptions: (searchResults: Array<unknown>) => Array<DataTypeWithIdAndLabel>
  textFieldProps?: TextFieldProps
  getOptionLabel?: (option: DataTypeWithIdAndLabel) => string
  autocompleteProps?: Partial<AutocompleteProps<DataTypeWithIdAndLabel, Multi, boolean, false>> | undefined
  tenantId?: string
}

export const EsAutocompleteSchema = createUniqueFieldSchema(
  z.object({
    id: z.string(),
    label: z.string(),
  }),
  'autocomplete'
)

export function EsAutocompleteField({
  layout,
  fuzzySearchProps,
  initialOptions = [],
  mapSearchResultsToOptions,
  getOptionLabel = (option) => option.label || '',
  textFieldProps,
  autocompleteProps,
  tenantId,
}: EsAutocompleteFieldProps<false>) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<DataTypeWithIdAndLabel>()
  const { label, placeholder } = useDescription()
  const [options, setOptions] = useState<Array<DataTypeWithIdAndLabel>>(initialOptions)
  const onSearchUpdate = useCallback(
    (searchResults: Array<unknown>) => {
      const newOptions = mapSearchResultsToOptions(searchResults)
      setOptions([...initialOptions, ...newOptions])
    },
    [initialOptions, mapSearchResultsToOptions]
  )

  return (
    <Grid container item xs {...layout}>
      <BillyMuiEsAutocomplete<DataTypeWithIdAndLabel, false>
        onSearchUpdate={onSearchUpdate}
        autocompleteProps={{
          ...autocompleteProps,
          disableCloseOnSelect: true,
          options: options,
          value,
          onChange: (_event, value) => {
            if (value) {
              onChange(value)
            }
          },
          getOptionLabel,
        }}
        fuzzySearchProps={fuzzySearchProps}
        error={error?.errorMessage || ''}
        textFieldProps={{
          label,
          disabled: isLoading || isSubmitting,
          placeholder: placeholder,
          ...textFieldProps,
        }}
        tenantId={tenantId}
      />
    </Grid>
  )
}

export const EsMultiAutocompleteSchema = createUniqueFieldSchema(
  z.array(
    z.object({
      id: z.string(),
      label: z.string(),
    })
  ),
  'autocomplete-multi'
)

export function EsMultiAutocompleteField({
  layout,
  fuzzySearchProps,
  textFieldProps,
  getOptionLabel = (option) => option.label || '',
  initialOptions = [],
  mapSearchResultsToOptions,
  autocompleteProps,
}: EsAutocompleteFieldProps<true>) {
  const {
    field: { value, onChange },
    error,
    formState: { isLoading, isSubmitting },
  } = useTsController<Array<DataTypeWithIdAndLabel>>()
  const { label, placeholder } = useDescription()
  const [options, setOptions] = useState<Array<DataTypeWithIdAndLabel>>(initialOptions)
  const onSearchUpdate = useCallback(
    (searchResults: Array<unknown>) => {
      const newOptions = mapSearchResultsToOptions(searchResults)
      setOptions([...initialOptions, ...newOptions])
    },
    [initialOptions, mapSearchResultsToOptions]
  )

  return (
    <Grid container item xs {...layout}>
      <BillyMuiEsAutocomplete<DataTypeWithIdAndLabel, true>
        onSearchUpdate={onSearchUpdate}
        autocompleteProps={{
          ...autocompleteProps,
          disableCloseOnSelect: true,
          multiple: true,
          options: options,
          value,
          onChange: (_event, value) => {
            if (value) {
              onChange(value)
            }
          },
          getOptionLabel,
        }}
        fuzzySearchProps={fuzzySearchProps}
        error={error?.errorMessage || ''}
        textFieldProps={{
          label,
          disabled: isLoading || isSubmitting,
          placeholder: placeholder,
          ...textFieldProps,
        }}
      />
    </Grid>
  )
}
