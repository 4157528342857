import { useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { debounceDelayMillis } from '../../../../util/order'
import buildLogger from '@/util/logger'
const logger = buildLogger('useDebouncedActionEffect')

export function useDebouncedActionEffect(realtimeCounter: number, onTrigger?: () => void) {
  const [_debouncedCounter] = useDebounce(realtimeCounter, debounceDelayMillis, {
    trailing: true,
  })

  useEffect(() => {
    if (_debouncedCounter > 0 && _debouncedCounter === realtimeCounter) {
      onTrigger?.()
    }
  }, [_debouncedCounter, realtimeCounter, onTrigger])
}
