import { Typography, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import React from 'react'
import { EnvType } from '../../util/isEnv'
import buildLogger from '../../util/logger'
import { DeepImmutable } from '../SchemaForm/DeepImmutable'

const logger = buildLogger('EnvInfoBar')

export type EnvInfoBannerProps = DeepImmutable<{
  env: EnvType
}>

export const EnvInfoBanner: React.FC<React.PropsWithChildren<EnvInfoBannerProps>> = ({ env }) => {
  const theme = useTheme()
  return env === 'prod' ? (
    <></>
  ) : (
    <div
      role="banner"
      style={{
        right: '100%',
        border: 'none',
        bottom: '48px',
        height: '38px',
        margin: 0,
        display: 'flex',
        outline: 'none',
        padding: '0px 14px',
        overflow: 'hidden',
        position: 'fixed',
        transform: 'rotateZ(90deg) translateX(0px) translateY(0px)',
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'rgb(0 0 0 / 10%) -4px -12px 25px 0px',
        transition: 'transform 400ms ease 0s',
        alignItems: 'center',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        borderRadius: '2px 2px 0px 0px',
        justifyContent: 'center',
        transformOrigin: '100% 100%',
        appearance: 'none',
        zIndex: theme.zIndex.tooltip,
      }}
    >
      <Typography
        variant="body1"
        fontWeight={(theme: Theme) => theme.typography.fontWeightBold}
        color={'secondary.contrastText'}
      >
        {env}
      </Typography>
    </div>
  )
}
