import React, { ForwardedRef, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { InputAdornment, TextField, Tooltip } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Theme } from '@mui/material/styles'
import { Draft } from 'immer'
import { useAtom } from 'jotai'
import { selectAtom, useUpdateAtom } from 'jotai/utils'
import { makeStyles } from 'tss-react/mui'
import { useYupError } from '../../util/jotai'
import buildLogger from '../../util/logger'
import { PageLoadingContext } from '../placeholder/pageLoadingPlaceholder'
import { JotaiForm } from '../state/useJotaiForm'

const logger = buildLogger('JotaiMuiField')

export type BillyMuiFieldProps = {
  children?: React.ReactNode
  disabledExplanation?: string | false
  helpTooltip?: React.ReactNode
  textFieldProps?: TextFieldProps
  error?: string
  isLoading?: boolean
  hidden?: boolean
}

export type JotaiMuiFieldProps<T> = {
  atomSelector: (form: T) => string | undefined | null
  atomUpdater?: (value: string, draft: Draft<T>) => void
  form: JotaiForm<T>
  selectOnFocus?: boolean
  errorPath?: string
} & BillyMuiFieldProps

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    width: '100%',
  },
  helpAdornment: {
    cursor: 'default',
    color: theme.palette.text.secondary,
  },
}))

function JotaiMuiField<T>(
  {
    atomSelector,
    atomUpdater,
    children,
    disabledExplanation,
    errorPath,
    form,
    helpTooltip,
    selectOnFocus,
    textFieldProps,
  }: JotaiMuiFieldProps<T>,
  ref: ForwardedRef<HTMLInputElement> | undefined
): JSX.Element {
  const { isLoading } = useContext(PageLoadingContext)
  const inputRef = useRef<HTMLInputElement | undefined>()
  const { atom } = form
  const setAtom = useUpdateAtom(atom) as (updater: (draft: Draft<T>) => void) => void
  const selectorAtom = useMemo(() => selectAtom(atom, atomSelector), [atom, atomSelector])
  const [atomValue] = useAtom(selectorAtom)

  const error = useYupError(form, errorPath ?? '')

  const { className: textFieldClassName, ...otherTextFieldProps } = textFieldProps || {}

  useEffect(() => {
    if (error) {
      const refObj = ref as MutableRefObject<HTMLInputElement>
      if (refObj) {
        refObj?.current?.focus()
      } else {
        inputRef.current?.focus()
      }
    }
  }, [error, ref])

  return (
    <BillyMuiField
      disabledExplanation={disabledExplanation}
      textFieldProps={{
        inputRef: ref || inputRef,
        className: textFieldClassName,
        onChange: useCallback(
          (e) => {
            if (atomUpdater) {
              const value = e.target.value
              setAtom((draft) => {
                atomUpdater(value, draft)
              })
            }
          },
          [atomUpdater, setAtom]
        ),
        onFocus: useCallback((event) => selectOnFocus && event.target.select(), [selectOnFocus]),
        value: atomValue ?? '',
        ...otherTextFieldProps,
      }}
      error={error}
      helpTooltip={helpTooltip}
      isLoading={isLoading}
    >
      {children}
    </BillyMuiField>
  )
}

export function BillyMuiField({
  children,
  disabledExplanation,
  error,
  helpTooltip,
  textFieldProps,
  isLoading,
}: BillyMuiFieldProps) {
  const { classes, cx } = useStyles()
  const {
    className: textFieldClassName,
    inputRef,
    onChange,
    onFocus,
    value,
    ...otherTextFieldProps
  } = textFieldProps || {}
  return (
    <Tooltip title={disabledExplanation || ''}>
      <div style={{ width: '100%' }}>
        <TextField
          inputRef={inputRef}
          className={cx(classes.textField, textFieldClassName)}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          disabled={!!disabledExplanation || isLoading}
          error={!!error}
          helperText={error}
          InputLabelProps={{
            ...otherTextFieldProps.InputLabelProps,
            disableAnimation: isLoading,
          }}
          InputProps={{
            endAdornment: helpTooltip && (
              <InputAdornment position="end" className={classes.helpAdornment}>
                <Tooltip title={helpTooltip} arrow>
                  <HelpOutlineIcon />
                </Tooltip>
              </InputAdornment>
            ),
            ...otherTextFieldProps.InputProps,
          }}
          name={
            otherTextFieldProps.name ? otherTextFieldProps.name : (otherTextFieldProps.label as string)?.toLowerCase()
          }
          {...otherTextFieldProps}
        >
          {children}
        </TextField>
      </div>
    </Tooltip>
  )
}

export default React.forwardRef(JotaiMuiField)
