import BillyAlert from './alert'

export function PurchaseOrderWarning({
  type,
  hasPurchaseOrderNumber,
  purchaseOrderRequiredForInvoicing,
}: {
  type: 'order' | 'subscription' | 'invoice'
  hasPurchaseOrderNumber: boolean
  purchaseOrderRequiredForInvoicing: boolean | null | undefined
}) {
  if (!hasPurchaseOrderNumber && purchaseOrderRequiredForInvoicing) {
    return (
      <BillyAlert showIcon alertType="warning" message={`This ${type} is missing a P.O. required for invoicing.`} />
    )
  } else return null
}
