import buildLogger from '@/util/logger'
import { TextField } from '@mui/material'
import { BaseSingleInputFieldProps, FieldSection } from '@mui/x-date-pickers/'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { formatLuxonDate } from '../../util/datetime/luxon/dateUtil'

const logger = buildLogger('BillyDateTextField')

export const BillyDateTextField = React.forwardRef(function DateTextField(
  props: BaseSingleInputFieldProps<DateTime | null, DateTime, FieldSection, unknown> & {
    error?: boolean
    required?: boolean
    helperText?: string
  },
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const format = props.format ?? 'MM/dd/yyyy'

  const [textValue, setTextValue] = useState(props.value?.toFormat(format) ?? '')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setTextValue(
      formatLuxonDate({
        date: props.value,
        format,
        timezone: props.timezone,
      })
    )
    setErrorMessage('')
  }, [props, format])

  const updateDateFromTextValue = useCallback(() => {
    let validationError = ''
    let newDate: DateTime | null = null
    if (!textValue) {
      props.onChange?.(null, {
        validationError,
      })
      return
    }
    try {
      const inputDate = DateTime.fromFormat(textValue, 'M/d/yy', { zone: props.timezone })
      if (inputDate.isValid) {
        newDate = inputDate
      } else {
        throw new Error('Invalid date')
      }
    } catch (err) {
      validationError = format
      setErrorMessage(validationError)
    }
    const shouldUpdate = newDate?.toSeconds() !== props.value?.toSeconds()
    if (shouldUpdate) {
      if (!validationError) {
        props.onChange?.(newDate, {
          validationError,
        })
      }
      setTextValue(
        formatLuxonDate({
          date: props.value,
          format,
          timezone: props.timezone,
        })
      )
    }
  }, [props, textValue, format])

  return (
    <TextField
      ref={ref}
      disabled={props.disabled}
      label={props.label}
      required={props.required}
      InputProps={{
        placeholder: format,
        ...props.InputProps,
      }}
      inputProps={{
        role: 'textbox',
        'aria-label': props.label?.toString() ?? 'date-picker-input',
        ref: props.inputRef,
      }}
      InputLabelProps={{
        sx: {
          maxWidth: 'calc(100% - 52px)',
        },
      }}
      value={textValue}
      onChange={(event) => {
        setTextValue(event.target.value)
      }}
      onBlur={() => {
        updateDateFromTextValue()
      }}
      error={!!errorMessage || !!props.error}
      helperText={errorMessage || props.helperText}
    />
  )
})
