import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

export function getJwtEmail(idToken: string): string {
  const jwt = jwtDecode<any>(idToken)
  if (jwt) {
    return jwt.email
  }
  return ''
}

export function getJwtEmailFromCookie(): string {
  const idToken = Cookies.get('id_token')
  if (idToken) {
    return getJwtEmail(idToken)
  }
  return ''
}

export function setWasAtUrl(): void {
  const email = getJwtEmailFromCookie()
  if (email) {
    Cookies.set(`wasAtUrl-${getJwtEmailFromCookie()}`, window.location.href)
    Cookies.remove('wasAtUrl')
  } else {
    Cookies.set(`wasAtUrl`, window.location.href)
  }
}

export function getExpireOn(): string | undefined {
  // can also get from JWT, but does not cover the challenge case
  return Cookies.get('expire_on')
}

export function getWasAtUrl(): string | undefined {
  const email = getJwtEmailFromCookie()
  if (email) {
    return Cookies.get(`wasAtUrl-${email}`)
  }
  return Cookies.get('wasAtUrl')
}
