import React, { useCallback } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import ActionButton from '../button/actionButton'

export interface RevertToDraftWarningInterface {
  revertToDraft: () => void
}

type DialogProps = WithModalParams & RevertToDraftWarningInterface

function SalesRoomLinkRevertToDraftWarningDialog({ open, onClose, revertToDraft }: DialogProps) {
  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogTitle>Sales Room Link Warning</DialogTitle>
      <DialogContent dividers>
        <Typography>
          The previously generated sales room link will be invalid once the order is reverted to draft.
        </Typography>
      </DialogContent>
      <DialogActions>
        <ActionButton
          key={'Close'}
          buttonData={{
            label: 'Close',
            onClick: onClose,
            buttonProps: {
              variant: 'outlined',
            },
          }}
        />
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Revert To Draft',
            onClick: () => {
              revertToDraft()
              onClose?.()
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useSalesRoomLinkRevertToDraftWarningDialog(modalProps: RevertToDraftWarningInterface) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: SalesRoomLinkRevertToDraftWarningDialog,
    schema: {
      key: `salesRoomLinkRevertToDraftWarningDialog`,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal('salesRoomLinkRevertToDraftWarningDialog')
  }, [toggleModal])
}
