import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import JotaiMuiCheckbox from '../../../../../components/input/JotaiMuiCheckbox'
import { useJotaiFormContext } from '../../../../../components/state/jotaiFormProvider'

export function PurchaseOrderRequiredInvoicingField() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  return (
    <Grid item xs={6}>
      <JotaiMuiCheckbox
        atomSelector={(form) => form.orderDetail.purchaseOrderRequiredForInvoicing}
        atomUpdater={(value, draft) => (draft.orderDetail.purchaseOrderRequiredForInvoicing = value)}
        form={jotaiForm}
        fieldLabel="Purchase Order Required for Invoicing"
      />
    </Grid>
  )
}
