import { Grid, Typography } from '@mui/material'
import { createUniqueFieldSchema, useDescription, useTsController } from '@ts-react/form'
import { FileUpload } from '../../FileUpload/FileUpload'
import { z } from 'zod'
import { useState } from 'react'
import { useErrorHandler } from '../../ErrorHandler/ErrorHandler'
import { DropzoneOptions } from 'react-dropzone'
import BillyLink from '../../link/billyLink'
import { PriceAttributeFragment } from '../../../generated/graphql'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { downloadEncodedUri } from '../../../util/file'
import { createPriceTableRowsAndColumns } from '@/pageComponents/rateCard/RateCardView'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import Papa from 'papaparse'

export type PriceTableCsvUploadFieldProps = {
  priceAttributes: ReadonlyArray<PriceAttributeFragment>
  currency?: string
}

const baseSchema = z.array(
  z.object({
    price: z.number(),
    attributeReferences: z.array(
      z.object({
        attributeValue: z.string(),
        attributeDefinitionId: z.string(),
      })
    ),
  })
)
export const PriceTableCsvUploadFieldSchema = createUniqueFieldSchema(baseSchema, 'price-table')

export function PriceTableCsvUploadField({ priceAttributes, currency = 'USD' }: PriceTableCsvUploadFieldProps) {
  const {
    field: { value, onChange },
    error: zodError,
    formState: { isLoading, isSubmitting },
  } = useTsController<z.infer<typeof PriceTableCsvUploadFieldSchema>>()
  const { label } = useDescription()
  const snackbarHandler = useSnackbarHandler()

  const [uploading, setUploading] = useState<boolean>(false)
  const errorHandler = useErrorHandler()
  const csvTemplateLabels: string[] = [
    ...(priceAttributes?.map((field) => {
      return field.name || ''
    }) || []),
    'price',
  ]

  function downloadSample() {
    const csvContent = 'data:text/csv;charset=utf-8,' + csvTemplateLabels.join(',')
    const encodedUri = encodeURI(csvContent)
    downloadEncodedUri({ encodedUri, fileName: `price_table_template.csv` })
  }

  const onDropAccepted: DropzoneOptions['onDropAccepted'] = function (selectedFiles) {
    async function doAsync() {
      const uploadFile = selectedFiles[0]
      setUploading(true)
      try {
        const csvString = await uploadFile.text()
        const csvData = Papa.parse(csvString, {
          delimiter: ',',
        })
        if (csvData.errors.length > 0) {
          throw new Error(csvData.errors[0].message)
        }
        const csvArray = (csvData.data as Array<Array<string>>).filter((row) => row.length > 1) // This is to remove any empty rows
        const labels = csvArray[0].slice(0, -1) // Ignores the price line
        labels.forEach((label) => {
          if (!csvTemplateLabels.includes(label)) {
            throw new Error(`${label} on row 1 is not a valid attribute`)
          }
        })
        const priceTable: z.infer<typeof baseSchema> = csvArray.slice(1).map((row) => {
          const priceString = z
            .string({ required_error: 'Price must be present and the last item in a row' })
            .parse(row.at(-1))
          const price = parseFloat(priceString)
          try {
            z.number().parse(price)
          } catch (error) {
            throw new Error(`"${priceString}" is not a valid price`)
          }
          const unfilteredAttributeReferences = row
            .slice(0, -1)
            .map((attributeValue) => attributeValue.trim())
            .map((attributeValue, index) => {
              if (attributeValue.length === 0) {
                return undefined
              }
              const priceAttribute = priceAttributes.find((attribute) => attribute.name === labels[index])
              const attributeDefinitionId = z
                .string({ required_error: `${label} in row 1 is not a valid attribute` })
                .parse(priceAttribute?.id)
              if (priceAttribute?.values.includes(attributeValue)) {
                return {
                  attributeDefinitionId,
                  attributeValue,
                }
              } else {
                throw new Error(
                  `${attributeValue} in the ${priceAttribute?.name} column is not a valid attribute value for ${priceAttribute?.name}`
                )
              }
            })
          const attributeReferences = unfilteredAttributeReferences.filter(
            (attributeReference) => attributeReference !== undefined
          ) as Array<{
            attributeDefinitionId: string
            attributeValue: string
          }>
          return {
            price,
            attributeReferences,
          }
        })
        if (!priceTable.length) {
          snackbarHandler.pushAlert('Rate card price table entries cannot be blank', 'error')
        }
        onChange(priceTable)
      } catch (error) {
        errorHandler(error)
      } finally {
        setUploading(false)
      }
    }
    doAsync().catch(errorHandler)
  }
  const error = zodError?.errorMessage

  const { rows, columns } = createPriceTableRowsAndColumns({ priceTable: value, priceAttributes, currency })

  return (
    <Grid container item xs={12}>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          paddingBottom: '.5rem',
        }}
      >
        <Typography variant="h6">{label}</Typography>
        <BillyLink
          linkProps={{
            onClick: downloadSample,
          }}
        >
          {'Download CSV Template'}
        </BillyLink>
      </div>
      <FileUpload
        disabled={isLoading || isSubmitting}
        idleMessage={{
          topMessage: 'Drag and drop or click to upload the CSV',
          bottomMessage: '.xl and other file formats not accepted at this time',
        }}
        errorMessage={{
          topMessage: 'The file contained errors detailed below',
          bottomMessage: error,
        }}
        maxFiles={1}
        onDropAccepted={onDropAccepted}
        accept={['.csv']}
        error={!!error}
        uploading={uploading}
      />
      {value?.length && value?.length > 0 ? (
        <div style={{ height: '100%', width: '100%', paddingTop: '.5rem' }}>
          <DataGridPro
            disableColumnMenu={false}
            disableMultipleRowSelection={false}
            disableColumnResize={false}
            autoHeight={true}
            rows={rows}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            density="compact"
            slotProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </div>
      ) : null}
    </Grid>
  )
}
