import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { deepMutable } from '../../../../../components/SchemaForm/DeepMutable'
import JotaiMuiAutocomplete from '../../../../../components/input/JotaiMuiAutocomplete'

export function BillToAutocomplete() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const { disabledExplanation } = jotaiForm.useSelect(
    useCallback((form) => {
      const { account, resoldBy } = form.orderDetail
      return {
        disabledExplanation: !account && !resoldBy ? 'Account or Reseller must be selected first' : '',
      }
    }, [])
  )
  return (
    <Grid item xs={4}>
      <JotaiMuiAutocomplete
        atomOptionsSelector={(form: CommonOrderFormPageState) => deepMutable(form.billingContactList)}
        atomUpdater={(value, draft) => (draft.orderDetail.billingContact = value)}
        atomValueSelector={(form) => form.orderDetail.billingContact}
        autocompleteProps={{
          getOptionLabel: (option) => `${option.firstName} ${option.lastName}`,
        }}
        disabledExplanation={disabledExplanation}
        errorPath="orderDetail.billingContact"
        form={jotaiForm}
        textFieldProps={{ label: 'Bill to' }}
      />
    </Grid>
  )
}
