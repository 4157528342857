import { Box } from '@mui/material'
import { FC, ReactNode, createContext, useContext, useState, useMemo, useCallback } from 'react'
import Loading from './Loading'

export type LoadingHandler = {
  showLoading: () => void
  hideLoading: () => void
  setLoading: (loading: boolean) => void
  loading: boolean
}

const LoadingHandlerContext = createContext<LoadingHandler>({
  showLoading: () => {
    return null
  },
  hideLoading: () => {
    return null
  },
  setLoading: () => {
    return null
  },
  loading: false,
})

interface LoadingHandlerProps {
  readonly children: ReactNode | FC<React.PropsWithChildren<unknown>> | JSX.Element
}

export function useLoadingHandler() {
  return useContext(LoadingHandlerContext)
}

export function LoadingHandler(props: LoadingHandlerProps) {
  const [loadingCount, setLoadingCount] = useState(0)
  const showLoading = useCallback(() => {
    setLoadingCount((loadingCount) => loadingCount + 1)
  }, [])

  const hideLoading = useCallback(() => {
    setLoadingCount((loadingCount) => (loadingCount > 0 ? loadingCount - 1 : 0))
  }, [])

  const setLoading = useCallback((loading: boolean) => {
    setLoadingCount((loadingCount) => (loading ? loadingCount + 1 : 0))
  }, [])

  const loading = useMemo(() => loadingCount > 0, [loadingCount])
  const value = useMemo(
    () => ({ showLoading, hideLoading, setLoading, loading }),
    [showLoading, hideLoading, setLoading, loading]
  )

  return (
    <LoadingHandlerContext.Provider value={value}>
      {loading && <Loading />}
      <Box visibility={loading ? 'hidden' : undefined}>
        <>{props.children}</>
      </Box>
    </LoadingHandlerContext.Provider>
  )
}
