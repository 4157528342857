import React, { useEffect, useRef } from 'react'
import buildLogger from '../../util/logger'
import { nextApiClient } from '../data/billyRestClient'
import { useNewUpdateDeployedDialog } from './NewUpdateDeployedDialog'

const logger = buildLogger('NextBuildRefreshManager')

const BUILD_UPDATE_CHECK_INTERVAL = 30 * 1000 // checks build ID from server every 30 seconds

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useInterval<P extends (...args: any[]) => any>(
  callback: P,
  { interval, lead }: { interval: number; lead?: boolean }
) {
  const savedCallback = useRef<P | null>(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = (): void => savedCallback.current?.()

    lead && tick()

    if (interval !== null) {
      const id = setInterval(tick, interval)

      return () => clearInterval(id)
    }
  }, [interval, lead])
}

export function useBuildUpdateHandler() {
  const [shouldPrompt, setShouldPrompt] = React.useState(true)
  const toggleNewUpdateDeployedDialog = useNewUpdateDeployedDialog({
    onCancel: () => {
      setShouldPrompt(false)
    },
  })

  const [buildId, setBuildId] = React.useState<string | undefined>(undefined)
  async function handleBuildUpdateAsync() {
    const newBuildId = (
      await nextApiClient.request({
        path: '/build-id',
      })
    ).data?.buildId
    if (newBuildId) {
      setBuildId(newBuildId)
    }

    if (shouldPrompt && newBuildId && process.env.BUILD_ID && newBuildId !== process.env.BUILD_ID) {
      toggleNewUpdateDeployedDialog()
    }
  }

  const handleBuildUpdate = () => {
    handleBuildUpdateAsync().catch((error) => {
      logger.warn('Failed to check for build update', error)
    })
  }

  return [handleBuildUpdate, buildId] as [typeof handleBuildUpdate, typeof buildId]
}

export function NextBuildRefreshManager() {
  const [handleBuildUpdate] = useBuildUpdateHandler()

  useInterval(handleBuildUpdate, { interval: BUILD_UPDATE_CHECK_INTERVAL })

  return <></>
}
