import React, { useEffect, useMemo } from 'react'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'
import Big from 'big.js'
import { Draft } from 'immer'
import { selectAtom, useAtomValue } from 'jotai/utils'
import buildLogger from '../../util/logger'
import { trimDecimalPlaces, trimNumber } from '../../util/string'
import JotaiMuiField, { BillyMuiField, BillyMuiFieldProps, JotaiMuiFieldProps } from './JotaiMuiField'
import { StandardTextFieldProps } from '@mui/material'

const logger = buildLogger('JotaiMuiNumberField')

function preventNumberScroll(event): void {
  event.target.blur()
}

interface NumberFieldProps {
  percent?: boolean
  unrestrictedPercentRange?: boolean
  maxDecimalPlaces?: number
}

export type BillyMuiNumberFieldProps = NumberFieldProps &
  BillyMuiFieldProps & {
    textFieldProps: { value: number | undefined; onChange: (value: number) => void }
  }

export type JotaiMuiNumberFieldProps<T> = Omit<Omit<JotaiMuiFieldProps<T>, 'atomSelector'>, 'atomUpdater'> & {
  atomSelector: (form: T) => number | null | undefined
  atomUpdater: (value: number | null | undefined, draft: Draft<T>) => void
} & NumberFieldProps

function percentSelector<T>(
  atomSelector: JotaiMuiNumberFieldProps<T>['atomSelector']
): JotaiMuiNumberFieldProps<T>['atomSelector'] {
  return function percentSelectorInner(form: T): number | null | undefined {
    const rawValue = atomSelector(form)
    if (rawValue === null || rawValue === undefined) {
      return rawValue
    }
    return Big(rawValue).times(100).toNumber()
  }
}

function percentUpdater<T>(
  atomUpdater: JotaiMuiNumberFieldProps<T>['atomUpdater']
): JotaiMuiNumberFieldProps<T>['atomUpdater'] {
  return function percentUpdaterInner(rawValue: number | null | undefined, draft: Draft<T>): void {
    let value = rawValue
    if (rawValue !== null && rawValue !== undefined) {
      value = Big(rawValue).div(100).toNumber()
    }
    atomUpdater(value, draft)
  }
}

function JotaiMuiNumberField<T>(props: JotaiMuiNumberFieldProps<T>): JSX.Element {
  const { atomSelector, atomUpdater, percent, unrestrictedPercentRange, maxDecimalPlaces, ...otherProps } = props
  const { atom } = otherProps.form
  const customAtomSelector = useMemo(
    () => (percent ? percentSelector(atomSelector) : atomSelector),
    [atomSelector, percent]
  )

  const customAtomUpdater = useMemo(() => (percent ? percentUpdater(atomUpdater) : atomUpdater), [atomUpdater, percent])

  const selectorAtom = useMemo(() => selectAtom(atom, customAtomSelector), [atom, customAtomSelector])
  const customAtomValue = useAtomValue(selectorAtom)
  const [valueStr, setValueStr] = React.useState<string | undefined | null>(
    customAtomValue ? customAtomValue.toString() : '0'
  )

  useEffect(() => {
    // This effect handles the case when the atom value is changed by other means than the user input.
    if (customAtomValue !== parseFloat(valueStr + '')) {
      const newAtomValue = customAtomValue + ''
      if (!['0', ''].includes(newAtomValue) || !['-', ''].includes(valueStr + '')) {
        setValueStr(newAtomValue)
      }
    }
  }, [customAtomValue, setValueStr, valueStr])

  const jotaiMuiNumberAtomSelector = (value: T): string => {
    const selectedValue = customAtomSelector(value)
    const selectedValueStr = selectedValue !== null && selectedValue !== undefined ? selectedValue.toString() : ''
    return selectedValueStr
  }

  const newProps: JotaiMuiFieldProps<T> = {
    atomSelector: jotaiMuiNumberAtomSelector,
    atomUpdater: (rawValue, draft) => {
      const trimmedRawValue = trimDecimalPlaces(trimNumber(rawValue), maxDecimalPlaces)
      setValueStr(trimmedRawValue)
      if (['0', ''].includes(trimmedRawValue)) {
        customAtomUpdater(0, draft)
      } else {
        let value = parseFloat(trimmedRawValue)
        if (percent && !unrestrictedPercentRange) {
          value = Math.min(Math.max(value, 0), 100)
        }
        customAtomUpdater(value, draft)
      }
    },
    ...otherProps,
  }

  newProps.textFieldProps = {
    type: 'number',
    onWheel: preventNumberScroll,
    InputLabelProps: { shrink: true, ...props.textFieldProps?.InputLabelProps },
    InputProps: {
      ...props.textFieldProps?.inputProps,
      endAdornment: percent ? <span>&nbsp;%</span> : undefined,
    } as StandardInputProps,
    ...props.textFieldProps,
    value: valueStr,
  }
  return <JotaiMuiField selectOnFocus={true} {...newProps} />
}

export function BillyMuiNumberField({
  percent,
  unrestrictedPercentRange,
  maxDecimalPlaces,
  ...otherProps
}: BillyMuiNumberFieldProps) {
  const valueNumber = otherProps.textFieldProps?.value
  const onChangeNumber = otherProps.textFieldProps?.onChange

  const [valueStr, setValueStr] = React.useState<string | undefined | null>(
    otherProps.textFieldProps?.value ? otherProps.textFieldProps?.value.toString() : '0'
  )

  useEffect(() => {
    // This effect handles the case when the atom value is changed by other means than the user input.
    if (valueNumber !== parseFloat(valueStr + '')) {
      const newValue = valueNumber + ''
      if (!['0', ''].includes(newValue) || !['-', ''].includes(valueStr + '')) {
        setValueStr(newValue)
      }
    }
  }, [valueNumber, valueStr])

  const onChange: StandardTextFieldProps['onChange'] = (event) => {
    const rawValue = event.target.value
    const trimmedRawValue = trimDecimalPlaces(trimNumber(rawValue), maxDecimalPlaces)
    setValueStr(trimmedRawValue)
    if (['0', ''].includes(trimmedRawValue)) {
      onChangeNumber(0)
    } else {
      let value = parseFloat(trimmedRawValue)
      if (percent && !unrestrictedPercentRange) {
        value = Math.min(Math.max(value, 0), 100)
      }
      onChangeNumber(value)
    }
  }

  const newProps = {
    ...otherProps,
    textFieldProps: {
      type: 'number',
      onWheel: preventNumberScroll,
      InputLabelProps: { shrink: true, ...otherProps.textFieldProps?.InputLabelProps },
      InputProps: {
        ...otherProps.textFieldProps?.inputProps,
        endAdornment: percent ? <span>&nbsp;%</span> : undefined,
      } as StandardInputProps,
      ...otherProps.textFieldProps,
      onChange,
      value: valueStr,
    },
  }
  return <BillyMuiField {...newProps} />
}

export default JotaiMuiNumberField
