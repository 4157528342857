import { useMemo } from 'react'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { OperationDefinitionNode } from 'graphql/language/ast'
import produce from 'immer'
import { atom } from 'jotai'
import buildLogger from '../../util/logger'
import { WithUrql } from '../state/useJotaiUrqlQuery'
import useJotaiBillySnackBar, { UseJotaiBillySnackBarProps, UseJotaiBillySnackBarResult } from './useJotaiBillySnackBar'

const logger = buildLogger('UseJotaiUrqlBillySnackBar')

export type UseJotaiUrqlBillySnackBarProps<T extends WithUrql> = Omit<
  UseJotaiBillySnackBarProps<T>,
  'isWorkingAtomSelector'
> & {
  document: TypedDocumentNode<any, any>
}

function useJotaiUrqlBillySnackBar<T extends WithUrql>(
  props: UseJotaiUrqlBillySnackBarProps<T>
): UseJotaiBillySnackBarResult {
  const { isWorkingAtomSelector, showSuccessAtom } = useMemo(() => {
    const opNode = props.document.definitions.find((definition) => definition.kind === 'OperationDefinition') as
      | OperationDefinitionNode
      | undefined
    const name = opNode?.name?.value || ''

    const showSuccessAtom = atom<boolean, boolean>(
      (get) => {
        return get(props.jotaiForm.atom).urqlSuccesses?.[name] || false
      },
      (get, set, update) => {
        const formData = get(props.jotaiForm.atom)
        const immerUpdate = produce(formData, (formDataDraft) => {
          if (!formDataDraft.urqlSuccesses) {
            formDataDraft.urqlSuccesses = {}
          }
          formDataDraft.urqlSuccesses[name] = update
        })
        set(props.jotaiForm.atom, immerUpdate)
      }
    )

    const isWorkingAtomSelector = (form: T): boolean | undefined => form.urqlIsFetching?.[name]

    return { isWorkingAtomSelector, showSuccessAtom }
  }, [props.document.definitions, props.jotaiForm.atom])

  return useJotaiBillySnackBar({ ...props, isWorkingAtomSelector, showSuccessAtom })
}

export default useJotaiUrqlBillySnackBar

export function JotaiUrqlBillySnackBar<T extends WithUrql>(props: UseJotaiUrqlBillySnackBarProps<T>): JSX.Element {
  return useJotaiUrqlBillySnackBar(props).jsxElement
}
