import { useCallback, useEffect } from 'react'
import { useSnackbarHandler } from '../../components/SnackbarHandler/SnackbarHandler'
import { JotaiForm, WithYup } from '../../components/state/useJotaiForm'
import { WithUrql } from '../../components/state/useJotaiUrqlQuery'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'

export function useDryRunError<T extends WithUrql & WithYup>(
  { jotaiForm }: { jotaiForm: JotaiForm<T> },
  option: { silent?: boolean } = { silent: false }
) {
  const { silent } = option || {}
  const { clearDryRun } = useDryRunActions()
  const { yupErrors, urqlErrors } = jotaiForm.useSelect(
    useCallback((form) => {
      return { yupErrors: form.yupErrors, urqlErrors: form.urqlErrors }
    }, [])
  )
  const snackbarHandler = useSnackbarHandler()

  useEffect(() => {
    if ((yupErrors && Object.keys(yupErrors).length > 0) || (urqlErrors && Object.keys(urqlErrors).length > 0)) {
      console.log(yupErrors)
      console.log(urqlErrors)
      clearDryRun()
      if (!silent) {
        snackbarHandler.pushAlert('The form has errors. Please fix them to recalculate totals.', 'error')
      }
    }
  }, [yupErrors, urqlErrors, snackbarHandler, silent, clearDryRun])
}
