import { datadogLogs } from '@datadog/browser-logs'
import { GraphQLError } from 'graphql'
import _ from 'lodash'
import { ErrorOption } from 'react-hook-form'
import { CombinedError } from 'urql'

export function handleServerFieldValidation(
  graphQLErrors: GraphQLError[] | undefined,
  setError: (name, error: ErrorOption) => void
): void {
  const [fieldErrors, genericErrors] = _.partition(graphQLErrors, (gqlError) => {
    return gqlError.extensions?.fieldId && gqlError.message
  })
  graphQLErrors?.splice(0, graphQLErrors?.length, ...genericErrors)

  fieldErrors.forEach((fieldError) => {
    const fieldId = fieldError.extensions?.fieldId
    const message = fieldError.message
    setError(fieldId, { message })
  })
}

export interface ExtrapolatedCombinedErrorMessages {
  readonly messages: ReadonlyArray<string>
  readonly type: 'error' | 'warning'
}

export function isTypeOfCombinedError(error: unknown): error is CombinedError {
  if (typeof error !== 'object') {
    return false
  }

  if (!error) {
    return false
  }

  return 'name' in error && 'message' in error && 'graphQLErrors' in error
}

export function extrapolateCombinedErrorMessages(error?: Readonly<CombinedError>): ExtrapolatedCombinedErrorMessages {
  const [serverErrors, gqlErrors] = _.partition(error?.graphQLErrors, (gqlError) => {
    return gqlError.name === 'GraphQLError'
  })
  const isNetworkError = error?.networkError
  const isAuthError = error?.graphQLErrors.some((e) => e.extensions?.errorId === 'unauthorized')
  const isServerError = error?.response?.status >= 500

  let messages: readonly string[] = []
  const type = !isAuthError && gqlErrors.length ? 'error' : 'warning'

  if (!isAuthError && gqlErrors.length) messages = gqlErrors.map((gqlErr) => gqlErr.message)
  if (isServerError) messages = ['There was an error on the server.']
  if (isNetworkError && !isAuthError && !isServerError)
    if (error?.response?.status === 403) {
      messages = ['This operation is not permitted']
    } else {
      messages = ["Looks like there's a network problem. Check your connection and try again."]
    }
  if (!isAuthError && serverErrors.length > 0)
    messages = [serverErrors[0].message || 'Something went wrong on the server.']

  datadogLogs.logger.error(messages.join(' '), { error })
  return { messages, type }
}
