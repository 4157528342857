import { TimeZoneStr } from '../../util/datetime/datetimeTypes'
import { getTimeZone } from '../../util/datetime/luxon/dateUtil'
import { useUserTenantSession } from './UserTenantSessionContext'

export function useTenantTimeZone(): TimeZoneStr {
  const userTenantSession = useUserTenantSession()
  const appTimeZone = userTenantSession.setting.defaultTimeZone ?? getTimeZone()

  return appTimeZone
}
