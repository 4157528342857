import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro'
import { DataGridProProps } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import BillyDataGridPro from '../../../components/table/billyDataGridPro'
import { CrmContactFragment, CrmContactSyncAction } from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { toTitleCase } from '../../../util/string'
import TableEmptyContent from '../../../components/table/tableEmptyContent'
const logger = buildLogger('ImportContactTable')

export type ImportContactTableProps = {
  contacts: readonly CrmContactFragment[]
  onSelect?: DataGridProProps['onRowSelectionModelChange']
}

export function ImportContactTable({ contacts, onSelect }: ImportContactTableProps): JSX.Element {
  const modifiedContacts = useMemo(
    () =>
      [...contacts]
        .map((contact, index) => ({
          ...contact,
          action: contact.action ?? CrmContactSyncAction.Insert,
          id: contact.id ?? index,
          fullName: `${contact.firstName} ${contact.lastName}`.trim(),
        }))
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .sort((a, b) => -a.action.localeCompare(b.action)),
    [contacts]
  )
  const contactColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'fullName',
        minWidth: 80,
        flex: 4,
        align: 'left',
        headerAlign: 'left',
      },
      {
        headerName: 'Email',
        field: 'email',
        minWidth: 80,
        flex: 4,
        align: 'left',
        hideSortIcons: true,
      },
      {
        headerName: 'Contact Record',
        field: 'action',
        minWidth: 80,
        flex: 4,
        align: 'left',
        hideSortIcons: true,
        valueFormatter: (params: GridValueFormatterParams<CrmContactFragment['action']>) => {
          function formatSalesforceAction(action: CrmContactSyncAction) {
            switch (action) {
              case CrmContactSyncAction.Insert:
                return 'Add'
              case CrmContactSyncAction.Update:
                return 'Replace'
              default:
                return toTitleCase(action)
            }
          }
          return params.value ? formatSalesforceAction(params.value) : ''
        },
      },
    ],
    []
  )

  return modifiedContacts?.length ? (
    <BillyDataGridPro
      rows={modifiedContacts}
      columns={contactColumns}
      onRowSelectionModelChange={onSelect}
      checkboxSelection
      sx={{
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
          display: 'none',
        },
      }}
    />
  ) : (
    <TableEmptyContent message="No contact to import." />
  )
}
