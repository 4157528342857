import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { Grid, Typography } from '@mui/material'
import { useCallback } from 'react'
import { CommonOrderFormPageState } from '../../CommonOrderFormPageState'
import { ImportCRMContactLinkInRow } from '../../ImportCRMContactLinkInRow'
import { useOrderPageFormStyles } from '../../hooks/useOrderPageFormStyles'
import { AccountESAutocomplete } from './AccountAutocomplete'
import { BillToAutocomplete } from './BillToAutocomplete'
import { FieldProps } from './BillyOrderDetailsCard'
import { CRMOpportunityAutocomplete, CancelRestructureCRMAutocomplete } from './CRMOpportunityAutocomplete'
import { ShippingContactAutocomplete } from './ShippingContactAutocomplete'
import { ResellerESAutocomplete } from '@/pageComponents/orders/EditOrderPage/cards/BillyOrderDetailsCard/ResellerAutocomplete'
import { OrderType } from '@/generated/graphql'

export function DetailsGridLayout({ fields }: { fields: FieldProps[] }) {
  const { classes } = useOrderPageFormStyles()
  return fields?.length ? (
    <Grid container direction="row" spacing={2} className={classes.formRow}>
      {fields.map(({ Field, hidden }) => (!!Field && !hidden ? <Field key={Field.name} /> : <></>))}
    </Grid>
  ) : (
    <></>
  )
}
const customerDetailsRow = [
  {
    Field: AccountESAutocomplete,
  },
  {
    Field: ResellerESAutocomplete,
  },
]

const customerDetailsWithOpptyRow = [
  {
    Field: AccountESAutocomplete,
  },
  {
    Field: CRMOpportunityAutocomplete,
  },
  {
    Field: ResellerESAutocomplete,
  },
]

const customerDetailsWithOpptyRowForCancelAndRestructure = [
  {
    Field: AccountESAutocomplete,
  },
  {
    Field: CancelRestructureCRMAutocomplete,
  },
  {
    Field: ResellerESAutocomplete,
  },
]

const contactDetailsRow = [
  {
    Field: ShippingContactAutocomplete,
  },
  {
    Field: BillToAutocomplete,
  },
]

export function OrderCustomerDetailsSection() {
  const { classes } = useOrderPageFormStyles()
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const compositeOrderId = jotaiForm.useSelect(useCallback((form) => form.orderDetail.compositeOrderId, []))
  const orderType = jotaiForm.useSelect(useCallback((form) => form.orderDetail.orderType, []))

  const getCrmField = () => {
    if (orderType === OrderType.Restructure) {
      return customerDetailsWithOpptyRowForCancelAndRestructure
    } else if (compositeOrderId) {
      return customerDetailsRow
    }
    return customerDetailsWithOpptyRow
  }

  return (
    <>
      <Typography component="h6" className={classes.rowLabel}>
        Customer Details
      </Typography>
      <DetailsGridLayout fields={getCrmField()} />
      <DetailsGridLayout fields={contactDetailsRow} />
      <ImportCRMContactLinkInRow />
    </>
  )
}
const AmendCancelCustomerDetailsRow = [
  {
    Field: ShippingContactAutocomplete,
  },
  {
    Field: BillToAutocomplete,
  },
]

const AmendCancelCustomerDetailsWithOpptyRow = [
  {
    Field: ShippingContactAutocomplete,
  },
  {
    Field: BillToAutocomplete,
  },
  {
    Field: CRMOpportunityAutocomplete,
  },
]

export function AmendCancelCustomerDetailsSection() {
  const { classes } = useOrderPageFormStyles()
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const compositeOrderId = jotaiForm.useSelect(useCallback((form) => form.orderDetail.compositeOrderId, []))

  return (
    <>
      <Typography component="h6" className={classes.rowLabel}>
        Customer Details
      </Typography>
      <DetailsGridLayout
        fields={compositeOrderId ? AmendCancelCustomerDetailsRow : AmendCancelCustomerDetailsWithOpptyRow}
      />
      <ImportCRMContactLinkInRow />
    </>
  )
}
