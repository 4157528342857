import React from 'react'
import buildLogger from '../../util/logger'
import { useSnackbarHandler } from '../SnackbarHandler/SnackbarHandler'
import { SnackbarTypes } from '../snackBar/billySnackBar'

const logger = buildLogger('BillyErrorSnackbarDisplay')

export interface BillyErrorSnackbarDisplayProps {
  messages?: readonly string[]
  type: SnackbarTypes
}

export function BillyErrorSnackbarDisplay({ messages, type }: BillyErrorSnackbarDisplayProps): JSX.Element {
  const snackbarHandler = useSnackbarHandler()

  React.useEffect(() => {
    messages?.forEach((message) => snackbarHandler.pushAlert(message, type))
  }, [messages, type, snackbarHandler])

  return <></>
}
