import { useCallback } from 'react'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import CancellationOrderPageDrawer from '../CancellationOrderPageDrawer'
import { CancellationOrderPageState } from './CancellationOrderPage'

export function CancellationRightDrawerWithData() {
  const jotaiForm = useJotaiFormContext<CancellationOrderPageState>()
  const { orderDetail } = jotaiForm.useSelect(
    useCallback((form: CancellationOrderPageState) => {
      const orderDetail = form.orderDetail

      return {
        orderDetail: {
          subscriptionId: orderDetail.subscriptionId,
          currency: orderDetail.currency,
          totalListAmount: orderDetail.totalListAmount,
          totalAmount: orderDetail.totalAmount,
          orderMetrics: orderDetail.orderMetrics,
        },
      }
    }, [])
  )
  return <CancellationOrderPageDrawer orderDetail={orderDetail} />
}
